import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse } from 'src/app/models/api-response';
import { storageApiKey, authModel, apiAuthModel, authStorage, authLocalStorageModel } from 'src/app/models/auth';
import { Authentication, AuthenticationData } from 'src/app/models/authentication';
import { environmentDashboard, environment, environmentAVPInruilmodule } from 'src/environments/environment';
import * as moment from 'moment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private api_base: string = environment.apiUrl;
  private RequestHeaders = new HttpHeaders();
  configType: any;
  constructor(
    private http: HttpClient,
  ) { }

  getToken(username: string, token: string, type: string, device_token?: string) {
    return new Promise((resolve, reject) => {
      // Check if we need to use the api-key, or create access token using the given cridentials
      if (type == 'apiKey') {
        this.configType = 'dashboard';
        // Get username and token from view
        const reqData = {
          grant_type: 'password',
          username: String(username),
          password: token,
          level: 'key',
          device_token: device_token
        }
        this.createAccessToken(reqData).subscribe(data => {
          // Save username in storage
          localStorage.setItem('username', username);
          resolve(data);
        }, error => {
          reject(error);
        })
      } else if (type == 'token') {
        this.configType = 'inruilmodule';
        // Get username and token from view
        const reqData = {
          grant_type: 'password',
          username: String(username),
          password: token,
          level: 'application'
        }
        this.createAccessToken(reqData).subscribe(data => {
          // Save username in storage
          localStorage.setItem('username', username);
          resolve(data);
        }, error => {
          reject(error);
        })
      }
    });
  }

  public getAuthData(): AuthenticationData {
    const authData = JSON.parse(localStorage.getItem('auth')!)
    return authData;
  }

  checkApiKeyValidity() {
    const apiKey: storageApiKey = JSON.parse(localStorage.getItem('apiKey') as any);
    if (apiKey) {
      // FIX: Use moment instead of Data for support on mobile browsers
      let expireDate = moment(apiKey.api_key_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
      const now = new Date()
      if (expireDate > now) {
        return true;
      } else {
        return false;
      }
    } else {
      // No API key set.
      return false;
    }
  }

  checkRefreshTokenValidity() {
    const auth: authLocalStorageModel = JSON.parse(localStorage.getItem('auth') as any);
    if (auth == null) {
      return false;
    }
    // FIX: Use moment instead of Data for support on mobile browsers
    let expireDate = moment(auth.refresh_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
    expireDate.setMinutes(expireDate.getMinutes() - 5);
    const now = new Date();
    if (expireDate > now) {
      return true;
    } else {
      return false;
    }
  }

  checkAccessTokenValidity() {
    const auth: authLocalStorageModel = JSON.parse(localStorage.getItem('auth') as any);

    if (auth == null) {
      return false;
    }

    // FIX: Use moment instead of Date for support on mobile browsers
    let expireDate = moment(auth.access_token_expires, 'YYYY-MM-DD HH:mm:ss').toDate();
    expireDate.setMinutes(expireDate.getMinutes() - 5);

    const now = new Date();
    if (expireDate > now) {
      return true;
    }

    return false;
  }

  generateDeviceToken(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // GET
  getAuthUserInformation() {
    return this.http.get<apiResponse>(`${this.api_base}/api/v1/authentication`,
      { headers: this.RequestHeaders });
  }

  // PUT
  updateAuthUserInformation(companyId: number, employeeId: number) {
    return this.http.put<apiResponse>(`${this.api_base}/api/v1/authentication`, { companyId: companyId, employeeId: employeeId },
      { headers: this.RequestHeaders });
  }

  // POST
  createAccessToken(data: Authentication) {
    // We need custom headers when using this endpoint.
    let RequestHeaders = new HttpHeaders();
    RequestHeaders = RequestHeaders.set('Accept', 'application/json');
    RequestHeaders = RequestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    RequestHeaders = RequestHeaders.set('Authorization', this.encodeBasicHeader());

    let body = new HttpParams();
    Object.entries(data).forEach(([key, value]) => {
      body = body.set(key, value);
    })

    return this.http.post<apiResponse>(`${this.api_base}/api/v1/oauth/token`, body,
      { headers: RequestHeaders }).pipe(map((user: any) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        const authModel = authStorage.createapiAuth(user, true, String(data.device_token), String(data.username), String(data.level));
        localStorage.setItem('auth', authModel);
      }));
  }

  revokeAccessToken(token_type_hint: string, token: string) {
    this.RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<apiResponse>(`${this.api_base}/api/v1/oauth/token`, { token_type_hint: token_type_hint, token: token },
      { headers: this.RequestHeaders });
  }

  loginWithRefreshToken(refreshToken: string): Observable<authModel> {
    const oldKey: authModel = JSON.parse(localStorage.getItem('auth') as any);
    const username = localStorage.getItem('username') as any;
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .append('refresh_token', refreshToken)
      .append('username', username)

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .append('Authorization', this.encodeBasicHeader())
      .append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post<apiAuthModel>(`${environment.apiUrl}/api/v1/oauth/token/`, body, { headers })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        const authModel = authStorage.createapiAuth(user, oldKey.save_login, String(oldKey.device_token), oldKey.username, oldKey.level)
        localStorage.setItem('auth', authModel);
        return JSON.parse(authModel);
      }));
  }

  loginWithApiKey(apiKey: string, username: string, deviceToken: string): Observable<authModel> {
    const oldKey: authModel = JSON.parse(localStorage.getItem('auth') as any);
    const body = new HttpParams()
      .set('grant_type', 'password')
      .append('username', username)
      .append('password', apiKey)
      .append('device_token', deviceToken)
      .append('level', 'key');

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .append('Authorization', this.encodeBasicHeader())
      .append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post<apiAuthModel>(`${environment.apiUrl}/api/v1/oauth/token/`, body, { headers })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        const authModel = authStorage.createapiAuth(user, oldKey.save_login, String(oldKey.device_token), oldKey.username, oldKey.level);
        localStorage.setItem('auth', authModel);
        return JSON.parse(authModel);
      }));
  }

  requestApiKey(deviceToken: string) {
    const body = new HttpParams()
      .set('device_token', deviceToken);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Authorization', 'Bearer ' + this.getAuth().access_token)

    return this.http.post<any>(`${environment.apiUrl}/api/v1/authentication/key/`, body, { headers })
      .pipe(map(api => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('apiKey', authStorage.createapiKey(api.data));
        return api;
      }));
  }

  getAuth(): authModel {
    return JSON.parse(localStorage.getItem('auth') as any);
  }

  encodeBasicHeader() {
    if (this.configType == 'inruilmodule') {
      return ('Basic ' + btoa(environmentAVPInruilmodule.application + ':' + environmentAVPInruilmodule.password));
    } else {
      return ('Basic ' + btoa(environmentDashboard.application + ':' + environmentDashboard.password));
    }
  }

  logout() {
    // remove user from local storage to log user out
    // localStorage.clear();
    window.location.href = "https://autotaxatiepartners.nl";
  }
}
