<div class="container">
    <div class="header" fxLayout="row" fxLayoutAlign="center center">
        <img height="100" class="header-logo" src="../../../../assets/images/logo.jpg">
    </div>

    <div class="message" fxLayout="row" fxLayoutAlign="center center">
        <h1>Er is een fout opgetreden</h1>
    </div>
    <div class="" fxLayout="row" fxLayoutAlign="center center">
        <p>De authenticatie code is niet meer geldig. Probeer het later opniew.</p>
    </div>

    <div class="message" fxLayout="row" fxLayoutAlign="center center">
        <button onclick="history.back()" mat-raised-button color="primary">Ga terug</button>
    </div>
</div>