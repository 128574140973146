import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Damage } from 'src/app/components/damage/damage.component';
import { VehicleDescription } from 'src/app/models/vehicle-description';
import * as moment from 'moment';

type Tracker = {
  send: (
    hitType: string,
    category: string,
    action: string,
    label?: string
  ) => void;
};

declare const ga: {
  (...args: any[]): () => void;
  getAll: () => Tracker[];
};

const has = Object.prototype.hasOwnProperty;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public convertFormFields(fields: FormGroup | undefined | null) {
    if (!fields) {
      return null;
    }

    const filteredFields: any = {};
    const fieldControls = fields.controls;
    for (const field in fieldControls) {
      const control = fieldControls[field];

      let fieldValue = control.value;

      // Convert boolean fields
      if (fieldValue === "false") {
        fieldValue = false;
      } else if (fieldValue === "true") {
        fieldValue = true;
      }

      // Handle special case for 'vs_mileage' field
      if (field === 'vs_mileage') {
        fieldValue = String(fieldValue).replace(/[.,]/g, '');
      }
      filteredFields[field] = fieldValue;
    }

    return filteredFields;
  }

  public filterEmptyFormFields(fields: FormGroup | undefined | null) {
    if (!fields) {
      return null;
    }

    const filteredFields: any = {};
    const fieldControls = fields.controls;
    for (const field in fieldControls) {
      const control = fieldControls[field];

      if (control.value !== null && field !== 'images_count' && field !== 'vs_damages') {
        let fieldValue = control.value;

        // Convert boolean fields
        if (fieldValue === "false") {
          fieldValue = false;
        } else if (fieldValue === "true") {
          fieldValue = true;
        }

        if (field === 'vs_atlCode') {
          if (fieldValue === 'null' || fieldValue === '') {
            fieldValue = null;
          }
        }

        // Handle special case for 'vs_mileage' field
        if (field === 'vs_mileage') {
          fieldValue = String(fieldValue).replace(/[.,]/g, '');
        }

        // Check if the field is one of the specified fields
        if (field === 'va_defaultOptions' || field === 'va_optionalOptions' || field === 'va_packages') {
          // Check if fieldValue is an array
          if (Array.isArray(fieldValue)) {
            fieldValue = fieldValue.map(item => {
              // If item is an object with .id property, convert it to .id, otherwise keep it as is
              if (item && item.id) {
                return item.id;
              } else {
                return item;
              }
            });
          } // If fieldValue is not an array, no change is needed
        }
        filteredFields[field] = fieldValue;
      }
    }

    return filteredFields;
  }

  public filterChangedFormFields(fields: FormGroup | undefined | null) {
    if (!fields) {
      return null;
    }

    const filteredFields: any = {};
    const fieldControls = fields.controls;

    for (const field in fieldControls) {
      const control = fieldControls[field];

      if (control.value !== null && field !== 'images_count' && control.dirty) {
        let fieldValue = control.value;

        // Convert boolean fields
        if (fieldValue === "false") {
          fieldValue = false;
        } else if (fieldValue === "true") {
          fieldValue = true;
        }

        if (field === 'vs_atlCode') {
          if (fieldValue === 'null' || fieldValue === '') {
            fieldValue = null;
          }
        }

        // Handle special case for 'vs_mileage' field
        if (field === 'vs_mileage') {
          fieldValue = String(fieldValue).replace(/[.,]/g, '');
        }

        filteredFields[field] = fieldValue;
      }
    }

    return filteredFields;
  }

  public getSingleFieldFromSpec(pageDetails: null | VehicleDescription, fieldName: string) {
    if (pageDetails!.fields != null) {
      for (let field of pageDetails!.fields) {
        if (field.Field.name == fieldName) {
          return field;
        }
      }
    }
    return null;
  }

  public getSingleFieldFromDamage(damage: any | Damage, fieldName: string) {
    if (damage != null) {
      for (let field of damage) {
        if (field.Field.name == fieldName) {
          return field;
        }
      }
    }
    return null;
  }

  public getVehiclePreviewImage(pageDetails: null | VehicleDescription) {
    const modelYearImagesField = this.getSingleFieldFromSpec(pageDetails, 'vs_modelYearImages');
    if (modelYearImagesField != null) {
      // Return found image
      if (modelYearImagesField.value && modelYearImagesField.value.length > 0) {
        return modelYearImagesField.value[0].image;
      }
    }
    // Return placeholder image
    return '/assets/images/car-placeholder.jpg';
  }

  public getVehiclePreviewImages(pageDetails: null | VehicleDescription) {
    const modelYearImagesField = this.getSingleFieldFromSpec(pageDetails, 'vs_modelYearImages');
    if (modelYearImagesField != null) {
      // Return found image
      if (modelYearImagesField.value && modelYearImagesField.value.length > 0) {
        return modelYearImagesField.value
      }
    }
    // Return placeholder image
    return [{ image: '/assets/images/car-placeholder.jpg', placeholder: '/assets/images/car-placeholder.jpg' }];
  }

  public getVehicleDamageImages(pageDetails: null | VehicleDescription) {
    const damageImagesField = this.getSingleFieldFromSpec(pageDetails, 'vs_damageImages');
    if (damageImagesField != null) {
      // Return found image
      if (damageImagesField.value && damageImagesField.value.length > 0) {
        return damageImagesField.value
      }
    }
    // Return empty array
    return [];
  }

  public logAnalyticsPageView(url: string) {
    if (typeof ga !== "undefined") {
      ga(() => {
        if (has.call(window, "ga")) {
          ga("set", "page", url);
          ga("send", "pageview");
        } else {
          console.log("Analytics not loaded");
        }
      });
    } else {
      console.log("Analytics not loaded");
    }
  }

  public logAnalyticsCustomEvent(eventCategory: string, eventAction: string, eventLabel?: string) {
    if (typeof ga !== "undefined") {
      ga(() => {
        if (has.call(window, "ga")) {
          ga("send", "event", eventCategory, eventAction, eventLabel);
        } else {
          console.log("Analytics not loaded");
        }
      });
    } else {
      console.log("Analytics not loaded");
    }
  }

  public calculateMileageDelivery(dateFirstAdmission: Date | string, dateDelivery: Date | string, mileage: number, dateMileage: Date | string | null = null): number {
    // Set dates
    const today: Date = (dateMileage === null || typeof dateMileage === 'string') ? (dateMileage ? new Date(dateMileage) : new Date()) : dateMileage;
    dateDelivery = (typeof dateDelivery === 'string') ? new Date(dateDelivery) : dateDelivery;

    // Set date-first-admission
    dateFirstAdmission = (typeof dateFirstAdmission === 'string') ? new Date(dateFirstAdmission) : dateFirstAdmission;

    // Get differences (days) by date-first-admission
    const difference1: number = Math.floor((today.getTime() - dateFirstAdmission.getTime()) / (1000 * 60 * 60 * 24));
    const difference2: number = Math.floor((dateDelivery.getTime() - dateFirstAdmission.getTime()) / (1000 * 60 * 60 * 24));

    // Calculate mileage by differences
    let output: number = mileage;
    if (difference1 > 0 && difference2 > 0 && mileage > 0) {
      output = Math.round((difference2 / difference1) * mileage);
    }

    // Return
    return output;
  }
}
