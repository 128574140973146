import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay-loading',
  templateUrl: './overlay-loading.component.html',
  styleUrls: ['./overlay-loading.component.scss']
})
export class OverlayLoadingComponent implements OnInit {
  @Input() loading: boolean = true; // Control whether the overlay is shown
  @Input() textArray: string[] = []; // Customizable text array input
  animatedText: string = '';
  private defaultTextArray: string[] = ['Loading', 'Almost There', 'Just a Moment', 'Preparing...'];
  private textIndex: number = 0;

  ngOnInit(): void {
    if (this.textArray.length === 0) {
      this.textArray = this.defaultTextArray; // Use default texts if none provided
    }
    this.animatedText = this.textArray[this.textIndex]; // Set initial text immediately
    this.startTextAnimation();
  }

  startTextAnimation(): void {
    setInterval(() => {
      this.textIndex = (this.textIndex + 1) % this.textArray.length;
      this.animatedText = this.textArray[this.textIndex];
    }, 2000); // Change text every 1.5 seconds
  }
}
