<title>Auto Verkoop Partner Invoer</title>

<app-overlay-loading [loading]="loading" [textArray]="loadingTexts"></app-overlay-loading>

<!-- <div class="stepper-container" *ngIf="apiError == false">
  <div class="stepper-nav">
    <button mat-icon-button [disabled]="loading" *ngIf="currentStep <= 3 && currentStep > 1" (click)="previousStep()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>

  <div class="stepper-content" *ngIf="currentStep == 1">
    <div class="stepper-indicator">
      <span>1/4</span>
    </div>
    <span class="stepper-title">Auto gegevens</span>
  </div>

  <div class="stepper-content" *ngIf="currentStep == 2">
    <div class="stepper-indicator">
      <span>2/4</span>
    </div>
    <span class="stepper-title">Uw gegevens</span>
  </div>

  <div class="stepper-content" *ngIf="currentStep == 3">
    <div class="stepper-indicator">
      <span>3/4</span>
    </div>
    <span class="stepper-title">Foto's</span>
  </div>

  <div class="stepper-content" *ngIf="currentStep == 4">
    <div class="stepper-indicator">
      <span>3/4</span>
    </div>
    <span class="stepper-title">Samenvatting</span>
  </div>

  <div class="stepper-nav">
    <button mat-icon-button [disabled]="loading" *ngIf="currentStep <= 3" (click)="nextStep()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div> -->

<!-- <div class="header-footer" *ngIf="apiError == false">
  <app-progress-bar [loading]="loading" [value]="progressPercentage"></app-progress-bar>
</div>
 -->
<div *ngIf="validationError" class="main-container">
  <mat-card class="example-card">
    <mat-card-header fxLayoutAlign="center center">
      <img class="ico" src="../../../../assets/images/notify-icon.png">
      <mat-card-title>Dit voertuig kan niet ingevoerd worden</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center">
      <ul *ngFor="let message of errorMessages">
        <li *ngIf="message.descriptionNl">{{message.descriptionNl}}</li>
        <li *ngIf="!message.descriptionNl">{{message}}</li>
      </ul>
    </mat-card-content>
  </mat-card>

  <div style="padding-top: 50px;" fxLayout="row" fxLayoutAlign="center center">
    <button (click)="cancelRequest()" mat-raised-button color="primary">Ga terug</button>
  </div>
</div>

<div *ngIf="apiError" class="main-container">
  <mat-card class="example-card">
    <mat-card-header fxLayoutAlign="center center">
      <img class="ico" src="../../../../assets/images/notify-icon.png">
      <mat-card-title>Er is een fout opgetreden</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center">
      <div>
        <p>Helaas is het niet (meer) mogelijk om deze aanvraag af te ronden, omdat: </p>
        <ul>
          <li>De aanvraag al is afgerond</li>
          <li>U niet gemachtigd bent om deze aanvraag te doen</li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <div style="padding-top: 50px;" fxLayout="row" fxLayoutAlign="center center">
    <button (click)="cancelRequest()" mat-raised-button color="primary">Ga terug</button>
  </div>
</div>

<div class="main-content-container" *ngIf="!validationError && !apiError">
  <div fxLayout.xs="column" [@fade]="!loading" fxLayout="row" fxLayoutGap="5%">
    <!-- First step -->
    <div class="container" *ngIf="currentStep == 1" fxFlex="50">
      <h2>Vul de gegevens van jouw auto in</h2>
      <p>Zorg dat de omschrijving juist en volledig is. Kloppen al jouw
        gegevens? Dan is het bod dat je van ons krijgt gegarandeerd.</p>

      <app-skeleton-loader *ngIf="skeletonLoading"></app-skeleton-loader>

      <!-- Generate dynamic fields -->
      <ng-container *ngIf="pageDetails">
        <div style="padding-bottom: 20px" *ngIf="pageDetails && showResumeLaterText == false" fxShow.xs fxHide.gt-xs>
          <div>
            <!-- Flexbox container for the image and details -->
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">

              <!-- Image section -->
              <div fxFlex="100" fxLayoutAlign="center">
                <img width="100%" [src]="carPreviewImage">
              </div>

              <!-- Details section -->
              <div fxFlex="100">
                <table class="custom-table">
                  <tbody>
                    <tr>
                      <th class="custom-th">Kenteken</th>
                      <td class="custom-td">
                        <b><app-form [show]="['v_registration']" [fields]="pageDetails.fields"></app-form></b>
                      </td>
                    </tr>
                    <tr>
                      <th class="custom-th">Merk</th>
                      <td class="custom-td custom-uppercase">
                        <b><app-form [show]="['v_make']" [fields]="pageDetails.fields"></app-form></b>
                      </td>
                    </tr>
                    <tr>
                      <th class="custom-th">Model</th>
                      <td class="custom-td custom-uppercase">
                        <b><app-form [show]="['v_modelSpecific']" [fields]="pageDetails.fields"></app-form></b>
                      </td>
                    </tr>
                    <tr>
                      <th class="custom-th">Bouwjaar</th>
                      <td class="custom-td">
                        <b><app-form [show]="['v_year']" [fields]="pageDetails.fields"></app-form></b>
                      </td>
                    </tr>
                    <tr>
                      <th class="custom-th">Brandstof</th>
                      <td class="custom-td">
                        <b><app-form [show]="['vs_fuelTypeId']" [fields]="pageDetails.fields"></app-form></b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <section class="form-section">
          <form *ngIf="form" [formGroup]="form">
            <mat-form-field appearance="outline">
              <mat-label>Tellerstand</mat-label>
              <input formControlName="vs_mileage" min="0" pattern="[0-9]*" type="number" matInput
                placeholder="Tellerstand">
              <span matSuffix>km</span>
              <mat-error *ngIf="(form!.controls['vs_mileage'].dirty || form!.controls['vs_mileage'].touched)">
                Tellerstand is een verplicht veld</mat-error>
            </mat-form-field>
          </form>
        </section>

        <section class="form-section">
          <app-form (formChange)="formChange($event)" [show]="['vs_atlCode']" [fields]="pageDetails.fields"></app-form>
        </section>

        <mat-label>Opties</mat-label>
        <section class="checkbox-grid">
          <mat-checkbox *ngFor="let option of options" [checked]="option.selected" id="{{option.id}}"
            (change)="optionChanged($event.checked, option.id)">{{option.description}}</mat-checkbox>
        </section>

        <form *ngIf="form" [formGroup]="form">
          <section class="form-section" *ngIf="form && form.get('vs_vatMarginTypeId_question')">
            <div><mat-label>Staat de auto op naam van uw bedrijf?*</mat-label></div>
            <mat-radio-group (ngModelChange)="updateVatMarginType($event)" formControlName="vs_vatMarginTypeId_question"
              fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="15%" [value]="1">Ja</mat-radio-button>
              <mat-radio-button fxFlex="15%" [value]="2">Nee</mat-radio-button>
            </mat-radio-group>
            <mat-error
              *ngIf="(form!.controls['vs_vatMarginTypeId_question'].invalid && form!.controls['vs_vatMarginTypeId_question'].touched)">
              Staat de auto op naam van uw bedrijf is een verplicht veld
            </mat-error>
          </section>

          <section class="form-section"
            *ngIf="form && form.get('vs_vatMarginTypeId') && form.controls['vs_vatMarginTypeId_question'].value == '1'">
            <div><mat-label>Bent u met uw bedrijf BTW plichtig?*</mat-label></div>
            <mat-radio-group formControlName="vs_vatMarginTypeId" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="15%" [value]="1">Ja</mat-radio-button>
              <mat-radio-button fxFlex="15%" [value]="2">Nee</mat-radio-button>
            </mat-radio-group>
            <mat-error
              *ngIf="(form!.controls['vs_vatMarginTypeId'].invalid && form!.controls['vs_vatMarginTypeId'].touched)">
              Bent u met uw bedrijf BTW plichtig is een verplicht veld
            </mat-error>
          </section>

          <section class="form-section" *ngIf="form && form.get('vs_conditionBodyTypeId')">
            <div><mat-label>Conditie buitenkant*</mat-label></div>
            <mat-radio-group formControlName="vs_conditionBodyTypeId" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="30%" [value]="3">Goede conditie</mat-radio-button>
              <mat-radio-button fxFlex="30%" [value]="2">Redelijke conditie</mat-radio-button>
              <mat-radio-button fxFlex="30%" [value]="1">Slechte conditie</mat-radio-button>
            </mat-radio-group>
            <mat-error
              *ngIf="(form!.controls['vs_conditionBodyTypeId'].invalid && form!.controls['vs_conditionBodyTypeId'].touched)">
              Conditie buitenkant is een verplicht veld
            </mat-error>
          </section>

          <section class="form-section" *ngIf="form && form.get('vs_conditionTechnicalTypeId')">
            <div><mat-label>Conditie technisch*</mat-label></div>
            <mat-radio-group formControlName="vs_conditionTechnicalTypeId" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="30%" [value]="3">Goede conditie</mat-radio-button>
              <mat-radio-button fxFlex="30%" [value]="2">Redelijke conditie</mat-radio-button>
              <mat-radio-button fxFlex="30%" [value]="1">Slechte conditie</mat-radio-button>
            </mat-radio-group>
            <mat-error
              *ngIf="(form!.controls['vs_conditionTechnicalTypeId'].invalid && form!.controls['vs_conditionTechnicalTypeId'].touched)">
              Conditie technisch is een verplicht veld
            </mat-error>
          </section>

          <section class="form-section" *ngIf="form && form.get('vs_smoked')">
            <div><mat-label>Is er gerookt in de auto?*</mat-label></div>
            <mat-radio-group formControlName="vs_smoked" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="15%" [value]="true">Ja</mat-radio-button>
              <mat-radio-button fxFlex="15%" [value]="false">Nee</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="(form!.controls['vs_smoked'].invalid && form!.controls['vs_smoked'].touched)">
              Dit is een verplicht veld
            </mat-error>
          </section>

          <section *ngIf="form && form.get('vs_pets')">
            <div><mat-label>Zijn er huisdieren vervoerd?*</mat-label></div>
            <mat-radio-group formControlName="vs_pets" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="15%" [value]="true">Ja</mat-radio-button>
              <mat-radio-button fxFlex="15%" [value]="false">Nee</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="(form!.controls['vs_pets'].invalid && form!.controls['vs_pets'].touched)">
              Dit is een verplicht veld
            </mat-error>
          </section>

          <div class="additional-comments" fxShow.xs fxHide.gt-xs>
            <app-form (formChange)="formChange($event)"
              [hint]="'Denk daarbij aan informatie over eventuele beschadigingen of extra opties'"
              [show]="['vs_commentVehicleInfoCustomer']" [fields]="pageDetails.fields"
              [appearance]="'outline'"></app-form>
          </div>
          <br><br>
        </form>
      </ng-container>
    </div>

    <!-- Second step -->
    <div class="container" *ngIf="currentStep == 2" fxFlex="50">
      <h2>Vul jouw persoonlijke gegevens in</h2>
      <p>Wij gaan vertrouwelijk met je gegevens om en gebruiken ze alleen voor deze aanvraag.</p>

      <ng-container *ngIf="pageDetails && pageDetails.fields">
        <section>
          <app-form [appearance]="'outline'" (formChange)="formChange($event)"
            [show]="['c_salutationTypeId', 'c_lastName', 'c_city']" [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form [appearance]="'outline'" [hint]="'Wij hebben uw email nodig om biedingen met u te communiceren.'"
            (formChange)="formChange($event)" [show]="['c_emailAddress']" [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form [appearance]="'outline'"
            [hint]="'Uw telefoonnummer kan makkelijk zijn als wij vragen hebben over uw auto. Dit veld is niet verplicht'"
            (formChange)="formChange($event)" [show]="['c_phone']" [fields]="pageDetails.fields"></app-form>
        </section>
      </ng-container>
      <br><br>
    </div>

    <!-- Thirth step -->
    <div class="container" *ngIf="currentStep == 3 && skeletonLoading == false && showResumeLaterText == false"
      fxFlex="100">
      <h2>Maak foto's van jouw auto en upload deze</h2>
      <a href="javascript:void(0)" (click)="resumeLater()">Of kies voor later toevoegen</a><br>
      <p>Een paar tips voor het beste resultaat: </p>
      <ul>
        <li>Maak de foto's overdag.</li>
        <li>Zorg dat de auto er schoon en netjes uitziet.</li>
        <li>Maak de foto's zoals op de plaatjes is aangegeven.</li>
        <li>Hoe meer foto's je toevoegt, hoe beter.</li>
      </ul>
      <!-- Rest of the content of this step is down below -->
    </div>

    <!-- Second step -->
    <div class="container" *ngIf="currentStep == 4" fxFlex="50">
      <h2>Controleren en versturen</h2>
      <p>Controleer onderstaande gegevens goed voordat je ze
        verstuurt. Heb je alles naar waarheid ingevuld? Dit is
        heel belangrijk. want hier baseren we ons bod op</p>
      <!-- Rest of the content of this step is down below -->
    </div>

    <div class="container" fxFlex="50" *ngIf="pageDetails  && showResumeLaterText == false" fxShow.gt-xs fxHide.xs>
      <div class="sell-window">
        <h2 class="sell-title"><b>Deze auto wil je verkopen</b></h2>
        <div fxLayout="row">
          <img class="sell-image" [src]="carPreviewImage">
          <div class="sell-table">
            <table>
              <tbody>
                <tr>
                  <td> Kenteken: <b>
                      <app-form [show]="['v_registration']" [fields]="pageDetails.fields"></app-form>
                    </b></td>
                </tr>
                <tr>
                  <td> Merk: <b><span style="text-transform: uppercase;">
                        <app-form [show]="['v_make']" [fields]="pageDetails.fields"></app-form>
                      </span></b></td>
                </tr>
                <tr>
                  <td> Model: <b><span style="text-transform: uppercase;">
                        <app-form [show]="['v_modelSpecific']" [fields]="pageDetails.fields"></app-form>
                      </span></b></td>
                </tr>
                <tr>
                  <td> Bouwjaar: <b>
                      <app-form [show]="['v_year']" [fields]="pageDetails.fields"></app-form>
                    </b></td>
                </tr>
                <tr>
                  <td> Brandstof: <b>
                      <app-form [show]="['vs_fuelTypeId']" [fields]="pageDetails.fields"></app-form>
                    </b></td>
                </tr>
              </tbody>
            </table>
            <a (click)="cancelRequest()" href="javascript:void(0)">Aanvraag annuleren</a>
          </div>
        </div>
      </div>

      <div *ngIf="currentStep == 1" class="additional-comments">
        <app-form (formChange)="formChange($event)"
          [hint]="'Denk daarbij aan informatie over eventuele beschadigingen of extra opties'"
          [show]="['vs_commentVehicleInfoCustomer']" [fields]="pageDetails.fields"></app-form>
      </div>
    </div>
  </div>

  <!--   File upload view  -->
  <div class="container" [@fade]="!loading" style="padding-top: 30px;"
    *ngIf="currentStep == 3  && showResumeLaterText == false">
    <section>
      <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="5%">
        <div fxFlex="30">
          <p class="image-title">Linksvoor (verplicht)</p>
          <img class="placeholder-image" [src]="images.front_left.current">
          <file-pond (onaddfile)="setPhotoType($event, 'front_left')" [options]="pondOptions"></file-pond>
        </div>
        <div fxFlex="30">
          <p class="image-title">Rechtsachter (verplicht)</p>
          <img class="placeholder-image" [src]="images.rear_right.current">
          <file-pond (onaddfile)="setPhotoType($event, 'rear_right')" [options]="pondOptions"></file-pond>
        </div>
        <div fxFlex="30">
          <p class="image-title">Interieur (verplicht)</p>
          <img class="placeholder-image" [src]="images.interior.current">
          <file-pond (onaddfile)="setPhotoType($event, 'interior')" [options]="pondOptions"></file-pond>
        </div>
      </div>

      <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="5%">
        <div fxFlex="30">
          <img class="placeholder-image" [src]="images.mileage.current">
          <p class="image-title">Tellers met draaiende motor (verplicht)</p>
          <file-pond (onaddfile)="setPhotoType($event, 'mileage')" [options]="pondOptions"></file-pond>
        </div>
        <div fxFlex="30">
          <img class="placeholder-image" [src]="images.dashboard.current">
          <p class="image-title">Dashboard geheel (verplicht)</p>
          <file-pond (onaddfile)="setPhotoType($event, 'dashboard')" [options]="pondOptions"></file-pond>
        </div>
        <div fxFlex="30">
          <img class="placeholder-image" src="../../../../assets/images/templates/2134/icon-damages.svg">
          <p class="image-title">Schades (optioneel)</p>
          <file-pond (onaddfile)="setPhotoType($event, 'other')" [options]="pondOptionsMultiple"></file-pond>
        </div>

        <form *ngIf="form && form.get('images_count')" [formGroup]="form" style="display: none;">
          <section>
            <mat-form-field appearance="fill">
              <input formControlName="images_count" type="number" matInput>
            </mat-form-field>
          </section>
        </form>
      </div>
    </section>

    <p>Na het toevoegen van de foto's is de taxatie afgerond en ontvang je binnen 1 uur een gegarandeerd bod voor jouw
      auto. Dit bod is helemaal vrijblijvend. Jij bepaalt zelf of je dit bod accepteert. Dus jij hebt alles zelf in de
      hand. Ga je akkoord? Wij komen je auto altijd gratis ophalen!</p>
    <!-- <button [disabled]="loading" (click)="resumeLater()" mat-raised-button color="primary">Later toevoegen</button> -->
  </div>

  <div class="container" [@fade]="!loading" style="padding-top: 30px;" *ngIf="showResumeLaterText">
    <h2>Later afronden</h2>
    <p>Je hebt ervoor gekozen om later foto’s toe te voegen. </p>
    <p>
      Je ontvangt een email met daarin een link om foto’s toe te voegen en de taxatie af te ronden.
      Na het toevoegen van de foto's ontvang je binnen 1 uur een gegarandeerd bod voor jouw auto. Dit bod is helemaal
      vrijblijvend.
      Jij bepaalt zelf of je dit bod accepteert. Dus jij hebt alles zelf in de hand. Akkoord? Wij komen je auto altijd
      gratis ophalen!
    </p>
    <p>Alsnog foto’s toevoegen?</p>
    <div fxLayout="col" fxLayoutAlign="left left">
      <button (click)="resumeSpecification()" style="margin-left: -2px;" mat-raised-button color="primary">Nu
        uploaden</button>
    </div>
  </div>

  <!-- Overview view  -->
  <div style="padding-top: 30px;" *ngIf="currentStep == 4" class="container">
    <mat-accordion *ngIf="pageDetails">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Stap 1 - Voertuig
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <section class="form-section">
            <form *ngIf="form" [formGroup]="form">
              <mat-form-field appearance="outline">
                <mat-label>Tellerstand</mat-label>
                <input formControlName="vs_mileage" min="0" pattern="[0-9]*" type="number" matInput
                  placeholder="Tellerstand">
                <span matSuffix>km</span>
                <mat-error *ngIf="(form!.controls['vs_mileage'].dirty || form!.controls['vs_mileage'].touched)">
                  Tellerstand is een verplicht veld</mat-error>
              </mat-form-field>
            </form>
          </section>

          <app-form (formChange)="formChange($event)" [show]="['vs_atlCode']" [appearance]="'fill'"
            [fields]="pageDetails.fields">
          </app-form>
        </section>

        <mat-label>Opties</mat-label>
        <section class="checkbox-grid">
          <mat-checkbox style="padding-right: 10px;" *ngFor="let option of options" [checked]="option.selected"
            id="{{option.id}}" (change)="optionChanged($event.checked, option.id)">{{option.description}}</mat-checkbox>
        </section>

        <section>
          <form *ngIf="form" [formGroup]="form">
            <section *ngIf="form && form.get('vs_vatMarginTypeId_question')">
              <mat-form-field appearance="outline">
                <mat-label>Staat de auto op naam van uw bedrijf?</mat-label>
                <mat-select (ngModelChange)="updateVatMarginType($event)" formControlName="vs_vatMarginTypeId_question">
                  <mat-option [value]="1">Ja</mat-option>
                  <mat-option [value]="2">Nee</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_vatMarginTypeId_question'].dirty || form!.controls['vs_vatMarginTypeId_question'].touched)">
                  Staat de auto op naam van uw bedrijf is een verplicht veld</mat-error>
              </mat-form-field>
            </section>

            <section
              *ngIf="form && form.get('vs_vatMarginTypeId') && form.controls['vs_vatMarginTypeId_question'].value == '1'">
              <mat-form-field appearance="outline">
                <mat-label>Bent u met uw bedrijf BTW plichtig?</mat-label>
                <mat-select formControlName="vs_vatMarginTypeId">
                  <mat-option [value]="1">Ja</mat-option>
                  <mat-option [value]="2">Nee</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_vatMarginTypeId'].dirty || form!.controls['vs_vatMarginTypeId'].touched)">
                  Staat de auto op naam van uw bedrijf is een verplicht veld</mat-error>
              </mat-form-field>
            </section>
          </form>
        </section>

        <form *ngIf="form" [formGroup]="form">
          <section class="form-section" *ngIf="form && form.get('vs_conditionBodyTypeId')">
            <div><mat-label>Conditie buitenkant*</mat-label></div>
            <mat-radio-group formControlName="vs_conditionBodyTypeId" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="30%" [value]="3">Goede conditie</mat-radio-button>
              <mat-radio-button fxFlex="30%" [value]="2">Redelijke conditie</mat-radio-button>
              <mat-radio-button fxFlex="30%" [value]="1">Slechte conditie</mat-radio-button>
            </mat-radio-group>
            <mat-error
              *ngIf="(form!.controls['vs_conditionBodyTypeId'].invalid && form!.controls['vs_conditionBodyTypeId'].touched)">
              Conditie buitenkant is een verplicht veld
            </mat-error>
          </section>

          <section class="form-section" *ngIf="form && form.get('vs_conditionTechnicalTypeId')">
            <div><mat-label>Conditie technisch*</mat-label></div>
            <mat-radio-group formControlName="vs_conditionTechnicalTypeId" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="30%" [value]="3">Goede conditie</mat-radio-button>
              <mat-radio-button fxFlex="30%" [value]="2">Redelijke conditie</mat-radio-button>
              <mat-radio-button fxFlex="30%" [value]="1">Slechte conditie</mat-radio-button>
            </mat-radio-group>
            <mat-error
              *ngIf="(form!.controls['vs_conditionTechnicalTypeId'].invalid && form!.controls['vs_conditionTechnicalTypeId'].touched)">
              Conditie technisch is een verplicht veld
            </mat-error>
          </section>

          <section class="form-section" *ngIf="form && form.get('vs_smoked')">
            <div><mat-label>Is er gerookt in de auto?*</mat-label></div>
            <mat-radio-group formControlName="vs_smoked" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="15%" [value]="true">Ja</mat-radio-button>
              <mat-radio-button fxFlex="15%" [value]="false">Nee</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="(form!.controls['vs_smoked'].invalid && form!.controls['vs_smoked'].touched)">
              Dit is een verplicht veld
            </mat-error>
          </section>

          <section *ngIf="form && form.get('vs_pets')">
            <div><mat-label>Zijn er huisdieren vervoerd?*</mat-label></div>
            <mat-radio-group formControlName="vs_pets" fxLayout="row" fxLayout.xs="column">
              <mat-radio-button fxFlex="15%" [value]="true">Ja</mat-radio-button>
              <mat-radio-button fxFlex="15%" [value]="false">Nee</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="(form!.controls['vs_pets'].invalid && form!.controls['vs_pets'].touched)">
              Dit is een verplicht veld
            </mat-error>
          </section>
        </form>

        <app-form (formChange)="formChange($event)"
          [hint]="'Denk daarbij aan informatie over eventuele beschadigingen of extra opties'"
          [show]="['vs_commentVehicleInfoCustomer']" [fields]="pageDetails.fields"></app-form>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Stap 2 - Persoonlijke gegevens
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <app-form (formChange)="formChange($event)" [show]="['c_salutationTypeId', 'c_lastName', 'c_city']"
            [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form [hint]="'Wij hebben uw email nodig om biedingen met u te communiceren.'"
            (formChange)="formChange($event)" [show]="['c_emailAddress']" [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form
            [hint]="'Uw telefoonnummer kan makkelijk zijn als wij vragen hebben over uw auto. Dit veld is niet verplicht'"
            (formChange)="formChange($event)" [show]="['c_phone']" [fields]="pageDetails.fields"></app-form>
        </section>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Stap 3 - Fotos
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <app-form [show]="['vs_images']" [fields]="pageDetails.fields"></app-form>
        </section>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="!form?.valid && submitPressed" fxLayout="row" class="container error-message">
    <h3>Niet alle verplichte velden zijn ingevuld om verder te kunnen gaan.</h3>
  </div>

  <div *ngIf="currentStep == 4" class="container">
    <p fxLayout="row" class="container">
      Na het klikken op "Aanvraag versturen" is de taxatie afgerond, en ontvang je binnen 1 uur een gegarandeerd bod
      voor jouw auto. Dit bod is helemaal vrijblijvend. Jij bepaalt zelf of je dit bod accepteert. Dus jij hebt
      alles
      zelf in de hand. Ga je akkoord? Wij komen je auto altijd gratis ophalen!
    </p>

    <section fxLayout="row" class="container">
      <mat-checkbox (change)="registerToMailchimpNewsletter($event)">Abonneren op onze nieuwsbrief?</mat-checkbox>
    </section>
  </div>

  <!--   <div class="footer-buttons">
    <div *ngIf="currentStep == 4">
      <div style="padding-top: 20px;">
        <p fxLayout="row" class="container">
          Na het klikken op "Aanvraag versturen" is de taxatie afgerond, en ontvang je binnen 1 uur een gegarandeerd bod
          voor jouw auto. Dit bod is helemaal vrijblijvend. Jij bepaalt zelf of je dit bod accepteert. Dus jij hebt
          alles
          zelf in de hand. Ga je akkoord? Wij komen je auto altijd gratis ophalen!
        </p>
        <section fxLayout="row" class="container checkbox-grid">
          <mat-checkbox (change)="registerToMailchimpNewsletter($event)">Abonneren op onze nieuwsbrief?</mat-checkbox>
        </section>
      </div>
      <div fxLayout="row">
        <button [disabled]="loading" (click)="submitValuation()" mat-raised-button color="primary">
          Aanvraag versturen
        </button>
      </div>
    </div>

    <div *ngIf="showResumeLaterText == false" class="sticky-buttons" style="padding-top: 30px;" fxLayout="row"
      fxLayoutAlign="space-between center">
      <div>
        <button mat-button [disabled]="loading" *ngIf="currentStep == 1" (click)="cancelRequest()" color="secondary">
          Annuleren
        </button>

        <button [disabled]="loading" *ngIf="currentStep <= 3 && currentStep > 1" (click)="previousStep()"
          mat-raised-button color="warn">Vorige</button>
      </div>

      <div [matTooltipDisabled]="form?.valid" matTooltip="Niet alle verplichte velden zijn ingevuld">
        <button [disabled]="loading" *ngIf="currentStep <= 3" (click)="nextStep()" mat-raised-button color="primary">
          <span class="button-content">Volgende <i class="material-icons">chevron_right</i></span>
        </button>
      </div>
    </div>
  </div> -->