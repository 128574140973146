var active = []; //welke onderdelen zijn er actief/hebben er schade
var pushOutElement = null; //voor de functie pushOut is dit het element waarom het gaat, zo hoeft er niet bij iedere iteratie document.getElementById gedaan te worden

/*
 * wordt niet meer gebruikt maar kan gebruikt worden als er een handje ipv default cursor getoond moet worden bij het hoveren over de plaatjes
 */
function showMousePointer() {
    for(var i in damage_part_ids) {
        overlays[i].style.cursor = 'pointer';
        overlays_active[i].style.cursor = 'pointer';
    }
}

/*
 * wordt niet meer gebruikt maar kan gebruikt worden als er een handje ipv default cursor getoond moet worden bij het hoveren over de plaatjes
 */
function hideMousePointer() {
    for(var i in damage_part_ids) {
        overlays[i].style.cursor = 'auto';
        overlays_active[i].style.cursor = 'auto';
    }
}

function mouseOver(id) {
    if(active[id] > 0) {
        document.getElementById('overlay_'+id+'_active').style.visibility = 'hidden';
    }
    document.getElementById('overlay_'+id).style.visibility = 'visible';
}

function mouseOut(id) {
    document.getElementById('overlay_'+id).style.visibility = 'hidden';
    if(active[id] > 0) {
        document.getElementById('overlay_'+id+'_active').style.visibility = 'visible';
    }
}

function activatePart(id) {
    active[id] = 1;
    document.getElementById('overlay_'+id+'_active').style.visibility = 'visible';    
}

function deactivatePart(id) {
    active[id] = 0;
    document.getElementById('overlay_'+id+'_active').style.visibility = 'hidden';    
}

function toggle(damagePartId, show_damageimage, show_repairtypes, show_repaircosts) {
    if(pushOutElement != null) {
        //bezig doe niks.
        return;
    }
    if(active[damagePartId] == undefined || active[damagePartId] < 1) {
        addDamage(damagePartId, show_damageimage, show_repairtypes, show_repaircosts);
    }
}