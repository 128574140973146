<h1 mat-dialog-title><b>Wijzig model</b></h1>
<mtx-loader [loading]="loading" [type]="'spinner'" [color]="'primary'" [hasBackdrop]="true">
  <mat-dialog-content>
    <form *ngIf="form" [formGroup]="form">
      <section *ngIf="pageDetails.fields">
        <app-form (formChange)="formChange($event)" [show]="['vs_modelYear']" [fields]="pageDetails.fields">
        </app-form>
      </section>
    </form>
  </mat-dialog-content>
</mtx-loader>

<mat-dialog-actions class="action-buttons" align="start">
  <button mat-raised-button color="warn" (click)="closeDialog()" mat-dialog-close>Annuleren</button>
  <button mat-raised-button color="primary" (click)="submit()" mat-button>Opslaan</button>
</mat-dialog-actions>