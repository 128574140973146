import { Injectable } from '@angular/core';
import { apiResponse } from 'src/app/models/api-response';
import { Option, StartVehicleDescription, UpdateVehicleDescription, VehicleDescription, VehicleDescriptionImage } from 'src/app/models/vehicle-description';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleSpecsService {
  constructor(
    private apiService: ApiService
  ) { }

  public excludeFields(data: { [key: string]: any }): { [key: string]: any } {
    const fieldsToExclude = ['vs_modelYearImages', 'vs_vehicleStatus'];
    const filteredData: { [key: string]: any } = {};
    for (const key in data) {
      if (!fieldsToExclude.includes(key)) {
        filteredData[key] = data[key];
      }
    }
    return filteredData;
  }

  public startVehicleDescription(options: StartVehicleDescription, isEncoded: boolean = true) {
    return new Promise<VehicleDescription>((resolve, reject) => {
      // Parse options (parameters) and add to request
      if (options.fields != null && isEncoded) {
        options.fields = JSON.parse(atob(options.fields));
      }

      this.apiService.startVehicleDescription(options).subscribe(data => {
        // Process response, and return data
        if (data.error == false) {
          // Set descriptionToken in storage
          localStorage.setItem('descriptionToken', data.descriptionToken);
          let response = {
            error: data.error,
            historicRequests: data.historicRequests,
            descriptionToken: data.descriptionToken,
            descriptionId: data.descriptionId,
            fields: data.page.fields,
            name: data.page.name,
            title: data.page.title,
          }
          resolve(response);
        } else {
            let response = {
              message: data.message,
              error: data.error
            }
          resolve(response);
        }
      }, error => {
        reject(error);
      })
    });
  }

  public updateVehicleDescription(descriptionId: number, data: UpdateVehicleDescription) {
    data.fields = this.excludeFields(data.fields);
    return new Promise<VehicleDescription>((resolve, reject) => {
      this.apiService.updateVehicleDescription(descriptionId, data).subscribe(data => {
        let response = {};
        if (data.error == false) {
          // Process response, and return data
          response = {
            error: data.error,
            fields: data.page.fields,
            name: data.page.name,
            title: data.page.title,
          }
        } else {
          response = data;
        }
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  public finishVehicleDescription(descriptionId: number) {
    return new Promise<apiResponse>((resolve, reject) => {
      this.apiService.finishVehicleDescription(descriptionId).subscribe(data => {
        // Process response, and return data
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getPages(fields: any = null, orderBy: any = null, filter: any = null, defaultFilter: any = null) {
    return new Promise<any>((resolve, reject) => {
      this.apiService.getPages({ page: 1, limit: 100, fields: JSON.stringify(fields), orderBy: JSON.stringify(orderBy), filter: JSON.stringify(filter), defaultFilter: JSON.stringify(defaultFilter) }).subscribe(data => {
        resolve(data.data);
      }, error => {
        reject(error);
      });
    });
  }

  public getPageByName(id: number, page: string) {
    return new Promise<VehicleDescription>((resolve, reject) => {
      this.apiService.getVehicleDescriptionsPageByName(id, page).subscribe(data => {
        // Process response, and return data
        let response = {
          fields: data.data.fields,
          name: data.data.name,
          title: data.data.title,
        }
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  public getVehicleDescriptionById(id: number) {
    return new Promise<apiResponse>((resolve, reject) => {
      this.apiService.getVehicleDescriptionById(id).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public deleteVehicleDescription(descriptionId: number) {
    return new Promise<apiResponse>((resolve, reject) => {
      this.apiService.deleteVehicleDescription(descriptionId).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public addVehicleDescriptionImage(descriptionId: number, positionType: string, fileName: string, fileType: string, image: any) {
    return new Promise<any>((resolve, reject) => {
      this.apiService.addVehicleDescriptionImage(descriptionId, positionType, fileName, fileType, image).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public deleteVehicleDescriptionImage(descriptionId: number, imageId: number) {
    return new Promise<apiResponse>((resolve, reject) => {
      this.apiService.deleteVehicleDescriptionImage(descriptionId, imageId).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public addVehicleDescriptionMedia(descriptionId: number, type?: string, contentType?: string, media?: any, mediaUrl?: string, positionTypeId?: number, positionType?: string) {
    return new Promise<any>((resolve, reject) => {
      this.apiService.addVehicleDescriptionMedia(descriptionId, type, contentType, media, mediaUrl, positionTypeId, positionType).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public deleteVehicleDescriptionMedia(descriptionId: number, imageId: number) {
    return new Promise<apiResponse>((resolve, reject) => {
      this.apiService.deleteVehicleDescriptionMedia(descriptionId, imageId).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public resumeLater(descriptionId: number, pageName: string) {
    return new Promise<apiResponse>((resolve, reject) => {
      this.apiService.resumeLater(descriptionId, { pageName: pageName }).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
}
