import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ApiService } from './services/api/api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RegistrationPipe } from './pipes/registration.pipe';
import { Template108Component } from './components/templates/template-108/template-108.component';
import { TemplateDefaultComponent } from './components/templates/template-default/template-default.component';
import { ProxyComponent } from './components/proxy/proxy.component';
import { ApiInterceptor } from './interceptors/api/api.interceptor';
import { Template2134Component } from './components/templates/template-2134/template-2134.component';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import * as FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import * as FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import * as FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { FormComponent } from './components/form/form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsService } from './services/utils/utils.service';
import { ErrorComponent } from './components/error/error.component';
import { DamageComponent } from './components/damage/damage.component';
import { WijzigModelComponent } from './components/modals/wijzig-model/wijzig-model.component';
import { WijzigGegevensComponent } from './components/modals/wijzig-gegevens/wijzig-gegevens.component';

import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { HistorischeAanvragenComponent } from './components/modals/historische-aanvragen/historische-aanvragen.component';
import { NgxWrapperTinySliderModule } from 'ngx-wrapper-tiny-slider';
import {IvyCarouselModule} from 'carousel-angular';
import { SchadeToevoegenComponent } from './components/damage/modals/schade-toevoegen/schade-toevoegen.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { FilePickerModule } from 'ngx-awesome-uploader';
import { CarouselModule } from '@marcreichel/angular-carousel';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MaintenanceService } from './interceptors/maintenance.service';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { OverlayLoadingComponent } from './components/overlay-loading/overlay-loading.component';
registerLocaleData(localeNl);

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginImageEdit);
registerPlugin(FilePondPluginFilePoster);

@NgModule({
  declarations: [
    AppComponent,
    SkeletonLoaderComponent,
    RegistrationPipe,
    CapitalizePipe,
    TemplateDefaultComponent,
    Template108Component,
    Template2134Component,
    ProgressBarComponent,
    WijzigModelComponent,
    WijzigGegevensComponent,
    SchadeToevoegenComponent,
    ProxyComponent,
    FormComponent,
    ErrorComponent,
    DamageComponent,
    HistorischeAanvragenComponent,
    MaintenanceComponent,
    OverlayLoadingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FilePondModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    FormsModule,
    NgxWrapperTinySliderModule,
    IvyCarouselModule,
    FilePickerModule,
    CarouselModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    FormComponent,
    ApiService,
    UtilsService,
    MaintenanceService,
    HistorischeAanvragenComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
