import { catchError, startWith, switchMap, concatMap } from 'rxjs/operators';
import { Observable, from, of, Subject } from 'rxjs';
import { FilePickerAdapter, FilePreviewModel, UploadResponse, UploadStatus } from 'ngx-awesome-uploader';
import { VehicleSpecsService } from 'src/app/services/vehicle-specs/vehicle-specs.service';

export class SpecsFilePickerAdapter extends FilePickerAdapter {
    private uploadQueue: Subject<FilePreviewModel> = new Subject();
    private currentUploadObservable: Observable<UploadResponse> = of();

    constructor(
        public vehicleSpecs: VehicleSpecsService,
        public currentDescriptionId: number,
        public contentType: string = 'image') {
        super();

        this.processQueue();
    }

    private processQueue() {
        this.uploadQueue.pipe(
            concatMap(fileItem => this.uploadFileInternal(fileItem))
        ).subscribe();
    }

    public uploadFile(fileItem: FilePreviewModel, type?: string): Observable<UploadResponse> {
        this.uploadQueue.next(fileItem);
        return this.currentUploadObservable;
    }

    private uploadFileInternal(fileItem: FilePreviewModel): Observable<UploadResponse> {
        if (!this.currentDescriptionId) {
            return of({ status: UploadStatus.ERROR, body: 'currentDescriptionId is not initialized!' });
        }

        this.currentUploadObservable = from(this.vehicleSpecs.addVehicleDescriptionMedia(Number(this.currentDescriptionId), 'image', this.contentType, fileItem.file)).pipe(
            switchMap(data => {
                if (data.data != null) {
                    return of({ status: UploadStatus.UPLOADED, body: data.data });
                } else {
                    return of({ status: UploadStatus.ERROR, body: 'error uploading!' });
                }
            }),
            catchError(error => {
                return of({ status: UploadStatus.ERROR, body: 'error uploading!' });
            }),
            startWith({ status: UploadStatus.IN_PROGRESS, progress: 50 })
        );

        return this.currentUploadObservable;
    }

    public removeFile(fileItem: FilePreviewModel): Observable<any> {
        if (fileItem.uploadResponse) {
            return from(this.vehicleSpecs.deleteVehicleDescriptionMedia(Number(this.currentDescriptionId), fileItem.uploadResponse.id)).pipe(
                switchMap(data => {
                    if (data.data != null) {
                        return of({ status: UploadStatus.UPLOADED, body: data.data });
                    } else {
                        return of({ status: UploadStatus.ERROR, body: 'error removing!' });
                    }
                })
            );
        } else {
            return of({ status: UploadStatus.UPLOADED, body: null });
        }
    }
}
