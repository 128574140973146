import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VehicleDescription, VehicleDescriptionDamage } from 'src/app/models/vehicle-description';
import { ApiService } from 'src/app/services/api/api.service';
import { VehicleSpecsService } from 'src/app/services/vehicle-specs/vehicle-specs.service';

export interface Damage {
  id: number
  name: string
  part: { name: string, id: number },
  damage?: { name: string, id: number },
  repair?: { name: string, id: number },
  repairCosts: number
};

export interface DamageOption {
  id: string,
  descriptionNl: string
}

@Component({
  selector: 'app-schade-toevoegen',
  templateUrl: './schade-toevoegen.component.html',
  styleUrls: ['./schade-toevoegen.component.scss']
})
export class SchadeToevoegenComponent implements OnInit {
  loading: boolean = false;
  // Default values of form elements
  damagePart = null;
  damageType = null;
  damageRepair = null;
  damageCosts = null;

  currentDamageParts: DamageOption[] = [];
  currentDamageTypes: DamageOption[] = [];
  currentDamageRepairs: DamageOption[] = [];

  public form: FormGroup = new FormGroup({});
  public pageDetails: VehicleDescription = {};

  damageImage: any = null;

  constructor(
    public dialogRef: MatDialogRef<SchadeToevoegenComponent>,
    public vehicleSpecs: VehicleSpecsService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.setData();
  }

  private setData() {
    // Set damageTypes
    this.currentDamageTypes = this.findFormElement(this.data.data.damageData, 'd_damageTypeId').options;

    if (this.data.data.type === 'edit') {
      // Set partTypes
      this.currentDamageParts = this.findFormElement(this.data.data.damageData, 'd_partTypeId').options;
      // Set damageRepairs
      this.currentDamageRepairs = this.findFormElement(this.data.data.damageData, 'd_repairTypeId').options;
      // Set damageType options
      this.currentDamageTypes = this.findFormElement(this.data.data.damageData, 'd_damageTypeId').options;

      // Set values
      this.damagePart = this.findFormElement(this.data.data.damageData, 'd_partTypeId').value;
      this.damageType = this.findFormElement(this.data.data.damageData, 'd_damageTypeId').value;
      this.damageRepair = this.findFormElement(this.data.data.damageData, 'd_repairTypeId').value;
      this.damageCosts = this.findFormElement(this.data.data.damageData, 'd_repairCosts').value;
    } else if (this.data.data.type === 'add') {
      this.currentDamageParts = this.findFormElement(this.data.data.damageData, 'd_partTypeId').options;
      this.damagePart = this.findFormElement(this.data.data.damageData, 'd_partTypeId').value;
    }
  }

  public updateDamagePart(event: number) {
    this.updateDamage({ d_partTypeId: event, d_damageTypeId: this.findFormElement(this.data.data.damageData, 'd_damageTypeId').value, d_repairTypeId: this.findFormElement(this.data.data.damageData, 'd_repairTypeId').value });
  }

  public updateDamageType(event: number) {
    this.updateDamage({ d_damageTypeId: event, d_repairTypeId: this.findFormElement(this.data.data.damageData, 'd_repairTypeId').value, d_partTypeId: this.findFormElement(this.data.data.damageData, 'd_partTypeId').value });
  }

  public updateDamageRepair(event: number) {
    this.updateDamage({ d_repairTypeId: event, d_damageTypeId: this.findFormElement(this.data.data.damageData, 'd_damageTypeId').value, d_partTypeId: this.findFormElement(this.data.data.damageData, 'd_partTypeId').value });
  }

  public findFormElement(data: [any], field: string) {
    for (const item of data) {
      if (item.Field.name == field) {
        return item;
      }
    }
    return null;
  }

  public updateDamage(data: VehicleDescriptionDamage): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.apiService.updateVehicleDescriptionDamage(Number(this.data.data.vehicleDescriptionId), this.findFormElement(this.data.data.damageData, 'id').value, data).subscribe(data => {
        this.loading = false;
        if (data.error == false) {
          const damageTypes = this.findFormElement(data.data, 'd_damageTypeId').options;
          if (damageTypes != null) {
            this.currentDamageTypes = damageTypes;
          }

          const damageRepairs = this.findFormElement(data.data, 'd_repairTypeId').options;
          if (damageRepairs != null) {
            this.currentDamageRepairs = damageRepairs;
          }
          // Set values
          this.data.data.damageData = data.data;
          resolve(data);
        } else {
          reject(data);
        }
      })
    });
  }

  public deleteDamage(damageId: number) {
    this.loading = true;
    this.apiService.deleteVehicleDescriptionDamage(Number(this.data.data.vehicleDescriptionId), damageId).subscribe(data => {
      this.loading = false;
      if (data.error == false) {
        // Close modal
        this.dialogRef.close(false);
      }
    })
  }

  public submit() {
    this.loading = true;
    // Push to damages array
    this.setData();

    const damage: VehicleDescriptionDamage = {
      d_repairCosts: this.damageCosts,
      d_damageTypeId: Number(this.damageType),
      d_partTypeId: Number(this.damagePart),
      d_repairTypeId: Number(this.damageRepair)
    }

    this.updateDamage(damage).then(data => {
      this.dialogRef.close(data.data);
    });
  }

  public closeDialog(): void {
    if (this.data.data.type == 'add') {
      this.deleteDamage(this.findFormElement(this.data.data.damageData, 'id').value);
    } else if (this.data.data.type == 'edit') {
      this.dialogRef.close(false);
    }
  }
}
