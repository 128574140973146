<title>Autotaxatie Partners InruilModule</title>

<div class="header-bar container">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="20">
      <img class="header-logo" src="../../../../assets/images/logo.jpg">
    </div>

    <div fxFlex="80">
      <div fxLayout="row">
        <div class="header-menu-buttons">
          <a href="https://www.autotaxatiepartners.nl/" target="_blank"><span
              style="margin-right: 20px;">Home</span></a>
          <a href="https://www.autotaxatiepartners.nl/#producten" target="_blank"><span
              style="margin-right: 20px;">Producten</span></a>
          <a href="https://www.autotaxatiepartners.nl/#over-ons" target="_blank"><span style="margin-right: 20px;">Over
              ons</span></a>
          <a href="https://www.autotaxatiepartners.nl/#contact" target="_blank"><span
              style="margin-right: 20px;">Contact</span></a>
          <a href="https://dashboard.autotaxatiepartners.nl/logout;" target="_blank"><span>Uitloggen</span></a>
        </div>
      </div>

      <div class="header-menu-links" fxLayout="row">
        <div fxLayout="column" style="padding-right: 40px;">
          <a href="https://dashboard.autotaxatiepartners.nl/dashboard" target="_blank" style="color:#000000;">
            <mat-icon class="inline-icon navigation-icon">home</mat-icon>
            Dashboard
          </a>
        </div>

        <div fxLayout="column">
          <a href="https://dashboard.autotaxatiepartners.nl/account" target="_blank" style="color:#000000;">
            <mat-icon class="inline-icon navigation-icon">home</mat-icon>
            Bedrijfsinformatie
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="header-footer">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>

<div class="main-container">
  <div *ngIf="validationError">
    <mat-card class="example-card">
      <mat-card-header fxLayoutAlign="center center">
        <img class="ico" src="../../../../assets/images/notify-icon.png">
        <mat-card-title>Er is een fout opgetreden</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayoutAlign="center center">
        <ul *ngFor="let message of errorMessages">
          <li *ngIf="message.descriptionNl">{{message.descriptionNl}}</li>
          <li *ngIf="!message.descriptionNl">{{message}}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="container">
    <div>
      <div class="header-title">
        <span class="fictive-registration" *ngIf="getSingleField('v_fictiveRegistration').value === true">
          {{getSingleField('v_registration').value | registration: getSingleField('v_registration').value}}
        </span>

        <span *ngIf="getSingleField('v_fictiveRegistration').value === false">
          {{getSingleField('v_registration').value | registration: getSingleField('v_registration').value}}
        </span>
        {{getSingleField('v_make').value | capitalize}}
        <b>{{getSingleField('v_model').value | capitalize}}</b>
      </div>
    </div>
  </div>

  <!-- Generate dynamic fields -->
  <ng-container>
    <mat-stepper #mainStepper linear>
      <mat-step>
        <mat-tab-group #stepperVehicle mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">

          <!-- Start tab -->
          <mat-tab [disabled]="currentTab != 0" label="Start">
            <app-skeleton-loader *ngIf="skeletonLoading"></app-skeleton-loader>
            <ng-template matStepLabel>Stap 1 - Voertuiggegevens</ng-template>

            <mat-card
              *ngIf="pageDetails.fields && navigationIndexStep == 0 && currentStep == 0 && currentTab == 0 && isFictiveRegistrationFlow == false"
              class="container">

              <div style="height:20px;"></div>
              <h2>Benodigde basisgegevens</h2>
              <mat-divider style="margin-top: unset;" class="divider"></mat-divider>

              <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" fxLayoutGap.gt-sm="30px">

                <div fxFlex="100" fxFlex.gt-sm="65" class="main-content">
                  <div fxLayout="column">
                    <form *ngIf="form" [formGroup]="form">
                      <section>
                        <mat-checkbox formControlName="v_fictiveRegistration">Geen kenteken (bekend)?</mat-checkbox>
                      </section>

                      <section *ngIf="form.controls['v_fictiveRegistration'].value != true"
                        style="background-color: white !important;">
                        <mat-form-field class="registration" appearance="fill" floatLabel="always"
                          style="background-color: white !important;">
                          <mat-label>Kenteken</mat-label>
                          <input formControlName="v_registration" matInput type="text" name="licenseplate"
                            class="licenseplate" maxlength="8" placeholder="kenteken" />
                        </mat-form-field>
                      </section>

                      <section *ngIf="form.controls['v_fictiveRegistration'].value == true">
                        <mat-form-field appearance="fill" floatLabel="always">
                          <mat-label>Referentie</mat-label>
                          <input formControlName="v_registration" matInput type="text" name="licenseplate"
                            placeholder="Referentie om voertuig te herkennen" minlength="6" maxlength="6" required />
                          <mat-error *ngIf="form.controls['v_registration'].hasError('minlength')">Minimale lengte is 6
                            tekens</mat-error>
                          <mat-error *ngIf="form.controls['v_registration'].hasError('maxlength')">Maximale lengte is 6
                            tekens</mat-error>
                        </mat-form-field>
                      </section>

                      <section
                        *ngIf="form.controls['v_fictiveRegistration'].value == false || form.controls['v_fictiveRegistration'].value == null">
                        <app-form (formChange)="formChange($event)" [show]="['vs_country']"
                          [fields]="pageDetails.fields"></app-form>
                      </section>

                      <div fxLayout="row" fxLayoutGap="30px">
                        <div fxLayout="column" fxFlex="50">
                          <mat-form-field appearance="fill">
                            <mat-label>Tellerstand</mat-label>
                            <input min="0" pattern="[0-9]*" type="number" formControlName="vs_mileage" matInput>
                          </mat-form-field>
                        </div>
                        <div fxLayout="column" fxFlex="50">
                          <mat-form-field appearance="fill">
                            <mat-label>Tellersoort</mat-label>
                            <mat-select formControlName="vs_mileageType">
                              <mat-option value="km">km</mat-option>
                              <mat-option value="mi">mi</mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="form.controls['vs_mileageType'].invalid && form.controls['vs_mileageType'].touched">
                              Dit is een verplicht veld
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div style="padding-bottom: 20px;" fxLayout="row"
                        *ngIf="form.controls['v_fictiveRegistration'].value == false || form.controls['v_fictiveRegistration'].value == null">
                        <div fxLayout="column" fxFlex="100">
                          <app-form (formChange)="formChange($event)" [show]="['vs_mileageHistoryCheck']"
                            [fields]="pageDetails.fields"></app-form>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div fxFlex="100" fxFlex.gt-sm="45" fxLayoutAlign="start" class="info-box">
                  <div class="notification-content">
                    <h3><b>{{notificationData?.title}}</b></h3>
                    <span [innerHTML]="notificationData?.message"></span>
                  </div>
                </div>
              </div>
            </mat-card>
          </mat-tab>

          <!-- Voertuiggegevens tab -->
          <mat-tab [disabled]="currentTab != 1" label="Voertuiggegevens">
            <app-skeleton-loader *ngIf="skeletonLoading"></app-skeleton-loader>
            <mat-card
              *ngIf="pageDetails && pageDetails.fields != null && currentStep == 0 && currentTab == 1 && navigationIndexStep == 1"
              class="container">
              <div fxLayout="row" style="margin-top: 20px;">
                <div fxLayout="column" fxFlex="50">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <h2 fxFlex="none" style="margin-right: 10px; margin-top:15px;">Controleer gegevens</h2>
                    <div fxLayoutAlign="center center">
                      <a (click)="openWijzigGegevensModal()" href="javascript:void(0)">Gegevens wijzigen</a>
                    </div>
                  </div>

                  <mat-divider style="margin-top: unset;" class="divider m-r-10"></mat-divider>

                  <section>
                    <app-form (formChange)="formChange($event)"
                      [show]="['vs_vehicleTypeId', 'vs_bodyTypeId', 'vs_fuelTypeId', 'vs_power', 'vs_cylinderVolume', 'vs_transmissionTypeId']"
                      [fields]="pageDetails.fields"></app-form>
                  </section>

                  <section>
                    <app-form (formChange)="formChange($event)"
                      [show]="['v_dateFirstAdmission', 'v_dateFirstRegistration', 'vs_dateSecondRegistration', 'vs_dateApkExpires', 'vs_okrStatusTypeId']"
                      [fields]="pageDetails.fields"></app-form>
                  </section>

                  <section *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'vs_mileageHistoryCheck')?.value == true">
                    <app-form (formChange)="formChange($event)" [show]="['vs_mileageHistoryCheckStatusTypeId']"
                      [fields]="pageDetails.fields"></app-form>
                  </section>
                </div>

                <div fxLayout="column" fxFlex="50">
                  <div fxLayout="column">

                    <div fxLayout="row" fxLayoutAlign="start center">
                      <h2 fxFlex="none" style="margin-right: 10px; margin-top:15px;">Controleer model</h2>
                      <div fxLayoutAlign="center center">
                        <a (click)="openWijzigModelModal()" href="javascript:void(0)">Model wijzigen</a>
                      </div>
                    </div>

                    <mat-divider style="margin-top: unset;" class="divider m-l-10"></mat-divider>

                    <angular-carousel [bullets]="true" [auto]="true" style="max-width: 375px;"
                      *ngIf="carPreviewImages != null && carPreviewImages[0]['image'] != '' && carPreviewImages[0]['image'] != '/assets/images/car-placeholder.jpg'">
                      <ng-template ngFor let-image [ngForOf]="carPreviewImages">
                        <div *angularCarouselSlide>
                          <img [src]="image.image" class="carousel-image">
                        </div>
                      </ng-template>
                    </angular-carousel>
                  </div>

                  <section>
                    <app-form (formChange)="formChange($event)"
                      [show]="['vs_modelYear', 'vs_recalculatedNewPrice', 'v_bpmAmount', 'vs_restBpm', 'v_officialImport']"
                      [fields]="pageDetails.fields"></app-form>
                  </section>
                </div>
              </div>

              <mat-divider class="divider m-t-10"></mat-divider>
              <h2>Uitvoering bepalen</h2>

              <section *ngIf="getVsAtlCode() == 'Onbekend'">
                <app-form (formChange)="formChange($event)" [show]="['vs_atlCode']" [fields]="pageDetails.fields">
                </app-form>
              </section>

              <section *ngIf="getVsAtlCode() != 'Onbekend'">
                <section *ngIf="isAtlCodeChanging">
                  <app-form (formChange)="formChange($event)" [show]="['vs_atlCode']" [fields]="pageDetails.fields">
                  </app-form>
                </section>
                <section *ngIf="isAtlCodeChanging === false">
                  <h2>{{getVsAtlCode()}}</h2>
                  <p>Uitvoering is bepaald op waarschijnlijkheid. Niet correct? <a (click)="isAtlCodeChanging = true"
                      href="javascript:void(0)">Uitvoering wijzigen</a></p>
                </section>
              </section>
            </mat-card>
          </mat-tab>

          <!-- Opties tab -->
          <mat-tab [disabled]="currentTab != 2" label="Opties">
            <app-skeleton-loader *ngIf="skeletonLoading"></app-skeleton-loader>
            <mat-card *ngIf="pageDetails.fields && currentStep == 0 && currentTab == 2 && navigationIndexStep == 2"
              class="container">
              <form *ngIf="form && !loading" [formGroup]="form">
                <section *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_defaultOptions')">
                  <h2>Standaardopties</h2>
                  <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                  <p
                    *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_defaultOptions')?.options != null; else noOptionsMessage">
                    <mat-checkbox
                      *ngFor="let option of utils.getSingleFieldFromSpec(pageDetails, 'va_defaultOptions')?.options"
                      (change)="defaultOptionUpdated($event, option)"
                      [checked]="selectedDefaultOptions.includes(option)">
                      {{option.descriptionNl}}
                    </mat-checkbox>
                  </p>
                  <ng-template #noOptionsMessage>
                    <p>Geen standaardopties beschikbaar.</p>
                  </ng-template>
                </section>
                <section *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_packages')">
                  <h2>Optiepaketten</h2>
                  <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                  <p
                    *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_packages')?.options != null; else noOptionsMessage">
                    <mat-checkbox
                      *ngFor="let option of utils.getSingleFieldFromSpec(pageDetails, 'va_packages')?.options"
                      (change)="packageUpdated($event, option)" [checked]="selectedPackages.includes(option)">
                      <b>{{ option.descriptionNl }}</b>
                      (<span *ngFor="let includedOption of option.options; let last = last">
                        {{ includedOption }}{{ !last ? ',' : '' }}
                      </span>)
                    </mat-checkbox>
                  </p>
                  <ng-template #noOptionsMessage>
                    <p>Geen optiepaketten beschikbaar.</p>
                  </ng-template>
                </section>

                <h2>Overige opties</h2>
                <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                <div fxLayout="row" fxLayoutGap="30px" fxLayout.xs="column" fxLayoutGap.xs="0">
                  <div fxLayout="column" fxFlex="50">
                    <section>
                      <app-form (formChange)="formChange($event)" [show]="['vs_colorTypeId', 'vs_lacTypeId']"
                        [fields]="pageDetails.fields"></app-form>
                    </section>
                  </div>
                  <div fxLayout="column" fxFlex="50">
                    <section>
                      <app-form (formChange)="formChange($event)" [show]="['vs_interiorColorTypeId', 'vs_liningTypeId']"
                        [fields]="pageDetails.fields"></app-form>
                    </section>
                  </div>
                </div>

                <div fxLayout="row">
                  <section *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_optionalOptions')">
                    <p
                      *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_optionalOptions')?.options != []; else noOptionsMessage">
                      <mat-checkbox
                        *ngFor="let option of utils.getSingleFieldFromSpec(pageDetails, 'va_optionalOptions')?.options"
                        (change)="optionalOptionUpdated($event, option)"
                        [checked]="selectedOptionalOptions.includes(option)">
                        {{option.descriptionNl}}
                      </mat-checkbox>
                    </p>
                    <ng-template #noOptionsMessage>
                      <p>Geen extra opties beschikbaar.</p>
                    </ng-template>
                  </section>
                </div>

                <div fxLayout="row" fxLayoutGap="30px" fxLayout.xs="column" fxLayoutGap.xs="0">
                  <div fxLayout="column" fxFlex="50">
                    <section>
                      <app-form (formChange)="formChange($event)"
                        [show]="['vs_numberOfSeats', 'vs_numberOfKeys', 'vs_aircoStatusTypeId', 'vs_napManualStatusTypeId', 'vs_vatMarginTypeId', 'vs_usedAsTypeId']"
                        [fields]="pageDetails.fields"></app-form>
                    </section>

                    <section *ngIf="getSingleField('v_fictiveRegistration').value === true">
                      <app-form (formChange)="formChange($event)" [show]="['vs_importCountryOfOriginTypeId']"
                        [fields]="pageDetails.fields"></app-form>
                    </section>
                  </div>
                  <div fxLayout="column" fxFlex="50">
                    <section>
                      <app-form (formChange)="formChange($event); checkTimingBeltReplaced();"
                        [show]="['vs_maintenanceHistoryTypeId', 'vs_lastMaintenanceMileage', 'vs_tiresTypeId', 'vs_extraTiresTypeId', 'vs_timingBeltReplacedTypeId']"
                        [fields]="pageDetails.fields"></app-form>

                      <mat-form-field appearance="fill" *ngIf="showTimingBeltReplacedDate">
                        <mat-label>Datum distributieriem vervangen</mat-label>
                        <input formControlName="vs_timingBeltReplacedDate" matInput
                          [matDatepicker]="pickerTimingBeltDate">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerTimingBeltDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerTimingBeltDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field appearance="fill" *ngIf="showTimingBeltReplacedDate">
                        <mat-label>Distributieriem vervangen bij</mat-label>
                        <input formControlName="vs_timingBeltReplacedMileage" min="0" pattern="[0-9]*" type="number"
                          matInput placeholder="Kilometers">
                        <span matSuffix>km</span>
                      </mat-form-field>
                    </section>
                  </div>
                </div>

                <h2>Prijsinformatie</h2>
                <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                <div fxLayout="row" fxLayoutGap="30px" fxLayout.xs="column" fxLayoutGap.xs="0">
                  <div fxLayout="column" fxFlex="50">
                    <section>
                      <mat-form-field appearance="fill">
                        <mat-label>Verwachte leverdatum</mat-label>
                        <input (ngModelChange)="updateMileageDelivery()" [min]="minDateDelivery" [max]="maxDateDelivery"
                          formControlName="tax_dateDelivery" matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </section>

                    <section>
                      <mat-form-field appearance="fill">
                        <mat-label>Verwachte tellerstand</mat-label>
                        <input (ngModelChange)="checkMileageDelivery()" formControlName="vs_mileageDelivery" matInput
                          min="0" pattern="[0-9]*" type="number" />
                      </mat-form-field>
                      <p style="color:rgb(210, 135, 0);" *ngIf="showMileageError">De verwachte tellerstand is lager dan
                        de tellerstand waarmee deze taxatie is gestart</p>
                    </section>
                  </div>
                  <div fxLayout="column" fxFlex="50">
                    <section>
                      <app-form (formChange)="formChange($event)" [show]="['tax_tradingBid', 'vs_newPriceInvoice']"
                        [fields]="pageDetails.fields"></app-form>
                    </section>
                  </div>
                </div>

                <h2>Opmerkingen</h2>
                <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                <div fxLayout="row" fxLayoutGap="30px" fxLayout.xs="column" fxLayoutGap.xs="0">
                  <div fxLayout="column" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex.xs="100">
                    <section>
                      <app-form (formChange)="formChange($event)" [show]="['vs_commentAccessories']"
                        [fields]="pageDetails.fields"></app-form>
                    </section>
                  </div>
                  <div fxLayout="column" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex.xs="100">
                    <section>
                      <app-form (formChange)="formChange($event)" [show]="['taxcom_text_7']"
                        [fields]="pageDetails.fields"></app-form>
                    </section>
                  </div>
                </div>
              </form>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </mat-step>

      <mat-step>
        <mat-tab-group #stepperCondition mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
          <!-- Foto's tab -->
          <mat-tab [disabled]="currentTab != 0" label="Foto's">
            <app-skeleton-loader *ngIf="skeletonLoading"></app-skeleton-loader>
            <ng-template matStepLabel>Stap 2 - Conditie</ng-template>
            <mat-card *ngIf="pageDetails.fields && currentStep == 1 && currentTab == 0 && navigationIndexStep == 3"
              class="container">
              <div fxLayout="row" fxLayoutGap="30px" style="padding-top: 20px;">
                <div fxLayout="column" fxFlex="100">
                  <h2>Foto's uploaden</h2>
                  <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                  <p>Het uploaden van foto's is niet verplicht maar statistieken tonen aan dat taxaties met foto's méér
                    en hogere biedingen opleveren.</p>
                  <p>U kunt meerdere foto's toevoegen door deze foto's in één keer te selecteren of na elke foto in
                    onderstaand vak te klikken.</p>
                  <p>Er zijn {{requiredImages}} foto's verplicht. </p>
                  <div fxLayout="column" fxFlex="100">
                    <ngx-awesome-uploader *ngIf="adapter" #uploader [fileMaxCount]="24" [fileMaxSize]="75"
                      [totalMaxSize]="250" [uploadType]="'multi'" [adapter]="adapter"
                      [fileExtensions]="['jpg', 'JPG','jpeg', 'JPEG', 'png', 'PNG']" [enableCropper]="false"
                      [showeDragDropZone]="true" [showPreviewContainer]="true" [enableAutoUpload]="true"
                      [captions]="captions"></ngx-awesome-uploader>
                  </div>
                </div>
              </div>
            </mat-card>
          </mat-tab>

          <!-- Schades tab -->
          <mat-tab [disabled]="currentTab != 1" label="Schades">
            <app-skeleton-loader *ngIf="skeletonLoading"></app-skeleton-loader>
            <mat-card *ngIf="pageDetails.fields && currentStep == 1 && currentTab == 1 && navigationIndexStep == 4"
              class="container">
              <div fxLayout="row" fxLayoutGap="30px" style="padding-top: 20px;">
                <div fxLayout="column" fxFlex="100">
                  <h2>Huidige status</h2>
                  <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                  <form *ngIf="form" [formGroup]="form">
                    <div fxLayout="row" fxLayoutGap="30px">
                      <div fxLayout="column" fxFlex="50">
                        <section>
                          <mat-form-field appearance="fill">
                            <mat-label>Conditie buitenkant</mat-label>
                            <mat-select formControlName="vs_conditionBodyTypeId">
                              <mat-option [value]="3">Goede conditie</mat-option>
                              <mat-option [value]="2">Redelijke conditie</mat-option>
                              <mat-option value="1">Slechte conditie</mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="(form!.controls['vs_conditionBodyTypeId'].dirty || form!.controls['vs_conditionBodyTypeId'].touched)">
                              Conditie buitenkant is een verplicht veld</mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="fill">
                            <mat-label>Conditie technisch</mat-label>
                            <mat-select formControlName="vs_conditionTechnicalTypeId">
                              <mat-option [value]="3">Goede conditie</mat-option>
                              <mat-option [value]="2">Redelijke conditie</mat-option>
                              <mat-option value="1">Slechte conditie</mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="(form!.controls['vs_conditionTechnicalTypeId'].dirty || form!.controls['vs_conditionTechnicalTypeId'].touched)">
                              Conditie technisch is een verplicht veld</mat-error>
                          </mat-form-field>
                        </section>
                      </div>

                      <div fxLayout="column" fxFlex="50">
                        <section>
                          <app-form (formChange)="formChange($event)" [show]="['vs_smoked', 'vs_pets']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>
                      </div>
                    </div>
                  </form>
                  <hr>
                  <h2 *ngIf="hasDamages == null">Is de auto schadevrij?</h2>
                  <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                  <div *ngIf="hasDamages == null" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="setHasDamage(false)" fxFlex="20" mat-raised-button color="primary">Ja</button>
                    <button (click)="setHasDamage(true)" fxFlex="20" mat-raised-button color="warn">Nee</button>
                  </div>

                  <p *ngIf="hasDamages != null && hasDamages != true">Toch schades? Klik <a (click)="hasDamages = true"
                      href="javascript:voud(0)">hier</a>.</p>

                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50">
                      <section *ngIf="hasDamages === false">
                        <mat-form-field appearance="fill" style="width: 80%; margin: 0 auto;">
                          <mat-label>Overige kosten</mat-label>
                          <input [(ngModel)]="damageCosts" min="0" pattern="[0-9]*" type="number" matInput
                            placeholder="Opknapkosten">
                          <span matSuffix>&euro;</span>
                        </mat-form-field>
                      </section>
                    </div>
                  </div>

                  <div *ngIf="hasDamages == true">
                    <br>
                    <h2>Schades</h2>
                    <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                    <app-damage (formChange)="damageFormChanged($event)" [fields]="pageDetails"
                      [vehicleDescriptionId]="currentDescriptionId" [vehicleType]="'hatchback'"></app-damage>
                  </div>
                </div>
              </div>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </mat-step>

      <mat-step>
        <mat-tab-group #stepperSummary mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
          <!-- Afronding tab -->
          <mat-tab label="Afronding">
            <app-skeleton-loader *ngIf="skeletonLoading"></app-skeleton-loader>
            <ng-template matStepLabel>Stap 3 - Afronding</ng-template>
            <mat-card class="container"
              *ngIf="pageDetails.fields && currentStep == 2 && currentTab == 0 && navigationIndexStep == 5"
              style="padding-bottom: 10px;">
              <div fxLayout="row" fxLayoutGap="30px">
                <div fxLayout="column" fxFlex="100">
                  <h2 style="padding-top: 20px;">Samenvatting</h2>
                  <mat-divider style="margin-top: unset;" class="divider"></mat-divider>
                  <form *ngIf="form && pageDetails.fields" [formGroup]="form">
                    <mat-accordion multi style="padding-bottom: 40px;">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center">
                            Voertuiggegevens
                            <a style="padding-right: 25px;" (click)="navigateToSpecificStep(1)"
                              href="javascript:void(0)">Gegevens wijzigen</a>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <section>
                          <app-form (formChange)="formChange($event)"
                            [show]="['vs_vehicleTypeId', 'vs_bodyTypeId', 'vs_fuelTypeId', 'vs_power', 'vs_cylinderVolume', 'vs_transmissionTypeId', 'v_dateFirstAdmission', 'v_dateFirstRegistration', 'v_dateFirstAdmission', 'vs_dateApkExpires', 'vs_okrStatusTypeId']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>
                      </mat-expansion-panel>

                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center">
                            Opties
                            <a style="padding-right: 25px;" (click)="navigateToSpecificStep(2)"
                              href="javascript:void(0)">Gegevens wijzigen</a>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <section>
                          <div class="label-row">
                            <div><b>Standaardopties</b></div>
                            <div
                              *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_defaultOptions')?.options != null; else noOptionsMessage">
                              <mat-checkbox
                                *ngFor="let option of utils.getSingleFieldFromSpec(pageDetails, 'va_defaultOptions')?.options"
                                [checked]="selectedDefaultOptions.includes(option)" [disabled]="true">
                                {{option.descriptionNl}}
                              </mat-checkbox>
                            </div>
                          </div>

                          <div class="label-row">
                            <div><b>Optiepaketten</b></div>
                            <div
                              *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_packages')?.options != null; else noOptionsMessage">
                              <mat-checkbox
                                *ngFor="let option of utils.getSingleFieldFromSpec(pageDetails, 'va_packages')?.options"
                                [checked]="selectedPackages.includes(option)" [disabled]="true">
                                <b>{{ option.descriptionNl }}</b>
                                (<span *ngFor="let includedOption of option.options; let last = last">
                                  {{ includedOption }}{{ !last ? ',' : '' }}
                                </span>)
                              </mat-checkbox>
                            </div>
                          </div>

                          <div class="label-row">
                            <div><b>Extra opties</b></div>
                            <div *ngIf="utils.getSingleFieldFromSpec(pageDetails, 'va_optionalOptions')?.options != []; else
                            noOptionsMessage">
                              <mat-checkbox
                                *ngFor="let option of utils.getSingleFieldFromSpec(pageDetails, 'va_optionalOptions')?.options"
                                [checked]="selectedOptionalOptions.includes(option)" [disabled]="true">
                                {{option.descriptionNl}}
                              </mat-checkbox>
                            </div>
                          </div>

                          <ng-template #noOptionsMessage>
                            <p>Geen opties geselecteerd.</p>
                          </ng-template>

                          <app-form (formChange)="formChange($event)" [show]="['vs_commentAccessories']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>

                        <section>
                          <app-form (formChange)="formChange($event)"
                            [show]="['vs_colorTypeId', 'vs_lacTypeId', 'vs_interiorColorTypeId', 'vs_liningTypeId']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>

                        <section>
                          <app-form (formChange)="formChange($event)"
                            [show]="['vs_numberOfSeats', 'vs_numberOfKeys', 'vs_aircoStatusTypeId', 'vs_napManualStatusTypeId', 'vs_napStatusTypeId', 'vs_vatMarginTypeId', 'vs_usedAsTypeId', 'vs_lastMaintenanceMileage', 'vs_timingBeltReplacedTypeId', 'vs_importCountryOfOriginTypeId']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>

                        <section>
                          <app-form (formChange)="formChange($event)"
                            [show]="['tax_dateDelivery', 'vs_mileageDelivery']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>
                      </mat-expansion-panel>

                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center">
                            Foto's
                            <a style="padding-right: 25px;" (click)="navigateToSpecificStep(3)"
                              href="javascript:void(0)">Gegevens wijzigen</a>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <section>
                          <app-form (formChange)="formChange($event)" [show]="['vs_images']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>
                      </mat-expansion-panel>

                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center">
                            Schade
                            <a style="padding-right: 25px;" (click)="navigateToSpecificStep(4)"
                              href="javascript:void(0)">Gegevens wijzigen</a>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <section>
                          <app-form (formChange)="formChange($event)"
                            [show]="['vs_conditionBodyTypeId', 'vs_conditionInteriorTypeId', 'vs_smoked', 'vs_pets', 'ds_repairCosts']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>

                        <section>
                          <app-form (formChange)="formChange($event)" [show]="['vs_damageImages']"
                            [fields]="pageDetails.fields"></app-form>
                        </section>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </form>
                </div>
              </div>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </mat-step>
    </mat-stepper>

    <div class="container">
      <div class="sticky-buttons" style="padding-top: 30px; padding-bottom: 100px;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div>
          <button (click)="cancelVehicleDescription()" [disabled]="loading"
            *ngIf="currentStep == 1 && navigationIndexStep == 0" (click)="previousStep()" mat-raised-button
            color="warn">Annuleren</button>

          <button [disabled]="loading" *ngIf="navigationIndexStep > 0" (click)="previousStep()" mat-raised-button
            color="warn">Vorige stap</button>
        </div>

        <div [matTooltipDisabled]="form.valid" matTooltip="Niet alle verplichte velden zijn ingevuld">
          <button [disabled]="form.valid == false || loading" *ngIf="currentStep <= 1" (click)="nextStep()"
            mat-raised-button color="primary">Volgende stap</button>

          <button [disabled]="form.valid == false || loading"
            *ngIf="navigationIndexStep == 5 && currentStep == 2 && currentTab == 0" (click)="submitValuation()"
            mat-raised-button color="primary">Aanvraag afronden</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>