<h1 mat-dialog-title>Schade aan {{findFormElement(this.data.data.damageData, 'd_partTypeId').descriptionNl}} <span
        *ngIf="data.data.type == 'add'">toevoegen</span> <span *ngIf="data.data.type == 'edit'">bewerken</span></h1>
<mtx-loader [loading]="loading" [type]="'spinner'" [color]="'primary'" [hasBackdrop]="true">
    <mat-dialog-content>
        <mat-form-field appearance="fill">
            <mat-label>Onderdeel</mat-label>
            <mat-select [(ngModel)]="damagePart" (selectionChange)="updateDamagePart($event.value)">
                <mat-option *ngFor="let item of currentDamageParts" [value]="item.id">{{item.descriptionNl |
                    capitalize}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Soort</mat-label>
            <mat-select [(ngModel)]="damageType" (selectionChange)="updateDamageType($event.value)">
                <mat-option *ngFor="let item of currentDamageTypes" [value]="item.id">{{item.descriptionNl |
                    capitalize}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Reparatie</mat-label>
            <mat-select [(ngModel)]="damageRepair" (selectionChange)="updateDamageRepair($event.value)">
                <mat-option *ngFor="let item of currentDamageRepairs" [value]="item.id">{{item.descriptionNl |
                    capitalize}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Kosten</mat-label>
            <span matPrefix>&euro;&nbsp;&nbsp;</span>
            <input [(ngModel)]="damageCosts" type="number" matInput>
        </mat-form-field>
    </mat-dialog-content>
</mtx-loader>

<mat-dialog-actions align="start">
    <button mat-raised-button color="warn" (click)="closeDialog()" mat-button>Annuleren</button>
    <button mat-raised-button color="primary" (click)="submit()" mat-button>Opslaan</button>
</mat-dialog-actions>