import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MaintenanceService } from './interceptors/maintenance.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'inruilmodule';

  constructor(private maintenanceService: MaintenanceService, private router: Router) {}
  ngOnInit() {
    this.maintenanceService.isMaintenance$.subscribe((isMaintenance) => {
      if (isMaintenance) {
        // Redirect to maintenance page if not already there
        if (this.router.url !== '/maintenance') {
          this.router.navigate(['/maintenance']);
        }
      }
    });
  }
}