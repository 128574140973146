import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VehicleDescription } from 'src/app/models/vehicle-description';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { VehicleSpecsService } from 'src/app/services/vehicle-specs/vehicle-specs.service';

@Component({
  selector: 'app-wijzig-model',
  templateUrl: './wijzig-model.component.html',
  styleUrls: ['./wijzig-model.component.scss']
})
export class WijzigModelComponent implements OnInit {

  loading: boolean = false;
  public form: FormGroup = new FormGroup({});
  public pageDetails: VehicleDescription = {};

  constructor(
    public dialogRef: MatDialogRef<WijzigModelComponent>,
    public vehicleSpecs: VehicleSpecsService,
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.vehicleSpecs.getPageByName(Number(this.data.data.descriptionId), 'wijzig_model').then(data => {
      this.pageDetails = data;
      this.populateFormElements();
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  // This function is used to create a form control wih all custom fields for validation
  private populateFormElements() {
    // To override form values, add the field name and value to the overrides object
    const overrides: { [key: string]: any } = {
      vs_mileageType: 'km'
    }

    if (this.pageDetails.fields != undefined) {
      for (let field of this.pageDetails.fields) {
        const fieldValue = overrides[field.Field.name] ?? field.value;
        const validators = field.required ? [Validators.required] : null;
        this.form.addControl(field.Field.name, new FormControl(fieldValue, validators));
        if (overrides[field.Field.name]) {
          this.form.get(field.Field.name)?.patchValue(overrides[field.Field.name]);
        }
      }
    }
    this.formChange(this.form);
  }

  // Event when a form field has changed, update the form control.
  public formChange(event: FormGroup) {
    for (let field in event.controls) {
      if (event.controls[field].dirty == true) {
        this.form?.controls[field].patchValue(event.controls[field].value, { emitEvent: false });
      }
    }
  }

  public submit() {
    this.loading = true;
    // Extract fields from form and construct request object
    const fields = this.utils.filterEmptyFormFields(this.form);
    const request = {
      fields,
      pageDetails: true,
      pageName: 'controle'
    };

    this.vehicleSpecs.updateVehicleDescription(this.data.data.descriptionId, request).then(result => {
      this.loading = false;
      // Pass the updated data back to the parent component
      this.dialogRef.close(result);
    });
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }
}
