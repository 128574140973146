import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { VehicleDescription } from 'src/app/models/vehicle-description';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { VehicleSpecsService } from 'src/app/services/vehicle-specs/vehicle-specs.service';
import { WijzigModelComponent } from '../wijzig-model/wijzig-model.component';

@Component({
  selector: 'app-wijzig-gegevens',
  templateUrl: './wijzig-gegevens.component.html',
  styleUrls: ['./wijzig-gegevens.component.scss']
})
export class WijzigGegevensComponent implements OnInit {
  loading: boolean = false;
  public form: FormGroup = new FormGroup({});
  public pageDetails: VehicleDescription = {};
  public updatedData: any;

  public customVsPowerSelected = false;
  public customVsCylinderVolumeSelected = false;

  constructor(
    public dialogRef: MatDialogRef<WijzigGegevensComponent>,
    public vehicleSpecs: VehicleSpecsService,
    private utils: UtilsService,
    private mtxDialog: MtxDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.vehicleSpecs.getPageByName(Number(this.data.data.descriptionId), 'wijzig_gegevens').then(data => {
      this.pageDetails = data;
      this.updatedData = data;
      this.populateFormElements();
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  private populateFormElements() {
    const overrides: { [key: string]: any } = {}
    if (this.pageDetails.fields != undefined) {
      for (let field of this.pageDetails.fields) {
        const fieldValue = overrides[field.Field.name] ?? field.value;
        const validators = field.required ? [Validators.required] : null;
        this.form.addControl(field.Field.name, new FormControl(fieldValue, validators));
        if (overrides[field.Field.name]) {
          this.form.get(field.Field.name)?.patchValue(overrides[field.Field.name]);
        }
      }
    }
    this.formChange(this.form);
  }

  // Event when a form field has changed, update the form control.
  public formChange(event: FormGroup) {
    for (let field in event.controls) {
      if (event.controls[field].dirty == true) {
        this.form?.controls[field].patchValue(event.controls[field].value, { emitEvent: false });
      }
    }
  }

  public update(field?: string | null) {
    if (field == 'vs_cylinderVolume') {
      if (this.form.controls['vs_cylinderVolume'].value == 'custom') {
        this.customVsCylinderVolumeSelected = true;
        return;
      } else {
        this.customVsCylinderVolumeSelected = false;
      }
    }

    if (field == 'vs_power') {
      if (this.form.controls['vs_power'].value == 'custom') {
        this.customVsCylinderVolumeSelected = true;
        return;
      } else {
        this.customVsCylinderVolumeSelected = false;
      }
    }

    this.loading = true;

    // Extract fields from form and construct request object
    const fields = this.utils.convertFormFields(this.form);
    const request = {
      fields,
      pageDetails: true,
      pageName: 'wijzig_gegevens'
    };

    this.vehicleSpecs.updateVehicleDescription(this.data.data.descriptionId, request).then(data => {
      this.updatedData = data;
      if (data.fields) {
        for (let field of data.fields) {
          if (field.Field.name == 'vs_power') {
            if (field.options?.length == 0) {
              this.customVsPowerSelected = true;
            } else {
              this.customVsPowerSelected = false;
            }
          }

          if (field.Field.name == 'vs_cylinderVolume') {
            if (field.options?.length == 0) {
              this.customVsCylinderVolumeSelected = true;
            } else {
              this.customVsCylinderVolumeSelected = false;
            }
          }
        }
      }
      this.loading = false;
    });
  }

  public submit() {
    if (this.form.valid == true) {
      this.loading = true;
      // Extract fields from form and construct request object
      const fields = this.utils.filterEmptyFormFields(this.form);
      const request = {
        fields,
        pageDetails: true,
        pageName: 'controle'
      };

      this.vehicleSpecs.updateVehicleDescription(this.data.data.descriptionId, request).then(data => {
        if (this.utils.getSingleFieldFromSpec(this.pageDetails, 'v_fictiveRegistration')?.value == true || this.utils.getSingleFieldFromSpec(this.pageDetails, 'vs_atlCode')?.value == null) {
          this.mtxDialog.open({
            width: '800px',
            data: { descriptionId: this.data.data.descriptionId }
          }, WijzigModelComponent).afterClosed().subscribe(result => {
            this.loading = false;
            this.dialogRef.close(result);
          });
        } else {
          this.loading = false;
          this.dialogRef.close(data);
        }
      });
    }
  }

  public getVehicleTypes() {
    if (Object.keys(this.pageDetails).length === 0) {
      return [];
    } else {
      return this.utils.getSingleFieldFromSpec(this.pageDetails, 'vs_vehicleTypeId')?.options ?? [];
    }
  }

  public getModels() {
    if (Object.keys(this.updatedData).length === 0) {
      return [];
    } else {
      return this.utils.getSingleFieldFromSpec(this.updatedData, 'v_modelSpecific')?.options ?? [];
    }
  }

  public getMakes() {
    if (Object.keys(this.updatedData).length === 0) {
      return [];
    } else {
      return this.utils.getSingleFieldFromSpec(this.updatedData, 'v_make')?.options ?? [];
    }
  }

  public getBodyTypes() {
    if (Object.keys(this.updatedData).length === 0) {
      return [];
    } else {
      return this.utils.getSingleFieldFromSpec(this.updatedData, 'vs_bodyTypeId')?.options ?? [];
    }
  }

  public getTransmissionTypes() {
    if (Object.keys(this.updatedData).length === 0) {
      return [];
    } else {
      return this.utils.getSingleFieldFromSpec(this.updatedData, 'vs_transmissionTypeId')?.options ?? [];
    }
  }

  public getFuelTypes() {
    if (Object.keys(this.updatedData).length === 0) {
      return [];
    } else {
      return this.utils.getSingleFieldFromSpec(this.updatedData, 'vs_fuelTypeId')?.options ?? [];
    }
  }

  public getCylinderVolumes() {
    if (Object.keys(this.updatedData).length === 0) {
      return [];
    } else {
      return this.utils.getSingleFieldFromSpec(this.updatedData, 'vs_cylinderVolume')?.options ?? [];
    }
  }

  public getPowerOptions() {
    if (Object.keys(this.updatedData).length === 0) {
      return [];
    } else {
      return this.utils.getSingleFieldFromSpec(this.updatedData, 'vs_power')?.options ?? [];
    }
  }

  isPageDetailsEmpty(): boolean {
    return Object.keys(this.pageDetails).length === 0;
  }


  public closeDialog(): void {
    this.dialogRef.close(false);
  }
}
