import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { ProxyComponent } from './components/proxy/proxy.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: ':templateId/token/:token/params/:params',
    component: ProxyComponent
  },
  {
    path: ':templateId/token/:token',
    component: ProxyComponent
  },
  // Note: this path is only in use for AVP!
  {
    path: ':action/:templateId/token/:token/params/:params',
    component: ProxyComponent
  },
  {
    path: ':templateId/api-key/:apiKey',
    component: ProxyComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: '',
    component: RedirectComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
