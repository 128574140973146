import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationData } from 'src/app/models/authentication';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private authService: AuthenticationService,
    private snackBar: MatSnackBar
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const data: AuthenticationData = this.authService.getAuthData();
    if (data && !request.url.match(/oauth|authentication/gi) && !this.isRefreshing) {
      const headers = this.buildHeaders(data, request.headers);
      request = request.clone({ headers });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError(error => this.handleError(error, data))
    );
  }

  private buildHeaders(data: AuthenticationData, headers: HttpHeaders) {
    const descriptionToken = localStorage.getItem('descriptionToken');
    if (descriptionToken) {
      return headers.set('x-description-token', descriptionToken).set('Authorization', `Bearer ${data.access_token}`);
    } else {
      return headers.set('Authorization', `Bearer ${data.access_token}`);
    }
  }

  private handleError(error: HttpErrorResponse, data: AuthenticationData) {
    if (error.error.title === 'invalid_token_ip') {
      this.authService.logout();
      return throwError(() => (error))
    } else {
      if (!this.isRefreshing && (error.status === 401 || error.status === 400)) {
        this.isRefreshing = true;
        if (!this.authService.checkAccessTokenValidity()) {
          this.authService.loginWithRefreshToken(data.refresh_token).subscribe(() => {
            this.isRefreshing = false;
          }, error => {
            localStorage.clear();
            this.snackBar.open(`Er is een fout opgetreden: ${error.error.detail}`, 'Sluiten');
            return throwError(() => (error))
          });
        } else {
          localStorage.clear();
          this.snackBar.open(`Er is een fout opgetreden: ${error.error.detail}`, 'Sluiten');
          return throwError(() => (error))
        }
      }
      return throwError(() => (error))
    }
  }

  doLogout() {
    this.authService.logout();
  }
}
