import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse } from 'src/app/models/api-response';
import { GetVehicleDescription, StartVehicleDescription, UpdateVehicleDescription, VehicleDescriptionDamage, VehicleDescriptionDamageImage, VehicleDescriptionImage } from 'src/app/models/vehicle-description';
import { GetVehicleSpecs } from 'src/app/models/vehicle-specs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private api_base: string = environment.apiUrl;
  private RequestHeaders = new HttpHeaders();
  constructor(private http: HttpClient) { }

  /*-------------------------------
  == Communication CALLS ==
  --------------------------------*/
  // GET
  getAnnouncements(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any) {
    const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
    let Params = new HttpParams()
    Params = Params.append('limit', String(limit));
    Params = Params.append('page', String(page));
    if (orderBy) Params = Params.append('orderBy', orderBy);
    if (filter) Params = filter && Params.append('filter', filter);
    if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
    if (fields) Params = fields && Params.append('fields', fields);
    return this.http.get<apiResponse>(this.api_base + '/communication/v1/announcement',
      { headers: RequestHeaders, params: Params });
  }

  /*-------------------------------
  == General CALLS ==
  --------------------------------*/
  // GET
  ping() {
    return this.http.get<apiResponse>(`${this.api_base}/ping`,
      { headers: this.RequestHeaders });
  }

  /*-------------------------------
  == VehicleDescription CALLS ==
  --------------------------------*/
  // POST
  startVehicleDescription(body: StartVehicleDescription) {
    if (body.force != null || body.force != undefined) {
      body.skipHistoricRequestCheck = body.force;
      delete body.force;
    }
    
    this.RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<apiResponse>(`${this.api_base}/request/v1/vehicle-description`, body,
      { headers: this.RequestHeaders });
  }

  addVehicleDescriptionImage(descriptionId: number, positionType: string, fileName: string, type: string, image: any) {
    let formData = new FormData();
    formData.append('media', image, fileName);
    formData.append('positionType', positionType);
    formData.append('type', type);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${descriptionId}/image`, formData);
  }

  addVehicleDescriptionMedia(descriptionId: number, type?: string, contentType?: string, media?: string, mediaUrl?: string, positionTypeId?: number, positionType?: string) {
    const formData = new FormData();
    if (type) formData.set('type', type);
    if (contentType) formData.append('contentType', contentType);
    if (media) formData.append('media', media);
    if (mediaUrl) formData.append('mediaUrl', mediaUrl);
    if (positionTypeId) formData.append('positionTypeId', positionTypeId.toString());
    if (positionType) formData.append('positionType', positionType);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${descriptionId}/media`, formData, { headers: headers });
  }

  addVehicleDescriptionDamage(id: number, body: VehicleDescriptionDamage) {
    this.RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/damage`, body,
      { headers: this.RequestHeaders });
  }

  addVehicleDescriptionDamageImage(id: number, body: VehicleDescriptionDamageImage) {
    this.RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/image`, body,
      { headers: this.RequestHeaders });
  }

  finishVehicleDescription(id: number) {
    this.RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/finish`,
      { headers: this.RequestHeaders });
  }

  resumeLater(id: number, body: any) {
    this.RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/finish-later`, body,
      { headers: this.RequestHeaders });
  }

  // GET
  getVehicleDescriptions(params: GetVehicleDescription) {
    let parameters = new HttpParams({ fromString: Object.entries(params).map(e => e.join('=')).join('&') });
    return this.http.get<apiResponse>(`${this.api_base}/request/v1/vehicle-description`,
      { headers: this.RequestHeaders, params: parameters });
  }

  getVehicleDescriptionsPageByName(id: number, page: string) {
    return this.http.get<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/page/${page}`,
      { headers: this.RequestHeaders });
  }

  getVehicleDescriptionById(id: number) {
    return this.http.get<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}`,
      { headers: this.RequestHeaders });
  }

  // DELETE
  deleteVehicleDescription(id: number) {
    return this.http.delete<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}`,
      { headers: this.RequestHeaders });
  }

  deleteVehicleDescriptionImage(id: number, imageId: number) {
    return this.http.delete<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/image/${imageId}`,
      { headers: this.RequestHeaders });
  }

  deleteVehicleDescriptionMedia(id: number, imageId: number) {
    return this.http.delete<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/media/${imageId}`,
      { headers: this.RequestHeaders });
  }

  deleteVehicleDescriptionDamage(id: number, damageId: number) {
    return this.http.delete<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/damage/${damageId}`,
      { headers: this.RequestHeaders });
  }

  // PUT
  updateVehicleDescription(id: number, body: UpdateVehicleDescription) {
    this.RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.put<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}`, body,
      { headers: this.RequestHeaders });
  }

  updateVehicleDescriptionDamage(id: number, damageId: number, body: VehicleDescriptionDamage) {
    this.RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.put<apiResponse>(`${this.api_base}/request/v1/vehicle-description/${id}/damage/${damageId}`, body,
      { headers: this.RequestHeaders });
  }

  /*-------------------------------
  == VehicleSpecification CALLS ==
  --------------------------------*/
  // GET
  getPages(params: GetVehicleSpecs) {
    let parameters = new HttpParams({ fromString: Object.entries(params).map(e => e.join('=')).join('&') });
    return this.http.get<apiResponse>(`${this.api_base}/vehicle-specs/v1/page`,
      { headers: this.RequestHeaders, params: parameters });
  }

  // OPTIONS
  getPagesOptions() {
    return this.http.options<apiResponse>(`${this.api_base}/vehicle-specs/v1/page`,
      { headers: this.RequestHeaders });
  }
}
