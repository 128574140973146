import { Component, Input, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { VehicleDescription } from 'src/app/models/vehicle-description';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { VehicleSpecsService } from 'src/app/services/vehicle-specs/vehicle-specs.service';
import { WijzigModelComponent } from '../../modals/wijzig-model/wijzig-model.component';
import { MatDialog } from '@angular/material/dialog';
import { WijzigGegevensComponent } from '../../modals/wijzig-gegevens/wijzig-gegevens.component';
import { HistorischeAanvragenComponent } from '../../modals/historische-aanvragen/historische-aanvragen.component';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { SpecsFilePickerAdapter } from './file-upload-adapter';
import { HttpClient } from '@angular/common/http';
import { FilePickerComponent, FilePreviewModel, UploaderCaptions } from 'ngx-awesome-uploader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from 'src/app/services/api/api.service';
import { Notification } from 'src/app/models/notification';
import * as moment from 'moment';

@Component({
  selector: 'app-template-108',
  templateUrl: './template-108.component.html',
  styleUrls: ['./template-108.component.scss']
})
export class Template108Component implements OnInit {
  @Input() template!: number;
  @Input() parameters!: any;
  @Input() authReady!: any;
  @Input() isResuming!: boolean;

  public loading = false;
  public skeletonLoading = false;
  public showResumeLaterText = false;
  public validationError = false;
  public showMileageError = false;
  public showTimingBeltReplacedDate = false;
  public today = moment();
  public isAtlCodeChanging = false;
  public errorMessages: any;
  public pageDetails: VehicleDescription = {};
  public notificationData: Notification | undefined;
  public form: FormGroup = new FormGroup({});
  private pages = ['start', 'controle', 'voertuig', 'fotos', 'schade', 'samenvatting'];
  public currentDescriptionId: number | undefined;
  public currentRegistration: string | undefined;
  public submitPressed = false;
  public carPreviewImages = null;
  public minDateDelivery = new Date(new Date().setDate(new Date().getDate() + 1));
  public maxDateDelivery = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  // Template variables
  public hasDamages: boolean | null = null;
  public navigationIndexStep = 0;
  public currentStep = 0;
  public currentTab = 0;
  public requiredImages = 0;
  public isFictiveRegistrationFlow = false;
  public damageCosts = 350;

  // Variables for options
  selectedPackages: any[] = [];
  selectedOptionalOptions: any[] = [];
  selectedDefaultOptions: any[] = [];

  @ViewChild('stepperVehicle') stepperVehicle!: MatTabGroup;
  @ViewChild('stepperCondition') stepperCondition!: MatTabGroup;
  @ViewChild('stepperSummary') stepperSummary!: MatTabGroup;
  @ViewChild('uploader') uploader!: FilePickerComponent;
  @ViewChild('mainStepper') private mainStepper!: MatStepper;

  adapter: SpecsFilePickerAdapter | undefined;
  public captions: UploaderCaptions = {
    dropzone: {
      title: 'Sleep afbeeldingen om te uploaden',
      or: 'Of klik op "browsen" om bestanden te selecteren.',
      browse: 'Browsen'
    },
    cropper: {
      crop: 'Knippen',
      cancel: 'Annuleren'
    },
    previewCard: {
      remove: 'Verwijderen',
      uploadError: 'Er is een fout opgetreden tijdens het uploaden'
    }
  };

  constructor(
    public vehicleSpecs: VehicleSpecsService,
    public utils: UtilsService,
    private mtxDialog: MtxDialog,
    public viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private apiService: ApiService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['authReady'] && changes['authReady'].currentValue == true) {
      this.skeletonLoading = true;

      // Check if we need to retrieve notification data
      if (this.currentStep === 0) {
        this.apiService.getAnnouncements(1, 1).subscribe((data: any) => {
          this.notificationData = data.data[0];
        });
      }

      const currentUrl = window.location.href;
      const currentUrlBase64 = btoa(unescape(encodeURIComponent(currentUrl)));
      const savedUrlBase64 = localStorage.getItem('urlBase64');

      if (this.isResuming || savedUrlBase64 === currentUrlBase64) {
        // The Base64 encodings match, so we're resuming
        this.setLocalSpecData();
        this.resumeVehicleDescription();
      } else {
        // The Base64 encodings don't match, so we'll start a new valuation
        localStorage.setItem('urlBase64', currentUrlBase64);
        // Assuming you have a method named startVehicleDescription, uncomment the line below
        this.startVehicleDescription();
      }
    }
  }

  ngOnInit(): void {
    this.loading = true;

    var c = document.createElement('script');
    c.type = "text/javascript";
    c.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "o66qs1yvzl");`;
    document.head.appendChild(c);
  }

  private setLocalSpecData() {
    if (localStorage.getItem('navigationData') != null) {
      const data = JSON.parse(localStorage.getItem('navigationData') || '{}');
      this.currentDescriptionId = data.descriptionId;
      this.navigationIndexStep = data.navigationIndexStep;
      this.currentStep = data.currentStep;
      this.currentTab = data.currentTab;
    }
  }

  private saveLocalSpecData() {
    localStorage.setItem('navigationData', JSON.stringify({ descriptionId: this.currentDescriptionId, navigationIndexStep: this.navigationIndexStep, currentStep: this.currentStep, currentTab: this.currentTab }));
    this.setLocalSpecData();
  }

  private startVehicleDescription() {
    this.saveLocalSpecData();
    this.getAndMapPages().then((navigationData: any[]) => {
      this.pageDetails.fields = navigationData[0].FormFields;
      this.populateFormElements();
      this.loading = false;
      this.skeletonLoading = false;
    })
  }

  public async nextStep(): Promise<void> {
    // Set loading flag and scroll to top of page
    this.loading = true;
    window.scroll(0, 0);

    // Override mileage history check status if not set
    if (this.pages[this.navigationIndexStep] === 'controle') {
      if (this.form.controls['vs_atlCode'].value == 'null') {
        this.form.controls['vs_atlCode'].setValue(null);
      }
    }

    // Extract fields from form and construct request object
    let fields = this.utils.filterEmptyFormFields(this.form);

    // Strip all dashes from registration
    if (fields.v_registration) {
      fields.v_registration = fields.v_registration.replace(/-/g, '');
    }

    const request = {
      fields,
      pageDetails: true,
      pageName: this.pages[this.navigationIndexStep + 1] // Page name is based on the current navigation index step
    };

    // Override page when fictive registration is set
    if (request.fields['v_fictiveRegistration'] == true && this.navigationIndexStep == 0) {
      request.pageName = 'wijzig_gegevens';
    }

    // If a vehicle description already exists, update it
    if (this.currentDescriptionId) {
      if (this.pages[this.navigationIndexStep] === 'fotos') {
        // Uset data in request object to update vehicle description
        request.fields = null;
      }

      if (this.pages[this.navigationIndexStep] === 'schade') {
        delete request.fields['vs_damageImages'];
      }

      if (this.pages[this.navigationIndexStep] === 'controle') {
        delete request.fields['vs_mileageHistoryCheckStatusTypeId'];
      }

      console.log('Updating vehicle description');
      const data = await this.vehicleSpecs.updateVehicleDescription(this.currentDescriptionId, request);
      if (data.error) {
        // If there was an error starting the description, display an error message and set validation error flag
        this.errorMessages = data.message;
        this.validationError = true;
        this.submitPressed = false;
        this.loading = false;
      } else {
        this.pageDetails = data; // Update page details with new data
        this.handlePageIndexChange('next'); // Handle page index change
      }
    } else {
      // If no vehicle description exists, start a new one
      console.log('No description id found, starting new vehicle description');
      const data = await this.vehicleSpecs.startVehicleDescription({ fields, skipHistoricRequestCheck: false, pageDetails: true, pageName: request.pageName }, false);
      if (data.error) {
        // If there was an error starting the description, display an error message and set validation error flag
        this.errorMessages = data.message;
        this.validationError = true;
        this.skeletonLoading = false;
        this.loading = false;
        return;
      }
      if (data.historicRequests && data.historicRequests.length > 0) {
        // If there are historic requests, display the `HistorischeAanvragenComponent`
        this.skeletonLoading = false;
        const result = await this.dialog.open(HistorischeAanvragenComponent, { data }).afterClosed().toPromise();

        if (result) {
          // If the user confirms starting a new description, start a new one with `skipHistoricRequestCheck` set to `true`
          const newData = await this.vehicleSpecs.startVehicleDescription({ fields, skipHistoricRequestCheck: true, pageDetails: true, pageName: request.pageName }, false);
          if (newData.error) {
            // If there was an error starting the new description, display an error message and set validation error flag
            this.errorMessages = newData.message;
            this.validationError = true;
            this.skeletonLoading = false;
            this.loading = false;
            return;
          }
          this.currentDescriptionId = newData.descriptionId; // Update current description ID
          this.pageDetails = newData; // Update page details with new data
        } else {
          // If the user cancels starting a new description, stop the loading spinner and return
          this.skeletonLoading = false;
          this.loading = false;
          return;
        }
      } else {
        // If there are no historic requests, start a new description normally
        this.currentDescriptionId = data.descriptionId; // Update current description ID
        this.pageDetails = data; // Update page details with new data
      }

      if (request.fields['v_fictiveRegistration'] === true) {
        this.isFictiveRegistrationFlow = true;
        // Ficitve registration flag is set, show wijzig gegevens modal
        this.openWijzigGegevensModal();
      } else {
        this.handlePageIndexChange('next'); // Handle page index change
        this.saveLocalSpecData(); // Save form data to local storage
        this.validationError = false; // Reset validation error flag
      }
    }

    // Clear form, populate form elements, set tab to true, and stop loading spinner
    this.form = new FormGroup({});
    this.populateFormElements();
    this.loading = false;
    this.skeletonLoading = false;
  }

  public async previousStep(): Promise<void> {
    // Set loading flag and scroll to top of page
    this.loading = true;
    this.skeletonLoading = true;
    window.scroll(0, 0);
    this.pageDetails = {};

    // Update or start a vehicle description based on the current description ID
    const data = await this.vehicleSpecs.getPageByName(Number(this.currentDescriptionId), this.pages[Number(this.navigationIndexStep) - 1]);

    // If there was an error, display error message and return
    if (data.error) {
      this.errorMessages = data.message;
      this.validationError = true;
      return;
    }

    // Update page details and current description ID
    this.pageDetails = data;

    // Decrement the navigation index step
    this.handlePageIndexChange('previous');

    // Clear form, populate form elements, and set tab to true
    this.form = new FormGroup({});
    this.populateFormElements();
    this.skeletonLoading = false;
    this.loading = false;
  }

  // Function triggered when submitting the valuation (in UI)
  public submitValuation() {
    this.loading = true;

    // Check if fields have been changed
    const changedFields = this.utils.filterChangedFormFields(this.form);
    if (Object.keys(changedFields).length === 0) {
      this.finishValuation();
    } else {
      const request = {
        fields: changedFields,
        pageDetails: false,
        pageName: this.pages[this.currentStep]
      };

      // Update changed data
      this.vehicleSpecs.updateVehicleDescription(this.currentDescriptionId!, request)
        .then(data => {
          this.finishValuation();
        });
    }
  }

  // Function that actually finishes the valuation
  private finishValuation() {
    this.vehicleSpecs.finishVehicleDescription(Number(this.currentDescriptionId))
      .then(data => {
        if (data.error === false) {
          this.validationError = false;
          localStorage.clear();
          parent.window.location.href = data.data.redirectUrl;
        } else {
          // Show validation error
          this.validationError = true;
          this.errorMessages = data.message;
          this.loading = false;
        }
      });
  }

  handlePageIndexChange(type: string | number) {
    if (type === 'next') {
      this.navigationIndexStep++;
    } else if (type === 'previous') {
      this.navigationIndexStep--;
    } else {
      this.navigationIndexStep = Number(type);
    }

    if (this.pages[this.navigationIndexStep] != undefined) {
      switch (this.navigationIndexStep) {
        // Start
        case 0:
          this.currentStep = 0;
          this.currentTab = 0;
          this.mainStepper.selectedIndex = this.currentStep;
          this.stepperVehicle.selectedIndex = this.currentTab;
          break;
        // Voertuiggegevens (controle)
        case 1:
          this.currentStep = 0;
          this.currentTab = 1;
          this.mainStepper.selectedIndex = this.currentStep;
          this.stepperVehicle.selectedIndex = this.currentTab;
          break;
        // Opties (voertuig)
        case 2:
          this.currentStep = 0;
          this.currentTab = 2;
          this.mainStepper.selectedIndex = this.currentStep;
          this.stepperVehicle.selectedIndex = this.currentTab;
          break;
        // Fotos
        case 3:
          this.currentStep = 1;
          this.currentTab = 0;
          this.mainStepper.selectedIndex = this.currentStep;
          this.stepperCondition.selectedIndex = this.currentTab;
          break;
        // Schade
        case 4:
          this.currentStep = 1;
          this.currentTab = 1;
          this.mainStepper.selectedIndex = this.currentStep;
          this.stepperCondition.selectedIndex = this.currentTab;
          break;
        case 5:
          this.currentStep = 2;
          this.currentTab = 0;
          // Navigate over 1 to allow to navigate to 2nd step
          this.mainStepper.selectedIndex = 1;
          this.mainStepper.selectedIndex = this.currentStep;
          this.stepperSummary.selectedIndex = this.currentTab;
          break;
        default:
          // Default to voertuiggegevens (controle)
          this.currentStep = 0;
          this.currentTab = 1;
          this.mainStepper.selectedIndex = this.currentStep;
          this.stepperVehicle.selectedIndex = this.currentTab;
          break;
      }
    } else {
      console.error('Page not found, defaulting to voertuiggegevens (controle)')
      this.navigationIndexStep = 1;
      this.currentStep = 0;
      this.currentTab = 1;
      this.mainStepper.selectedIndex = this.currentStep;
      this.stepperVehicle.selectedIndex = this.currentTab;
    }
    this.saveLocalSpecData();
  }

  public async navigateToSpecificStep(stepIndex: number) {
    // Set loading flag and scroll to top of page
    this.loading = true;
    this.skeletonLoading = true;

    // Clear page details and form
    window.scroll(0, 0);
    this.pageDetails = {};
    this.form = new FormGroup({});
    this.populateFormElements();

    // Get navigation data for the step in memory
    this.vehicleSpecs.getPageByName(Number(this.currentDescriptionId), this.pages[stepIndex]).then(data => {
      this.saveLocalSpecData();
      this.pageDetails = data;
      this.populateFormElements();
      this.skeletonLoading = false;
      this.loading = false;
      this.handlePageIndexChange(Number(stepIndex));
    }, error => {
      localStorage.clear();
      this.skeletonLoading = false;
      this.loading = false;
    })
  }

  private resumeVehicleDescription() {
    this.loading = true;
    this.skeletonLoading = true;

    // Set parameters if set
    if (this.parameters != null && this.isResuming == true) {
      const parameters = JSON.parse(atob(this.parameters));
      if (parameters) {
        this.currentDescriptionId = parameters.general.description_id;
        localStorage.setItem('descriptionToken', parameters.general.description_token);
      }
    }

    // Get page structure
    this.getAndMapPages().then((navigationData: any[]) => {
      if (this.currentDescriptionId) {
        this.vehicleSpecs.getVehicleDescriptionById(this.currentDescriptionId).then(data => {
          // Set current step
          this.navigationIndexStep = Number((this.pages.findIndex((page: string) => page == data.data.lastPage)));
          let step = null
          if (this.pages[this.navigationIndexStep] != undefined) {
            step = this.pages[this.navigationIndexStep];
          } else {
            this.navigationIndexStep = 1;
            step = 'controle'
            console.log('Page not found, defaulting to voertuiggegevens (controle)');
          }
          // Get navigation data for the step in memory
          this.vehicleSpecs.getPageByName(Number(this.currentDescriptionId), step).then(data => {
            this.handlePageIndexChange(this.navigationIndexStep);
            this.saveLocalSpecData();
            this.pageDetails = data;
            this.populateFormElements();
            this.skeletonLoading = false;
            this.loading = false;
            console.log('Vehicle description resumed');
          }, error => {
            localStorage.clear();
            this.skeletonLoading = false;
            this.loading = false;
          })
        });
      } else {
        console.log('No description id found, starting new vehicle description');
        this.startVehicleDescription();
      }
    });
  }

  // This function is used to create a form control wih all custom fields for validation
  private populateFormElements() {
    // Define an array of field names to check for null
    const fieldsToCheck: string[] = ['v_fictiveRegistration', 'vs_vehicleTypeId', 'vs_bodyTypeId', 'vs_fuelTypeId'];

    // Check if any of the specified fields are null
    if (this.pageDetails?.fields &&
      fieldsToCheck.some(fieldName => {
        const field = this.pageDetails!.fields!.find(f => f.Field.name === fieldName) as any;
        return field && field.value === null;
      }) &&
      this.navigationIndexStep === 1
    ) {
      this.isFictiveRegistrationFlow = true;
      // Fictive registration flag is set, show wijzig gegevens modal
      this.openWijzigGegevensModal();
    }

    this.carPreviewImages = this.utils.getVehiclePreviewImages(this.pageDetails);
    // To override form values, add the field name and value to the overrides object
    const overrides: { [key: string]: any } = {
      vs_mileageType: 'km',
      vs_damageImages: 1,
    }

    if (this.pageDetails.fields != undefined) {
      for (let field of this.pageDetails.fields) {
        // Filter out string fields (these are not used in the form)
        if (field.FieldType.name != 'string') {
          const fieldValue = overrides[field.Field.name] ?? field.value;
          const validators = field.required ? [Validators.required] : null;
          this.form.addControl(field.Field.name, new FormControl(fieldValue, validators));
          if (overrides[field.Field.name]) {
            this.form.get(field.Field.name)?.patchValue(overrides[field.Field.name]);
          }

          // Check if the vehicle has damages (and show damage template if so)
          if (field.Field.name == 'vs_damages') {
            if (field.value == null || field.value.length == 0) {
              this.hasDamages = null;
            } else {
              if (field.value.length > 0) {
                this.hasDamages = true;
              } else {
                this.hasDamages = false;
              }
            }
          }

          if (field.Field.name === 'vs_images') {
            // Init file uploader
            this.adapter = new SpecsFilePickerAdapter(this.vehicleSpecs, Number(this.currentDescriptionId), 'vehicle');
            let files = [] as unknown as FilePreviewModel[];
            // Check if field.value exists and is an array before proceeding
            if (Array.isArray(field.value) && field.value.length > 0) {
              const downloadAndPushImage = async (url: string) => {
                try {
                  const response = await fetch(url);
                  const blob = await response.blob();
                  files.push({ fileName: url, file: blob, uploadResponse: null });
                } catch (error) {
                  console.error('Error downloading image:', error);
                }
              };

              const downloadPromises = field.value.map((image: any) => downloadAndPushImage(image.image));
              Promise.all(downloadPromises).then(() => {
                setTimeout(() => {
                  if (this.uploader != undefined) {
                    this.uploader.enableAutoUpload = false;
                    this.uploader.setFiles(files);
                    this.uploader.enableAutoUpload = true;
                  }
                }, 1000);
              });
            }
          }

          // Function to filter options based on selected values
          function filterFieldOptions(field: any, options: any[]): any[] {
            // Check if both field.value and options are arrays
            if (Array.isArray(field.value) && Array.isArray(options)) {
              // Convert selected values to numbers
              const optionIds = field.value.map(Number);
              // Filter options based on selected values
              return options.filter(option => optionIds.includes(Number(option.id)));
            }
            // Return an empty array if either field.value or options is not an array
            return [];
          }

          if (field.Field.name === 'va_defaultOptions') {
            // Filter and assign selected default options
            this.selectedDefaultOptions = filterFieldOptions(field, field.options || []);
          }

          if (field.Field.name === 'va_optionalOptions') {
            // Filter and assign selected optional options
            this.selectedOptionalOptions = filterFieldOptions(field, field.options || []);
          }

          if (field.Field.name === 'va_packages') {
            // Filter and assign selected packages
            this.selectedPackages = filterFieldOptions(field, field.options || []);
          }
        }
      }
    }
    this.formChange(this.form);
  }

  // Event when a form field has changed, update the form control.
  public formChange(event: FormGroup) {
    for (let field in event.controls) {
      if (event.controls[field].dirty == true) {
        if (this.form?.controls[field] != undefined) {
          this.form?.controls[field].patchValue(event.controls[field].value, { emitEvent: false });
        }
      }
    }
  }

  public cancelVehicleDescription() {
    localStorage.clear();
    window.close();
  }

  public setHasDamage(value: boolean) {
    this.hasDamages = value;
  }

  public openWijzigModelModal() {
    const data = this.pageDetails.fields;
    this.pageDetails.fields = undefined;
    this.skeletonLoading = true;
    this.loading = true;
    this.mtxDialog.open({
      width: '800px',
      data: { descriptionId: this.currentDescriptionId }
    }, WijzigModelComponent).afterClosed().subscribe(result => {
      if (result) {
        this.pageDetails.fields = result.fields;
        this.form = new FormGroup({});
        this.populateFormElements();
        this.skeletonLoading = false;
        this.loading = false;
      } else {
        this.pageDetails.fields = data;
        this.skeletonLoading = false;
        this.loading = false;
      }
    });
  }

  public openWijzigGegevensModal() {
    const data = this.pageDetails.fields;
    this.pageDetails.fields = undefined;
    this.skeletonLoading = true;
    this.loading = true;
    this.mtxDialog.open({
      width: '800px',
      data: { descriptionId: this.currentDescriptionId }
    }, WijzigGegevensComponent).afterClosed().subscribe(result => {
      if (result) {
        this.pageDetails.fields = result.fields;
        this.form = new FormGroup({});
        this.populateFormElements();
        this.skeletonLoading = false;
        this.loading = false;
      } else {
        this.pageDetails.fields = data;
        this.skeletonLoading = false;
        this.loading = false;
      }
    });
  }

  private getAndMapPages(): any {
    this.pages = [];
    return new Promise((resolve, reject) => {
      this.vehicleSpecs.getPages(null, [{ field: "sequence", direction: "ASC" }], null, ['requiredPages']).then(navigationData => {
        for (const page of navigationData) {
          this.pages.push(page.name)
        }
        resolve(navigationData);
      }, error => {
        this.skeletonLoading = false;
        this.loading = false;
        reject(error);
      })
    });
  }

  public damageFormChanged(event: any) {
    this.form.controls['ds_repairCosts'].setValue(event.totalCosts);
    this.form.controls['vs_commentTechnicalDefects'].setValue(event.form.controls['vs_commentTechnicalDefects'].value);
  }

  public updateMileageDelivery() {
    const formControls = this.form.controls;
    const vsMileageControl = formControls['vs_mileage'];
    const vDateFirstAdmissionControl = formControls['v_dateFirstAdmission'];
    const taxDateDeliveryControl = formControls['tax_dateDelivery'];

    if (vsMileageControl && vDateFirstAdmissionControl && taxDateDeliveryControl) {
      const mileage = this.utils.calculateMileageDelivery(
        moment(vDateFirstAdmissionControl.value, "YYYY-MM-DD").toDate(),
        moment(taxDateDeliveryControl.value, "YYYY-MM-DD").toDate(),
        vsMileageControl.value
      );
      formControls['vs_mileageDelivery'].setValue(mileage);
    } else {
      if (!vsMileageControl) {
        console.warn("vs_mileage control does not exist.");
      }
      if (!vDateFirstAdmissionControl) {
        console.warn("v_dateFirstAdmission control does not exist.");
      }
      if (!taxDateDeliveryControl) {
        console.warn("tax_dateDelivery control does not exist.");
      }
    }
  }

  public checkMileageDelivery() {
    if (Number(this.utils.getSingleFieldFromSpec(this.pageDetails, 'vs_mileage')?.value) > Number(this.form.controls['vs_mileageDelivery'].value)) {
      this.showMileageError = true;
    } else {
      this.showMileageError = false;
    }
  }

  public checkTimingBeltReplaced() {
    if (this.form.controls['vs_timingBeltReplacedTypeId'].value == 1) {
      this.showTimingBeltReplacedDate = true;
    } else {
      this.showTimingBeltReplacedDate = false;
    }
  }

  public getVsAtlCode() {
    const data = this.utils.getSingleFieldFromSpec(this.pageDetails, 'vs_atlCode');
    if (data?.value && data.options) {
      for (const element of data.options!) {
        if (element.id === data.value) {
          return element.type + ' ' + element.descriptionNl;
        }
      }
    }
    return 'Onbekend';
  }

  public getSingleField(field: string): any {
    const data = this.utils.getSingleFieldFromSpec(this.pageDetails, field);
    if (data?.value != null) {
      return data;
    }
    return 'Onbekend';
  }

  public defaultOptionUpdated(event: any, option: any) {
    if (event.checked === true) {
      // If the option is selected, add the whole option object to the array
      if (!this.selectedDefaultOptions.includes(option)) {
        this.selectedDefaultOptions.push(option);

        // Check if the option's Group.id is present in optionalOptions
        const groupId = option.Group.id;
        this.selectedOptionalOptions = this.selectedOptionalOptions.filter(optionalOption => optionalOption.Group.id !== groupId);
      }
    } else {
      // If the option is deselected, remove the whole option object from the array
      const index = this.selectedDefaultOptions.indexOf(option);
      if (index !== -1) {
        this.selectedDefaultOptions.splice(index, 1);
      }
    }
    // Use patchValue to update only the 'va_packages' property
    this.form.patchValue({
      va_defaultOptions: this.selectedDefaultOptions.length > 0 ? this.selectedDefaultOptions : [],
      va_optionalOptions: this.selectedOptionalOptions.length > 0 ? this.selectedOptionalOptions : []
    });
  }

  public optionalOptionUpdated(event: any, option: any) {
    if (event.checked === true) {
      // Remove options with the same SubcategoryType from the selectedOptionalOptions array
      const subcategoryId = option.SubcategoryType.id;
      this.selectedOptionalOptions = this.selectedOptionalOptions.filter(opt => opt.SubcategoryType.id !== subcategoryId);

      // Add the selected option to the array
      this.selectedOptionalOptions.push(option);

      // If the Group.multiSelect property is false, remove default options with the same Group.id
      if (option.Group.multiSelect === false) {
        const groupId = option.Group.id;
        this.selectedDefaultOptions = this.selectedDefaultOptions.filter(defaultOption => defaultOption.Group.id !== groupId);
      }
    } else {
      // If the option is deselected, remove the whole option object from the array
      const index = this.selectedOptionalOptions.indexOf(option);
      if (index !== -1) {
        this.selectedOptionalOptions.splice(index, 1);
      }
    }

    // Use patchValue to update only the 'va_packages' property
    this.form.patchValue({
      va_optionalOptions: this.selectedOptionalOptions.length > 0 ? this.selectedOptionalOptions : [],
      va_defaultOptions: this.selectedDefaultOptions.length > 0 ? this.selectedDefaultOptions : []
    });
  }

  public packageUpdated(event: any, option: any) {
    if (event.checked === true) {
      // If the option is selected, add the whole option object to the array
      if (!this.selectedPackages.includes(option)) {
        this.selectedPackages.push(option);
      }
    } else {
      // If the option is deselected, remove the whole option object from the array
      const index = this.selectedPackages.indexOf(option);
      if (index !== -1) {
        this.selectedPackages.splice(index, 1);
      }
    }
    // Use patchValue to update only the 'va_packages' property
    this.form.patchValue({
      va_packages: this.selectedPackages.length > 0 ? this.selectedPackages : []
    });
  }

  public onUpload(event: any) {
    this.form.controls['vs_images'].setValue(event.target.files);
  }

  public openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }
}
