
<div class="container header-bar">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="20">
        <img class="header-logo" src="../../../../assets/images/logo.jpg">
      </div>
  
      <div fxFlex="40"></div>
  
      <div fxFlex="40">
        <div fxLayout="row">
          <div class="header-menu-buttons">
            <a href="https://www.autotaxatiepartners.nl/"><span style="margin-right: 20px;">Home</span></a>
            <a href="https://www.autotaxatiepartners.nl/#producten"><span style="margin-right: 20px;">Producten</span></a>
            <a href="https://www.autotaxatiepartners.nl/#over-ons"><span style="margin-right: 20px;">Over ons</span></a>
            <a href="https://www.autotaxatiepartners.nl/#contact"><span style="margin-right: 20px;">Contact</span></a>
            <a href="https://dashboard.autotaxatiepartners.nl/logout;"><span>Uitloggen</span></a>
          </div>
        </div>
  
        <div class="header-menu-links" fxLayout="row">
          <div fxLayout="column" fxFlex="50">
            <a href="https://dashboard.autotaxatiepartners.nl/account" style="color:#000000;">
              <mat-icon class="inline-icon navigation-icon">home</mat-icon>
              Bedrijfsinformatie
            </a>
          </div>
  
          <div fxLayout="column" fxFlex="50">
            <a href="https://dashboard.autotaxatiepartners.nl/dashboard" style="color:#000000;">
              <mat-icon class="inline-icon navigation-icon">home</mat-icon>
              Dashboard
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="header-footer">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  
  <div class="main-container">
    <mat-stepper linear #stepper>
      <mat-step>
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="Start">
            <form>
              <ng-template matStepLabel>Stap 1 - Voertuiggegevens</ng-template>
              <mat-card class="container">
                <mat-card-title>
                  <mat-icon class="inline-icon navigation-icon">directions_car</mat-icon> Voertuiggegevens
                </mat-card-title>
                <mat-divider class="divider"></mat-divider>
  
                <div fxLayout="row" fxLayoutGap="30px">
                  <div fxLayout="column" fxFlex="55">
                    <h2>Benodigde basisgegevens</h2>
  
                    <div fxLayout="row">
                      <div fxLayout="column" fxFlex="60">
                        <div>
                          <label>Spoedtaxatie </label>
                          <mat-radio-group>
                            <mat-radio-button value="auto">Ja</mat-radio-button>
                            <mat-radio-button value="always">Nee</mat-radio-button>
                          </mat-radio-group>
                        </div>
  
                        <mat-form-field class="registration" appearance="fill">
                          <mat-label>Kenteken</mat-label>
                          <input matInput type="text" name="licenseplate" class="licenseplate" maxlength="7" />
                        </mat-form-field>
  
                        <mat-checkbox>Geen kenteken (bekend)</mat-checkbox>
  
                        <mat-form-field appearance="fill">
                          <mat-label>Tellerstand</mat-label>
                          <input matInput placeholder="Tellerstand">
                          <span matSuffix>km</span>
                        </mat-form-field>
  
                        <div>
                          <label>NAP Controle </label>
                          <mat-radio-group>
                            <mat-radio-button value="auto">Ja</mat-radio-button>
                            <mat-radio-button value="always">Nee</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="40">
                    <div style="width: 400px; padding: 0px 10px 10px 10px; background: #F5F5F5; color: #008499;">
                      <h3 style="margin-top: 0px; padding-top: 20px;">Update App beschikbaar!</h3>
                      Er een nieuwe versie van onze taxatie-App beschikbaar voor iOS en Android. <br><br>
                      In deze nieuwe versie hebben we de camera functie stevig verbeterd.<br>
                      Hierdoor kun je beter foto's toevoegen via de camera zonder dat er vertraging optreed. <br>
                      Verder is de performance verbeterd, zijn diverse kleine bugs opgelost en hebben we hem geschikt
                      gemaakt
                      voor de nieuwste devices en software.<br>
                      <br><br>
                      Goede zaken gewenst!<br>
                      U kunt ons bereiken op <b>088 - 88 33 222</b> of via <a
                        href="mailto:info@autotaxatiepartners.nl"><span
                          style="color: #008499;"><b>info&#64;autotaxatiepartners.nl</b></span></a>
                    </div>
                  </div>
                </div>
              </mat-card>
            </form>
          </mat-tab>
          <mat-tab label="Voertuiggegevens">
            <mat-card class="container">
              <mat-card-title>
                <mat-icon class="inline-icon navigation-icon">directions_car</mat-icon> Voertuiggegevens
              </mat-card-title>
              <mat-divider class="divider"></mat-divider>
  
              <div fxLayout="row">
                <div fxLayout="column" fxFlex="50">
                  <h2>Controleer gegevens</h2>
                  <table>
                    <tr>
                      <td><b>Voertuigsoort</b></td>
                      <td>Personenvoertuig</td>
                    </tr>
                    <tr>
                      <td><b>Carrosserie</b></td>
                      <td>Hatchback 5</td>
                    </tr>
                    <tr>
                      <td><b>Brandstof</b></td>
                      <td>Diesel</td>
                    </tr>
                    <tr>
                      <td><b>Vermogen</b></td>
                      <td>66 kW (90 pk)</td>
                    </tr>
                    <tr>
                      <td><b>Motorinhoud</b></td>
                      <td>1.461 cc</td>
                    </tr>
                    <tr>
                      <td><b>Transmissie</b></td>
                      <td>Handgeschakeld 5-bak</td>
                    </tr>
  
                    <div style="height: 30px;"></div>
  
                    <tr>
                      <td><b>Voertuigstatus</b></td>
                      <td>Normaal</td>
                    </tr>
                    <tr>
                      <td><b>Datum 1e toelating</b></td>
                      <td>07-01-2013</td>
                    </tr>
                    <tr>
                      <td><b>Datum deel 1a</b></td>
                      <td>07-01-2013</td>
                    </tr>
                    <tr>
                      <td><b>Datum deel 1b</b></td>
                      <td>17-02-2021</td>
                    </tr>
                    <tr>
                      <td><b>APK vervaldatum</b></td>
                      <td>16-01-2023</td>
                    </tr>
                    <tr>
                      <td><b>Transmissie</b></td>
                      <td>Handgeschakeld 5-bak</td>
                    </tr>
                  </table>
  
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <button mat-stroked-button color="primary">Wijzigen</button>
                  </div>
                </div>
  
                <div fxLayout="column" fxFlex="50">
                  <h2>Controleer model</h2>
  
                  <img class="car-preview-image"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/2019_Renault_Clio_Iconic_TCE_1.0_Front.jpg/560px-2019_Renault_Clio_Iconic_TCE_1.0_Front.jpg">
  
                  <div fxLayout="column" fxFlex="60">
                    <table>
                      <tr>
                        <td><b>Modeljaar reeks</b></td>
                        <td>10/2012-</td>
                      </tr>
                      <tr>
                        <td><b>Herberekende nieuwprijs</b></td>
                        <td>€ 17.980
                        </td>
                      </tr>
                      <tr>
                        <td><b>Bruto BPM</b></td>
                        <td>€ 528</td>
                      </tr>
                      <tr>
                        <td><b>Rest BPM</b></td>
                        <td>€ 43</td>
                      </tr>
                      <tr>
                        <td><b>Officiele import</b></td>
                        <td>Ja</td>
                      </tr>
                    </table>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <button mat-stroked-button color="primary">Wijzigen</button>
                  </div>
                </div>
              </div>
  
              <mat-divider class="divider"></mat-divider>
              <h2>Uitvoering bepalen</h2>
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="40">
                  <mat-selection-list [multiple]="false">
                    <mat-list-option value="value1">
                      <span class="probabilty-label positive">
                        Waarschijnlijk
                      </span>
                      1.5 DCI ECO EXPRESSION
                    </mat-list-option>
                    <mat-list-option selected value="value2">
                      <span class="probabilty-label neutral">
                        Niet waarschijnlijk
                      </span>
                      1.5 DCI ECO COLLECTION
                    </mat-list-option>
                    <mat-list-option value="value2">
                      <span class="probabilty-label neutral">
                        Niet waarschijnlijk
                      </span>
                      1.5 DCI ECO AUTHENTIQUE
                    </mat-list-option>
                    <mat-list-option value="value2">
                      <span class="probabilty-label negative">
                        Niet waarschijnlijk
                      </span>
                      1.5 DCI DYNAMIQUE
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </mat-card>
          </mat-tab>
  
          <mat-tab label="Opties">
            <mat-card class="container">
              <mat-card-title>
                <mat-icon class="inline-icon navigation-icon">directions_car</mat-icon> Opties
              </mat-card-title>
              <mat-divider class="divider"></mat-divider>
              <h2>Controleer gegevens</h2>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
        <div>
          <button mat-raised-button color="primary" (click)="next()" mat-button matStepperNext>Volgende stap</button>
        </div>
      </mat-step>
      <mat-step>
        <form>
          <ng-template matStepLabel>Stap 2 - Conditie</ng-template>
          <mat-form-field appearance="fill">
            <mat-label>Address</mat-label>
            <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required>
          </mat-form-field>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Stap 3 - Controle</ng-template>
        <p>You are now done.</p>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="stepper.reset()">Reset</button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>