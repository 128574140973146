import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.scss']
})
export class ProxyComponent implements OnInit, OnDestroy {
  template!: number;
  token!: string;
  apiKey!: string;
  parameters!: any;
  isResuming!: boolean;
  action!: string;
  authReady = false;

  private paramMapSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      this.setParams(params);
      this.handleAuthenticationByTemplate(this.template);
    });
  }

  ngOnDestroy(): void {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }

  private setParams(params: ParamMap): void {
    this.template = Number(params.get('templateId'));
    this.token = String(params.get('token'));
    this.apiKey = String(params.get('apiKey'));
    this.parameters = params.get('params');
    this.action = String(params.get('action'));
  }

  private handleAuthenticationByTemplate(templateId: number): void {
    switch (templateId) {
      case 2134:
        this.handleAuthenticationForTemplate2134();
        break;
      case 108:
        this.handleAuthenticationForTemplate108();
        break;
      default:
        console.warn('Unhandled template ID:', templateId);
    }
  }

  private handleAuthenticationForTemplate2134(): void {
    if (this.action == 'resume') {
      this.isResuming = true;
    } else {
      this.isResuming = false;
    }

    if (this.token && this.apiKey === 'null') {
      this.authenticate(this.template, this.token, 'token');
    }
  }

  private handleAuthenticationForTemplate108(): void {
    let data = null;
    const isAccessTokenValid = this.auth.checkAccessTokenValidity();
    const isRefreshTokenValid = this.auth.checkRefreshTokenValidity();
    if (this.action == 'resume') {
      data = atob(this.token).split(':');
      this.isResuming = true;
    } else {
      data = atob(this.apiKey).split(':');
      this.isResuming = false;
    }

    if (this.apiKey && !isAccessTokenValid && !isRefreshTokenValid) {
      this.authenticate(data[0], data[2], 'apiKey', data[1]);
    } else if (isAccessTokenValid) {
      this.setAuthReady();
    } else {
      const authData = this.auth.getAuthData();
      this.auth.loginWithRefreshToken(authData.refresh_token).subscribe(() => {
        this.setAuthReady();
      });
    }
  }

  private authenticate(template: string | number, token: string, type: string, apiKey?: string): void {
    this.auth.getToken(String(template), token, type, apiKey).then(() => {
      this.setAuthReady();
    }).catch(() => {
      this.navigateToErrorPage();
    });
  }

  private setAuthReady(): void {
    const data = atob(this.apiKey).split(':');
    if (!localStorage.getItem('apiToken') || localStorage.getItem('apiToken') !== data[2]) {
      localStorage.setItem('apiToken', data[2]);
    }
    this.authReady = true;
  }

  private navigateToErrorPage(): void {
    this.router.navigate(['error']);
  }
}
