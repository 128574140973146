<h1 mat-dialog-title><b>Wijzig gegevens</b></h1>
<mtx-loader [loading]="loading" [type]="'spinner'" [color]="'primary'" [hasBackdrop]="true">
  <mat-dialog-content *ngIf="!isPageDetailsEmpty()">
    <form *ngIf="form.controls" [formGroup]="form">
      <section *ngIf="form.controls">

        <mat-form-field appearance="fill" [class.hidden-when-loading]="loading">
          <mat-label>Voertuigsoort</mat-label>
          <mat-select formControlName="vs_vehicleTypeId" (selectionChange)="update('vs_vehicleTypeId')">
            <mat-option *ngFor="let option of this.getVehicleTypes()" [value]="option.id">{{option.descriptionNl |
              capitalize}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Merk</mat-label>
          <mat-select formControlName="v_make" (selectionChange)="update('v_make')">
            <mat-option *ngFor="let option of this.getMakes()" [value]="option.descriptionNl">{{option.descriptionNl |
              capitalize}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Model</mat-label>
          <mat-select formControlName="v_modelSpecific" (selectionChange)="update('v_modelSpecific')">
            <mat-option *ngFor="let option of this.getModels()" [value]="option.descriptionNl">{{option.descriptionNl |
              capitalize}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Carrosserie</mat-label>
          <mat-select formControlName="vs_bodyTypeId" (selectionChange)="update('vs_bodyTypeId')">
            <mat-option *ngFor="let option of this.getBodyTypes()" [value]="option.id">{{option.descriptionNl |
              capitalize}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Transmissie</mat-label>
          <mat-select formControlName="vs_transmissionTypeId" (selectionChange)="update('vs_transmissionTypeId')">
            <mat-option *ngFor="let option of this.getTransmissionTypes()" [value]="option.id">{{option.descriptionNl |
              capitalize}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Brandstof</mat-label>
          <mat-select formControlName="vs_fuelTypeId" (selectionChange)="update('vs_fuelTypeId')">
            <mat-option *ngFor="let option of this.getFuelTypes()" [value]="option.id">{{option.descriptionNl |
              capitalize}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="customVsCylinderVolumeSelected == false">
          <mat-label>Motorinhoud</mat-label>
          <mat-select formControlName="vs_cylinderVolume" (selectionChange)="update('vs_cylinderVolume')">
            <mat-option *ngFor="let option of this.getCylinderVolumes()" [value]="option.id">{{option.descriptionNl |
              capitalize}}</mat-option>
            <mat-option [value]="'custom'">Anders...</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="customVsCylinderVolumeSelected == true">
          <mat-label>Motorinhoud (cc)</mat-label>
          <input formControlName="vs_cylinderVolume" type="number" matInput>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="customVsPowerSelected == false">
          <mat-label>Vermogen</mat-label>
          <mat-select formControlName="vs_power" (selectionChange)="update('vs_power')">
            <mat-option *ngFor="let option of this.getPowerOptions()" [value]="option.id">{{option.descriptionNl |
              capitalize}}</mat-option>
            <mat-option [value]="'custom'">Anders...</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="customVsPowerSelected == true">
          <mat-label>Vermogen (kW)</mat-label>
          <input formControlName="vs_power" type="number" matInput>
        </mat-form-field>

        <app-form (formChange)="formChange($event)" [show]="['v_dateFirstAdmission']" [fields]="pageDetails.fields">
        </app-form>

      </section>
    </form>
  </mat-dialog-content>
</mtx-loader>

<mat-dialog-actions class="action-buttons" align="start">
  <button mat-raised-button color="warn" (click)="closeDialog()" mat-dialog-close>Annuleren</button>
  <button [disabled]="form.valid == false || loading" mat-raised-button color="primary" (click)="submit()"
    mat-button>Opslaan</button>
</mat-dialog-actions>