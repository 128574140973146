<div fxLayout="row">
    <div fxFlex="50">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <p>Klik op een schade-onderdeel om een nieuwe schade toe te voegen.</p>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex="80">
                <mat-form-field>
                    <input type="text" placeholder="Zoek een onderdeel..." matInput [matAutocomplete]="auto"
                        [value]="selectedOption?.label" [ngModel]="damagePartSearchInput"
                        (ngModelChange)="filterDamageParts($event)">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredDamageParts" [value]="option.value"
                            (onSelectionChange)="selectedOption = option; createDamage($event.source.value)">
                            {{ option.label }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div style="padding-top: 20px;" id="imgmapdiv" [ngSwitch]="parseVehicleType(vehicleType)">
            <div *ngSwitchCase="'bestelauto'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="290,40, 289,47, 287,47, 281,48, 280,49, 279,49, 233,49, 232,48, 227,47, 223,47, 223,40"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="26,44, 25,45, 23,46, 21,47, 15,48, 13,47, 13,47, 14,47, 16,46, 19,45, 20,45"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="173,146, 174,146, 178,147, 179,147, 184,148, 183,149, 177,148, 176,148, 173,147, 169,145"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="167,220, 168,221, 178,221, 179,222, 182,226, 186,232, 187,236, 187,241, 187,271, 186,272, 186,274, 183,277, 177,283, 167,284, 159,284, 149,285, 149,283, 151,280, 152,273, 153,271, 153,267, 154,265, 154,255, 154,239, 153,238, 153,233, 152,231, 151,224, 150,223, 150,221, 149,220, 149,220"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 3 -->
                        <area shape="poly" alt="3"
                            coords="247,3, 255,3, 258,3, 266,3, 268,3, 269,3, 276,3, 283,4, 284,5, 284,7, 285,9, 286,14, 284,14, 281,14, 277,13, 231,14, 228,15, 227,14, 230,7, 231,5, 232,4, 237,3"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="182,4, 183,4, 184,4, 186,5, 187,5, 186,5, 187,6, 184,8, 179,6, 130,7, 129,7, 104,8, 103,8, 97,9, 96,9, 91,11, 81,16, 77,17, 76,16, 75,16, 75,15, 78,13, 78,13, 82,11, 85,10, 85,9, 85,10, 87,9, 88,8, 89,8, 90,8, 93,7, 96,6, 98,6, 100,5, 101,5, 110,4, 111,4, 112,4, 116,4, 126,3"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="87,105, 88,105, 95,106, 98,107, 99,106, 101,107, 103,108, 104,108, 107,109, 110,110, 113,111, 118,114, 119,115, 119,114, 120,116, 121,115, 122,117, 122,116, 122,118, 120,118, 119,119, 117,118, 114,117, 111,115, 105,111, 99,110, 96,109, 90,109, 76,108, 59,108, 43,107, 15,108, 12,109, 10,106, 10,106, 12,105, 13,105, 14,105, 15,105, 21,104"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="119,224, 120,225, 120,230, 121,231, 122,242, 123,247, 122,265, 122,266, 120,276, 120,277, 120,280, 117,282, 116,283, 113,283, 111,282, 97,282, 92,281, 14,281, 14,280, 13,281, 11,277, 13,276, 12,227, 12,227, 12,225, 14,223, 62,223, 108,222, 109,222, 117,222"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="281,14, 282,14, 282,14, 283,14, 284,14, 285,16, 285,16, 286,20, 286,20, 287,23, 288,27, 288,28, 288,30, 289,34, 290,39, 289,39, 223,39, 224,33, 224,32, 227,20, 229,15, 230,14, 231,14, 232,14, 233,14, 246,13"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4" coords="74,17, 49,40, 47,38, 74,16" onmouseover="mouseOver(4)"
                            onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4" coords="125,118, 150,140, 149,140, 147,140, 123,118, 123,118"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="149,220, 151,224, 151,226, 153,233, 154,246, 153,268, 153,269, 151,279, 151,280, 149,284, 149,284, 148,284, 147,284, 138,284, 136,283, 130,283, 129,282, 120,281, 120,280, 120,275, 121,274, 122,263, 123,261, 123,253, 123,243, 122,242, 122,236, 121,235, 120,224, 122,223, 135,221, 136,221, 148,220"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 18 -->
                        <area shape="poly" alt="18"
                            coords="257,105, 257,169, 225,169, 225,155, 226,135, 227,116, 228,113, 229,110, 229,107, 230,107, 229,106, 230,106, 241,105"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="5,223, 5,224, 7,225, 8,226, 8,226, 9,226, 11,227, 12,227, 12,228, 13,228, 13,252, 3,252, 3,232, 4,225, 4,225, 4,224, 4,222"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt="19"
                            coords="284,106, 285,106, 284,107, 285,108, 286,115, 286,116, 286,117, 286,118, 287,121, 288,131, 288,135, 288,143, 288,147, 288,169, 257,169, 257,105"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="13,253, 12,277, 12,277, 10,278, 10,277, 5,281, 6,281, 5,280, 5,281, 5,281, 4,282, 4,282, 3,279, 3,252"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <!-- 12 -->
                        <area shape="poly" alt="12"
                            coords="233,48, 234,49, 234,51, 235,54, 235,56, 236,59, 223,59, 223,47"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="289,48, 289,59, 278,59, 278,54, 279,53, 279,49, 280,48, 281,48, 287,47"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="15,48, 11,53, 10,56, 9,58, 8,59, 5,59, 6,57, 7,53, 9,50, 11,48, 13,48"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="229,69, 230,70, 228,72, 226,71, 227,71, 226,70, 227,68"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="287,69, 286,71, 285,72, 284,71, 284,68"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="186,149, 186,150, 187,149, 190,154, 191,156, 191,156, 191,157, 192,160, 190,161, 188,160, 186,155, 184,151, 181,149"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="186,222, 187,222, 187,222, 191,229, 192,231, 191,232, 185,230, 179,222, 179,221"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="192,273, 190,277, 189,279, 186,283, 179,283, 179,281, 186,274, 191,272"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="279,50, 279,53, 278,55, 277,59, 278,59, 293,59, 292,74, 292,75, 290,76, 221,76, 221,74, 220,70, 220,63, 220,59, 236,59, 235,56, 235,54, 234,51, 234,49"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="27,60, 22,65, 21,68, 19,72, 18,78, 10,77, 8,75, 7,74, 4,72, 2,70, 1,66, 1,60, 4,59"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="195,161, 196,164, 196,170, 192,174, 189,176, 187,178, 181,179, 178,179, 176,170, 173,164, 170,161"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="187,220, 189,222, 190,223, 191,224, 191,224, 191,224, 192,226, 193,227, 193,227, 194,228, 195,229, 196,232, 196,238, 196,245, 196,268, 195,269, 196,270, 195,276, 192,278, 190,281, 187,285, 185,285, 185,284, 189,280, 190,277, 192,273, 191,272, 186,273, 187,271, 187,266, 188,257, 187,237, 187,236, 187,231, 192,232, 192,232, 188,223, 185,219"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7" coords="196,65, 196,66, 196,73, 193,74, 186,75, 186,65"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7" coords="12,167, 11,174, 10,176, 4,175, 2,175, 1,174, 1,171, 1,166"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="294,170, 294,175, 293,176, 294,176, 292,178, 289,177, 222,178, 221,178, 220,178, 220,175, 219,174, 220,170"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="6,216, 6,218, 4,220, 3,224, 3,245, 3,282, 4,283, 4,285, 6,288, 4,288, 2,284, 2,283, 1,277, 1,268, 1,224, 2,223, 2,220, 3,217"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="287,14, 287,17, 288,17, 287,18, 288,20, 289,22, 289,24, 289,24, 289,25, 290,28, 291,32, 291,35, 292,37, 292,38, 292,39, 292,40, 293,46, 293,47, 293,54, 292,59, 289,59, 289,32, 288,31, 288,27, 287,26, 287,22, 286,20, 286,18, 285,14, 286,14"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="77,17, 77,18, 74,20, 69,23, 67,24, 67,26, 65,28, 62,29, 61,30, 60,31, 56,35, 53,40, 49,44, 52,45, 54,45, 54,67, 53,67, 54,66, 53,65, 53,64, 52,64, 53,64, 51,62, 48,59, 45,58, 44,58, 41,57, 37,57, 34,57, 29,59, 26,59, 9,59, 10,56, 10,55, 13,50, 15,48, 17,48, 22,46, 47,39, 48,39, 49,38, 60,30, 61,29, 64,26, 65,25, 66,24, 66,23, 68,22, 68,21, 70,20, 74,17, 75,16"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="171,215, 172,216, 176,216, 179,218, 180,218, 184,219, 186,220, 186,222, 182,221, 172,221, 166,220, 159,220, 140,220, 139,221, 131,221, 130,222, 125,222, 123,223, 121,223, 120,224, 118,224, 117,223, 118,221, 124,220, 125,220, 131,219, 132,219, 136,218, 141,216, 141,216, 144,215, 151,215"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9"
                            coords="228,15, 224,31, 223,38, 223,48, 223,59, 220,59, 220,39, 221,38, 221,35, 222,34, 222,31, 223,30, 222,29, 223,27, 224,25, 224,24, 225,21, 225,20, 225,20, 226,17, 226,16, 227,15, 226,15, 227,14"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="123,118, 135,129, 136,130, 138,131, 148,140, 150,141, 152,142, 154,142, 156,143, 158,143, 160,144, 162,144, 164,145, 166,145, 172,147, 174,147, 176,148, 183,150, 186,153, 187,157, 188,159, 187,161, 168,160, 167,159, 167,160, 166,159, 163,158, 160,158, 153,158, 152,159, 150,159, 147,162, 144,165, 145,166, 144,166, 144,167, 143,167, 143,168, 143,146, 147,146, 147,145, 142,140, 143,139, 138,134, 133,128, 132,129, 134,130, 134,130, 134,129, 134,128, 134,128, 132,128, 131,127, 129,126, 122,120, 120,119, 120,118"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="133,129, 133,130" onmouseover="mouseOver(9)"
                            onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="133,130, 134,130" onmouseover="mouseOver(9)"
                            onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="120,280, 122,281, 131,283, 135,283, 138,284, 144,284, 146,285, 166,284, 167,284, 181,283, 182,283, 186,283, 183,286, 180,286, 178,287, 178,287, 178,287, 175,288, 171,289, 165,289, 142,289, 141,288, 140,287, 136,286, 134,286, 131,285, 129,285, 118,283, 117,283, 117,281"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 13 -->
                        <area shape="poly" alt="13" coords="194,44, 193,60, 190,59, 191,44" onmouseover="mouseOver(13)"
                            onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="7,145, 7,161, 3,161, 4,160, 3,159, 3,145"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="225,145, 225,161, 220,161, 221,145"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="293,145, 293,147, 293,161, 288,161, 289,145"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="14" coords="146,74, 146,77, 55,77, 55,75, 56,75, 58,75"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15" coords="111,176, 142,176, 141,178, 51,178, 51,175"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="105,14, 105,74, 104,74, 55,74, 54,61, 54,45, 49,44, 50,42, 56,36, 57,36, 58,35, 59,33, 62,31, 63,30, 63,31, 68,26, 74,20, 75,21, 76,19, 76,20, 79,18, 80,17, 81,16, 81,16, 85,15, 96,14"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="300,32, 301,35, 301,40, 295,41, 295,42, 293,42, 293,38, 294,38, 294,31"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="219,133, 219,140, 220,141, 220,144, 218,144, 218,142, 213,142, 213,134, 215,133"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="135,208, 136,211, 139,216, 141,216, 141,218, 139,218, 135,219, 134,219, 131,219, 131,218, 128,219, 127,220, 121,220, 121,221, 109,221, 91,221, 92,215, 123,215, 134,215, 133,214, 133,208"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="219,32, 219,38, 220,38, 220,41, 218,41, 218,42, 217,42, 217,41, 212,41, 213,32, 214,31"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="99,116, 112,116, 113,117, 114,117, 115,117, 117,118, 118,119, 119,120, 119,120, 120,121, 122,121, 127,126, 136,133, 139,135, 139,137, 141,139, 142,139, 140,137, 140,138, 141,139, 142,139, 142,140, 143,141, 146,143, 146,144, 147,144, 148,145, 147,146, 145,146, 143,147, 142,175, 141,175, 92,175, 92,118, 92,115"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="300,133, 301,134, 301,139, 301,142, 295,143, 295,145, 294,144, 293,142, 294,140, 295,139, 295,133"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="94,283, 118,283, 119,284, 123,284, 124,285, 129,285, 129,286, 133,286, 138,287, 138,287, 139,288, 141,288, 140,289, 138,289, 138,290, 136,291, 137,292, 134,296, 132,296, 133,292, 133,291, 133,290, 134,289, 133,289, 126,289, 91,289, 92,283"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="42,60, 43,60, 44,60, 44,61, 45,61, 48,63, 48,64, 50,65, 52,70, 52,72, 52,79, 51,80, 47,85, 44,87, 44,87, 43,88, 40,88, 32,88, 28,85, 27,84, 27,84, 26,83, 25,82, 24,78, 23,76, 24,69, 27,63, 30,61, 34,60, 36,59"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="294,70, 294,88, 293,88, 292,88, 285,87, 285,77, 292,76, 292,75, 294,70"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="219,69, 221,76, 222,77, 228,77, 227,88, 225,88, 219,88, 218,87, 219,68"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="164,161, 165,162, 171,166, 171,167, 172,167, 173,171, 174,174, 173,180, 173,181, 171,184, 169,186, 167,188, 163,190, 154,189, 153,188, 152,189, 151,188, 150,187, 149,186, 146,183, 145,178, 145,170, 148,165, 149,165, 150,164, 151,163, 155,161, 157,161"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="169,60, 173,62, 173,62, 177,66, 178,70, 179,72, 178,79, 178,80, 174,84, 172,87, 168,88, 158,88, 158,87, 157,87, 156,86, 155,85, 155,86, 151,82, 150,78, 150,68, 154,63, 157,61, 157,61, 158,60, 161,60, 163,59"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="220,176, 220,178, 221,178, 222,178, 229,178, 228,190, 227,190, 219,190, 219,175"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="37,161, 38,162, 39,162, 39,163, 40,162, 42,164, 43,165, 45,167, 47,172, 47,180, 46,181, 41,188, 38,189, 35,190, 27,189, 22,186, 22,185, 20,184, 18,179, 18,176, 18,170, 19,169, 24,163, 27,162, 29,161, 31,161"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="295,176, 294,190, 286,190, 285,188, 286,178, 293,178, 294,176"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <!-- 25 -->
                        <area shape="poly" alt="25"
                            coords="182,7, 183,7, 186,9, 187,13, 189,24, 189,32, 190,38, 189,52, 190,56, 189,57, 190,59, 189,65, 185,65, 186,72, 186,75, 181,74, 181,69, 180,68, 181,67, 180,66, 180,65, 179,65, 180,64, 176,60, 174,58, 173,58, 173,57, 171,56, 166,56, 159,56, 158,57, 156,58, 156,58, 156,58, 150,63, 149,65, 149,66, 147,70, 147,72, 146,74, 105,74, 105,34, 105,14, 94,14, 85,14, 85,14, 88,12, 91,11, 96,9, 98,9, 103,8, 108,8, 130,7, 139,7"
                            onmouseover="mouseOver(25)" onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <area shape="poly" alt="25"
                            coords="45,214, 46,214, 50,215, 51,215, 75,215, 91,215, 92,221, 106,221, 106,222, 85,223, 12,222, 10,220, 9,218, 9,217, 9,216, 14,215, 14,215, 15,215, 17,214, 25,214, 28,213"
                            onmouseover="mouseOver(25)" onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <!-- 26 -->
                        <area shape="poly" alt="26"
                            coords="51,108, 64,108, 65,109, 45,109, 44,110, 44,162, 42,159, 41,160, 41,159, 39,158, 38,158, 38,157, 34,157, 26,157, 25,158, 24,158, 22,159, 22,160, 22,159, 18,163, 19,164, 17,165, 17,167, 16,167, 17,168, 16,169, 15,173, 15,175, 14,176, 12,176, 11,176, 12,168, 12,167, 11,166, 7,166, 8,128, 8,127, 10,114, 10,113, 12,109, 15,108, 31,107, 42,108"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <area shape="poly" alt="26"
                            coords="100,110, 101,110, 103,111, 104,111, 108,113, 112,116, 106,116, 92,115, 91,111, 90,110, 90,109"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <area shape="poly" alt="26"
                            coords="102,282, 102,283, 47,283, 47,284, 46,290, 37,291, 19,290, 18,290, 15,289, 12,288, 9,288, 10,284, 12,282, 14,281"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <!-- 27 -->
                        <area shape="poly" alt="27"
                            coords="75,109, 76,110, 91,110, 92,111, 92,175, 50,175, 50,171, 49,170, 49,169, 48,169, 49,168, 45,164, 46,164, 44,162, 45,109"
                            onmouseover="mouseOver(27)" onmouseout="mouseOut(27)" (click)="createDamage(27)">
                        <area shape="poly" alt="27" coords="91,283, 90,289, 49,290, 48,290, 47,290, 47,283"
                            onmouseover="mouseOver(27)" onmouseout="mouseOut(27)" (click)="createDamage(27)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="189,7, 189,9, 190,10, 189,10, 190,13, 191,16, 191,17, 193,31, 193,36, 193,42, 193,43, 190,43, 189,27, 189,26, 188,18, 188,17, 186,10, 185,7, 187,6"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10" coords="193,60, 193,60, 192,65, 190,64, 190,60"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="230,106, 229,109, 228,113, 227,116, 227,123, 226,128, 226,132, 225,140, 225,145, 220,145, 221,141, 221,134, 222,132, 222,125, 223,124, 222,123, 223,122, 224,112, 225,111, 225,109, 226,108, 226,107, 229,105"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="220,153, 220,157, 220,161, 223,162, 225,162, 225,169, 219,169, 218,155"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="9,216, 11,221, 13,224, 11,227, 7,225, 4,222, 4,221, 4,220, 5,218, 7,217"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="286,106, 286,106, 287,106, 287,107, 288,107, 288,110, 290,115, 290,119, 291,120, 291,126, 292,137, 293,145, 288,145, 288,129, 287,128, 287,119, 286,117, 286,112, 285,109, 285,107, 284,106, 284,105"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="10,107, 10,106, 12,109, 10,114, 8,124, 8,130, 7,139, 7,145, 4,145, 3,144, 4,136, 4,128, 5,127, 5,122, 6,121, 6,115, 7,112, 9,107, 10,107"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="12,109, 10,114, 8,124, 8,130, 7,139, 7,145, 4,145, 3,144, 4,136, 4,128, 5,127, 5,122, 6,121, 6,115, 7,112, 9,107, 11,107"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="295,153, 294,154, 295,155, 295,168, 294,169, 294,170, 288,169, 288,162, 293,161, 294,158"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11" coords="6,161, 7,162, 6,166, 4,166, 4,162, 4,161"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="12,278, 14,281, 11,283, 10,285, 9,288, 6,287, 4,284, 5,280, 8,278, 11,278"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="112,23, 113,25, 112,28, 106,33, 106,35, 108,37, 109,40, 120,40, 132,39, 141,38, 142,37, 144,36, 162,36, 163,35, 169,36, 172,36, 177,37, 181,37, 186,38, 189,38, 192,39, 194,39, 198,41, 199,43, 200,51, 204,54, 204,61, 195,66, 180,69, 179,68, 178,69, 177,73, 173,78, 171,80, 166,81, 159,81, 152,76, 149,71, 146,73, 69,73, 68,72, 64,73, 64,74, 58,80, 56,80, 54,81, 47,81, 40,76, 36,72, 18,72, 17,71, 15,71, 16,68, 14,67, 14,65, 14,62, 12,59, 12,57, 12,54, 15,51, 20,47, 31,44, 33,43, 36,43, 38,42, 41,42, 43,41, 46,41, 51,40, 57,40, 61,39, 67,39, 80,38, 86,35, 89,33, 110,22"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="172,179, 173,180, 181,180, 185,185, 190,186, 197,192, 199,195, 200,197, 202,200, 204,212, 203,235, 203,236, 202,239, 201,242, 198,247, 189,256, 184,258, 181,261, 170,262, 159,262, 150,262, 149,261, 147,261, 145,260, 132,261, 64,261, 63,262, 41,261, 40,261, 35,260, 30,258, 24,256, 18,252, 15,246, 14,244, 14,241, 13,239, 13,236, 12,234, 12,230, 12,207, 13,206, 15,193, 24,185, 27,185, 28,184, 30,184, 36,180, 53,179, 64,180, 65,180, 67,180, 90,180, 134,180, 149,180, 150,179, 154,179"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/bestelauto/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/18.gif" usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/19.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/12.gif" usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/13.gif" usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/14.gif" usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/15.gif" usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/16.gif" usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/17.gif" usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/20.gif" usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/21.gif" usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/22.gif" usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/23.gif" usemap="#imgmap" />
                    <img id="overlay_25" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/25.gif" usemap="#imgmap" />
                    <img id="overlay_26" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/26.gif" usemap="#imgmap" />
                    <img id="overlay_27" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/27.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/10.gif" usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/mouseover/11.gif" usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/23.gif" usemap="#imgmap" />
                    <img id="overlay_25_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/25.gif" usemap="#imgmap" />
                    <img id="overlay_26_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/26.gif" usemap="#imgmap" />
                    <img id="overlay_27_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/27.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelauto/active/11.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'bestelbus'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 12 -->
                        <area shape="poly" alt="12" coords="13,55, 12,58, 8,61, 3,61, 3,60, 4,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="257,56, 258,59, 259,62, 258,63, 249,63, 244,62, 244,61, 243,59, 244,56, 245,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="303,55, 310,56, 310,58, 310,61, 306,63, 295,63, 296,56, 302,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="7,72, 8,74, 7,76, 3,76, 3,75, 3,74, 2,72"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="256,75, 256,76, 254,77, 249,77, 248,75, 249,74, 254,74"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="305,74, 306,75, 306,74, 306,75, 304,77, 300,77, 298,77, 298,74, 298,75, 299,74"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="198,158, 198,164, 192,164, 189,159, 189,157"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="200,175, 199,175, 200,176, 199,176, 198,178, 194,178, 194,177, 194,175, 195,175"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="193,218, 195,220, 198,223, 198,228, 199,230, 199,233, 197,232, 195,229, 195,226, 193,223, 189,217"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="199,267, 198,274, 198,276, 197,278, 193,282, 191,282, 190,282, 193,278, 195,274, 195,271, 197,268, 198,267"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="30,38, 30,39, 25,41, 24,41, 14,46, 8,51, 4,55, 3,55, 4,52, 5,51, 7,49, 8,47, 9,47, 12,44, 13,43, 15,42, 18,40, 19,41, 21,39, 23,38, 25,38"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="308,42, 307,44, 306,46, 301,55, 300,55, 252,55, 251,54, 249,48, 245,41"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1" coords="8,47, 8,47" onmouseover="mouseOver(1)"
                            onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1" coords="8,47, 7,48" onmouseover="mouseOver(1)"
                            onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="180,141, 181,142, 186,144, 186,145, 187,144, 187,145, 188,146, 189,147, 190,147, 192,149, 192,148, 197,154, 198,158, 197,157, 192,152, 182,145, 172,141, 172,141"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="182,220, 183,220, 188,222, 193,223, 195,225, 197,237, 197,239, 198,247, 197,262, 197,263, 196,268, 195,270, 195,275, 193,277, 190,277, 189,278, 180,281, 177,281, 176,281, 176,279, 179,273, 181,268, 181,264, 182,261, 182,254, 182,238, 181,237, 181,232, 180,231, 178,225, 176,219"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 3 -->
                        <area shape="poly" alt="3" coords="194,1, 195,2, 195,3, 66,3, 66,2, 70,1, 72,1, 85,0"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="307,2, 309,5, 309,15, 296,15, 244,15, 244,9, 245,8, 245,4, 246,3, 246,2, 248,2, 272,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3" coords="132,103, 133,104, 136,105, 136,105, 6,105, 7,103, 10,103"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="144,217, 147,218, 149,227, 151,237, 152,245, 151,265, 150,266, 149,275, 148,276, 147,282, 143,283, 16,284, 8,283, 7,282, 7,281, 9,280, 8,279, 8,266, 8,216"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="55,14, 57,15, 42,30, 40,29, 37,29, 36,32, 36,37, 34,38, 30,38, 27,37, 28,35, 41,21, 51,14"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="282,15, 289,15, 306,15, 309,16, 310,18, 310,41, 243,41, 243,33, 244,24, 244,22, 244,20, 245,15, 267,15, 271,15"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="152,117, 164,127, 175,139, 174,140, 166,139, 165,138, 165,132, 162,131, 161,131, 160,132, 160,133, 145,117, 145,117, 146,117, 149,116"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4" coords="150,124, 150,124, 150,124, 150,124"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="171,216, 173,217, 175,218, 175,219, 176,220, 179,226, 181,232, 181,236, 182,241, 182,249, 182,262, 181,263, 181,268, 180,270, 178,276, 175,281, 172,284, 164,284, 156,283, 146,283, 145,283, 146,281, 147,279, 149,274, 149,272, 150,270, 150,266, 151,263, 152,257, 151,235, 150,234, 149,225, 148,224, 148,222, 146,217, 149,217, 165,216, 166,216"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="296,57, 295,60, 295,62, 295,63, 307,63, 309,62, 313,63, 314,63, 314,66, 312,75, 312,76, 311,77, 312,77, 310,79, 310,80, 290,81, 287,81, 284,81, 271,81, 267,81, 261,81, 252,80, 243,80, 243,78, 242,76, 241,74, 240,72, 240,68, 239,67, 240,63, 245,62, 246,63, 256,63, 259,63, 259,62, 259,61, 258,59, 259,59, 257,57, 259,56"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="23,64, 20,69, 18,74, 18,79, 4,78, 4,76, 8,75, 7,73, 5,72, 1,72, 1,64, 2,64"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="201,167, 201,169, 200,175, 195,175, 194,177, 195,178, 198,179, 197,181, 184,181, 183,179, 183,173, 182,172, 181,169, 180,166"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="198,221, 200,227, 201,230, 201,240, 201,271, 200,272, 200,276, 197,279, 198,276, 198,274, 199,270, 199,267, 199,267, 197,268, 196,268, 197,264, 197,261, 198,254, 197,238, 197,237, 197,232, 199,233, 199,229, 197,222, 197,221, 197,220"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7"
                            coords="201,64, 200,77, 181,77, 180,73, 180,72, 177,66, 175,64, 175,64"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="26,167, 25,169, 24,169, 24,171, 22,175, 21,177, 21,179, 20,180, 1,180, 1,177, 1,175, 1,166"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="245,167, 246,176, 247,176, 307,176, 307,175, 307,166, 315,167, 314,175, 313,177, 312,181, 302,181, 276,182, 250,181, 240,180, 239,177, 239,173, 238,169, 239,166"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="3,214, 3,219, 3,267, 3,287, 2,286, 1,285, 1,256, 0,248, 1,247, 1,215, 2,214"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="201,64, 201,77, 181,77, 180,73, 180,72, 177,66, 175,64, 175,64"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7" coords="3,164, 4,165, 28,166, 27,166, 1,167, 1,179, 0,179, 1,164"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="245,167, 246,176, 247,176, 307,176, 307,175, 307,166, 315,167, 314,175, 313,177, 313,180, 312,181, 304,181, 274,182, 251,181, 240,181, 240,180, 239,177, 239,173, 238,169, 239,166"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="3,214, 3,220, 3,266, 3,287, 2,286, 1,285, 1,257, 0,248, 1,246, 1,214"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="311,41, 311,46, 312,48, 312,49, 313,55, 314,56, 315,63, 310,62, 310,58, 309,55, 307,55, 306,55, 302,55, 302,52, 308,42"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="162,213, 161,215, 160,214, 152,214, 152,213, 160,212"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="181,214, 183,215, 185,216, 186,216, 186,216, 188,217, 193,221, 193,223, 189,222, 188,222, 182,220, 180,220, 178,219, 173,217, 170,216, 168,215, 165,215, 165,212"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="121,214, 120,216, 117,216, 117,214"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="63,4, 64,3, 85,4, 85,76, 77,75, 81,49, 80,14, 52,13, 58,7, 58,7"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="55,9, 54,9, 54,10, 53,10, 54,10, 54,9"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="54,9, 54,10" onmouseover="mouseOver(8)"
                            onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="54,10, 53,10" onmouseover="mouseOver(8)"
                            onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="36,37, 36,37, 36,41, 35,43, 36,44, 37,43, 38,43, 39,43, 38,49, 39,50, 40,57, 41,58, 43,58, 47,62, 49,65, 51,69, 53,75, 52,76, 51,75, 51,71, 50,70, 49,69, 49,69, 49,68, 48,66, 45,64, 43,61, 41,60, 41,61, 40,60, 39,60, 37,59, 37,60, 36,59, 30,60, 29,60, 28,60, 27,60, 26,61, 22,63, 3,63, 3,61, 9,61, 13,55, 11,54, 5,54, 10,48, 20,43, 31,38, 33,38, 35,38, 35,37"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9"
                            coords="245,42, 251,52, 251,55, 244,56, 243,59, 244,62, 239,62, 239,57, 240,52, 242,51, 242,46, 243,43, 243,41"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="138,105, 138,106, 145,111, 149,116, 143,116, 121,117, 121,158, 122,159, 125,177, 124,178, 117,178, 117,105"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="149,114, 150,115" onmouseover="mouseOver(9)"
                            onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="171,141, 181,145, 189,150, 194,154, 196,156, 195,157, 189,158, 189,159, 194,164, 199,164, 199,166, 178,165, 177,164, 176,165, 175,163, 175,164, 173,163, 171,162, 162,162, 160,164, 159,164, 158,164, 152,172, 150,177, 150,178, 149,178, 150,173, 150,172, 152,167, 157,162, 160,160, 163,159, 163,152, 172,151, 172,150, 172,148, 168,141"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="193,277, 193,278, 188,283, 184,285, 183,284, 182,285, 180,286, 175,287, 170,287, 165,287, 164,286, 163,287, 162,287, 161,288, 160,288, 152,288, 152,287, 161,286, 162,285, 166,285, 170,284, 170,284, 172,283, 175,282, 178,281, 180,281, 186,279"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="121,284, 120,286, 117,285, 117,284"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 13 -->
                        <area shape="poly" alt="13" coords="201,48, 200,62, 199,61, 199,49" onmouseover="mouseOver(13)"
                            onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="245,150, 245,166, 239,165, 239,150"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="313,150, 313,157, 314,158, 313,166, 307,166, 307,150"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="3,152, 2,164, 1,164, 1,150" onmouseover="mouseOver(13)"
                            onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="2,150, 3,151" onmouseover="mouseOver(13)"
                            onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt="14" coords="85,76, 85,77, 51,77, 52,76" onmouseover="mouseOver(14)"
                            onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15" coords="150,178, 150,181, 131,180, 117,180, 117,178"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="81,14, 80,55, 80,57, 76,76, 53,76, 52,72, 49,65, 43,59, 39,57, 38,43, 37,44, 35,44, 36,40, 36,39, 36,34, 37,29, 38,29, 41,29, 42,31, 48,25, 47,25, 42,30, 42,31, 48,25, 47,25, 57,15, 60,14"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="311,18, 312,31, 313,33, 313,39, 314,43, 315,42, 316,36, 321,36, 321,46, 318,46, 316,46, 315,45, 313,46, 313,51, 312,51, 312,49, 311,48, 311,36, 310,31, 310,18"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="236,138, 236,145, 239,145, 239,147, 235,147, 232,148, 230,148, 229,144, 229,138"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="165,204, 167,210, 165,214, 163,215, 162,216, 149,217, 144,216, 143,216, 133,216, 121,216, 122,214, 126,214, 162,214, 163,212, 162,212, 163,211, 161,208, 162,204"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="244,19, 243,34, 243,36, 242,49, 241,51, 240,52, 240,52, 239,45, 237,46, 236,46, 233,46, 232,44, 233,36, 238,36, 239,43, 240,42, 240,35, 241,34, 240,33, 241,32, 242,27, 242,27, 242,26, 242,25, 243,19, 243,18"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="143,117, 160,133, 160,132, 161,131, 165,132, 165,133, 166,143, 166,143, 167,145, 165,147, 163,145, 162,147, 162,153, 162,160, 157,162, 156,163, 156,162, 154,166, 152,167, 153,167, 152,168, 152,169, 150,172, 150,174, 149,176, 148,178, 125,177, 121,152, 122,116"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="323,138, 323,147, 321,148, 318,148, 317,147, 313,147, 313,145, 317,144, 318,138"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="151,283, 166,284, 165,287, 165,289, 167,291, 164,296, 161,296, 162,291, 163,289, 162,288, 163,288, 161,285, 142,286, 124,285, 124,284, 146,283, 147,283"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="121,284, 120,286, 117,285, 117,284"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="40,63, 41,64, 44,65, 48,70, 49,75, 49,81, 48,82, 47,86, 42,89, 37,91, 31,91, 30,90, 26,88, 23,84, 21,79, 23,70, 26,65, 30,64, 32,63, 35,63"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20" coords="313,75, 313,92, 306,91, 307,80, 311,80, 312,77, 313,75"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="241,75, 242,77, 243,78, 242,79, 243,80, 247,80, 247,92, 240,92, 240,74"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="171,166, 175,168, 176,169, 176,169, 177,171, 178,171, 180,176, 181,179, 179,187, 175,191, 175,190, 173,192, 168,193, 161,193, 155,189, 153,183, 153,177, 153,173, 157,169, 159,167, 164,165"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="168,63, 169,64, 172,65, 176,69, 177,74, 177,82, 172,88, 169,90, 167,91, 164,91, 156,89, 152,85, 150,81, 149,79, 150,72, 154,66, 156,64, 162,63"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22" coords="244,181, 247,181, 246,194, 240,193, 240,181"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="43,166, 44,166, 47,168, 51,172, 52,176, 52,184, 47,190, 43,193, 41,193, 32,193, 29,190, 25,186, 25,184, 24,183, 25,174, 29,168, 34,166, 36,165"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23" coords="313,181, 312,194, 306,193, 306,181"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <!-- 25 -->
                        <area shape="poly" alt="25"
                            coords="196,4, 195,4, 196,5, 197,7, 196,8, 197,9, 197,12, 198,14, 198,19, 199,23, 199,31, 200,31, 199,32, 200,35, 200,40, 200,41, 200,43, 200,47, 198,49, 199,49, 199,49, 199,48, 198,49, 199,49, 198,51, 199,63, 200,62, 199,63, 173,63, 173,62, 172,63, 172,61, 170,60, 169,61, 167,60, 167,60, 166,60, 158,60, 158,61, 157,60, 156,61, 155,61, 154,62, 150,66, 150,66, 151,66, 150,65, 150,66, 150,66, 149,67, 150,68, 149,68, 149,69, 148,69, 148,74, 147,76, 147,77, 85,77, 85,3"
                            onmouseover="mouseOver(25)" onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <area shape="poly" alt="25"
                            coords="55,212, 57,213, 70,214, 116,214, 116,216, 5,216, 5,215, 5,214, 21,213, 22,212, 32,212"
                            onmouseover="mouseOver(25)" onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <!-- 26 -->
                        <area shape="poly" alt="26"
                            coords="57,106, 57,180, 54,180, 54,175, 51,168, 48,166, 48,165, 46,164, 43,163, 41,162, 36,162, 30,164, 27,166, 1,165, 1,165, 3,164, 3,152, 1,149, 2,135, 2,133, 4,116, 4,115, 6,106, 7,105"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <area shape="poly" alt="26"
                            coords="11,284, 56,284, 55,287, 51,288, 36,288, 24,287, 23,288, 22,287, 17,287, 16,286, 15,287, 14,286, 5,285, 7,283"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <!-- 27 -->
                        <area shape="poly" alt="27" coords="116,105, 116,180, 58,180, 58,105"
                            onmouseover="mouseOver(27)" onmouseout="mouseOut(27)" (click)="createDamage(27)">
                        <area shape="poly" alt="27" coords="116,284, 116,286, 58,287, 57,287, 56,287, 56,284"
                            onmouseover="mouseOver(27)" onmouseout="mouseOut(27)" (click)="createDamage(27)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="248,104, 249,103, 276,104, 276,104, 251,106, 250,108, 249,113, 247,123, 246,130, 246,135, 245,150, 239,149, 240,146, 240,135, 241,135, 240,134, 241,133, 242,128, 242,127, 242,127, 242,126, 243,119, 243,112, 244,111, 244,107, 244,106, 244,107, 245,105, 246,104, 246,104"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="307,104, 307,104, 307,105, 307,106, 309,108, 309,109, 309,111, 310,114, 310,117, 311,126, 311,127, 312,133, 312,135, 313,141, 313,150, 307,149, 307,134, 306,132, 306,122, 305,121, 305,115, 304,114, 302,107, 300,105, 277,104, 277,103"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 18 -->
                        <area shape="poly" alt="18"
                            coords="276,105, 276,176, 246,175, 246,174, 245,156, 246,143, 246,127, 247,126, 249,113, 249,111, 251,106, 252,105, 256,105"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="7,217, 8,220, 8,220, 8,222, 7,223, 7,250, 3,249, 3,215, 5,215"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt="19"
                            coords="293,105, 301,105, 303,109, 305,116, 305,120, 306,124, 306,131, 307,137, 307,152, 307,155, 307,175, 306,176, 277,176, 277,104"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="7,250, 7,253, 7,262, 7,279, 5,279, 5,281, 7,282, 7,283, 4,285, 3,284, 3,260, 3,250"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="120,25, 121,26, 121,30, 114,35, 114,37, 116,40, 117,43, 129,43, 142,42, 152,41, 153,40, 155,39, 173,38, 175,38, 181,38, 184,39, 190,40, 194,40, 200,41, 203,41, 206,42, 209,42, 212,44, 214,46, 215,55, 220,58, 219,66, 209,71, 194,74, 193,74, 191,74, 190,78, 186,84, 183,86, 178,87, 171,87, 164,82, 160,76, 156,78, 74,78, 73,77, 69,78, 69,80, 63,86, 60,86, 58,87, 51,87, 43,82, 39,77, 20,77, 19,76, 16,76, 17,74, 15,72, 15,70, 15,67, 13,64, 13,61, 13,58, 16,54, 22,50, 33,47, 35,47, 38,46, 41,46, 44,45, 46,44, 49,44, 55,43, 61,43, 66,42, 72,42, 86,41, 93,37, 96,36, 118,24"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="184,193, 186,193, 195,194, 199,198, 204,200, 212,206, 214,210, 215,212, 217,215, 219,228, 218,253, 218,254, 217,256, 216,260, 213,266, 203,274, 198,277, 194,281, 182,281, 171,282, 161,281, 160,281, 158,280, 156,279, 142,280, 69,281, 68,281, 44,281, 43,280, 37,279, 32,277, 26,275, 19,270, 16,265, 15,262, 15,259, 14,256, 14,253, 13,251, 13,246, 13,222, 14,221, 16,208, 25,199, 29,198, 30,198, 32,197, 38,193, 57,193, 69,193, 70,194, 72,194, 97,193, 144,194, 160,193, 161,193, 165,192"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/bestelbus/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/18.gif" usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/19.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/12.gif" usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/13.gif" usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/14.gif" usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/15.gif" usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/16.gif" usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/17.gif" usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/20.gif" usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/21.gif" usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/22.gif" usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/23.gif" usemap="#imgmap" />
                    <img id="overlay_25" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/25.gif" usemap="#imgmap" />
                    <img id="overlay_26" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/26.gif" usemap="#imgmap" />
                    <img id="overlay_27" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/27.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/10.gif" usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/mouseover/11.gif" usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/23.gif" usemap="#imgmap" />
                    <img id="overlay_25_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/25.gif" usemap="#imgmap" />
                    <img id="overlay_26_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/26.gif" usemap="#imgmap" />
                    <img id="overlay_27_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/27.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/bestelbus/active/11.gif" usemap="#imgmap" />
                </div>
            </div>

            <div *ngSwitchCase="'cabriolet'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="340,20, 341,21, 349,21, 350,22, 350,25, 348,26, 344,27, 341,28, 332,29, 330,29, 315,30, 291,29, 290,29, 275,27, 272,24, 272,22, 273,21, 276,21, 288,20, 303,19"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="81,21, 83,21, 85,22, 86,22, 86,23, 63,24, 61,24, 41,26, 40,27, 21,29, 21,28, 25,27, 32,25, 42,24, 49,23, 52,22, 59,22, 64,21, 75,21"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="159,116, 160,115, 174,116, 179,116, 189,117, 190,117, 211,121, 212,121, 216,122, 216,122, 220,124, 206,122, 205,122, 189,120, 184,119, 176,119, 168,118, 154,117, 153,117, 154,116, 158,116"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="184,200, 185,201, 199,203, 201,203, 207,205, 215,212, 221,221, 224,231, 224,234, 225,238, 224,251, 224,252, 222,259, 221,260, 218,267, 211,275, 206,278, 203,280, 199,280, 196,281, 183,283, 168,283, 154,283, 153,282, 151,282, 151,280, 158,273, 160,270, 162,263, 162,260, 163,256, 163,249, 163,225, 162,224, 162,219, 161,217, 159,212, 153,204, 151,201, 152,201, 156,200, 162,199, 182,200"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt="2"
                            coords="202,17, 204,18, 208,19, 214,19, 217,20, 224,21, 224,21, 225,21, 226,21, 229,22, 229,22, 230,23, 231,22, 232,26, 231,25, 229,25, 225,22, 221,22, 217,21, 211,21, 208,20, 198,19, 194,19, 193,19, 194,17, 195,17, 200,17"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="2"
                            coords="337,103, 339,104, 344,104, 344,109, 343,110, 342,111, 336,112, 333,114, 288,113, 286,112, 283,111, 279,111, 278,104, 291,103, 297,103"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="46,112, 47,114, 34,114, 32,115, 24,116, 22,116, 15,117, 10,120, 9,121, 8,121, 7,121, 9,117, 9,118, 12,116, 16,116, 17,115, 17,116, 19,115, 22,114, 27,114, 31,113, 37,112, 42,112"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="52,211, 50,216, 48,224, 48,237, 48,259, 49,260, 51,270, 52,271, 52,273, 37,272, 36,272, 10,270, 7,268, 7,263, 6,260, 6,254, 5,247, 6,226, 6,224, 8,214, 12,213, 23,212, 29,212, 38,211, 44,211"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 29 -->
                        <area shape="poly" alt="29"
                            coords="193,16, 193,18, 189,19, 184,19, 181,20, 167,21, 165,21, 161,21, 162,19, 167,18, 169,17, 187,17, 188,16"
                            onmouseover="mouseOver(29)" onmouseout="mouseOut(29)" (click)="createDamage(29)">
                        <area shape="poly" alt="29"
                            coords="60,112, 75,112, 76,113, 79,114, 79,116, 69,116, 66,115, 55,114, 53,114, 48,113, 47,111, 49,111, 53,111, 55,112"
                            onmouseover="mouseOver(29)" onmouseout="mouseOut(29)" (click)="createDamage(29)">
                        <area shape="poly" alt="29"
                            coords="134,198, 134,199, 128,201, 114,201, 103,202, 97,203, 91,203, 88,204, 81,206, 77,211, 76,213, 76,223, 75,243, 76,258, 76,271, 77,272, 79,275, 83,278, 89,280, 97,280, 101,281, 118,281, 124,282, 132,283, 134,284, 133,285, 96,284, 95,283, 80,283, 79,282, 63,280, 62,280, 60,279, 53,274, 51,269, 50,267, 50,264, 49,259, 48,254, 49,221, 50,220, 50,215, 51,214, 53,209, 57,205, 65,203, 68,202, 73,201, 76,201, 84,200, 95,199, 104,199, 121,198"
                            onmouseover="mouseOver(29)" onmouseout="mouseOut(29)" (click)="createDamage(29)">
                        <!-- 4 -->
                        <area shape="poly" alt=""
                            coords="127,1, 127,4, 127,6, 118,14, 112,19, 111,23, 99,22, 99,22, 98,22, 90,22, 86,21, 87,20, 98,14, 117,3, 120,2, 122,2, 122,1, 124,1"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt=""
                            coords="334,1, 336,2, 339,2, 340,3, 342,4, 343,6, 346,11, 349,17, 350,18, 350,19, 350,20, 351,21, 349,21, 336,20, 326,19, 280,20, 278,21, 271,21, 275,12, 275,11, 276,9, 278,6, 278,6, 281,2, 282,3, 283,2, 286,2, 290,1, 297,1"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt=""
                            coords="332,88, 334,89, 339,89, 342,93, 344,96, 346,101, 347,104, 346,104, 344,104, 335,103, 329,103, 283,103, 281,104, 275,104, 276,101, 276,99, 280,92, 282,90, 288,89, 291,88, 304,88"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt=""
                            coords="117,96, 119,97, 122,98, 142,108, 153,116, 153,116, 144,117, 135,117, 129,117, 128,115, 129,114, 124,110, 119,106, 112,100, 113,96, 114,95"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt=""
                            coords="142,198, 143,199, 147,200, 150,202, 152,203, 158,209, 161,216, 163,227, 163,240, 163,257, 162,258, 162,264, 161,265, 160,269, 156,276, 151,281, 149,281, 147,283, 145,283, 143,284, 142,285, 134,284, 133,283, 132,283, 132,282, 130,283, 130,281, 128,281, 127,281, 126,281, 126,281, 125,280, 124,280, 124,279, 123,280, 122,278, 122,279, 121,278, 121,278, 119,276, 119,277, 119,278, 120,277, 119,276, 119,277, 117,275, 117,208, 123,203, 125,203, 125,203, 126,203, 127,203, 128,202, 130,200, 134,199, 135,199, 140,198"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="" coords="119,206, 118,206" onmouseover="mouseOver(4)"
                            onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 12 -->
                        <area shape="poly" alt=""
                            coords="296,57, 299,60, 298,63, 297,63, 296,64, 283,64, 283,63, 279,62, 278,60, 279,57"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="359,57, 360,60, 359,62, 356,63, 352,64, 341,63, 340,61, 341,58, 344,57"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="41,58, 40,60, 38,62, 33,63, 29,64, 24,65, 21,64, 24,60, 27,60, 29,59, 31,58, 36,58"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="286,72, 289,76, 288,78, 286,80, 281,79, 280,75, 282,73, 283,71"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="" coords="27,73, 29,75, 30,75, 30,78, 26,79, 24,77, 23,75, 24,72"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="286,30, 290,33, 290,37, 274,37, 273,36, 271,35, 270,34, 270,30, 272,29"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="352,30, 352,31, 352,35, 350,36, 345,37, 340,37, 332,37, 331,34, 333,31, 334,30, 337,30, 343,29"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="32,30, 31,32, 26,35, 24,36, 21,37, 17,37, 11,37, 12,35, 17,32, 24,30, 27,30"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="17,45, 18,47, 20,48, 19,52, 16,52, 14,49, 14,45, 16,45"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="278,46, 280,48, 279,52, 278,52, 273,52, 272,50, 273,46, 274,45"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="349,46, 350,47, 349,51, 347,52, 342,52, 342,49, 343,46, 345,45"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="221,126, 227,129, 228,130, 229,131, 229,132, 219,132, 217,131, 212,129, 208,126, 209,125"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="226,140, 226,145, 222,147, 220,146, 221,142, 223,140"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="220,202, 226,206, 229,211, 232,219, 232,221, 227,221, 222,217, 211,203, 211,201, 213,200, 216,201"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="232,262, 232,267, 226,276, 222,280, 219,281, 215,282, 212,283, 211,281, 211,279, 226,263, 227,262, 230,262"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 6 -->
                        <area shape="poly" alt=""
                            coords="275,27, 276,27, 277,27, 282,28, 287,29, 291,29, 301,30, 315,30, 333,29, 334,29, 342,28, 344,27, 347,27, 349,27, 350,28, 352,29, 354,29, 355,30, 359,31, 361,34, 360,45, 360,47, 359,50, 359,52, 358,55, 355,58, 356,59, 355,60, 355,59, 352,60, 351,60, 346,60, 326,60, 267,60, 265,57, 265,56, 264,56, 265,55, 264,54, 262,49, 262,44, 261,39, 262,32, 264,30, 268,30, 270,29, 272,28, 272,27"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt=""
                            coords="39,28, 39,37, 34,42, 33,43, 34,43, 32,44, 32,47, 30,50, 31,51, 30,52, 30,61, 16,60, 11,60, 9,58, 10,57, 9,56, 7,55, 7,47, 6,46, 6,45, 5,42, 6,38, 7,37, 6,36, 8,34, 9,35, 12,31, 13,32, 14,31, 16,30, 17,30, 21,30, 20,31, 15,33, 12,35, 12,37, 22,37, 24,36, 28,34, 32,31, 30,30, 25,30, 26,29, 32,28, 35,27"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt=""
                            coords="209,122, 210,123, 212,124, 212,124, 209,125, 208,126, 210,128, 217,131, 220,132, 225,132, 229,132, 226,128, 222,126, 219,126, 219,126, 219,124, 225,125, 227,127, 228,126, 229,128, 231,129, 235,133, 234,134, 235,135, 235,139, 234,140, 234,140, 234,142, 232,146, 232,150, 230,151, 231,153, 231,154, 230,153, 229,154, 222,155, 219,155, 209,155, 209,142, 203,133, 201,130, 201,125, 201,122"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt=""
                            coords="212,194, 216,198, 220,199, 221,199, 222,201, 225,203, 226,203, 226,203, 226,202, 225,203, 226,203, 226,203, 229,206, 230,206, 229,207, 233,212, 234,214, 234,215, 236,219, 237,221, 237,222, 238,228, 239,229, 239,238, 239,254, 238,255, 237,260, 237,262, 236,263, 235,267, 233,270, 234,271, 230,275, 231,276, 226,280, 226,281, 225,280, 222,283, 222,283, 221,283, 219,284, 219,285, 219,284, 217,285, 216,285, 216,286, 216,285, 214,286, 215,287, 212,290, 212,290, 211,290, 210,290, 208,291, 204,290, 204,288, 205,281, 206,278, 212,273, 217,267, 219,263, 221,260, 223,255, 224,252, 224,250, 225,246, 224,232, 224,231, 222,224, 221,223, 218,217, 210,208, 206,205, 206,205, 205,200, 204,195, 204,192"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="6"
                            coords="233,27, 234,35, 237,37, 239,38, 239,47, 227,53, 225,53, 219,55, 212,56, 211,48, 211,47, 208,41, 204,37, 205,36, 212,35, 216,34, 218,33, 219,32, 222,32, 222,32, 223,33, 227,34, 231,34, 232,32, 232,27"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="6"
                            coords="333,114, 334,118, 335,120, 340,121, 352,121, 354,119, 360,122, 361,123, 360,132, 360,134, 357,140, 353,144, 350,144, 269,144, 264,139, 262,135, 262,134, 262,134, 262,132, 261,124, 262,122, 265,121, 268,119, 269,119, 272,121, 286,121, 288,118, 289,116, 289,114"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="6"
                            coords="8,122, 9,129, 16,128, 18,127, 22,127, 23,128, 31,130, 35,131, 35,131, 35,133, 30,138, 29,143, 28,145, 27,152, 9,146, 1,141, 1,132, 7,129, 7,121"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="7"
                            coords="352,21, 353,22, 354,23, 355,24, 358,26, 359,26, 359,27, 360,28, 361,32, 360,32, 357,30, 354,30, 351,28, 349,27, 349,25, 350,23, 350,21"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="7"
                            coords="90,23, 91,22, 96,24, 93,29, 92,32, 93,50, 93,52, 93,56, 75,55, 74,48, 73,47, 71,44, 72,43, 70,41, 69,40, 66,36, 65,37, 65,35, 63,35, 60,33, 57,32, 53,32, 47,32, 45,33, 39,35, 39,27, 56,25, 61,24, 71,24, 88,23, 89,22"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="7"
                            coords="204,192, 204,204, 201,203, 199,203, 196,202, 193,201, 188,201, 183,200, 171,199, 153,200, 152,201, 150,201, 145,199, 145,198, 148,198, 152,193, 152,193, 162,193, 172,193, 173,192, 177,191"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt=""
                            coords="272,22, 272,24, 273,26, 273,27, 269,29, 267,30, 263,31, 262,32, 261,32, 263,27, 271,21"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt=""
                            coords="168,118, 170,119, 185,119, 186,120, 196,121, 198,121, 201,122, 201,130, 198,129, 197,129, 196,128, 193,127, 191,127, 182,127, 181,128, 181,127, 180,128, 175,130, 168,137, 169,138, 168,139, 167,141, 165,149, 164,150, 146,150, 147,147, 147,142, 148,135, 147,124, 144,119, 143,118, 143,117"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt=""
                            coords="205,279, 204,288, 204,290, 203,291, 182,292, 169,290, 168,290, 152,290, 151,288, 146,285, 145,285, 146,283, 150,281, 158,283, 185,283, 186,282, 194,281, 195,281, 200,280, 201,280"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="197,19, 207,20, 213,21, 217,21, 227,23, 228,24, 226,26, 222,28, 222,30, 221,32, 211,35, 208,36, 202,35, 201,35, 201,35, 200,34, 197,33, 193,32, 183,33, 179,35, 179,36, 178,35, 177,37, 176,37, 173,40, 173,41, 171,42, 172,43, 171,43, 170,46, 170,47, 169,48, 168,49, 157,48, 161,42, 163,37, 163,34, 164,32, 163,26, 161,22, 162,22, 176,21, 178,21, 185,20, 195,19"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="278,104, 278,111, 277,112, 272,112, 270,114, 268,119, 263,122, 261,122, 260,122, 261,121, 262,114, 263,113, 264,112, 267,108, 267,109, 270,107, 273,106, 275,105, 275,104"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="67,192, 68,193, 68,192, 70,193, 75,193, 85,193, 84,194, 80,197, 81,200, 76,201, 64,203, 63,203, 58,205, 53,209, 52,210, 9,213, 8,213, 10,207, 11,206, 12,204, 17,199, 18,200, 19,199, 21,199, 24,197, 27,196, 29,194, 30,195, 31,194, 32,194, 34,193, 43,192, 54,191"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="347,105, 349,106, 351,107, 353,107, 359,113, 361,118, 361,122, 356,121, 354,119, 352,114, 348,112, 343,111, 345,104"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="53,114, 55,115, 65,116, 67,116, 78,117, 78,119, 76,121, 76,124, 76,130, 77,132, 80,139, 82,143, 81,144, 71,144, 69,139, 68,139, 67,135, 63,132, 62,130, 62,131, 60,129, 60,130, 59,129, 58,129, 58,128, 53,127, 52,127, 45,127, 43,128, 41,129, 36,131, 31,130, 27,130, 26,129, 18,126, 17,123, 14,120, 12,120, 12,117, 16,117, 20,116, 27,116, 30,115, 39,114, 42,114"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="48,273, 53,273, 58,278, 65,280, 67,281, 71,281, 76,282, 81,283, 82,283, 80,286, 82,288, 85,290, 84,290, 67,291, 66,291, 66,291, 65,291, 39,291, 38,290, 37,291, 36,290, 32,289, 32,290, 27,286, 27,287, 25,286, 21,285, 17,282, 16,283, 14,280, 14,281, 12,280, 13,279, 11,277, 10,275, 8,271, 9,270, 20,270, 22,271, 34,272, 36,272"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 13 -->
                        <area shape="poly" alt="13"
                            coords="229,25, 231,25, 232,29, 232,34, 222,33, 222,30, 222,27, 226,25, 228,25"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="288,112, 288,113, 288,114, 288,115, 288,120, 285,121, 273,121, 269,119, 270,114, 273,112"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="350,112, 352,114, 353,116, 352,121, 349,121, 334,120, 334,117, 334,112, 336,112"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="16,121, 18,124, 17,127, 16,128, 14,129, 8,129, 9,120"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt="14"
                            coords="169,50, 168,53, 168,61, 75,61, 75,56, 144,55, 145,55, 149,54, 150,53, 154,52, 157,49"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15"
                            coords="85,145, 88,148, 96,150, 104,150, 132,151, 165,152, 165,156, 72,155, 72,144"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="117,16, 118,16, 121,16, 122,18, 122,22, 121,22, 119,23, 121,24, 153,23, 154,22, 162,23, 163,25, 164,28, 163,36, 163,37, 158,47, 153,53, 147,55, 139,55, 126,56, 94,56, 93,51, 93,47, 92,36, 93,28, 96,24, 99,23, 111,22, 112,20, 112,17, 116,16"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="364,16, 366,17, 365,21, 360,22, 357,23, 356,25, 357,26, 359,27, 360,29, 361,32, 360,32, 355,30, 354,30, 349,27, 349,27, 349,25, 350,23, 350,21, 352,21, 354,23, 354,22, 354,20, 355,16, 356,16"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="267,103, 268,104, 268,107, 264,109, 258,108, 256,106, 257,104, 258,103, 261,102"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="129,187, 132,190, 132,191, 132,192, 133,193, 152,193, 152,196, 148,198, 147,198, 144,199, 140,198, 128,196, 126,197, 126,198, 122,198, 96,199, 94,199, 81,199, 81,198, 80,198, 81,195, 81,196, 83,194, 86,193, 89,193, 124,192, 122,190, 122,188, 122,186"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="81,195, 80,196" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="267,16, 268,17, 268,23, 266,24, 265,24, 264,22, 258,22, 256,21, 257,17, 261,16"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="363,103, 366,105, 365,108, 364,109, 362,109, 356,109, 354,106, 355,104, 358,103"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="125,111, 128,113, 129,118, 129,119, 130,118, 138,117, 143,118, 146,121, 148,126, 147,144, 147,145, 146,150, 95,150, 94,149, 89,149, 85,145, 81,141, 78,134, 76,127, 76,120, 79,117, 89,117, 103,118, 124,118, 122,117, 119,117, 117,115, 118,111, 121,110"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="87,283, 99,284, 107,285, 129,285, 129,286, 130,285, 139,285, 149,285, 152,288, 152,290, 144,290, 132,291, 131,294, 129,296, 126,296, 122,296, 122,292, 124,291, 122,290, 85,290, 83,289, 80,287, 81,284, 82,283, 85,283, 86,283"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="60,37, 67,42, 69,46, 70,50, 71,54, 70,58, 70,60, 69,61, 68,63, 62,70, 57,71, 51,72, 50,71, 46,71, 45,70, 39,66, 36,62, 35,56, 35,47, 36,46, 40,40, 47,37, 49,36"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="361,50, 360,71, 349,71, 349,61, 351,60, 355,60, 357,57, 359,52, 360,49"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="262,51, 263,52, 265,57, 268,60, 273,61, 273,71, 262,71, 261,70, 261,50"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="193,131, 194,132, 198,134, 203,139, 205,144, 204,154, 204,155, 204,155, 198,163, 194,165, 192,166, 190,167, 181,166, 178,164, 173,160, 171,156, 170,152, 169,149, 171,140, 174,136, 178,132, 185,130"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="196,37, 203,41, 206,46, 208,52, 207,60, 203,67, 198,70, 191,72, 181,70, 179,68, 176,66, 173,61, 172,56, 173,47, 178,40, 185,36"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="262,136, 263,137, 266,142, 269,144, 272,145, 273,145, 273,158, 272,159, 262,158, 261,157, 261,135"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="56,131, 57,132, 61,134, 65,139, 67,142, 68,145, 68,147, 68,153, 67,154, 66,157, 63,161, 59,165, 57,165, 55,166, 52,167, 44,166, 37,162, 34,158, 32,152, 33,142, 39,134, 43,132, 45,131, 48,130"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="361,135, 361,158, 359,159, 349,158, 349,157, 349,145, 353,144, 359,139"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="85,219, 85,382, 85,2, 80,9, 80,411, 82,110, 82,519, 91,310, 100,252, 106,594, 107,11, 109,409, 122,483, 123,9, 127,296, 130,131, 134,268, 137,254, 140,390, 143,224, 145,60, 147,365, 149,593, 150,524, 151,4, 154,373, 153,615, 147,50, 136,259, 135,107, 134,19, 134,431, 130,67, 129,388, 125,257, 120,594, 115,157, 112,557, 110,11, 52,602, 51,451, 48,234, 48,470, 43,58, 42,276, 40,211, 35,162, 30,346, 27,126, 14,283, 13,132, 11,451, 12,214, 10,533, 11,296, 10,529, 9,611, 9,22, 9,607, 11,394, 16,260, 23,506, 25,183, 27,10, 29,308, 31,135, 32,432, 35,260, 39,388, 43,47, 47,24, 51,304, 61,10, 65,267, 68,86, 83,535"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="129,614, 130,144, 137,494, 140,93, 143,462, 149,4, 150,583, 151,500, 152,418, 154,107, 153,360, 153,596, 153,21, 152,30, 150,24, 142,363, 139,619, 137,168, 128,375, 121,109, 113,616, 112,465, 111,163, 109,483, 99,386, 48,612, 47,462, 31,401, 30,250, 26,352, 22,218, 18,319, 14,33, 11,58, 11,431, 11,43, 10,276, 9,51, 9,122, 9,35, 9,102, 9,487, 11,544, 17,501, 20,332, 21,482, 22,162, 27,442, 40,520, 48,551, 49,80, 51,147, 68,357, 101,1, 112,17, 113,17, 116,15"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/cabriolet/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_29" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/29.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/10.gif" usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/11.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/12.gif" usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/13.gif" usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/14.gif" usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/15.gif" usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/16.gif" usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/17.gif" usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/20.gif" usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/21.gif" usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/22.gif" usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/mouseover/23.gif" usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_29_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/29.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/11.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/cabriolet/active/23.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'chassis'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 12 -->
                        <area shape="poly" alt="12" coords="212,277, 212,277" onmouseover="mouseOver(12)"
                            onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="215,264, 214,273, 213,275, 208,280, 208,279, 206,280, 205,280, 211,272, 211,268, 213,265, 214,264"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="207,211, 213,216, 214,217, 214,221, 215,225, 215,227, 213,226, 211,224, 211,219, 205,211"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="214,138, 215,143, 214,145, 208,144, 205,139, 205,137"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="311,66, 310,67, 309,69, 304,69, 302,69, 303,66"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="258,66, 258,69, 251,69, 250,67, 249,67, 249,66"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="314,48, 315,49, 314,54, 312,54, 305,55, 299,55, 300,49, 301,48"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="259,48, 260,48, 261,50, 261,54, 260,55, 247,54, 245,53, 246,48, 247,47"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="16,45, 16,47, 12,52, 6,51, 7,47, 7,45, 15,44"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 13 -->
                        <area shape="poly" alt="13" coords="256,151, 256,156, 243,156, 243,151"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="317,152, 317,156, 304,156, 305,151"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="189,210, 190,211, 208,217, 209,217, 210,217, 210,219, 211,219, 211,224, 212,231, 213,234, 214,240, 213,259, 212,260, 212,266, 211,266, 212,267, 211,268, 210,273, 206,275, 198,278, 197,279, 185,282, 183,282, 184,280, 187,275, 188,272, 188,271, 190,267, 192,257, 192,251, 192,232, 191,231, 189,222, 189,221, 187,216, 184,210"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="194,121, 195,122, 198,123, 201,124, 201,125, 202,124, 202,126, 203,126, 204,127, 205,126, 206,128, 206,127, 208,129, 208,129, 214,134, 214,137, 214,138, 212,137, 208,133, 198,126, 191,123, 187,122, 186,122, 187,120"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="313,33, 312,36, 309,41, 305,47, 254,47, 254,46, 253,44, 250,38, 247,33"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="34,28, 32,29, 31,30, 24,33, 17,36, 10,43, 7,45, 7,45, 7,42, 8,41, 9,40, 12,36, 13,36, 15,34, 16,34, 16,33, 20,31, 25,29, 27,28, 30,27"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 3 -->
                        <area shape="poly" alt="3"
                            coords="159,211, 160,213, 162,217, 164,225, 164,229, 165,233, 165,240, 165,260, 164,261, 163,272, 162,273, 159,280, 159,280, 157,281, 154,281, 130,281, 129,280, 129,280, 129,211, 131,210"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="161,94, 162,95, 163,94, 163,96, 156,96, 152,96, 125,95, 126,94, 130,94"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="252,3, 258,2, 310,2, 310,1, 312,2, 313,2, 314,5, 314,6, 309,6, 286,5, 249,6, 248,6, 245,6, 246,4, 247,2, 249,2, 250,2"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3" coords="96,1, 96,1" onmouseover="mouseOver(3)"
                            onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3" coords="95,1, 96,2, 95,3, 64,3, 63,4, 62,3, 58,3, 58,2, 61,1, 63,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="184,210, 187,215, 189,222, 190,225, 191,227, 191,231, 192,234, 192,245, 192,258, 191,259, 189,269, 189,270, 187,275, 187,276, 183,281, 182,282, 171,281, 168,281, 160,280, 161,275, 162,273, 163,271, 163,269, 164,266, 164,263, 165,259, 165,254, 166,244, 165,239, 164,224, 163,222, 161,216, 161,215, 159,211, 173,210, 178,210"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="164,96, 165,96, 181,110, 189,120, 189,120, 180,119, 180,119, 179,113, 177,111, 175,112, 174,113, 162,101, 161,100, 160,100, 159,98, 159,98, 159,97"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="314,6, 314,7, 315,21, 315,29, 315,33, 245,32, 245,18, 246,8, 247,6, 257,6"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="58,3, 62,4, 54,13, 47,20, 47,20, 45,18, 43,18, 41,21, 40,26, 38,27, 36,27, 35,27, 34,27, 32,26, 34,24, 42,15, 57,3, 57,3"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt="5"
                            coords="128,215, 128,277, 127,276, 126,272, 126,259, 126,221, 127,220, 127,215"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="312,99, 313,108, 313,110, 314,118, 312,120, 248,119, 247,117, 247,111, 248,105, 249,99, 252,99"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="204,281, 203,282, 202,283, 201,284, 197,284, 198,282, 200,282, 201,281"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="215,216, 216,219, 217,225, 217,228, 217,270, 216,271, 215,276, 214,277, 213,279, 209,281, 207,282, 206,281, 205,280, 205,280, 207,279, 208,279, 211,278, 214,275, 215,266, 215,263, 212,266, 212,266, 212,261, 213,258, 214,253, 214,247, 214,226, 215,228, 215,227, 214,217, 212,214"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="202,208, 202,208, 203,208, 204,208, 205,209, 209,211, 211,211, 214,215, 215,215, 216,220, 217,224, 217,229, 217,270, 216,271, 215,276, 214,277, 213,279, 209,281, 207,282, 206,281, 205,280, 205,280, 207,279, 208,279, 211,278, 214,275, 215,266, 215,263, 212,266, 212,266, 212,261, 213,258, 214,253, 214,247, 214,226, 215,228, 215,227, 214,217, 211,214, 211,214, 210,212, 210,213, 209,212, 207,211, 205,211, 199,209, 198,208, 198,207"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="217,147, 217,155, 216,155, 215,158, 215,159, 213,161, 199,161, 198,155, 198,154, 195,149, 194,147, 194,147"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="26,54, 26,55, 24,59, 22,65, 21,68, 7,68, 3,62, 3,54, 4,55, 5,54"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="300,48, 299,53, 300,55, 304,55, 305,57, 308,56, 319,56, 319,59, 318,63, 315,71, 315,72, 245,72, 244,69, 244,68, 243,67, 243,66, 242,65, 243,64, 242,63, 242,59, 241,55, 251,56, 256,56, 257,55, 261,55, 262,55, 261,54, 260,48, 261,48"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7" coords="319,150, 318,159, 242,159, 242,150"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="181,206, 192,207, 193,207, 198,208, 198,209, 199,208, 205,211, 209,216, 208,217, 204,216, 199,214, 187,210, 185,210, 179,208, 176,207, 177,206"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="316,33, 317,40, 317,41, 318,44, 318,45, 319,52, 319,55, 307,56, 305,56, 305,55, 313,54, 315,52, 315,50, 315,49, 313,47, 307,47, 308,41, 312,35, 314,33"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="41,27, 40,30, 40,31, 40,33, 41,34, 43,33, 44,33, 43,50, 41,49, 33,50, 30,52, 30,51, 27,53, 26,53, 6,53, 6,52, 7,52, 12,51, 15,48, 16,45, 15,44, 13,44, 9,44, 10,42, 14,38, 24,33, 34,29, 36,28, 39,27, 39,28"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9"
                            coords="208,275, 208,277, 203,281, 194,284, 192,284, 188,285, 183,285, 176,285, 176,284, 183,282, 183,283, 184,282, 187,282, 196,279, 197,279, 208,275"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="183,121, 188,122, 191,123, 193,124, 197,126, 202,128, 211,134, 212,136, 211,137, 205,138, 205,139, 210,145, 215,145, 215,146, 193,146, 193,145, 192,146, 193,146, 193,146, 193,145, 192,146, 191,144, 191,145, 190,143, 189,144, 188,143, 188,143, 186,142, 185,143, 184,142, 177,142, 178,126, 180,127, 182,124, 180,122, 180,120"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="247,33, 249,36, 252,40, 254,47, 253,47, 247,48, 245,50, 247,54, 248,54, 252,55, 256,55, 255,56, 241,55, 241,52, 242,47, 242,45, 243,43, 243,42, 244,39, 244,38, 244,33"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="136,207, 136,210, 131,210, 130,211, 128,210, 127,208, 127,207"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10" coords="96,4, 96,67, 85,67, 89,45, 89,43, 90,3"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="134,282, 136,282, 136,284, 127,284, 127,282, 130,281, 133,282"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="131,97, 132,123, 132,139, 133,140, 133,145, 134,147, 134,150, 135,154, 136,157, 136,159, 136,161, 124,161, 124,96"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 14 -->
                        <area shape="poly" alt="14" coords="85,66, 85,67, 66,68, 57,68, 58,66"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15" coords="164,159, 164,161, 142,161, 136,161, 137,159"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="173,206, 175,206, 176,206, 177,208, 180,208, 180,208, 180,210, 165,210, 161,211, 154,210, 152,210, 149,210, 140,210, 137,209, 137,207, 166,207, 167,206"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="179,196, 181,201, 180,203, 179,205, 179,205, 177,205, 177,202, 177,201, 175,196, 175,196, 178,195"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="236,119, 237,123, 238,127, 239,127, 242,128, 241,129, 231,129, 230,128, 230,119"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="52,55, 52,55" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="51,54, 52,55" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="50,54, 51,54, 52,55, 52,55, 52,55, 51,54"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="54,13, 53,13" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="54,12, 54,13" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="55,12, 54,12" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="55,11, 55,12" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="56,11, 55,11" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="316,11, 315,11, 316,12, 316,14, 317,18, 317,19, 317,20, 317,22, 318,24, 318,26, 319,32, 319,34, 321,33, 321,27, 323,27, 328,27, 328,29, 328,36, 326,38, 321,37, 320,36, 318,37, 319,38, 318,43, 318,44, 318,44, 317,44, 317,41, 317,40, 316,32, 315,26, 315,14, 315,10"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="57,10, 56,11" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="57,10, 57,10" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="58,9, 57,10" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="58,8, 58,9" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="59,8, 58,8" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="59,7, 59,8" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="59,7, 60,7, 59,7, 60,7" onmouseover="mouseOver(16)"
                            onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="89,3, 89,46, 88,47, 85,66, 81,65, 57,64, 55,59, 54,59, 55,58, 51,53, 48,51, 47,52, 47,50, 46,51, 45,50, 44,49, 43,33, 41,34, 40,33, 40,30, 41,28, 42,18, 43,18, 47,18, 47,20, 66,3"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="179,287, 180,289, 181,289, 180,293, 178,296, 175,296, 176,291, 178,288, 177,287, 177,286"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="174,281, 175,282, 182,282, 181,283, 177,284, 176,285, 175,285, 166,285, 165,284, 164,284, 160,284, 137,284, 137,282, 167,281, 168,281"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="330,119, 330,129, 327,130, 323,129, 319,129, 319,127, 323,127, 324,119"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="166,105, 167,106" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="166,105, 166,105" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="165,104, 166,105" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="165,104, 165,104" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="164,103, 165,104" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="164,103, 164,103" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="163,102, 164,103" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="163,101, 163,102" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="162,101, 163,101" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="161,100, 162,101" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="161,100, 161,100" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="160,99, 161,100" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="160,99, 160,99" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="159,98, 160,99" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="159,98, 159,98, 159,98, 159,98" onmouseover="mouseOver(17)"
                            onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="157,97, 169,109, 175,113, 174,112, 174,111, 179,112, 180,114, 180,123, 182,126, 180,127, 178,126, 178,126, 177,127, 177,143, 173,145, 171,145, 166,151, 167,151, 166,152, 165,154, 165,156, 164,157, 164,159, 136,158, 135,152, 134,151, 133,140, 132,138, 132,134, 132,96"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="245,11, 245,29, 244,31, 244,40, 243,41, 242,41, 242,36, 238,37, 237,38, 233,37, 232,34, 233,27, 234,27, 239,27, 239,34, 242,34, 243,24, 243,22, 243,21, 244,16, 244,14, 244,13, 244,12, 244,11, 244,10"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="319,67, 318,82, 312,82, 311,81, 312,73, 315,72, 316,71, 318,67, 318,66"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="45,53, 50,57, 52,58, 53,62, 54,65, 53,73, 48,78, 46,81, 39,82, 34,81, 30,79, 30,78, 29,78, 28,76, 27,76, 25,71, 25,69, 25,62, 26,61, 27,58, 30,55, 31,55, 32,54, 35,53"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="187,146, 193,151, 196,156, 196,166, 191,172, 186,174, 184,175, 176,174, 170,170, 168,165, 167,163, 168,155, 172,149, 178,146"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="243,67, 243,69, 244,70, 245,73, 249,73, 249,82, 242,82, 242,66"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="187,53, 192,56, 192,57, 193,58, 196,63, 196,65, 196,72, 195,73, 192,78, 188,81, 185,81, 183,82, 177,81, 175,81, 173,79, 172,78, 170,77, 167,71, 168,62, 168,61, 171,57, 173,55, 176,53, 179,53"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22" coords="249,163, 248,174, 242,174, 242,172, 242,163"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22" coords="54,208, 54,216, 25,216, 26,208" onmouseover="mouseOver(22)"
                            onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="45,146, 49,149, 50,151, 52,151, 53,155, 54,157, 54,162, 53,166, 49,171, 47,173, 43,175, 34,174, 33,174, 29,171, 26,166, 25,164, 25,162, 25,155, 29,149, 33,147, 38,146"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23" coords="319,163, 318,174, 311,174, 312,163"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23" coords="54,276, 53,283, 25,282, 26,276" onmouseover="mouseOver(23)"
                            onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <!-- 28 -->
                        <area shape="poly" alt="28"
                            coords="127,208, 129,211, 129,215, 128,215, 127,216, 126,241, 127,273, 127,274, 128,276, 128,276, 129,280, 129,281, 127,284, 126,283, 125,274, 125,267, 124,250, 124,249, 124,244, 124,243, 124,239, 125,238, 126,208"
                            onmouseover="mouseOver(28)" onmouseout="mouseOut(28)" (click)="createDamage(28)">
                        <area shape="poly" alt="28" coords="319,160, 319,162, 241,161, 242,159"
                            onmouseover="mouseOver(28)" onmouseout="mouseOut(28)" (click)="createDamage(28)">
                        <area shape="poly" alt="28"
                            coords="248,94, 249,94, 252,95, 277,95, 309,94, 309,94, 309,94, 310,94, 313,94, 314,95, 314,95, 314,95, 315,97, 315,103, 316,103, 315,104, 316,105, 318,118, 318,118, 318,119, 318,120, 319,139, 319,140, 319,140, 319,141, 319,147, 319,150, 241,150, 240,145, 241,143, 242,138, 242,137, 243,115, 243,114, 244,109, 244,105, 245,103, 245,99, 246,95"
                            onmouseover="mouseOver(28)" onmouseout="mouseOut(28)" (click)="createDamage(28)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="123,25, 124,27, 123,30, 116,36, 117,38, 119,41, 119,44, 132,44, 145,43, 155,42, 156,40, 158,40, 177,39, 178,39, 185,39, 188,40, 194,40, 198,41, 204,41, 207,42, 211,43, 213,43, 217,45, 219,47, 219,56, 224,59, 224,67, 214,72, 198,76, 197,75, 195,76, 194,80, 189,86, 187,87, 182,89, 175,89, 167,83, 163,78, 160,80, 76,80, 75,79, 71,80, 70,81, 64,87, 62,88, 59,89, 52,89, 44,83, 40,79, 20,78, 19,78, 17,77, 17,75, 15,74, 16,72, 15,68, 13,65, 13,63, 13,59, 16,55, 22,51, 34,48, 36,48, 39,47, 41,47, 45,46, 47,45, 50,45, 56,44, 63,44, 67,43, 74,43, 88,42, 95,38, 98,36, 120,25"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="188,197, 189,197, 199,198, 203,202, 208,204, 216,211, 218,214, 220,216, 221,220, 224,233, 223,258, 222,259, 222,262, 220,265, 217,271, 207,280, 202,283, 198,286, 186,287, 175,287, 165,287, 164,286, 161,286, 159,285, 145,286, 70,286, 69,287, 45,286, 44,286, 38,285, 33,282, 26,281, 20,276, 17,270, 16,267, 15,264, 15,262, 14,258, 13,256, 13,252, 13,227, 14,226, 17,212, 26,203, 29,202, 30,202, 33,201, 39,197, 58,197, 70,197, 71,198, 74,198, 99,197, 147,198, 163,197, 164,197, 169,196"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/chassis cabine/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/1.gif"
                        usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/3.gif"
                        usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/4.gif"
                        usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/5.gif"
                        usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/6.gif"
                        usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/7.gif"
                        usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/8.gif"
                        usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/9.gif"
                        usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/10.gif"
                        usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/11.gif"
                        usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/12.gif"
                        usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/13.gif"
                        usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/14.gif"
                        usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/15.gif"
                        usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/16.gif"
                        usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/17.gif"
                        usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/20.gif"
                        usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/21.gif"
                        usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/22.gif"
                        usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/23.gif"
                        usemap="#imgmap" />
                    <img id="overlay_28" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/mouseover/28.gif"
                        usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/11.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/23.gif" usemap="#imgmap" />
                    <img id="overlay_28_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/chassis cabine/active/28.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'coupe'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 12 -->
                        <area shape="poly" alt="12" coords="29,34, 25,38, 14,42, 11,43, 9,43, 9,39, 14,35, 18,33"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="298,34, 300,34, 304,36, 308,41, 308,43, 293,41, 291,40, 290,39, 290,39, 290,34, 292,33"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="378,34, 379,36, 378,38, 377,39, 375,41, 372,42, 368,43, 362,43, 360,43, 361,40, 362,40, 362,39, 365,35, 374,33"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="14,53, 13,57, 9,58, 5,59, 4,57, 5,53, 7,53"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="302,54, 302,58, 298,57, 298,56, 298,54, 300,53"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="369,54, 370,55, 369,57, 368,58, 365,57, 365,54, 368,53"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="242,130, 246,133, 247,134, 249,136, 250,138, 249,140, 244,139, 242,138, 239,137, 236,136, 229,132, 229,130"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="254,150, 254,153, 253,155, 244,153, 244,151, 244,149"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="236,199, 243,204, 248,212, 247,213, 242,211, 242,211, 229,198"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="248,271, 247,275, 240,283, 234,286, 233,286, 232,286, 229,286, 230,284, 239,277, 245,272, 246,271"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="373,23, 374,22, 375,23, 376,24, 361,39, 358,40, 331,40, 319,40, 306,39, 298,31, 292,24, 293,22, 296,22, 346,22, 348,22"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="47,25, 48,24, 51,24, 51,26, 34,29, 32,30, 15,34, 13,36, 9,36, 7,33, 9,32, 9,32, 11,31, 11,32, 14,30, 18,30, 18,29, 19,30, 20,29, 23,28, 28,28, 29,27, 30,28, 31,27, 35,26, 41,25, 42,26, 43,25"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="214,122, 217,122, 223,123, 225,124, 225,123, 227,124, 229,124, 229,124, 230,124, 246,128, 247,128, 250,130, 251,129, 251,131, 247,133, 244,132, 238,130, 230,128, 228,127, 225,126, 223,126, 220,125, 217,124, 215,124, 211,123, 208,122, 206,122, 207,121"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="213,203, 214,204, 221,205, 222,205, 240,210, 244,212, 246,213, 245,213, 246,215, 247,218, 250,228, 250,236, 251,241, 250,246, 248,263, 248,264, 246,272, 234,276, 228,278, 213,281, 203,282, 188,281, 178,281, 179,279, 181,275, 183,269, 184,265, 184,263, 185,259, 186,251, 185,224, 184,223, 182,213, 180,209, 178,203"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt="2"
                            coords="232,19, 232,20, 233,19, 234,20, 237,20, 241,21, 243,22, 244,22, 247,23, 248,23, 248,24, 250,24, 249,25, 250,26, 252,30, 251,32, 250,32, 248,28, 246,25, 244,24, 242,24, 239,23, 236,22, 231,22, 227,21, 222,20, 221,20, 222,19, 223,18"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="37,116, 36,117, 30,118, 27,119, 22,119, 19,120, 11,122, 11,123, 8,128, 7,127, 8,124, 7,124, 9,122, 9,121, 12,119, 13,120, 14,119, 18,117, 20,117, 20,116, 28,116, 29,115"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="367,117, 367,119, 362,125, 360,129, 355,136, 352,137, 313,136, 310,133, 308,129, 301,119, 300,117, 300,117, 311,116"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="41,209, 36,217, 34,223, 34,225, 33,228, 32,232, 32,236, 32,255, 33,256, 35,265, 37,269, 41,277, 36,276, 30,275, 26,275, 14,273, 12,271, 9,270, 7,268, 5,265, 5,262, 4,256, 3,246, 4,226, 5,225, 5,220, 6,219, 5,219, 8,215, 11,214, 13,213, 15,212, 18,211, 20,211, 26,210, 30,209, 39,209"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 3 -->
                        <area shape="poly" alt="3"
                            coords="166,1, 165,1, 149,1, 138,1, 137,2, 126,3, 124,3, 114,4, 114,3, 117,3, 118,2, 119,3, 120,2, 124,1, 126,1, 126,0"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="323,1, 328,1, 355,1, 356,2, 357,1, 358,2, 363,3, 364,4, 363,5, 351,5, 304,5, 306,2, 316,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="111,96, 117,97, 128,97, 128,98, 138,99, 139,99, 140,99, 141,99, 144,100, 144,100, 144,101, 136,101, 135,100, 128,99, 126,99, 119,99, 118,97, 117,99, 92,98, 92,97, 97,97, 97,96"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="356,98, 357,99, 361,99, 360,103, 353,101, 348,100, 338,100, 319,100, 310,102, 308,103, 307,102, 307,99, 317,98, 327,97"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="142,213, 144,218, 146,225, 146,230, 147,232, 148,243, 147,252, 146,256, 146,260, 142,271, 140,273, 118,269, 107,269, 101,268, 85,267, 72,267, 73,265, 75,261, 77,252, 76,229, 76,227, 74,221, 72,219, 73,217, 105,217, 106,216, 119,215, 121,215, 136,213, 138,212"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="112,5, 113,4, 114,5, 115,5, 114,6, 113,7, 92,16, 80,22, 78,22, 79,20, 80,21, 81,20, 85,18, 88,16, 93,14, 94,13, 95,13, 97,11, 103,8, 107,6, 111,4"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="364,5, 365,6, 366,7, 365,7, 367,9, 369,11, 372,20, 371,22, 363,22, 296,21, 298,15, 298,14, 298,14, 299,13, 298,13, 300,12, 299,11, 303,6, 306,5, 335,5, 362,5"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="146,101, 148,101, 159,107, 160,108, 161,107, 164,110, 167,111, 173,115, 176,116, 177,117, 178,118, 178,119, 174,118, 169,115, 143,101"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="178,203, 182,211, 184,218, 184,223, 185,227, 186,238, 185,259, 184,261, 184,267, 183,269, 180,279, 177,281, 169,281, 167,280, 165,279, 162,279, 159,278, 153,276, 144,274, 142,273, 141,272, 144,265, 146,256, 147,252, 148,247, 147,230, 146,229, 146,224, 145,223, 143,216, 141,212, 141,211, 144,211, 153,209, 155,208, 158,207, 161,207, 167,205, 170,204, 173,203"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt="5"
                            coords="194,6, 195,7, 205,10, 205,11, 206,10, 210,12, 218,15, 219,16, 222,16, 223,18, 219,19, 213,18, 212,18, 209,17, 208,16, 188,7, 189,5"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="354,101, 356,102, 357,101, 358,103, 358,102, 364,105, 367,111, 368,116, 323,115, 319,116, 300,115, 300,110, 304,105, 309,102, 318,100"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="70,102, 69,103, 64,107, 45,115, 39,116, 35,115, 36,114, 40,112, 58,105"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="59,210, 60,211, 66,213, 70,216, 74,220, 74,223, 75,224, 76,227, 76,232, 77,237, 76,256, 76,257, 75,261, 74,263, 72,267, 71,267, 67,271, 60,274, 57,275, 52,275, 41,276, 38,272, 34,262, 34,259, 33,257, 32,252, 32,246, 32,230, 33,229, 34,224, 34,223, 36,217, 41,209"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="9,34, 11,38, 9,41, 9,43, 14,42, 16,41, 22,41, 32,41, 30,43, 28,47, 26,54, 25,64, 15,63, 14,63, 3,61, 4,59, 11,58, 14,54, 14,53, 1,53, 1,42, 2,41, 5,34"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="331,40, 339,40, 361,40, 361,43, 370,43, 371,42, 374,41, 377,40, 381,41, 380,43, 379,48, 377,60, 375,63, 374,63, 373,63, 355,63, 352,61, 313,62, 310,63, 292,63, 291,61, 292,61, 291,59, 290,56, 289,52, 288,48, 288,41, 288,41, 290,40, 293,41, 296,42, 299,43, 306,43, 308,43, 307,40"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="253,131, 254,133, 255,135, 256,136, 255,136, 257,139, 256,140, 257,141, 257,146, 257,150, 253,149, 244,150, 244,153, 248,155, 252,155, 256,157, 255,157, 246,159, 240,160, 236,161, 232,160, 232,153, 230,144, 229,142, 228,140, 227,140, 228,140, 226,137, 236,138, 243,138, 244,139, 247,140, 249,140, 250,138, 248,135, 247,134, 247,133, 252,130"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="240,199, 241,200, 242,200, 244,202, 244,202, 246,203, 245,203, 248,207, 249,207, 248,208, 250,209, 250,210, 250,211, 251,212, 250,213, 252,215, 255,225, 256,228, 256,230, 257,234, 257,240, 257,252, 256,254, 254,263, 254,264, 250,273, 250,274, 250,275, 248,278, 247,279, 246,281, 244,283, 244,283, 240,286, 238,286, 238,284, 246,277, 248,271, 247,271, 246,271, 248,261, 249,260, 250,256, 250,251, 251,240, 250,231, 250,227, 249,223, 248,222, 246,215, 247,214, 248,213, 244,206, 240,202, 236,199, 236,198"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7"
                            coords="251,37, 252,38, 254,38, 255,41, 257,43, 257,52, 255,53, 252,54, 250,55, 244,57, 240,57, 237,58, 233,59, 230,59, 227,60, 224,61, 219,61, 217,50, 216,49, 213,41, 240,39, 243,38, 246,38, 248,36"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="12,134, 16,136, 30,136, 32,137, 45,138, 45,140, 43,143, 42,146, 41,147, 41,149, 40,153, 40,157, 39,158, 26,156, 24,155, 9,152, 5,150, 1,149, 1,147, 1,140, 3,138, 5,134, 9,134, 10,133"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="310,135, 313,136, 315,137, 354,136, 356,135, 367,134, 368,134, 372,134, 373,135, 381,139, 380,143, 379,146, 379,150, 377,151, 378,152, 374,157, 373,158, 372,157, 369,154, 364,152, 353,151, 303,152, 299,154, 298,155, 294,158, 292,156, 292,155, 290,153, 289,149, 288,147, 288,146, 288,138, 293,136, 294,135, 299,134, 302,134, 306,135"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7" coords="375,157, 375,157" onmouseover="mouseOver(7)"
                            onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="16,203, 14,205, 9,208, 7,213, 5,218, 4,229, 3,235, 3,244, 3,248, 4,260, 5,264, 5,269, 6,271, 8,275, 12,279, 15,281, 14,282, 11,281, 10,280, 9,279, 5,275, 3,271, 1,260, 0,236, 1,235, 1,220, 2,219, 4,211, 5,209, 6,209, 5,208, 11,203, 16,202"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="155,1, 155,3, 153,3, 149,3, 148,3, 146,3, 144,3, 136,3, 137,2, 141,1, 146,1"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="124,4, 128,3, 130,3, 128,4, 124,4, 122,5, 121,5, 121,5, 119,6, 117,6, 112,8, 112,9, 111,8, 104,11, 97,15, 97,16, 98,15, 92,20, 83,27, 82,30, 80,36, 81,47, 82,49, 82,59, 68,58, 67,54, 67,51, 65,48, 65,45, 62,42, 59,40, 57,38, 57,38, 56,37, 55,38, 55,36, 46,35, 38,37, 38,38, 37,37, 34,40, 34,40, 33,40, 32,40, 20,40, 20,40, 24,38, 29,34, 27,32, 26,32, 26,31, 41,28, 44,27, 47,26, 51,26, 53,25, 59,24, 61,24, 69,23, 73,22, 82,22, 83,21, 97,14, 99,13, 101,13, 103,11, 106,10, 109,9, 111,7, 116,5, 117,5, 118,5, 121,4"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="368,7, 369,9, 369,9, 373,15, 373,16, 373,16, 374,18, 376,22, 375,22, 373,22, 372,20, 369,12, 364,4"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="366,4, 367,5, 367,5, 367,5" onmouseover="mouseOver(8)"
                            onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="367,5, 367,5" onmouseover="mouseOver(8)"
                            onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="379,27, 381,35, 381,40, 379,41, 377,40, 379,35, 376,33, 369,34, 367,34, 368,32, 376,24"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="229,196, 230,197, 231,197, 230,198, 229,199, 240,209, 240,210, 217,204, 211,203, 204,203, 171,203, 170,204, 153,209, 149,209, 146,210, 144,211, 134,213, 130,213, 126,214, 121,215, 115,215, 108,216, 103,216, 104,212, 105,210, 114,211, 126,210, 134,209, 147,206, 148,205, 172,200, 176,196, 221,196"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9" coords="303,4, 304,3, 304,5, 300,6, 301,5, 302,4, 302,5, 303,4"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="300,8, 300,11, 298,15, 296,20, 294,22, 292,22, 298,11, 298,11, 298,10, 299,9, 298,9"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="292,25, 300,32, 300,34, 290,34, 289,36, 290,40, 288,41, 287,38, 287,36, 286,34, 287,32, 288,28, 288,28"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="113,98, 112,99, 111,100, 103,99, 103,98"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="139,101, 140,101, 143,102, 147,103, 148,104, 153,107, 155,109, 156,109, 159,110, 161,111, 178,119, 202,121, 203,122, 215,124, 217,124, 234,129, 233,130, 229,130, 230,132, 236,136, 238,137, 237,138, 229,137, 225,136, 223,135, 223,136, 221,134, 217,132, 210,132, 206,132, 203,134, 203,134, 202,134, 201,134, 198,136, 194,141, 193,144, 191,150, 190,155, 190,156, 184,155, 175,155, 176,146, 177,141, 178,137, 177,128, 176,127, 176,124, 175,123, 169,119, 164,115, 163,114, 156,111, 155,110, 154,109, 153,109, 152,109, 151,107, 150,108, 149,108, 149,107, 141,103, 138,103, 136,103, 133,101, 129,101, 129,100"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="117,269, 119,270, 136,272, 137,273, 153,276, 155,277, 162,279, 175,282, 176,281, 195,282, 215,281, 216,281, 236,276, 237,275, 240,275, 240,275, 240,277, 232,284, 229,286, 230,286, 231,286, 230,288, 229,288, 222,289, 221,290, 219,290, 216,289, 175,287, 171,285, 169,284, 166,284, 159,282, 145,279, 142,277, 139,277, 136,276, 132,275, 128,275, 108,275, 104,274, 104,269"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="168,2, 173,3, 178,3, 181,4, 184,5, 186,5, 188,6, 192,8, 211,18, 219,20, 223,20, 226,21, 240,23, 242,24, 245,25, 244,26, 235,29, 242,34, 247,36, 246,38, 243,38, 234,39, 229,40, 219,40, 214,41, 211,40, 209,38, 206,37, 205,38, 205,36, 200,36, 190,36, 188,38, 188,38, 188,38, 181,43, 180,46, 178,49, 177,55, 176,57, 142,57, 140,57, 141,55, 144,50, 145,47, 146,46, 149,36, 149,33, 150,30, 151,28, 151,25, 152,21, 153,17, 153,14, 154,11, 155,7, 155,4, 156,1"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="307,101, 306,104, 302,108, 300,111, 300,117, 304,121, 303,122, 296,124, 294,127, 295,130, 298,134, 294,135, 287,138, 286,130, 287,128, 289,123, 292,118, 295,113, 298,108, 298,107, 298,106, 300,105, 299,105, 301,103, 306,99"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="78,196, 79,196, 80,196, 81,196, 106,197, 105,205, 105,207, 104,213, 103,217, 74,217, 70,217, 67,213, 59,211, 57,210, 54,209, 45,209, 38,209, 28,209, 26,210, 14,211, 17,207, 22,202, 21,202, 21,202, 24,200, 25,201, 26,200, 32,200, 34,199, 37,198, 38,197, 39,198, 40,197, 43,196, 46,195"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="103,99, 103,102, 103,105, 104,109, 107,126, 108,127, 111,140, 112,142, 115,149, 117,153, 117,154, 85,153, 81,153, 79,144, 72,136, 68,134, 66,133, 63,132, 53,133, 47,136, 45,138, 36,137, 28,136, 22,136, 11,134, 12,132, 18,129, 23,125, 22,124, 19,124, 16,123, 14,122, 13,122, 14,121, 16,121, 19,120, 24,119, 27,119, 32,118, 35,117, 40,117, 42,116, 43,115, 47,115, 48,114, 68,104, 72,102, 74,101, 77,101, 82,100, 85,99, 94,99"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="363,99, 364,99, 367,104, 368,104, 369,106, 369,106, 369,107, 370,108, 373,113, 373,114, 374,114, 373,115, 374,116, 375,117, 376,119, 377,121, 378,121, 377,122, 379,124, 380,126, 381,126, 380,127, 381,128, 380,138, 377,137, 370,133, 370,132, 373,130, 373,127, 371,124, 368,123, 365,122, 364,122, 366,119, 368,116, 367,111, 367,110, 367,109, 365,108, 363,105, 360,103"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="102,268, 103,269, 104,272, 106,288, 105,289, 103,288, 97,289, 84,288, 76,289, 74,290, 43,289, 41,288, 39,287, 38,288, 38,286, 34,286, 31,285, 20,283, 20,283, 23,282, 15,276, 14,273, 18,273, 20,274, 26,275, 30,275, 36,276, 41,276, 55,275, 56,275, 64,273, 71,267"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 13 -->
                        <area shape="poly" alt="13"
                            coords="252,33, 252,37, 248,36, 248,36, 247,36, 246,36, 239,32, 235,29, 236,28, 245,26, 248,26"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="18,123, 19,124, 23,126, 18,130, 14,132, 9,134, 5,134, 5,134, 7,130, 9,126, 11,122"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="305,122, 307,126, 310,134, 298,134, 298,132, 296,132, 294,128, 295,125, 297,124, 300,123, 304,122"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="364,123, 365,122, 369,123, 373,125, 373,126, 373,130, 371,132, 367,134, 357,134, 358,132"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="23,203, 13,212, 11,214, 5,217, 6,213, 7,212, 9,208, 13,205, 18,203"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="7,267, 11,271, 14,274, 22,282, 15,281, 9,277, 7,273, 5,267"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt="14" coords="177,57, 176,63, 138,63, 68,63, 68,59, 86,59, 126,58, 146,57"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15"
                            coords="97,154, 140,155, 155,155, 190,156, 190,160, 82,159, 82,158, 82,153"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="155,5, 154,13, 153,14, 149,37, 148,38, 146,45, 144,50, 144,51, 142,55, 139,57, 107,58, 83,57, 81,44, 81,34, 82,29, 82,28, 82,27, 83,28, 84,26, 86,23, 91,20, 95,18, 97,17, 97,16, 100,14, 113,8, 124,5, 135,3, 138,3, 152,3"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="387,19, 388,20, 388,24, 385,24, 377,24, 378,20, 379,18"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="290,116, 289,121, 280,121, 281,117, 284,115"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="162,189, 165,192, 166,196, 176,196, 175,198, 173,200, 166,201, 165,202, 161,202, 159,202, 157,203, 144,207, 142,207, 137,209, 136,208, 133,209, 122,210, 105,210, 105,206, 106,200, 107,199, 105,196, 107,196, 146,195, 159,194, 159,189, 159,188"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17" coords="290,19, 290,22, 291,22, 289,24, 280,24, 281,19, 283,18"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="132,101, 133,101, 137,102, 138,103, 145,105, 146,105, 157,110, 161,114, 165,115, 166,116, 167,117, 169,118, 175,123, 175,124, 176,124, 178,132, 177,142, 176,146, 176,150, 175,153, 175,155, 173,155, 136,155, 119,154, 117,153, 112,142, 110,136, 109,133, 109,130, 108,128, 107,125, 107,122, 105,112, 104,108, 103,104, 103,101, 109,100"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="387,116, 388,118, 387,121, 378,121, 378,119, 379,115"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="134,275, 135,276, 140,277, 142,277, 153,281, 156,281, 161,283, 165,283, 168,283, 173,284, 173,286, 176,288, 175,289, 165,290, 165,292, 161,296, 159,296, 159,290, 160,290, 159,289, 156,290, 117,289, 106,288, 105,279, 105,278, 105,275"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="52,38, 53,39, 57,41, 58,42, 60,43, 61,45, 62,45, 64,51, 63,61, 63,61, 63,62, 60,66, 55,70, 49,72, 38,70, 33,66, 30,60, 30,57, 30,49, 31,48, 33,44, 38,40, 45,38"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="381,46, 381,72, 378,72, 369,71, 369,64, 374,63, 378,59, 379,55, 379,51, 379,46"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21" coords="288,49, 290,60, 295,64, 299,65, 299,72, 287,71, 287,48"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="218,136, 225,142, 228,146, 229,151, 228,157, 227,159, 225,163, 220,167, 213,169, 205,169, 198,164, 195,159, 194,155, 194,152, 196,144, 200,139, 203,136, 205,136, 207,135"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="203,38, 210,43, 213,48, 214,51, 215,55, 213,63, 208,68, 207,69, 204,71, 202,72, 196,72, 194,72, 190,71, 184,67, 180,61, 181,60, 180,59, 180,56, 180,49, 185,42, 190,39, 199,38, 202,38"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="288,142, 290,152, 293,157, 295,159, 298,155, 298,155, 298,169, 286,169, 286,142"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="68,136, 72,139, 73,139, 72,140, 76,142, 78,148, 77,159, 70,167, 65,169, 61,169, 52,167, 47,163, 45,159, 44,156, 43,153, 44,146, 45,145, 47,142, 47,142, 47,141, 50,138, 51,138, 52,137, 56,135"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="381,144, 381,145, 381,169, 369,169, 369,155, 373,158, 377,153, 379,148, 380,143"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="144,30, 146,32, 145,36, 136,42, 137,45, 140,48, 140,52, 155,51, 171,51, 182,49, 183,47, 186,47, 208,46, 209,45, 217,46, 221,47, 228,47, 233,48, 240,49, 244,49, 248,50, 250,51, 255,53, 257,55, 257,66, 263,69, 263,79, 251,85, 232,89, 231,88, 229,89, 229,94, 223,101, 220,103, 213,105, 205,104, 196,98, 192,92, 188,94, 89,94, 88,93, 83,94, 82,95, 75,103, 72,103, 69,105, 61,104, 51,98, 47,93, 24,92, 22,92, 20,91, 20,88, 18,87, 18,84, 18,80, 16,76, 15,74, 16,69, 19,65, 26,60, 40,57, 42,56, 46,55, 49,55, 53,54, 55,53, 59,53, 66,52, 74,51, 79,51, 87,50, 103,49, 111,45, 115,43, 142,29"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="221,231, 223,232, 234,232, 238,238, 244,240, 254,248, 256,252, 258,254, 260,258, 263,273, 262,303, 261,304, 261,308, 259,311, 256,319, 243,329, 237,333, 233,337, 219,337, 205,338, 194,337, 192,337, 190,336, 187,335, 170,336, 82,337, 81,337, 53,337, 52,336, 45,335, 38,332, 31,330, 23,324, 20,317, 18,314, 18,310, 17,308, 16,304, 16,301, 15,296, 16,267, 16,265, 20,249, 30,238, 34,238, 36,237, 38,236, 46,232, 68,231, 82,232, 84,232, 87,232, 116,232, 173,232, 192,232, 193,231, 198,230"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/coupe/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/5.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/10.gif" usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/11.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/12.gif" usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/13.gif" usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/14.gif" usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/15.gif" usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/16.gif" usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/17.gif" usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/18.gif" usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/19.gif" usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/20.gif" usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/21.gif" usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/22.gif" usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/mouseover/23.gif" usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/11.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/coupe/active/23.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'hatchback'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 1 -->
                        <area shape="poly" alt=""
                            coords="258,26, 304,26, 326,26, 327,27, 327,31, 327,34, 323,36, 315,40, 313,43, 310,44, 301,44, 298,45, 276,44, 275,44, 267,43, 266,41, 264,39, 255,35, 254,35, 254,33, 254,26, 257,25"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt=""
                            coords="186,122, 197,124, 198,125, 204,127, 207,128, 210,129, 211,131, 212,131, 213,132, 214,132, 214,133, 215,133, 214,134, 212,133, 209,131, 206,129, 196,126, 188,124, 187,123, 179,122, 177,121, 171,120, 172,119, 180,120, 182,121"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt=""
                            coords="53,26, 53,27, 45,29, 43,29, 40,30, 26,34, 25,34, 16,38, 11,41, 10,40, 11,39, 13,38, 13,37, 24,33, 33,30, 41,28, 43,27, 45,27, 49,26, 51,26"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt=""
                            coords="201,206, 202,206, 203,207, 208,213, 217,221, 220,222, 220,229, 221,238, 220,261, 220,262, 219,266, 216,266, 207,275, 203,280, 203,281, 202,280, 201,281, 197,281, 182,281, 168,281, 166,280, 163,280, 163,277, 165,275, 167,271, 169,266, 170,256, 171,241, 170,234, 169,220, 168,219, 165,212, 163,207, 164,207, 166,206, 177,206, 182,205"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt=""
                            coords="216,22, 218,29, 219,34, 219,40, 215,38, 215,35, 215,34, 217,31, 216,30, 215,27, 213,26, 211,26, 206,25, 205,25, 205,22"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt=""
                            coords="13,115, 14,115, 20,115, 20,118, 11,120, 9,122, 8,126, 11,128, 9,132, 7,133, 6,133, 7,122, 7,120, 10,115"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt=""
                            coords="254,115, 254,114, 256,115, 263,115, 273,116, 294,117, 305,116, 328,115, 328,117, 328,117, 326,118, 322,118, 320,119, 314,120, 312,123, 312,126, 314,127, 316,128, 322,128, 321,133, 321,134, 320,136, 318,136, 313,137, 282,137, 262,136, 260,134, 259,128, 267,127, 270,125, 269,124, 267,121, 266,120, 263,119, 256,118, 252,117, 253,115"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt=""
                            coords="11,212, 10,216, 10,217, 10,222, 11,221, 12,215, 13,214, 13,214, 12,231, 11,247, 12,250, 12,264, 13,266, 12,270, 12,270, 10,265, 10,266, 10,272, 11,273, 10,276, 9,275, 8,271, 7,269, 7,262, 6,258, 6,240, 6,235, 7,221, 7,220, 8,214, 10,211"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 3 -->
                        <area shape="poly" alt=""
                            coords="177,1, 178,1, 200,3, 199,3, 163,2, 162,1, 124,2, 123,2, 113,3, 112,3, 105,5, 104,6, 100,7, 99,6, 95,5, 98,4, 100,4, 102,3, 104,3, 106,2, 109,2, 111,1, 113,1, 141,0"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt=""
                            coords="108,94, 109,94, 117,95, 119,95, 124,97, 128,98, 127,99, 124,99, 117,98, 114,97, 112,96, 110,96, 108,95, 104,95, 93,94, 90,94, 40,96, 39,96, 25,96, 24,95, 25,95, 41,94, 45,94, 98,93"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt=""
                            coords="314,94, 315,94, 315,94, 315,95, 306,95, 293,95, 271,95, 270,95, 265,95, 266,94, 268,94, 277,93"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt=""
                            coords="312,1, 313,2, 317,2, 318,3, 318,4, 318,6, 309,5, 298,4, 268,5, 267,6, 262,5, 264,3, 267,2, 272,1, 281,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt=""
                            coords="43,217, 95,218, 115,217, 123,217, 126,217, 128,227, 128,233, 129,239, 128,257, 128,258, 127,265, 127,266, 126,270, 114,270, 108,269, 38,270, 37,270, 36,270, 35,264, 35,262, 34,255, 34,244, 34,230, 35,226, 36,217"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt=""
                            coords="317,6, 318,6, 320,7, 322,11, 323,15, 326,24, 326,25, 307,26, 255,25, 256,20, 257,16, 259,11, 262,6, 267,6, 276,5"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt=""
                            coords="99,6, 98,7, 87,12, 78,17, 66,25, 59,25, 59,25, 59,24, 81,11, 94,6"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt=""
                            coords="133,99, 134,100, 149,107, 165,117, 166,118, 165,118, 158,118, 145,109, 141,108, 135,104, 126,100, 126,100, 126,99, 131,99"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt=""
                            coords="163,208, 166,213, 168,217, 168,220, 169,222, 169,226, 170,229, 170,238, 170,259, 169,260, 168,270, 167,271, 164,277, 160,280, 143,275, 137,273, 134,272, 133,272, 129,271, 127,271, 126,270, 128,261, 128,257, 129,251, 128,228, 128,227, 127,221, 127,220, 126,216, 143,212, 144,212, 146,211, 149,211, 160,207"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt=""
                            coords="315,95, 316,96, 318,96, 321,99, 327,113, 327,114, 322,115, 315,115, 299,116, 262,115, 261,115, 254,114, 254,113, 254,112, 262,98, 264,96, 268,95, 280,95"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="" coords="199,5, 207,11, 215,21, 214,21, 204,21, 197,10, 194,6"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="" coords="31,99, 30,101, 22,112, 20,114, 10,114, 12,112, 22,101, 26,98"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt=""
                            coords="24,205, 36,215, 35,222, 35,223, 34,231, 34,234, 34,260, 35,261, 35,273, 31,277, 22,283, 21,282, 18,281, 16,277, 14,273, 13,270, 13,266, 12,259, 12,250, 12,224, 13,223, 13,216, 14,215, 16,210, 18,206, 21,204"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 12 -->
                        <area shape="poly" alt=""
                            coords="328,55, 330,57, 329,60, 327,61, 324,61, 323,59, 324,56, 326,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="257,55, 257,57, 257,61, 256,61, 252,61, 252,59, 252,55, 254,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="331,35, 333,37, 332,43, 330,44, 326,44, 324,45, 319,45, 316,45, 314,44, 315,40, 316,39, 324,35, 329,34"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="256,35, 257,35, 265,39, 266,42, 266,44, 263,45, 256,45, 255,44, 250,44, 250,44, 249,43, 249,43, 248,40, 248,36, 249,35, 252,34"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="219,266, 219,271, 219,272, 218,276, 213,281, 210,284, 203,286, 201,286, 202,282, 210,272, 213,269, 217,266"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="208,202, 216,208, 218,212, 219,214, 219,217, 219,221, 218,221, 216,221, 205,209, 201,203, 202,201"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="" coords="12,55, 12,58, 12,61, 10,61, 10,55" onmouseover="mouseOver(12)"
                            onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="27,36, 25,40, 21,44, 19,44, 18,45, 13,46, 10,45, 10,43, 15,38, 18,36, 21,36, 25,35"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="" coords="215,149, 215,152, 215,154, 213,154, 214,149"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt=""
                            coords="204,129, 205,129, 205,129, 212,133, 214,135, 215,138, 215,139, 207,138, 206,138, 203,136, 199,133, 198,131, 199,128"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 6 -->
                        <area shape="poly" alt=""
                            coords="208,200, 209,201, 217,202, 219,205, 219,206, 220,206, 221,210, 222,212, 222,214, 223,218, 224,220, 224,229, 225,246, 224,254, 224,267, 223,268, 222,273, 222,275, 220,280, 219,281, 219,282, 218,283, 217,284, 212,286, 210,286, 207,287, 205,286, 205,286, 207,285, 209,284, 212,282, 217,276, 219,271, 220,263, 220,262, 221,253, 220,224, 220,223, 219,219, 219,213, 213,206, 206,201, 204,200, 204,199"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt=""
                            coords="256,45, 262,45, 264,45, 267,43, 270,44, 278,44, 281,45, 294,45, 295,45, 308,44, 309,44, 316,44, 319,46, 322,45, 325,45, 327,44, 332,44, 336,47, 337,47, 337,55, 336,56, 336,59, 335,61, 334,67, 333,66, 332,67, 280,67, 268,67, 247,66, 247,64, 246,62, 245,58, 244,54, 244,49, 244,47, 247,46, 249,43"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt=""
                            coords="217,135, 220,141, 221,142, 222,143, 221,155, 219,158, 217,159, 211,159, 205,160, 197,160, 197,150, 196,149, 193,143, 191,140, 191,140, 202,139, 205,137, 208,138, 215,139, 215,138, 215,136, 214,135, 214,134"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt=""
                            coords="11,41, 10,45, 10,46, 17,45, 18,45, 22,44, 24,47, 34,47, 33,48, 32,49, 30,51, 30,53, 29,54, 28,59, 27,62, 27,67, 7,65, 6,64, 4,64, 4,62, 3,59, 3,53, 3,49, 4,48, 7,46, 9,41"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt=""
                            coords="26,198, 24,199, 18,201, 13,206, 11,211, 9,212, 7,217, 7,222, 6,227, 6,239, 6,262, 7,263, 7,271, 8,272, 8,273, 10,276, 12,277, 13,281, 16,284, 20,286, 22,287, 27,289, 26,289, 15,287, 14,287, 13,287, 12,287, 7,284, 6,278, 5,272, 4,270, 4,264, 3,259, 3,249, 3,225, 4,224, 4,215, 5,214, 7,204, 12,200, 13,199, 13,200, 15,199, 17,199, 19,198, 25,198"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt=""
                            coords="335,124, 336,126, 337,133, 337,146, 336,147, 336,149, 335,150, 336,150, 332,155, 332,155, 330,156, 328,157, 328,157, 325,158, 254,157, 253,157, 253,157, 252,156, 251,157, 250,155, 249,156, 248,154, 247,154, 248,153, 246,151, 245,151, 246,150, 245,149, 244,146, 244,145, 244,128, 245,127, 246,124, 247,124, 249,127, 251,128, 259,128, 261,134, 263,137, 281,137, 317,137, 318,136, 321,134, 322,128, 322,128, 332,127, 334,125, 335,124"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt=""
                            coords="15,128, 25,128, 27,134, 27,135, 29,139, 26,141, 22,147, 21,155, 21,158, 15,157, 12,155, 11,154, 10,154, 9,154, 8,152, 6,151, 4,149, 2,147, 1,143, 1,136, 2,135, 5,134, 11,129, 11,128"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt=""
                            coords="214,35, 217,39, 220,41, 224,43, 225,45, 224,52, 218,59, 208,64, 206,65, 204,64, 203,57, 203,56, 201,51, 197,46, 196,46, 197,43, 198,40, 201,35"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt=""
                            coords="194,197, 195,198, 199,198, 201,199, 202,200, 201,202, 202,205, 201,206, 194,205, 169,206, 168,206, 163,207, 162,207, 158,207, 157,208, 148,211, 146,211, 140,213, 138,214, 136,214, 124,216, 122,217, 109,217, 93,217, 94,214, 116,213, 117,212, 126,212, 127,211, 132,211, 134,210, 137,210, 148,206, 149,207, 150,206, 150,206, 155,202, 155,198, 165,197, 169,197"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt=""
                            coords="319,3, 321,3, 324,8, 324,11, 327,15, 327,17, 331,29, 332,31, 333,36, 332,36, 330,34, 327,34, 327,27, 326,26, 326,21, 325,20, 322,12, 317,2"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt=""
                            coords="133,1, 133,4, 128,4, 118,5, 117,6, 106,7, 105,8, 95,12, 94,12, 88,15, 76,24, 73,27, 72,31, 72,33, 71,35, 71,40, 70,44, 71,59, 71,61, 71,63, 67,63, 67,57, 66,56, 64,52, 64,50, 59,47, 58,45, 58,45, 57,44, 52,43, 49,42, 47,41, 45,42, 41,43, 37,44, 36,45, 34,46, 22,45, 22,44, 22,43, 26,38, 27,36, 25,35, 26,34, 31,32, 44,29, 46,29, 48,28, 50,27, 55,26, 58,26, 68,24, 69,23, 71,22, 71,22, 72,21, 76,19, 77,18, 81,16, 82,15, 86,13, 97,8, 101,7, 104,5, 112,3, 114,3, 120,2, 124,2"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt=""
                            coords="335,36, 335,38, 336,38, 336,40, 337,40, 337,44, 337,46, 333,45, 332,44, 333,41, 333,40, 333,36, 333,36"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt=""
                            coords="263,3, 262,6, 261,8, 258,15, 256,19, 254,24, 255,25, 254,27, 254,29, 253,34, 250,35, 248,37, 248,42, 249,43, 246,46, 244,45, 244,40, 248,34, 252,20, 256,11, 257,10, 259,5, 260,3, 261,3"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt=""
                            coords="126,270, 127,271, 143,275, 145,275, 158,279, 159,280, 165,280, 166,281, 176,281, 182,282, 200,281, 201,281, 202,281, 201,285, 202,287, 199,288, 194,289, 192,290, 155,289, 154,284, 150,281, 140,278, 137,277, 135,277, 125,275, 122,275, 115,274, 107,273, 94,273, 92,270, 92,270"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt=""
                            coords="107,95, 108,96, 118,98, 119,98, 122,99, 126,100, 138,105, 143,109, 144,109, 145,110, 146,110, 146,111, 147,110, 149,112, 150,112, 150,113, 152,114, 153,115, 154,115, 154,115, 157,117, 160,118, 166,119, 175,120, 176,121, 179,122, 182,122, 184,123, 186,123, 188,124, 191,124, 193,125, 195,126, 200,128, 199,128, 198,129, 198,131, 200,133, 203,137, 201,140, 190,139, 189,138, 188,138, 188,137, 185,136, 185,137, 184,136, 180,135, 171,136, 168,138, 168,138, 167,138, 165,140, 162,142, 163,143, 161,143, 159,149, 157,156, 154,156, 154,152, 155,149, 155,137, 154,129, 154,128, 152,120, 147,115, 136,108, 130,105, 120,101, 107,99, 104,98, 95,98, 92,97, 93,95"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 10 -->
                        <area shape="poly" alt=""
                            coords="62,197, 62,198, 59,198, 55,200, 49,204, 46,206, 46,208, 48,211, 52,213, 57,214, 94,214, 94,216, 92,217, 36,217, 36,215, 34,212, 23,204, 24,203, 29,201, 26,198, 30,197, 36,197"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt=""
                            coords="264,95, 261,99, 254,113, 253,116, 252,117, 250,117, 250,114, 254,106, 257,101, 258,100, 260,96, 264,95"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt=""
                            coords="170,2, 171,2, 189,3, 191,3, 198,3, 198,4, 193,6, 194,7, 195,8, 196,8, 195,9, 199,13, 199,15, 200,16, 202,18, 202,19, 204,21, 205,22, 204,25, 202,25, 200,27, 199,30, 199,32, 199,34, 200,35, 199,37, 199,38, 196,45, 194,44, 191,43, 185,42, 184,41, 174,44, 170,47, 166,52, 166,53, 165,54, 165,56, 164,58, 164,61, 163,62, 156,61, 156,60, 161,55, 163,52, 175,31, 177,28, 179,25, 180,20, 181,18, 178,11, 175,7, 173,5, 166,4, 157,4, 133,3, 133,1"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt=""
                            coords="92,270, 93,273, 51,274, 46,279, 46,280, 51,285, 57,287, 59,288, 61,289, 62,289, 61,290, 28,289, 27,288, 27,287, 29,286, 27,285, 25,284, 23,284, 24,282, 36,272, 36,270, 48,270"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt=""
                            coords="321,96, 324,101, 331,117, 331,117, 328,117, 327,113, 322,102, 321,101, 319,98, 318,96, 318,95"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt=""
                            coords="92,95, 92,97, 92,98, 84,97, 58,98, 57,98, 50,99, 48,102, 45,109, 44,111, 45,115, 45,116, 50,126, 54,132, 56,135, 60,141, 64,147, 68,152, 69,154, 68,155, 61,155, 61,149, 59,147, 59,145, 57,142, 54,141, 55,140, 50,137, 49,137, 49,136, 43,135, 34,136, 31,137, 29,138, 26,131, 25,128, 26,126, 26,124, 24,119, 22,118, 21,118, 21,114, 23,112, 24,110, 27,105, 27,105, 29,103, 29,102, 32,99, 31,99, 28,97, 29,96, 47,96, 48,95, 71,95, 78,94"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 13 -->
                        <area shape="poly" alt=""
                            coords="211,26, 212,26, 215,27, 216,29, 216,31, 216,33, 215,34, 213,35, 212,34, 199,34, 199,33, 201,26, 202,25"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt=""
                            coords="24,119, 25,120, 25,122, 26,124, 25,128, 25,127, 24,128, 10,127, 8,126, 9,122, 10,121, 10,120, 13,119, 16,119, 21,118"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt=""
                            coords="11,266, 11,268, 12,270, 15,277, 18,281, 24,284, 28,285, 28,287, 25,287, 24,287, 20,286, 15,283, 12,279, 11,273, 10,271, 10,268, 10,265"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt=""
                            coords="28,200, 28,202, 26,202, 24,203, 21,204, 18,206, 14,210, 12,215, 10,222, 10,222, 10,215, 11,214, 13,205, 18,201, 20,201, 25,199"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt=""
                            coords="331,118, 332,119, 334,123, 333,127, 332,127, 312,127, 312,124, 312,123, 314,120, 315,120, 324,118, 326,118, 330,117"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt=""
                            coords="256,118, 257,118, 266,120, 268,121, 269,125, 268,127, 267,127, 248,127, 247,126, 248,121, 249,120, 248,119, 251,117"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt=""
                            coords="160,62, 161,62, 164,62, 164,66, 131,67, 67,66, 68,64, 73,63, 110,63, 115,62, 151,62, 152,61"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt=""
                            coords="76,155, 92,155, 115,156, 134,156, 157,157, 157,161, 155,160, 62,160, 61,159, 62,155, 68,155, 69,154"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt=""
                            coords="249,116, 248,119, 248,120, 246,123, 238,123, 238,122, 238,118, 242,116, 245,115"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt=""
                            coords="147,191, 150,196, 151,198, 155,198, 154,203, 152,203, 149,206, 147,207, 145,207, 139,209, 131,211, 126,211, 123,212, 117,212, 109,213, 95,212, 97,207, 100,202, 100,198, 101,197, 145,197, 143,193, 143,191"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt=""
                            coords="340,23, 344,26, 343,29, 342,30, 333,30, 332,30, 332,27, 332,22"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt=""
                            coords="132,4, 131,14, 130,15, 128,24, 127,27, 126,40, 126,62, 81,63, 80,63, 72,63, 71,62, 71,54, 71,35, 72,34, 72,29, 73,28, 74,26, 75,25, 85,17, 88,15, 94,12, 104,8, 109,7, 113,6, 118,6, 120,5"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt=""
                            coords="340,116, 343,118, 344,120, 343,123, 338,123, 335,123, 333,119, 332,117, 332,115"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt=""
                            coords="115,274, 118,275, 132,276, 133,277, 139,277, 154,282, 154,284, 155,289, 150,290, 150,292, 145,296, 143,296, 143,294, 144,293, 145,289, 134,289, 101,289, 100,287, 100,286, 100,285, 100,284, 99,282, 98,282, 94,273"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt=""
                            coords="340,116, 343,118, 344,120, 343,123, 338,123, 335,123, 333,119, 332,117, 332,115"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="" coords="249,22, 249,30, 248,30, 238,29, 238,25, 240,23, 245,22"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt=""
                            coords="99,98, 109,99, 111,99, 119,101, 120,101, 129,104, 130,105, 140,110, 143,113, 150,118, 152,121, 152,123, 153,124, 154,129, 154,131, 155,138, 154,153, 154,154, 153,156, 118,156, 110,155, 99,155, 99,150, 99,137, 99,126, 98,124, 96,115, 96,114, 94,104, 94,103, 93,99, 93,98"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 18 -->
                        <area shape="poly" alt=""
                            coords="100,198, 100,202, 99,203, 95,212, 93,213, 50,212, 47,209, 46,208, 46,206, 47,206, 48,205, 49,203, 57,199, 61,198, 63,198, 69,197"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt=""
                            coords="167,4, 168,5, 175,6, 178,9, 179,13, 180,15, 180,17, 180,22, 179,24, 175,31, 158,59, 156,61, 155,60, 154,61, 141,62, 127,62, 126,58, 126,48, 127,28, 128,27, 128,25, 131,10, 132,9, 132,6, 133,4, 146,4, 164,4"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt=""
                            coords="94,274, 95,275, 98,281, 98,282, 99,284, 99,284, 100,289, 60,289, 59,288, 54,286, 54,286, 53,286, 52,285, 52,285, 51,284, 50,285, 48,282, 47,281, 46,280, 47,277, 50,274, 61,273"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt=""
                            coords="92,98, 93,100, 95,108, 95,110, 96,114, 96,116, 98,121, 98,124, 99,128, 99,134, 99,155, 89,155, 70,154, 66,151, 49,123, 46,118, 45,115, 45,114, 44,112, 45,108, 45,107, 47,103, 50,99, 58,98, 65,98"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <!-- 20 -->
                        <area shape="poly" alt=""
                            coords="337,61, 336,78, 334,78, 327,78, 327,77, 327,67, 333,67, 335,65, 335,63, 336,61, 336,60"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt=""
                            coords="53,46, 54,47, 57,48, 59,50, 62,54, 64,61, 63,67, 63,68, 61,71, 56,76, 50,78, 47,78, 45,78, 41,77, 40,77, 34,72, 31,67, 31,61, 33,54, 35,50, 36,49, 41,47, 43,46, 45,45"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt=""
                            coords="245,62, 247,67, 249,67, 254,67, 254,78, 252,78, 244,78, 245,61"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt=""
                            coords="183,139, 184,140, 187,141, 191,145, 192,147, 194,151, 193,161, 189,167, 184,170, 177,171, 173,171, 171,170, 168,169, 165,166, 162,160, 161,157, 162,149, 166,143, 170,140, 174,139, 177,138"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="" coords="245,151, 250,156, 254,159, 254,171, 253,171, 244,171, 244,150"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt=""
                            coords="189,46, 196,50, 199,55, 199,57, 200,59, 199,67, 199,68, 197,72, 192,76, 188,78, 183,78, 182,78, 178,77, 171,73, 169,69, 168,67, 168,64, 168,56, 169,55, 170,52, 175,47, 181,45"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="" coords="337,150, 336,171, 327,171, 327,157, 330,157, 335,152, 336,150"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt=""
                            coords="46,139, 47,140, 50,141, 55,145, 57,151, 58,154, 57,160, 57,161, 55,165, 49,170, 44,171, 36,171, 31,168, 27,164, 25,159, 25,155, 26,147, 29,143, 33,141, 37,139, 40,138"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="123,25, 124,27, 123,30, 116,36, 117,38, 119,41, 119,44, 132,44, 145,43, 155,42, 156,40, 158,40, 177,39, 178,39, 185,39, 188,40, 194,40, 198,41, 204,41, 207,42, 211,43, 213,43, 217,45, 219,47, 219,56, 224,59, 224,67, 214,72, 198,76, 197,75, 195,76, 194,80, 189,86, 187,87, 182,89, 175,89, 167,83, 163,78, 160,80, 76,80, 75,79, 71,80, 70,81, 64,87, 62,88, 59,89, 52,89, 44,83, 40,79, 20,78, 19,78, 17,77, 17,75, 15,74, 16,72, 15,68, 13,65, 13,63, 13,59, 16,55, 22,51, 34,48, 36,48, 39,47, 41,47, 45,46, 47,45, 50,45, 56,44, 63,44, 67,43, 74,43, 88,42, 95,38, 98,36, 120,25"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="188,197, 189,197, 199,198, 203,202, 208,204, 216,211, 218,214, 220,216, 221,220, 224,233, 223,258, 222,259, 222,262, 220,265, 217,271, 207,280, 202,283, 198,286, 186,287, 175,287, 165,287, 164,286, 161,286, 159,285, 145,286, 70,286, 69,287, 45,286, 44,286, 38,285, 33,282, 26,281, 20,276, 17,270, 16,267, 15,264, 15,262, 14,258, 13,256, 13,252, 13,227, 14,226, 17,212, 26,203, 29,202, 30,202, 33,201, 39,197, 58,197, 70,197, 71,198, 74,198, 99,197, 147,198, 163,197, 164,197, 169,196"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/hatchback/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/5.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/10.gif" usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/11.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/12.gif" usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/13.gif" usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/14.gif" usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/15.gif" usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/16.gif" usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/17.gif" usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/18.gif" usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/19.gif" usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/20.gif" usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/21.gif" usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/22.gif" usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/mouseover/23.gif" usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/11.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/hatchback/active/23.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'mpv'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 12 -->
                        <area shape="poly" alt="12"
                            coords="212,267, 211,270, 211,272, 208,277, 202,283, 197,285, 193,284, 191,283, 192,282, 191,282"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="200,208, 206,213, 209,218, 212,224, 211,225, 211,225, 210,224, 207,222, 192,211, 192,210, 192,208, 195,208"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="215,162, 215,166, 214,165" onmouseover="mouseOver(12)"
                            onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="196,138, 203,140, 204,141, 208,143, 211,145, 210,146, 212,148, 212,151, 208,150, 206,150, 201,148, 192,141, 191,138"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="325,70, 325,73, 321,73, 322,70" onmouseover="mouseOver(12)"
                            onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="274,70, 275,72, 273,73, 270,73, 271,70"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="4,70, 4,74, 3,73, 3,69" onmouseover="mouseOver(12)"
                            onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="27,46, 19,54, 15,56, 9,58, 7,59, 6,58, 7,54, 8,53, 8,53, 11,50, 18,47, 23,46, 27,46"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="334,46, 336,50, 335,54, 332,56, 329,57, 327,57, 319,58, 318,58, 318,53, 321,49, 327,46"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="270,47, 276,51, 277,53, 277,56, 277,58, 268,57, 267,57, 263,56, 260,53, 260,47, 262,45"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="191,209, 209,225, 210,226, 212,234, 212,250, 211,262, 211,263, 209,269, 194,280, 189,283, 169,282, 169,282, 173,280, 180,272, 183,263, 184,261, 185,256, 186,252, 185,234, 185,233, 183,226, 181,222, 177,216, 172,211, 170,210, 170,209, 175,209"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="188,131, 189,132, 192,133, 192,134, 193,133, 196,135, 200,137, 201,137, 207,140, 208,140, 208,141, 209,141, 210,143, 211,142, 212,144, 212,144, 212,146, 210,145, 206,142, 199,139, 196,138, 192,138, 183,137, 177,137, 170,136, 166,133, 166,132, 184,131, 185,131"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="38,39, 47,40, 52,40, 51,41, 46,44, 41,44, 31,45, 27,46, 20,46, 19,47, 12,50, 7,53, 6,52, 7,51, 10,49, 12,47, 13,47, 14,46, 21,43, 23,42, 24,41, 24,42, 25,41, 28,40, 30,39, 30,38"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="335,38, 337,43, 333,45, 326,47, 319,51, 318,52, 293,53, 283,52, 276,52, 275,50, 266,46, 259,44, 260,39, 261,38"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt="2"
                            coords="14,217, 14,219, 13,221, 12,223, 12,225, 10,234, 9,241, 9,248, 9,249, 10,259, 11,260, 11,265, 12,266, 14,273, 14,275, 14,275, 4,275, 2,269, 1,253, 1,231, 2,230, 4,218, 4,218, 4,217, 6,217"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="280,133, 308,133, 328,133, 328,151, 327,156, 327,159, 324,160, 269,159, 269,157, 269,157, 268,147, 269,133"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="14,132, 13,134, 11,135, 7,141, 6,146, 4,148, 4,149, 3,149, 4,140, 4,139, 4,138, 9,133, 8,132"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="211,40, 210,41, 214,45, 214,46, 215,47, 215,47, 215,48, 215,49, 215,56, 214,56, 212,50, 210,46, 206,41, 205,41, 205,40"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 3 -->
                        <area shape="poly" alt="3"
                            coords="25,219, 26,220, 132,220, 131,272, 61,272, 24,273, 24,273, 21,271, 22,270, 21,269, 20,269, 21,268, 20,267, 19,264, 18,262, 18,259, 17,251, 18,233, 18,233, 18,232, 18,231, 19,226, 20,225, 20,224, 21,224, 20,223, 21,222, 21,222"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="319,105, 321,106, 325,106, 325,108, 325,113, 308,112, 271,112, 272,106, 281,105, 293,105"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="118,105, 119,106, 120,105, 121,106, 126,106, 130,107, 133,108, 133,109, 130,109, 128,108, 125,108, 121,107, 114,106, 101,106, 71,106, 67,107, 49,108, 48,108, 37,109, 35,109, 25,111, 23,112, 16,112, 18,110, 23,109, 24,109, 25,109, 27,109, 30,108, 36,108, 44,107, 53,106, 55,106, 56,106, 60,106, 69,105, 92,105"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="320,13, 321,14, 324,14, 324,17, 320,16, 315,15, 306,15, 277,15, 276,16, 271,17, 272,14, 279,13, 293,12"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="153,13, 154,14, 157,14, 170,14, 172,15, 178,15, 179,15, 198,17, 199,18, 200,18, 202,20, 195,20, 191,18, 185,17, 182,17, 173,16, 169,15, 156,15, 151,14, 128,14, 99,14, 98,15, 86,15, 88,15, 89,14, 90,15, 92,14, 95,14, 99,13, 116,12"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="170,210, 170,211, 171,211, 176,215, 180,221, 183,228, 185,236, 186,243, 185,257, 185,259, 182,269, 176,277, 172,281, 166,282, 164,283, 162,282, 137,277, 136,276, 135,276, 135,275, 134,276, 133,275, 133,273, 133,217, 134,217, 134,217, 135,216, 141,214, 150,212, 152,212, 154,211, 162,209"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="137,109, 138,109, 145,112, 146,113, 157,118, 169,125, 178,131, 177,131, 164,131, 164,131, 163,131, 162,130, 160,129, 159,128, 158,127, 157,127, 153,123, 151,122, 150,121, 149,121, 148,120, 147,120, 146,118, 146,119, 144,117, 143,117, 142,116, 141,116, 141,115, 139,114, 138,113, 137,112, 136,112, 135,111, 134,111, 134,108"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="85,16, 84,18, 82,20, 80,21, 79,21, 79,22, 78,22, 77,23, 76,24, 72,26, 71,27, 70,28, 69,28, 67,30, 67,30, 67,30, 65,31, 64,32, 63,33, 62,33, 61,34, 60,35, 60,35, 59,35, 57,37, 56,38, 54,38, 54,39, 40,39, 41,37, 44,35, 62,25, 81,17"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="317,15, 318,16, 324,17, 328,20, 332,30, 335,37, 334,38, 261,38, 262,34, 262,33, 267,19, 270,17, 277,16, 281,15, 302,15, 307,15"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt="5"
                            coords="24,212, 24,214, 24,219, 20,224, 18,230, 18,233, 17,238, 17,244, 17,256, 18,257, 18,263, 19,264, 20,267, 24,273, 25,273, 24,277, 24,280, 22,280, 18,279, 15,277, 15,275, 14,275, 12,269, 12,266, 11,263, 11,261, 10,255, 9,251, 10,234, 11,233, 12,222, 13,221, 15,217, 15,217, 15,216, 19,212, 22,212"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="327,114, 330,120, 333,127, 332,130, 330,132, 319,133, 266,132, 263,128, 263,125, 266,120, 269,114, 274,113, 305,112, 309,113"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="18,112, 24,113, 23,127, 20,129, 15,131, 9,131, 10,130, 12,122, 14,117, 13,117, 14,115, 14,115, 16,112"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="203,20, 205,24, 206,30, 207,31, 208,39, 202,38, 197,35, 195,34, 195,20"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="204,208, 209,211, 210,212, 212,213, 215,220, 215,222, 216,226, 217,231, 217,238, 217,264, 216,265, 214,275, 212,279, 211,279, 210,281, 208,282, 206,283, 204,284, 204,285, 203,284, 201,285, 199,286, 198,285, 198,284, 201,283, 209,275, 212,268, 211,267, 210,266, 212,258, 212,248, 212,233, 211,232, 211,227, 210,226, 210,225, 212,225, 210,219, 203,210, 199,208, 198,207, 198,206"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="213,146, 214,147, 214,147, 217,154, 218,158, 218,161, 214,162, 214,163, 214,166, 217,169, 217,169, 216,169, 213,170, 201,170, 196,170, 194,160, 190,153, 190,153, 196,151, 197,150, 203,149, 212,151, 212,150, 211,146, 211,146"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="7,53, 7,57, 6,58, 7,59, 18,57, 24,59, 25,59, 28,61, 28,63, 25,66, 23,72, 22,75, 22,78, 5,78, 4,77, 1,76, 1,75, 4,74, 5,72, 4,69, 3,69, 1,68, 1,62, 2,60, 2,59, 6,53"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="260,53, 262,55, 266,57, 269,57, 273,58, 278,57, 277,55, 277,53, 318,53, 318,57, 318,59, 321,58, 332,56, 337,53, 338,55, 340,58, 339,63, 338,66, 335,76, 331,79, 327,79, 264,79, 263,78, 259,75, 257,69, 256,57, 257,54, 259,53"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7"
                            coords="7,276, 12,282, 12,283, 9,283, 8,280, 6,279, 5,277, 5,277, 5,276"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="12,208, 12,210, 8,214, 6,216, 5,215, 7,213, 7,211, 9,210, 11,209"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="269,155, 269,159, 271,160, 326,159, 327,157, 328,154, 340,155, 340,160, 339,162, 338,166, 338,167, 336,170, 334,172, 332,173, 330,173, 321,174, 266,173, 264,173, 263,172, 262,172, 262,172, 261,171, 260,170, 257,164, 257,162, 256,158, 256,156, 256,154"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="17,143, 32,153, 28,157, 27,160, 26,161, 27,162, 25,163, 25,169, 5,169, 2,168, 2,167, 1,166, 1,160, 1,159, 4,158, 4,147, 6,145, 6,144, 12,144"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="204,50, 207,51, 212,52, 215,57, 215,66, 217,66, 218,67, 217,74, 215,76, 193,76, 192,70, 192,69, 189,63, 186,60, 187,59, 203,50"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="188,204, 189,204, 193,205, 195,205, 198,206, 197,207, 194,208, 191,209, 186,209, 161,209, 160,210, 141,214, 140,215, 134,217, 133,219, 132,219, 96,219, 96,217, 99,217, 116,216, 117,215, 125,215, 127,214, 131,214, 133,213, 148,210, 149,209, 153,209, 155,208, 157,208, 159,206, 158,204, 158,203"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="327,15, 329,18, 330,19, 330,22, 331,23, 332,24, 333,29, 334,29, 333,30, 334,31, 334,31, 334,33, 335,34, 335,34, 337,37, 336,38, 337,39, 337,40, 338,41, 340,46, 340,48, 340,57, 338,56, 337,53, 336,52, 335,49, 334,45, 334,44, 337,43, 336,41, 333,32, 332,30, 332,28, 331,28, 330,24, 330,23, 329,21, 328,21, 326,18, 325,17, 325,14"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="124,14, 122,15, 103,16, 102,17, 96,17, 95,18, 87,21, 80,25, 57,42, 55,43, 56,44, 62,43, 62,51, 64,72, 64,73, 66,79, 60,79, 60,69, 56,64, 55,63, 56,62, 54,60, 53,61, 51,59, 47,57, 47,57, 45,56, 35,57, 34,57, 31,59, 31,60, 31,59, 29,60, 25,60, 22,58, 17,56, 18,55, 29,46, 34,45, 46,44, 49,43, 51,41, 56,38, 56,38, 56,38, 62,34, 62,34, 67,30, 68,30, 70,28, 73,27, 74,26, 76,24, 78,24, 80,21, 85,18, 85,18, 85,17, 92,15, 106,14, 115,14"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9"
                            coords="133,273, 133,275, 137,276, 143,278, 151,280, 153,280, 156,281, 163,283, 184,283, 189,283, 191,282, 193,284, 196,285, 198,285, 197,286, 188,288, 182,289, 166,289, 158,289, 159,286, 160,285, 159,285, 156,284, 153,283, 151,283, 143,281, 140,280, 138,280, 134,279, 132,279, 130,278, 125,277, 122,277, 115,276, 111,276, 99,275, 96,275, 96,272"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="164,154, 163,155" onmouseover="mouseOver(9)"
                            onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="122,107, 124,108, 130,108, 131,109, 133,109, 135,112, 143,118, 144,117, 145,119, 146,118, 147,120, 151,123, 151,122, 153,124, 153,124, 154,125, 156,126, 157,127, 157,127, 159,128, 159,128, 161,130, 164,132, 165,133, 168,134, 173,137, 180,137, 192,139, 196,144, 201,149, 201,149, 189,153, 188,152, 188,153, 186,151, 186,151, 185,150, 180,149, 179,149, 178,149, 173,149, 172,150, 172,149, 170,150, 167,151, 167,152, 166,151, 159,160, 159,162, 158,163, 159,164, 158,165, 158,172, 152,171, 153,170, 153,167, 154,165, 154,162, 155,158, 156,154, 156,145, 157,136, 163,135, 153,128, 139,118, 127,111, 120,109, 118,109, 112,108, 102,108, 95,108, 95,106"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="265,24, 264,27, 263,27, 263,31, 259,43, 262,46, 259,51, 259,53, 255,57, 256,54, 255,52, 256,44, 258,40, 259,40, 258,39, 259,38, 259,35, 260,33, 261,30, 262,29, 262,26, 264,25, 263,25"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="269,15, 270,14, 271,14, 271,17, 267,20, 265,22, 264,21, 266,21, 265,20, 267,17, 267,16"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="60,202, 59,204, 54,208, 61,215, 62,217, 96,217, 96,219, 25,219, 23,209, 22,208, 20,207, 14,209, 12,208, 14,208, 15,206, 18,206, 19,206, 20,206, 25,205, 27,205, 28,204, 33,203, 50,202"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="270,108, 270,114, 268,116, 262,130, 260,135, 259,137, 260,143, 263,144, 268,145, 267,154, 256,154, 255,147, 256,142, 256,138, 259,131, 262,123, 263,122, 267,111, 269,108, 270,107"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="143,14, 160,15, 162,15, 176,16, 177,17, 193,18, 195,20, 195,39, 196,40, 198,44, 202,49, 202,50, 193,56, 186,60, 183,58, 177,56, 168,57, 162,61, 157,66, 156,70, 154,75, 151,75, 149,74, 146,73, 157,56, 159,53, 163,44, 164,42, 163,38, 163,37, 157,22, 155,17, 153,17, 142,16, 136,15, 124,15, 124,14"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="96,272, 96,275, 96,275, 62,276, 61,277, 53,285, 54,285, 54,285, 56,286, 59,289, 59,289, 31,289, 30,288, 27,288, 25,286, 19,286, 15,285, 12,283, 12,283, 15,283, 17,284, 20,285, 22,284, 24,282, 24,277, 25,275, 25,272"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="328,109, 328,109, 328,111, 330,112, 330,115, 333,121, 332,121, 335,125, 334,126, 336,130, 338,134, 338,135, 339,138, 340,141, 341,143, 340,154, 328,154, 328,144, 335,144, 337,142, 337,137, 335,131, 333,127, 328,115, 326,113, 326,108"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="95,107, 94,108, 72,108, 71,109, 63,109, 63,111, 59,121, 57,126, 54,134, 55,137, 56,138, 59,144, 69,160, 72,166, 65,167, 63,167, 63,165, 62,160, 56,153, 54,151, 49,149, 47,149, 38,149, 37,150, 34,151, 34,152, 33,151, 32,152, 28,150, 26,149, 19,144, 16,142, 17,140, 22,134, 22,132, 23,130, 24,125, 24,111, 25,111, 30,110, 35,109, 38,109, 48,108, 54,108, 67,107, 80,106"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 13 -->
                        <area shape="poly" alt="13"
                            coords="15,276, 19,279, 23,280, 22,284, 21,285, 16,284, 15,283, 11,282, 7,277, 8,276"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="22,208, 23,211, 22,212, 18,214, 14,217, 12,216, 7,215, 8,214, 9,214, 8,213, 12,209, 17,208, 21,207"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="334,129, 335,131, 335,133, 337,138, 336,144, 328,144, 329,132"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="263,129, 263,128, 267,132, 267,132, 267,144, 260,143, 259,141, 260,134, 260,133"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="23,128, 21,136, 13,144, 7,144, 7,140, 14,133, 15,132, 16,131, 22,128"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="199,37, 204,39, 204,40, 207,42, 212,49, 212,51, 204,51, 203,49, 198,44, 196,41, 195,36, 196,35"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt="14"
                            coords="146,74, 147,73, 149,75, 150,74, 153,75, 155,76, 154,79, 66,79, 66,78, 66,76, 83,76, 142,75, 143,75, 143,75, 144,75"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15"
                            coords="74,166, 77,167, 80,168, 153,169, 152,172, 151,172, 63,172, 63,168, 65,167, 67,167"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="153,196, 155,197, 157,201, 159,205, 160,206, 162,207, 159,208, 155,209, 153,209, 149,209, 147,210, 133,213, 130,214, 127,214, 122,215, 118,215, 111,216, 98,216, 98,214, 102,206, 102,205, 105,202, 153,202, 151,201, 150,196, 150,195"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="257,129, 257,131, 259,132, 258,133, 259,133, 257,135, 257,135, 256,135, 254,134, 247,134, 248,129"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16" coords="348,37, 348,42, 338,41, 338,39, 340,37, 340,37"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="123,15, 122,19, 122,20, 120,27, 120,28, 118,35, 116,40, 115,41, 115,46, 114,47, 114,51, 113,58, 112,69, 112,75, 103,76, 65,75, 64,73, 64,70, 63,64, 63,60, 62,50, 62,44, 59,43, 56,43, 56,42, 56,43, 58,40, 60,40, 62,39, 63,38, 62,38, 65,35, 79,25, 89,20, 95,18, 97,17, 101,17, 106,16, 117,15"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="118,276, 119,277, 133,279, 134,279, 149,282, 150,283, 154,284, 155,285, 162,285, 161,286, 159,286, 158,288, 157,291, 155,295, 153,296, 150,296, 150,293, 152,290, 151,289, 104,289, 103,287, 102,287, 103,286, 101,283, 98,276"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="348,130, 348,134, 341,135, 340,135, 338,135, 338,133, 337,132, 337,131, 339,131, 339,129"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="107,108, 118,109, 120,109, 128,111, 131,113, 138,117, 153,128, 154,130, 156,130, 156,131, 157,131, 159,131, 159,133, 160,133, 161,134, 162,134, 161,133, 160,134, 161,134, 162,134, 163,135, 162,136, 159,135, 157,136, 157,138, 156,154, 155,155, 153,168, 106,167, 105,149, 105,147, 104,139, 104,138, 103,134, 102,133, 102,133, 100,124, 99,123, 98,117, 96,108"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="254,37, 256,37, 257,39, 257,39, 257,41, 256,42, 247,41, 248,36"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 18 -->
                        <area shape="poly" alt="18"
                            coords="104,203, 103,205, 101,208, 97,216, 96,217, 62,216, 54,208, 56,205, 61,203, 63,202"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="145,16, 146,17, 155,17, 157,21, 163,38, 164,42, 162,48, 157,57, 147,72, 144,75, 140,75, 112,75, 113,61, 114,51, 115,42, 117,40, 117,36, 118,35, 123,16, 123,15"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt="19"
                            coords="98,276, 99,280, 101,284, 102,285, 102,285, 104,289, 103,289, 59,289, 59,288, 58,288, 57,287, 57,288, 54,285, 55,282, 62,276, 64,276"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="95,108, 97,115, 98,117, 99,124, 100,125, 104,140, 105,146, 105,156, 106,165, 106,168, 92,167, 74,167, 73,165, 70,163, 60,147, 54,135, 55,130, 57,126, 57,124, 58,122, 60,118, 63,109, 70,109, 79,108, 94,108"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="339,71, 338,91, 337,91, 330,91, 329,89, 330,80, 333,79, 337,75"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="46,59, 47,60, 53,63, 56,66, 57,71, 57,82, 53,87, 49,90, 43,91, 35,91, 29,86, 27,83, 25,79, 26,69, 31,62, 37,59, 41,59"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="183,152, 184,153, 188,156, 191,159, 193,164, 192,173, 188,180, 183,183, 179,183, 170,183, 164,178, 161,172, 160,166, 161,164, 163,159, 166,154, 170,153, 175,151"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="257,71, 259,76, 263,79, 265,79, 267,80, 266,91, 256,91, 257,70"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="257,165, 260,171, 264,173, 267,174, 266,183, 256,183, 256,164"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="179,59, 185,62, 185,63, 188,66, 190,70, 191,74, 190,80, 186,87, 181,90, 176,91, 169,91, 167,90, 164,88, 160,84, 159,79, 158,76, 159,70, 159,69, 161,65, 167,60, 171,59, 173,59"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="340,166, 339,183, 329,183, 330,173, 333,173, 338,169, 337,168"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="50,152, 57,157, 60,164, 60,166, 60,173, 56,179, 54,180, 53,181, 50,183, 47,183, 38,183, 31,178, 29,174, 28,169, 28,162, 29,161, 31,157, 34,154, 35,154, 36,153, 40,151"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="130,27, 131,28, 130,32, 122,38, 123,40, 125,43, 126,47, 139,46, 153,46, 163,44, 164,43, 167,42, 187,41, 188,41, 195,41, 199,42, 205,43, 209,43, 215,44, 219,44, 222,45, 225,46, 229,47, 231,50, 231,59, 237,62, 236,71, 225,76, 209,80, 208,79, 206,80, 205,84, 200,91, 198,92, 192,94, 185,93, 176,88, 172,82, 169,84, 80,84, 79,83, 75,84, 74,86, 67,92, 65,93, 62,94, 54,93, 46,88, 42,83, 21,83, 20,82, 18,82, 18,79, 16,78, 17,76, 16,72, 14,69, 14,66, 14,62, 17,59, 24,54, 35,51, 38,50, 41,50, 44,49, 47,49, 50,48, 53,47, 59,47, 66,46, 71,46, 78,45, 93,44, 100,40, 104,38, 127,26"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="199,208, 200,208, 210,209, 214,214, 219,215, 228,222, 230,226, 232,228, 234,232, 236,246, 235,272, 235,273, 234,276, 233,280, 230,286, 218,296, 213,299, 209,302, 196,303, 185,304, 174,303, 173,302, 170,302, 168,301, 153,302, 74,302, 73,303, 48,302, 47,302, 40,301, 34,298, 28,296, 21,291, 18,285, 17,282, 16,279, 15,276, 15,273, 14,270, 14,266, 14,240, 15,238, 18,224, 27,214, 31,214, 32,213, 34,212, 41,208, 62,208, 74,208, 75,209, 78,209, 104,208, 155,209, 172,208, 173,208, 178,207"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/mpv/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/5.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/10.gif" usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/11.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/12.gif" usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/13.gif" usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/14.gif" usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/15.gif" usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/16.gif" usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/17.gif" usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/18.gif" usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/19.gif" usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/20.gif" usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/21.gif" usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/22.gif" usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/mouseover/23.gif" usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/11.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/mpv/active/23.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'open laadbak'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 12 -->
                        <area shape="poly" alt="12"
                            coords="230,264, 230,269, 228,276, 225,279, 220,281, 219,281, 220,279, 225,273, 226,269, 227,267, 230,263"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="224,211, 226,213, 228,215, 230,221, 230,227, 227,225, 226,220, 224,216, 220,210"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="231,158, 230,159, 230,160, 230,161, 228,162, 225,161, 225,158"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="229,141, 230,147, 229,147, 223,147, 220,143, 219,141, 221,140"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="334,67, 333,69, 331,70, 328,70, 325,70, 325,66"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="281,67, 280,70, 274,70, 272,69, 272,66"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="8,63, 7,67, 3,67, 4,66, 3,65, 2,63, 5,63"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="337,48, 338,49, 337,55, 332,55, 322,55, 323,49, 325,48"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="278,48, 283,49, 283,52, 284,56, 277,55, 269,55, 268,53, 268,48, 271,47, 277,48"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="13,45, 13,48, 9,52, 3,51, 4,46, 4,45"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="204,209, 205,210, 223,216, 225,218, 226,222, 227,225, 227,228, 228,234, 228,237, 228,260, 227,262, 227,266, 226,266, 227,268, 226,269, 225,274, 225,273, 222,275, 220,275, 219,276, 208,280, 206,281, 204,281, 198,282, 200,278, 203,274, 204,269, 206,262, 207,257, 207,253, 207,231, 206,230, 206,225, 205,224, 202,215, 198,209, 200,209"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="210,124, 216,126, 216,127, 218,128, 218,129, 219,128, 220,130, 221,129, 222,132, 223,131, 227,136, 228,137, 228,137, 229,139, 228,140, 218,132, 207,126, 203,124, 202,124, 203,123"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="336,33, 335,35, 331,43, 329,48, 319,48, 277,47, 276,46, 272,38, 270,33"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="31,28, 31,29, 29,29, 22,32, 16,35, 7,43, 4,45, 4,45, 4,42, 10,36, 18,31, 26,28"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt="2" coords="4,204, 4,287, 0,287, 1,203" onmouseover="mouseOver(2)"
                            onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2" coords="348,129, 349,136, 348,144, 259,144, 259,129"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 3 -->
                        <area shape="poly" alt="3"
                            coords="174,209, 174,211, 175,211, 177,218, 177,221, 178,224, 179,227, 179,233, 180,239, 179,263, 179,264, 177,275, 176,277, 174,280, 173,282, 145,281, 143,280, 142,278, 143,274, 143,258, 144,210, 147,209"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="176,96, 176,97, 177,96, 177,97, 178,97, 177,98, 175,99, 171,98, 170,97, 166,97, 139,96, 139,96"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="271,2, 272,1, 275,2, 308,2, 323,2, 332,2, 332,1, 332,2, 334,1, 335,1, 336,1, 338,5, 337,6, 322,5, 268,5"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="93,1, 94,1, 93,1, 94,2, 62,2, 59,4, 55,3, 55,3, 55,2, 55,2, 55,1, 56,2, 57,1, 58,1, 59,1, 64,0, 90,1, 91,0"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="198,209, 202,215, 205,223, 207,233, 207,245, 207,259, 206,260, 206,265, 205,266, 204,270, 204,271, 202,276, 198,282, 197,283, 195,282, 185,281, 177,281, 175,280, 178,269, 179,266, 179,262, 180,254, 180,244, 180,236, 179,231, 179,226, 178,224, 176,210, 177,210, 195,209, 196,209"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="180,99, 186,104, 200,117, 204,121, 203,123, 196,122, 195,120, 194,115, 192,113, 189,114, 188,115, 173,100, 174,99, 175,99"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="337,6, 337,7, 338,19, 338,28, 338,33, 267,33, 268,20, 268,13, 269,7, 270,6, 302,5"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="60,4, 59,6, 44,20, 44,20, 43,18, 40,19, 38,20, 38,26, 35,28, 33,27, 32,28, 29,28, 29,25, 39,15, 52,4, 55,3"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt="5"
                            coords="141,215, 142,214, 142,215, 142,248, 141,277, 141,277, 140,271, 139,256, 140,221, 141,219"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="335,102, 336,109, 337,111, 337,121, 336,122, 270,121, 271,121, 269,120, 270,114, 271,108, 271,103, 272,103, 271,102, 272,102, 272,102, 274,101"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="228,278, 228,279, 227,280, 226,280, 223,281, 222,282, 219,282, 219,283, 216,283, 216,284, 212,284, 213,283, 220,281, 222,280, 225,279, 227,278, 227,277"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="217,207, 218,208, 221,208, 221,209, 224,210, 225,212, 226,211, 227,212, 228,212, 229,215, 230,215, 231,219, 232,225, 233,230, 232,269, 231,270, 231,274, 230,275, 229,275, 231,260, 230,223, 230,222, 229,218, 228,216, 228,214, 227,214, 227,213, 219,211, 218,210, 217,208, 215,209, 215,209, 214,209, 213,207, 212,208, 211,208, 211,206"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="231,150, 233,151, 232,158, 231,158, 225,159, 225,160, 226,162, 229,162, 228,164, 214,164, 213,160, 212,154, 210,149"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="23,55, 22,58, 20,62, 19,67, 19,69, 4,69, 4,67, 4,67, 7,66, 8,65, 7,63, 1,62, 1,55, 2,54"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="268,49, 268,54, 271,55, 283,56, 335,55, 338,53, 338,51, 339,49, 340,49, 343,49, 343,55, 343,57, 341,69, 340,70, 339,73, 266,73, 265,67, 264,63, 263,58, 263,56, 263,49"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="147,207, 147,209, 145,209, 144,209, 142,207, 141,208, 142,209, 142,208, 142,207, 141,208, 142,207"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="215,208, 216,209, 216,209, 218,210, 223,214, 224,216, 221,216, 219,215, 215,213, 210,212, 200,209, 197,208, 194,207, 194,207, 194,206, 195,205, 201,206, 204,206, 210,207, 213,208"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="191,206, 189,207, 188,207, 181,206, 181,206, 189,205"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="339,34, 340,40, 340,41, 341,44, 341,44, 341,44, 342,46, 341,47, 342,48, 341,49, 337,49, 335,47, 329,47, 330,44, 337,33"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="38,27, 38,31, 37,32, 38,34, 40,33, 41,33, 41,47, 45,49, 49,51, 54,57, 55,64, 55,66, 54,66, 54,64, 52,58, 51,58, 52,58, 50,55, 47,53, 47,54, 47,54, 48,54, 47,53, 47,54, 46,52, 42,51, 41,50, 40,50, 35,49, 34,50, 30,51, 29,51, 29,51, 28,51, 26,52, 24,54, 3,54, 4,52, 9,52, 13,47, 13,46, 11,44, 6,44, 7,43, 13,37, 26,31, 34,28, 37,28"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="94,2, 94,14, 94,66, 91,66, 83,66, 84,61, 84,58, 85,54, 85,51, 87,40, 87,3, 64,2, 64,2"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9" coords="191,284, 190,286, 183,285, 183,284, 189,284"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="147,281, 147,281, 147,284, 141,284, 141,283, 144,281"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="224,274, 224,276, 218,281, 213,283, 213,283, 211,284, 209,284, 204,285, 198,286, 194,285, 194,283, 201,282, 204,281, 206,281"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="197,124, 198,123, 202,124, 203,124, 208,126, 209,127, 218,131, 227,138, 227,139, 227,140, 220,140, 219,141, 224,147, 225,147, 230,147, 230,149, 208,149, 207,147, 206,148, 204,146, 196,145, 188,147, 186,148, 181,154, 179,159, 179,161, 177,161, 179,153, 185,145, 190,143, 192,141, 192,128, 195,129, 196,127, 195,124, 195,122"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="168,97, 167,98, 145,99, 146,139, 147,141, 150,161, 138,161, 139,97"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="270,34, 272,37, 277,47, 269,48, 268,49, 264,48, 264,46, 265,45, 266,37, 268,33"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 13 -->
                        <area shape="poly" alt="13" coords="341,155, 340,159, 328,159, 328,155"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="278,155, 278,159, 266,159, 267,155"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt="14" coords="94,66, 94,69, 54,69, 55,66" onmouseover="mouseOver(14)"
                            onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15" coords="179,161, 178,164, 154,164, 138,163, 138,161"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="87,4, 87,46, 86,47, 85,52, 85,54, 84,59, 84,61, 82,66, 55,66, 55,61, 54,60, 52,56, 47,50, 43,48, 41,47, 41,36, 41,33, 38,34, 37,32, 37,30, 38,28, 39,19, 40,18, 44,19, 44,19, 63,3"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="339,10, 341,34, 342,35, 343,34, 344,33, 344,28, 351,28, 351,37, 349,38, 346,38, 344,37, 341,38, 341,44, 340,44, 340,38, 339,35, 338,20, 338,17, 338,10"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="260,123, 259,128, 259,129, 258,132, 254,132, 253,130, 254,122"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="194,195, 196,201, 195,203, 195,207, 198,207, 199,208, 197,209, 194,210, 180,210, 171,209, 170,209, 156,209, 150,210, 148,210, 147,207, 147,207, 191,207, 192,203, 191,200, 191,199, 190,195, 190,195, 192,194"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="268,13, 267,33, 266,35, 266,41, 265,43, 265,44, 264,44, 263,37, 260,37, 260,38, 256,38, 255,37, 254,35, 255,28, 261,28, 262,34, 263,35, 264,34, 265,24, 265,23, 267,12"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="171,99, 172,100, 174,101, 188,114, 189,113, 191,114, 194,115, 194,116, 195,126, 196,127, 195,129, 193,129, 192,130, 192,143, 188,144, 184,147, 180,151, 178,158, 177,160, 175,161, 150,161, 148,147, 147,145, 146,134, 146,99"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="354,123, 354,132, 352,132, 349,132, 348,128, 348,127, 348,122"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="181,281, 195,283, 195,283, 194,284, 194,287, 196,290, 194,295, 192,296, 190,296, 191,292, 191,290, 192,288, 191,285, 189,284, 171,284, 147,284, 147,282, 174,281, 176,281"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="342,68, 342,83, 334,83, 335,73, 338,73, 340,70, 341,68, 341,67"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="42,54, 49,58, 51,62, 51,64, 52,68, 51,72, 51,74, 47,78, 46,79, 44,81, 38,82, 31,82, 25,78, 22,72, 22,69, 22,63, 28,55, 32,54, 35,53"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="204,150, 209,154, 210,159, 211,161, 210,168, 206,175, 200,177, 197,178, 189,176, 183,171, 182,167, 182,157, 186,152, 189,150, 195,148"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="265,69, 265,69, 265,70, 266,72, 268,73, 272,73, 272,82, 269,83, 264,82, 264,69"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22" coords="272,166, 271,178, 265,177, 265,176, 265,165"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="186,54, 191,57, 191,58, 192,58, 194,62, 195,64, 195,68, 194,75, 188,81, 185,82, 174,82, 170,79, 167,75, 166,71, 165,69, 166,63, 167,62, 168,58, 173,55, 176,54, 179,53"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23" coords="343,165, 342,177, 341,178, 335,177, 335,165"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="60,150, 63,152, 64,153, 67,159, 67,161, 67,168, 62,175, 58,177, 56,177, 54,178, 47,177, 46,177, 41,173, 38,167, 38,157, 41,153, 44,150, 49,149, 54,148, 55,149, 59,149"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <!-- 25 -->
                        <area shape="poly" alt="25" coords="136,201, 136,203, 1,203, 1,201" onmouseover="mouseOver(25)"
                            onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <area shape="poly" alt="25" coords="232,33, 231,49, 96,49, 97,33" onmouseover="mouseOver(25)"
                            onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <!-- 26 -->
                        <area shape="poly" alt="26" coords="137,288, 136,290, 0,290, 1,287" onmouseover="mouseOver(26)"
                            onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <area shape="poly" alt="26" coords="136,128, 137,133, 137,144, 0,144, 1,128"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <!-- 28 -->
                        <area shape="poly" alt="28"
                            coords="141,207, 144,210, 142,215, 141,215, 140,223, 141,231, 141,233, 139,234, 140,259, 141,259, 140,274, 141,277, 142,277, 142,282, 140,284, 139,283, 139,275, 139,256, 138,248, 139,213, 139,212, 139,212, 139,210, 140,207"
                            onmouseover="mouseOver(28)" onmouseout="mouseOut(28)" (click)="createDamage(28)">
                        <area shape="poly" alt="28"
                            coords="274,96, 275,97, 332,96, 334,96, 337,96, 338,97, 338,100, 339,105, 340,107, 341,120, 342,123, 341,128, 265,127, 265,118, 266,117, 266,111, 267,109, 269,97, 270,96, 272,96"
                            onmouseover="mouseOver(28)" onmouseout="mouseOut(28)" (click)="createDamage(28)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="132,27, 133,29, 132,32, 124,38, 125,41, 127,44, 128,47, 141,47, 156,46, 166,45, 167,43, 170,43, 190,42, 191,41, 198,42, 202,43, 208,43, 213,44, 219,44, 222,45, 226,46, 228,46, 233,48, 234,51, 235,60, 240,63, 240,72, 229,78, 212,81, 211,81, 209,81, 209,85, 203,92, 201,94, 195,96, 188,95, 179,90, 175,84, 171,85, 81,85, 80,85, 76,85, 75,87, 69,94, 66,94, 63,96, 55,95, 47,90, 43,85, 22,84, 20,84, 18,83, 19,81, 16,79, 17,77, 16,73, 14,70, 14,67, 14,63, 17,60, 24,55, 36,52, 38,51, 42,51, 44,50, 48,49, 51,49, 54,48, 60,47, 67,47, 72,46, 79,46, 94,45, 102,41, 105,39, 129,26"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="202,211, 203,212, 213,212, 218,217, 223,219, 232,226, 234,230, 236,232, 237,236, 240,250, 239,277, 239,278, 238,281, 236,284, 233,291, 222,301, 216,304, 213,307, 200,308, 188,308, 177,308, 176,307, 173,307, 171,306, 155,307, 75,307, 74,308, 49,307, 47,307, 41,306, 35,303, 28,301, 21,296, 18,290, 17,287, 16,283, 16,281, 15,277, 14,275, 14,270, 14,243, 15,242, 18,227, 28,218, 31,217, 32,216, 35,216, 42,212, 63,211, 75,212, 76,212, 79,212, 106,212, 158,212, 175,212, 176,211, 181,210"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/open laadbak/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/5.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/12.gif"
                        usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/13.gif"
                        usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/14.gif"
                        usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/15.gif"
                        usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/16.gif"
                        usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/17.gif"
                        usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/20.gif"
                        usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/21.gif"
                        usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/22.gif"
                        usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/23.gif"
                        usemap="#imgmap" />
                    <img id="overlay_25" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/25.gif"
                        usemap="#imgmap" />
                    <img id="overlay_26" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/26.gif"
                        usemap="#imgmap" />
                    <img id="overlay_28" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/mouseover/28.gif"
                        usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/23.gif" usemap="#imgmap" />
                    <img id="overlay_25_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/25.gif" usemap="#imgmap" />
                    <img id="overlay_26_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/26.gif" usemap="#imgmap" />
                    <img id="overlay_28_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/open laadbak/active/28.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'personenvervoer'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 12 -->
                        <area shape="poly" alt="12" coords="13,55, 12,58, 8,61, 3,61, 3,60, 4,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="257,56, 258,59, 259,62, 258,63, 249,63, 244,62, 244,61, 243,59, 244,56, 245,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="303,55, 310,56, 310,58, 310,61, 306,63, 295,63, 296,56, 302,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="7,72, 8,74, 7,76, 3,76, 3,75, 3,74, 2,72"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="256,75, 256,76, 254,77, 249,77, 248,75, 249,74, 254,74"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="305,74, 306,75, 306,74, 306,75, 304,77, 300,77, 298,77, 298,74, 298,75, 299,74"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="198,158, 198,164, 192,164, 189,159, 189,157"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="200,175, 199,175, 200,176, 199,176, 198,178, 194,178, 194,177, 194,175, 195,175"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="193,218, 195,220, 198,223, 198,228, 199,230, 199,233, 197,232, 195,229, 195,226, 193,223, 189,217"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="199,267, 198,274, 198,276, 197,278, 193,282, 191,282, 190,282, 193,278, 195,274, 195,271, 197,268, 198,267"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="30,38, 30,39, 25,41, 24,41, 14,46, 8,51, 4,55, 3,55, 4,52, 5,51, 7,49, 8,47, 9,47, 12,44, 13,43, 15,42, 18,40, 19,41, 21,39, 23,38, 25,38"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="308,42, 307,44, 306,46, 301,55, 300,55, 252,55, 251,54, 249,48, 245,41"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1" coords="8,47, 8,47" onmouseover="mouseOver(1)"
                            onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1" coords="8,47, 7,48" onmouseover="mouseOver(1)"
                            onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="180,141, 181,142, 186,144, 186,145, 187,144, 187,145, 188,146, 189,147, 190,147, 192,149, 192,148, 197,154, 198,158, 197,157, 192,152, 182,145, 172,141, 172,141"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="182,220, 183,220, 188,222, 193,223, 195,225, 197,237, 197,239, 198,247, 197,262, 197,263, 196,268, 195,270, 195,275, 193,277, 190,277, 189,278, 180,281, 177,281, 176,281, 176,279, 179,273, 181,268, 181,264, 182,261, 182,254, 182,238, 181,237, 181,232, 180,231, 178,225, 176,219"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 3 -->
                        <area shape="poly" alt="3" coords="194,1, 195,2, 195,3, 66,3, 66,2, 70,1, 72,1, 85,0"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="307,2, 309,5, 309,15, 296,15, 244,15, 244,9, 245,8, 245,4, 246,3, 246,2, 248,2, 272,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3" coords="132,103, 133,104, 136,105, 136,105, 6,105, 7,103, 10,103"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="144,217, 147,218, 149,227, 151,237, 152,245, 151,265, 150,266, 149,275, 148,276, 147,282, 143,283, 16,284, 8,283, 7,282, 7,281, 9,280, 8,279, 8,266, 8,216"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="55,14, 57,15, 42,30, 40,29, 37,29, 36,32, 36,37, 34,38, 30,38, 27,37, 28,35, 41,21, 51,14"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="282,15, 289,15, 306,15, 309,16, 310,18, 310,41, 243,41, 243,33, 244,24, 244,22, 244,20, 245,15, 267,15, 271,15"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="152,117, 164,127, 175,139, 174,140, 166,139, 165,138, 165,132, 162,131, 161,131, 160,132, 160,133, 145,117, 145,117, 146,117, 149,116"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4" coords="150,124, 150,124, 150,124, 150,124"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="171,216, 173,217, 175,218, 175,219, 176,220, 179,226, 181,232, 181,236, 182,241, 182,249, 182,262, 181,263, 181,268, 180,270, 178,276, 175,281, 172,284, 164,284, 156,283, 146,283, 145,283, 146,281, 147,279, 149,274, 149,272, 150,270, 150,266, 151,263, 152,257, 151,235, 150,234, 149,225, 148,224, 148,222, 146,217, 149,217, 165,216, 166,216"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="296,57, 295,60, 295,62, 295,63, 307,63, 309,62, 313,63, 314,63, 314,66, 312,75, 312,76, 311,77, 312,77, 310,79, 310,80, 290,81, 287,81, 284,81, 271,81, 267,81, 261,81, 252,80, 243,80, 243,78, 242,76, 241,74, 240,72, 240,68, 239,67, 240,63, 245,62, 246,63, 256,63, 259,63, 259,62, 259,61, 258,59, 259,59, 257,57, 259,56"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="23,64, 20,69, 18,74, 18,79, 4,78, 4,76, 8,75, 7,73, 5,72, 1,72, 1,64, 2,64"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="201,167, 201,169, 200,175, 195,175, 194,177, 195,178, 198,179, 197,181, 184,181, 183,179, 183,173, 182,172, 181,169, 180,166"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="198,221, 200,227, 201,230, 201,240, 201,271, 200,272, 200,276, 197,279, 198,276, 198,274, 199,270, 199,267, 199,267, 197,268, 196,268, 197,264, 197,261, 198,254, 197,238, 197,237, 197,232, 199,233, 199,229, 197,222, 197,221, 197,220"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7"
                            coords="201,64, 200,77, 181,77, 180,73, 180,72, 177,66, 175,64, 175,64"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="26,167, 25,169, 24,169, 24,171, 22,175, 21,177, 21,179, 20,180, 1,180, 1,177, 1,175, 1,166"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="245,167, 246,176, 247,176, 307,176, 307,175, 307,166, 315,167, 314,175, 313,177, 312,181, 302,181, 276,182, 250,181, 240,180, 239,177, 239,173, 238,169, 239,166"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="3,214, 3,219, 3,267, 3,287, 2,286, 1,285, 1,256, 0,248, 1,247, 1,215, 2,214"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="201,64, 201,77, 181,77, 180,73, 180,72, 177,66, 175,64, 175,64"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7" coords="3,164, 4,165, 28,166, 27,166, 1,167, 1,179, 0,179, 1,164"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="245,167, 246,176, 247,176, 307,176, 307,175, 307,166, 315,167, 314,175, 313,177, 313,180, 312,181, 304,181, 274,182, 251,181, 240,181, 240,180, 239,177, 239,173, 238,169, 239,166"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="3,214, 3,220, 3,266, 3,287, 2,286, 1,285, 1,257, 0,248, 1,246, 1,214"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="311,41, 311,46, 312,48, 312,49, 313,55, 314,56, 315,63, 310,62, 310,58, 309,55, 307,55, 306,55, 302,55, 302,52, 308,42"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="162,213, 161,215, 160,214, 152,214, 152,213, 160,212"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="181,214, 183,215, 185,216, 186,216, 186,216, 188,217, 193,221, 193,223, 189,222, 188,222, 182,220, 180,220, 178,219, 173,217, 170,216, 168,215, 165,215, 165,212"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="121,214, 120,216, 117,216, 117,214"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="63,4, 64,3, 85,4, 85,76, 77,75, 81,49, 80,14, 52,13, 58,7, 58,7"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="55,9, 54,9, 54,10, 53,10, 54,10, 54,9"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="54,9, 54,10" onmouseover="mouseOver(8)"
                            onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="54,10, 53,10" onmouseover="mouseOver(8)"
                            onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="36,37, 36,37, 36,41, 35,43, 36,44, 37,43, 38,43, 39,43, 38,49, 39,50, 40,57, 41,58, 43,58, 47,62, 49,65, 51,69, 53,75, 52,76, 51,75, 51,71, 50,70, 49,69, 49,69, 49,68, 48,66, 45,64, 43,61, 41,60, 41,61, 40,60, 39,60, 37,59, 37,60, 36,59, 30,60, 29,60, 28,60, 27,60, 26,61, 22,63, 3,63, 3,61, 9,61, 13,55, 11,54, 5,54, 10,48, 20,43, 31,38, 33,38, 35,38, 35,37"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9"
                            coords="245,42, 251,52, 251,55, 244,56, 243,59, 244,62, 239,62, 239,57, 240,52, 242,51, 242,46, 243,43, 243,41"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="138,105, 138,106, 145,111, 149,116, 143,116, 121,117, 121,158, 122,159, 125,177, 124,178, 117,178, 117,105"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="149,114, 150,115" onmouseover="mouseOver(9)"
                            onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="171,141, 181,145, 189,150, 194,154, 196,156, 195,157, 189,158, 189,159, 194,164, 199,164, 199,166, 178,165, 177,164, 176,165, 175,163, 175,164, 173,163, 171,162, 162,162, 160,164, 159,164, 158,164, 152,172, 150,177, 150,178, 149,178, 150,173, 150,172, 152,167, 157,162, 160,160, 163,159, 163,152, 172,151, 172,150, 172,148, 168,141"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="193,277, 193,278, 188,283, 184,285, 183,284, 182,285, 180,286, 175,287, 170,287, 165,287, 164,286, 163,287, 162,287, 161,288, 160,288, 152,288, 152,287, 161,286, 162,285, 166,285, 170,284, 170,284, 172,283, 175,282, 178,281, 180,281, 186,279"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="121,284, 120,286, 117,285, 117,284"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 13 -->
                        <area shape="poly" alt="13" coords="201,48, 200,62, 199,61, 199,49" onmouseover="mouseOver(13)"
                            onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="245,150, 245,166, 239,165, 239,150"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="313,150, 313,157, 314,158, 313,166, 307,166, 307,150"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="3,152, 2,164, 1,164, 1,150" onmouseover="mouseOver(13)"
                            onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="2,150, 3,151" onmouseover="mouseOver(13)"
                            onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt="14" coords="85,76, 85,77, 51,77, 52,76" onmouseover="mouseOver(14)"
                            onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15" coords="150,178, 150,181, 131,180, 117,180, 117,178"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="81,14, 80,55, 80,57, 76,76, 53,76, 52,72, 49,65, 43,59, 39,57, 38,43, 37,44, 35,44, 36,40, 36,39, 36,34, 37,29, 38,29, 41,29, 42,31, 48,25, 47,25, 42,30, 42,31, 48,25, 47,25, 57,15, 60,14"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="311,18, 312,31, 313,33, 313,39, 314,43, 315,42, 316,36, 321,36, 321,46, 318,46, 316,46, 315,45, 313,46, 313,51, 312,51, 312,49, 311,48, 311,36, 310,31, 310,18"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="236,138, 236,145, 239,145, 239,147, 235,147, 232,148, 230,148, 229,144, 229,138"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="165,204, 167,210, 165,214, 163,215, 162,216, 149,217, 144,216, 143,216, 133,216, 121,216, 122,214, 126,214, 162,214, 163,212, 162,212, 163,211, 161,208, 162,204"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="244,19, 243,34, 243,36, 242,49, 241,51, 240,52, 240,52, 239,45, 237,46, 236,46, 233,46, 232,44, 233,36, 238,36, 239,43, 240,42, 240,35, 241,34, 240,33, 241,32, 242,27, 242,27, 242,26, 242,25, 243,19, 243,18"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="143,117, 160,133, 160,132, 161,131, 165,132, 165,133, 166,143, 166,143, 167,145, 165,147, 163,145, 162,147, 162,153, 162,160, 157,162, 156,163, 156,162, 154,166, 152,167, 153,167, 152,168, 152,169, 150,172, 150,174, 149,176, 148,178, 125,177, 121,152, 122,116"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="323,138, 323,147, 321,148, 318,148, 317,147, 313,147, 313,145, 317,144, 318,138"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="151,283, 166,284, 165,287, 165,289, 167,291, 164,296, 161,296, 162,291, 163,289, 162,288, 163,288, 161,285, 142,286, 124,285, 124,284, 146,283, 147,283"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17" coords="121,284, 120,286, 117,285, 117,284"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="40,63, 41,64, 44,65, 48,70, 49,75, 49,81, 48,82, 47,86, 42,89, 37,91, 31,91, 30,90, 26,88, 23,84, 21,79, 23,70, 26,65, 30,64, 32,63, 35,63"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20" coords="313,75, 313,92, 306,91, 307,80, 311,80, 312,77, 313,75"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="241,75, 242,77, 243,78, 242,79, 243,80, 247,80, 247,92, 240,92, 240,74"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="171,166, 175,168, 176,169, 176,169, 177,171, 178,171, 180,176, 181,179, 179,187, 175,191, 175,190, 173,192, 168,193, 161,193, 155,189, 153,183, 153,177, 153,173, 157,169, 159,167, 164,165"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="168,63, 169,64, 172,65, 176,69, 177,74, 177,82, 172,88, 169,90, 167,91, 164,91, 156,89, 152,85, 150,81, 149,79, 150,72, 154,66, 156,64, 162,63"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22" coords="244,181, 247,181, 246,194, 240,193, 240,181"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="43,166, 44,166, 47,168, 51,172, 52,176, 52,184, 47,190, 43,193, 41,193, 32,193, 29,190, 25,186, 25,184, 24,183, 25,174, 29,168, 34,166, 36,165"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23" coords="313,181, 312,194, 306,193, 306,181"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="196,4, 195,4, 196,5, 197,7, 196,8, 197,9, 197,12, 198,14, 198,19, 199,23, 199,31, 200,31, 199,32, 200,35, 200,40, 200,41, 200,43, 200,47, 198,49, 199,49, 199,49, 199,48, 198,49, 199,49, 198,51, 199,63, 200,62, 199,63, 173,63, 173,62, 172,63, 172,61, 170,60, 169,61, 167,60, 167,60, 166,60, 158,60, 158,61, 157,60, 156,61, 155,61, 154,62, 150,66, 150,66, 151,66, 150,65, 150,66, 150,66, 149,67, 150,68, 149,68, 149,69, 148,69, 148,74, 147,76, 147,77, 85,77, 85,3"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="55,212, 57,213, 70,214, 116,214, 116,216, 5,216, 5,215, 5,214, 21,213, 22,212, 32,212"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="57,106, 57,180, 54,180, 54,175, 51,168, 48,166, 48,165, 46,164, 43,163, 41,162, 36,162, 30,164, 27,166, 1,165, 1,165, 3,164, 3,152, 1,149, 2,135, 2,133, 4,116, 4,115, 6,106, 7,105"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="11,284, 56,284, 55,287, 51,288, 36,288, 24,287, 23,288, 22,287, 17,287, 16,286, 15,287, 14,286, 5,285, 7,283"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 27 -->
                        <area shape="poly" alt="27" coords="116,105, 116,180, 58,180, 58,105"
                            onmouseover="mouseOver(27)" onmouseout="mouseOut(27)" (click)="createDamage(27)">
                        <area shape="poly" alt="27" coords="116,284, 116,286, 58,287, 57,287, 56,287, 56,284"
                            onmouseover="mouseOver(27)" onmouseout="mouseOut(27)" (click)="createDamage(27)">
                        <!-- 28 -->
                        <area shape="poly" alt="28"
                            coords="248,104, 249,103, 276,104, 276,104, 251,106, 250,108, 249,113, 247,123, 246,130, 246,135, 245,150, 239,149, 240,146, 240,135, 241,135, 240,134, 241,133, 242,128, 242,127, 242,127, 242,126, 243,119, 243,112, 244,111, 244,107, 244,106, 244,107, 245,105, 246,104, 246,104"
                            onmouseover="mouseOver(28)" onmouseout="mouseOut(28)" (click)="createDamage(28)">
                        <area shape="poly" alt="28"
                            coords="307,104, 307,104, 307,105, 307,106, 309,108, 309,109, 309,111, 310,114, 310,117, 311,126, 311,127, 312,133, 312,135, 313,141, 313,150, 307,149, 307,134, 306,132, 306,122, 305,121, 305,115, 304,114, 302,107, 300,105, 277,104, 277,103"
                            onmouseover="mouseOver(28)" onmouseout="mouseOut(28)" (click)="createDamage(28)">
                        <!-- 18 -->
                        <area shape="poly" alt="18"
                            coords="276,105, 276,176, 246,175, 246,174, 245,156, 246,143, 246,127, 247,126, 249,113, 249,111, 251,106, 252,105, 256,105"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="7,217, 8,220, 8,220, 8,222, 7,223, 7,250, 3,249, 3,215, 5,215"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt="19"
                            coords="293,105, 301,105, 303,109, 305,116, 305,120, 306,124, 306,131, 307,137, 307,152, 307,155, 307,175, 306,176, 277,176, 277,104"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="7,250, 7,253, 7,262, 7,279, 5,279, 5,281, 7,282, 7,283, 4,285, 3,284, 3,260, 3,250"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="120,25, 121,26, 121,30, 114,35, 114,37, 116,40, 117,43, 129,43, 142,42, 152,41, 153,40, 155,39, 173,38, 175,38, 181,38, 184,39, 190,40, 194,40, 200,41, 203,41, 206,42, 209,42, 212,44, 214,46, 215,55, 220,58, 219,66, 209,71, 194,74, 193,74, 191,74, 190,78, 186,84, 183,86, 178,87, 171,87, 164,82, 160,76, 156,78, 74,78, 73,77, 69,78, 69,80, 63,86, 60,86, 58,87, 51,87, 43,82, 39,77, 20,77, 19,76, 16,76, 17,74, 15,72, 15,70, 15,67, 13,64, 13,61, 13,58, 16,54, 22,50, 33,47, 35,47, 38,46, 41,46, 44,45, 46,44, 49,44, 55,43, 61,43, 66,42, 72,42, 86,41, 93,37, 96,36, 118,24"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="184,193, 186,193, 195,194, 199,198, 204,200, 212,206, 214,210, 215,212, 217,215, 219,228, 218,253, 218,254, 217,256, 216,260, 213,266, 203,274, 198,277, 194,281, 182,281, 171,282, 161,281, 160,281, 158,280, 156,279, 142,280, 69,281, 68,281, 44,281, 43,280, 37,279, 32,277, 26,275, 19,270, 16,265, 15,262, 15,259, 14,256, 14,253, 13,251, 13,246, 13,222, 14,221, 16,208, 25,199, 29,198, 30,198, 32,197, 38,193, 57,193, 69,193, 70,194, 72,194, 97,193, 144,194, 160,193, 161,193, 165,192"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/personenvervoer/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/1.gif"
                        usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/18.gif"
                        usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/19.gif"
                        usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/3.gif"
                        usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/4.gif"
                        usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/6.gif"
                        usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/7.gif"
                        usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/8.gif"
                        usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/9.gif"
                        usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/12.gif"
                        usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/13.gif"
                        usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/14.gif"
                        usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/15.gif"
                        usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/16.gif"
                        usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/17.gif"
                        usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/20.gif"
                        usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/21.gif"
                        usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/22.gif"
                        usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/23.gif"
                        usemap="#imgmap" />
                    <img id="overlay_27" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/27.gif"
                        usemap="#imgmap" />
                    <img id="overlay_28" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/28.gif"
                        usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/10.gif"
                        usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/mouseover/11.gif"
                        usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/18.gif"
                        usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/19.gif"
                        usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/12.gif"
                        usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/13.gif"
                        usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/14.gif"
                        usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/15.gif"
                        usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/16.gif"
                        usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/17.gif"
                        usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/20.gif"
                        usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/21.gif"
                        usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/22.gif"
                        usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/23.gif"
                        usemap="#imgmap" />
                    <img id="overlay_27_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/27.gif"
                        usemap="#imgmap" />
                    <img id="overlay_28_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/28.gif"
                        usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/10.gif"
                        usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/personenvervoer/active/11.gif"
                        usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'pick-up'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="352,25, 353,26, 357,27, 359,37, 358,40, 288,39, 290,27, 291,26, 299,25, 305,25"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="66,28, 67,29, 69,29, 69,32, 41,34, 40,34, 29,36, 28,37, 17,40, 16,41, 11,40, 13,37, 20,34, 33,31, 36,30, 40,29, 44,29, 50,28, 57,27"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="219,124, 221,125, 229,126, 231,126, 242,128, 243,129, 246,129, 254,133, 257,135, 256,136, 255,137, 250,136, 249,136, 242,134, 240,133, 237,133, 235,132, 231,131, 228,131, 223,130, 219,129, 210,129, 199,128, 199,126, 209,124, 217,124"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="226,212, 254,213, 256,219, 256,223, 257,228, 257,238, 257,268, 256,270, 256,276, 255,277, 253,281, 250,282, 215,282, 204,282, 205,279, 206,275, 208,268, 209,264, 210,261, 210,252, 210,232, 209,231, 207,221, 206,219, 205,212, 223,212"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt="2" coords="358,123, 358,149, 287,148, 287,123"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2" coords="15,208, 15,284, 15,286, 11,286, 12,208"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 3 -->
                        <area shape="poly" alt="3" coords="150,3, 150,4, 99,4, 99,3, 105,3, 112,2"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="345,3, 346,3, 349,4, 349,5, 349,6, 340,5, 332,4, 303,5, 301,6, 298,5, 299,3, 305,3, 312,2"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="167,99, 168,100, 171,101, 172,102, 170,102, 170,102, 169,101, 168,101, 165,101, 161,100, 119,100, 119,99, 122,99"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="346,99, 347,100, 349,101, 349,103, 342,102, 332,101, 300,102, 298,103, 297,102, 298,100, 301,99, 313,99"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="172,219, 173,224, 173,230, 173,257, 173,273, 172,274, 172,276, 163,275, 119,275, 119,219"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="201,210, 205,214, 208,222, 208,228, 209,230, 210,235, 210,244, 210,261, 209,263, 208,269, 208,270, 206,277, 205,279, 203,282, 201,285, 196,284, 194,284, 188,282, 172,277, 173,270, 173,268, 174,251, 173,225, 173,224, 173,217, 175,217, 193,211, 199,209"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="176,103, 178,105, 194,115, 204,123, 203,124, 201,125, 197,124, 189,117, 173,102"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4" coords="96,6, 80,21, 69,29, 65,27, 66,25, 83,12, 94,6"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="335,5, 348,6, 349,6, 351,7, 355,21, 356,24, 356,25, 355,26, 351,25, 344,25, 328,24, 300,25, 298,25, 291,25, 291,23, 293,17, 298,6, 300,6, 309,5, 330,5"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt="5"
                            coords="115,210, 118,216, 119,219, 118,279, 113,285, 113,285, 113,209"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="349,103, 351,105, 352,112, 353,114, 354,117, 354,119, 355,122, 291,122, 291,119, 292,116, 293,113, 293,111, 296,103, 298,103, 307,102"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="117,101, 117,114, 116,117, 115,118, 116,120, 115,122, 114,123, 113,122, 113,120, 115,109, 115,105, 116,104, 115,103, 115,102"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="152,3, 153,5, 153,7, 154,10, 154,11, 154,14, 154,15, 155,18, 156,20, 155,24, 156,25, 155,26, 154,27, 155,26, 154,24, 153,16, 152,15, 150,3"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="254,205, 256,205, 259,207, 263,212, 263,215, 264,217, 264,220, 265,224, 266,230, 266,238, 266,268, 265,269, 264,275, 264,277, 263,280, 263,282, 261,286, 257,289, 250,290, 245,291, 240,288, 243,287, 247,287, 251,286, 256,282, 259,274, 259,272, 260,268, 259,267, 257,267, 257,259, 258,249, 257,231, 257,229, 257,227, 260,226, 258,217, 254,210, 250,208, 248,207, 241,206, 240,205, 240,205, 247,204"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="265,151, 266,152, 265,159, 261,163, 257,164, 254,164, 248,164, 247,162, 247,154, 245,152, 246,152, 245,150"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6" coords="24,54, 22,61, 21,68, 20,68, 10,68, 3,61, 3,55, 6,54"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="344,40, 344,54, 366,55, 365,66, 363,68, 358,68, 283,68, 282,66, 281,62, 282,54, 303,54, 304,40"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7"
                            coords="11,205, 10,209, 10,289, 6,286, 3,281, 3,276, 3,213, 8,206, 10,205"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7" coords="10,150, 11,154, 13,160, 6,159, 3,151, 3,150"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="287,149, 289,149, 358,149, 358,148, 363,149, 363,158, 359,161, 284,161, 282,156, 282,153, 282,152, 283,148"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="266,54, 266,56, 264,60, 262,63, 258,64, 255,64, 256,61, 258,57, 258,54"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="359,28, 360,29, 361,29, 360,30, 361,33, 362,36, 363,38, 366,43, 366,54, 363,54, 362,43, 360,40, 359,40, 358,32, 358,31, 358,28"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="68,33, 67,44, 68,52, 68,54, 70,61, 72,65, 71,68, 70,68, 68,68, 67,64, 66,61, 63,52, 59,47, 55,44, 50,42, 47,41, 37,42, 36,43, 29,46, 25,51, 23,54, 20,54, 19,48, 16,41, 17,40, 27,37, 36,35, 40,34, 44,34, 50,33, 58,33"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="237,204, 238,205, 240,205, 243,207, 251,209, 252,212, 217,212, 202,211, 201,210, 201,210, 201,206, 204,203"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9"
                            coords="289,28, 289,34, 288,36, 287,40, 286,41, 284,46, 284,54, 281,54, 282,41, 284,38, 286,31, 287,30, 286,29"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="219,129, 221,130, 229,131, 230,131, 241,133, 242,134, 253,137, 252,139, 249,145, 248,150, 244,149, 242,145, 237,141, 230,138, 226,138, 218,138, 217,139, 211,142, 206,148, 203,157, 202,159, 200,164, 197,164, 198,159, 199,157, 199,154, 201,145, 201,140, 201,131, 200,130, 201,129"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="207,282, 252,282, 250,286, 247,287, 242,287, 238,290, 230,291, 203,291, 202,288, 201,285, 205,282, 206,282"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 12 -->
                        <area shape="poly" alt="12" coords="303,40, 302,54, 285,54, 286,41, 289,40"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="361,40, 362,43, 361,54, 354,54, 344,54, 344,40"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="16,42, 18,46, 19,52, 18,53, 9,53, 10,48, 10,41, 11,40"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="259,138, 259,143, 259,150, 249,149, 250,144, 250,143, 252,139, 255,137"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="253,209, 256,213, 258,218, 259,224, 260,226, 259,227, 257,226, 255,217, 254,215, 252,212, 251,210, 252,208"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="260,268, 258,277, 254,284, 252,285, 252,285, 252,282, 256,276, 257,268"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 13 -->
                        <area shape="poly" alt="13" coords="257,31, 258,33, 257,43, 249,42, 249,34, 252,30"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="17,127, 20,131, 20,136, 20,139, 11,138, 11,126"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="287,126, 287,139, 282,139, 282,136, 282,127, 284,126"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="363,127, 363,139, 358,138, 358,126"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt="14" coords="156,65, 156,68, 72,68, 72,64" onmouseover="mouseOver(14)"
                            onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15" coords="196,162, 197,163, 196,164, 112,164, 113,161"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <area shape="poly" alt="15" coords="196,162, 197,163, 196,164, 112,164, 113,161"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="129,4, 129,64, 71,64, 69,60, 68,50, 67,46, 69,31, 69,30, 69,29, 71,29, 75,24, 99,5, 103,4, 108,4"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="352,8, 353,10, 354,13, 356,19, 359,25, 359,24, 360,20, 361,20, 371,20, 372,22, 371,27, 367,27, 362,28, 361,29, 358,27, 356,27, 356,23, 355,22, 352,8"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="286,117, 287,119, 286,122, 284,123, 282,124, 275,124, 275,122, 275,118, 278,117"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="185,199, 188,205, 202,205, 201,208, 200,208, 197,210, 179,215, 170,218, 164,219, 141,219, 141,205, 182,204, 182,199"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="171,276, 172,277, 191,282, 192,283, 199,285, 199,285, 199,286, 196,287, 194,287, 192,287, 191,287, 188,288, 186,293, 184,295, 181,294, 182,289, 141,289, 141,275"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="368,117, 370,118, 371,120, 370,123, 367,124, 362,124, 360,122, 359,122, 359,117, 361,117"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="167,101, 168,101, 171,102, 198,124, 199,127, 201,134, 201,150, 200,151, 198,159, 196,161, 140,161, 140,100"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="297,4, 296,7, 296,8, 295,12, 294,13, 291,24, 289,27, 287,28, 286,29, 284,28, 281,27, 276,27, 275,25, 276,20, 284,20, 287,20, 287,24, 288,25, 290,22, 293,11, 294,10, 296,7, 295,4"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 18 -->
                        <area shape="poly" alt="18" coords="151,4, 154,26, 156,27, 156,64, 129,64, 129,4"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="296,102, 296,105, 294,109, 292,117, 291,119, 291,122, 290,122, 287,122, 287,119, 288,117, 291,108, 292,107, 294,103, 295,101"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="140,205, 140,219, 119,219, 118,216, 113,209, 113,208, 113,206, 115,205"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt="19"
                            coords="123,101, 124,102, 140,103, 140,161, 112,161, 113,124, 115,121, 119,101"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="351,101, 354,108, 358,119, 359,122, 355,122, 354,119, 351,107, 349,101, 349,101"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="140,276, 140,289, 113,289, 113,287, 113,285, 115,283, 119,275"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="50,46, 52,47, 55,48, 61,53, 62,57, 64,62, 63,72, 62,73, 61,77, 55,82, 50,84, 43,85, 41,84, 37,83, 29,77, 25,71, 25,64, 27,56, 31,50, 37,47, 41,46, 45,45"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="367,63, 367,84, 366,83, 365,84, 356,83, 356,69, 364,68, 366,66"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="280,63, 281,66, 283,68, 288,69, 291,69, 291,84, 290,83, 289,84, 280,83"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="231,143, 232,143, 236,145, 242,152, 243,158, 243,168, 238,175, 235,178, 229,180, 226,181, 218,180, 217,180, 213,178, 208,174, 205,168, 205,163, 205,155, 210,148, 214,144, 221,142"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="210,46, 211,47, 215,48, 219,52, 222,57, 223,59, 224,62, 223,72, 219,79, 213,83, 206,85, 199,84, 198,83, 192,80, 187,75, 185,68, 185,59, 186,57, 188,54, 194,48, 199,46, 206,45, 208,46"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="282,150, 281,155, 282,156, 284,160, 284,160, 284,161, 287,162, 290,162, 290,181, 289,180, 288,181, 279,180, 280,150"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 21 -->
                        <area shape="poly" alt="23"
                            coords="71,143, 78,147, 82,153, 83,159, 83,169, 76,177, 70,180, 66,181, 58,180, 57,180, 53,178, 49,174, 46,169, 45,166, 45,163, 45,156, 46,154, 48,150, 52,146, 58,143, 61,142"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="366,150, 366,153, 366,180, 364,181, 356,180, 356,162, 361,161, 363,158, 364,154, 365,150"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <!-- 25 -->
                        <area shape="poly" alt="25"
                            coords="256,27, 257,29, 252,30, 250,33, 248,38, 249,42, 252,43, 257,43, 257,50, 256,51, 257,56, 255,61, 250,64, 247,65, 242,66, 235,66, 229,66, 228,63, 228,59, 224,51, 219,45, 215,43, 210,42, 207,41, 196,42, 195,43, 191,45, 187,48, 184,55, 182,64, 181,68, 157,67, 157,26"
                            onmouseover="mouseOver(25)" onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <area shape="poly" alt="25" coords="287,123, 287,126, 282,126, 285,123"
                            onmouseover="mouseOver(25)" onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <area shape="poly" alt="25" coords="287,140, 287,147, 282,147, 282,139"
                            onmouseover="mouseOver(25)" onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <area shape="poly" alt="25"
                            coords="91,204, 92,205, 113,205, 112,206, 16,207, 15,208, 12,208, 13,205, 17,203"
                            onmouseover="mouseOver(25)" onmouseout="mouseOut(25)" (click)="createDamage(25)">
                        <!-- 26 -->
                        <area shape="poly" alt="26"
                            coords="112,123, 111,164, 87,163, 85,151, 81,143, 75,139, 66,138, 56,138, 55,139, 52,140, 52,141, 51,140, 50,141, 48,143, 44,148, 42,152, 41,154, 41,157, 40,159, 40,163, 36,163, 23,162, 22,161, 17,161, 13,156, 12,153, 12,145, 11,143, 12,140, 13,140, 18,140, 21,137, 20,132, 20,131, 18,128, 17,126, 15,126, 12,125, 13,124, 12,124, 13,123"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <area shape="poly" alt="26" coords="362,124, 363,125, 362,126, 359,126, 358,125, 358,123"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <area shape="poly" alt="26" coords="363,140, 363,147, 358,147, 358,139"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                        <area shape="poly" alt="26"
                            coords="16,287, 18,288, 112,289, 112,289, 87,290, 85,291, 15,290, 13,288, 12,286"
                            onmouseover="mouseOver(26)" onmouseout="mouseOut(26)" (click)="createDamage(26)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="140,29, 141,31, 140,34, 132,41, 133,43, 135,47, 136,50, 150,50, 165,49, 176,48, 177,46, 180,45, 201,45, 203,44, 210,45, 214,45, 221,46, 226,47, 232,47, 236,48, 240,48, 242,49, 247,51, 249,54, 249,64, 255,67, 254,76, 243,82, 225,86, 224,85, 222,86, 221,91, 215,98, 213,99, 206,101, 199,101, 190,95, 185,89, 182,91, 86,91, 85,90, 80,91, 80,92, 73,99, 70,100, 67,101, 59,101, 50,95, 45,90, 23,89, 22,89, 19,88, 20,85, 17,84, 18,82, 17,78, 15,74, 15,71, 15,67, 18,63, 25,58, 38,55, 41,54, 45,54, 47,53, 51,52, 54,52, 57,51, 64,50, 71,50, 76,49, 84,48, 100,48, 108,43, 112,41, 137,28"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="214,224, 215,224, 226,225, 231,230, 236,232, 246,240, 248,243, 250,246, 252,250, 254,264, 254,293, 253,294, 252,298, 250,301, 247,308, 235,319, 229,322, 226,326, 212,326, 199,327, 187,326, 186,326, 184,325, 181,324, 164,325, 80,326, 78,326, 52,326, 50,325, 43,324, 37,321, 30,319, 22,314, 19,307, 18,304, 17,300, 17,298, 16,294, 15,291, 15,286, 15,258, 16,257, 19,241, 29,231, 33,230, 34,229, 37,229, 45,224, 66,224, 80,224, 81,225, 84,225, 112,224, 167,225, 185,224, 187,224, 192,223"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/pick-up/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/5.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/12.gif" usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/13.gif" usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/14.gif" usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/15.gif" usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/16.gif" usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/17.gif" usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/18.gif" usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/19.gif" usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/20.gif" usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/21.gif" usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/22.gif" usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/23.gif" usemap="#imgmap" />
                    <img id="overlay_25" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/25.gif" usemap="#imgmap" />
                    <img id="overlay_26" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/mouseover/26.gif" usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/23.gif" usemap="#imgmap" />
                    <img id="overlay_25_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/25.gif" usemap="#imgmap" />
                    <img id="overlay_26_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/pick-up/active/26.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'sedan'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="370,27, 372,28, 370,33, 369,37, 368,39, 358,39, 355,42, 354,48, 333,48, 308,48, 307,42, 304,39, 296,39, 294,38, 293,34, 291,27, 294,27, 313,26"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="69,29, 69,29, 62,30, 58,31, 54,31, 48,32, 45,33, 41,33, 36,34, 33,35, 31,35, 23,37, 19,39, 14,40, 11,41, 8,43, 4,49, 1,48, 2,46, 5,41, 9,37, 12,36, 20,34, 21,33, 26,33, 27,32, 33,31, 41,30, 44,29, 51,29, 52,28, 65,28"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="205,128, 206,129, 213,130, 215,130, 215,130, 217,130, 223,131, 229,132, 233,133, 236,134, 236,133, 237,134, 247,137, 249,139, 251,141, 251,141, 252,143, 253,143, 254,146, 253,147, 254,148, 253,149, 250,148, 249,145, 245,141, 241,140, 239,139, 235,139, 231,137, 224,135, 203,131, 200,131, 193,130, 188,130, 187,130, 187,128"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="228,204, 229,205, 236,205, 242,212, 248,219, 250,220, 251,225, 251,229, 251,262, 250,264, 249,266, 245,270, 236,281, 235,280, 234,281, 219,281, 201,282, 183,281, 183,279, 185,275, 188,266, 190,254, 191,247, 190,229, 190,228, 188,217, 187,216, 185,211, 183,203"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt="2"
                            coords="230,21, 241,22, 244,23, 247,23, 248,26, 247,26, 245,26, 244,26, 243,24, 236,24, 232,23, 224,22, 223,22, 224,21, 228,21"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2" coords="249,29, 249,30, 247,29, 248,28" onmouseover="mouseOver(2)"
                            onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="364,120, 364,123, 361,124, 362,125, 354,126, 352,131, 357,131, 359,134, 358,140, 358,141, 357,143, 356,143, 306,143, 306,141, 304,135, 306,133, 305,133, 304,133, 305,134, 306,133, 305,133, 307,131, 312,130, 311,129, 309,126, 307,125, 302,125, 302,124, 300,123, 300,122, 299,122, 300,121, 301,120, 327,120"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="26,121, 27,120, 32,120, 31,122, 27,122, 24,123, 13,124, 12,124, 10,126, 7,126, 7,124, 10,122, 12,123, 13,122, 17,122"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="7,128, 7,128, 8,128, 8,128, 8,129, 8,130, 7,130, 7,130, 7,129, 7,128"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="34,210, 33,212, 30,218, 28,225, 27,229, 27,233, 26,238, 27,254, 27,256, 28,261, 29,262, 31,269, 34,275, 23,275, 22,274, 11,272, 10,271, 10,268, 9,267, 8,266, 6,262, 5,263, 6,264, 5,268, 5,268, 4,263, 3,258, 3,249, 3,225, 4,224, 5,217, 6,218, 7,222, 9,218, 10,217, 10,215, 12,213, 14,212, 21,211, 24,211, 30,210"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 3 -->
                        <area shape="poly" alt="3"
                            coords="310,1, 311,1, 358,1, 360,2, 360,2, 361,6, 343,5, 301,5, 302,2, 306,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="173,2, 174,3, 182,3, 183,4, 189,5, 188,6, 181,5, 180,5, 173,4, 168,3, 160,3, 127,3, 126,4, 117,5, 116,5, 107,7, 107,7, 107,6, 109,5, 115,4, 121,3, 129,2, 142,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="357,101, 358,102, 360,103, 360,105, 360,106, 352,105, 325,105, 324,105, 303,105, 304,103, 304,102, 310,101, 327,101"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="86,102, 89,101, 131,102, 132,103, 141,103, 142,104, 146,105, 149,106, 149,107, 148,106, 147,107, 143,106, 141,105, 137,105, 133,104, 130,103, 120,103, 81,103, 80,104, 67,105, 67,105, 67,104, 68,105, 71,103, 79,103"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="145,213, 146,217, 147,218, 147,223, 148,226, 149,231, 149,241, 149,257, 148,258, 146,270, 145,271, 145,273, 141,273, 135,272, 124,271, 108,271, 67,270, 65,264, 65,261, 64,256, 63,251, 64,227, 65,226, 65,221, 66,220, 68,215, 69,214, 75,215, 128,214, 130,213, 143,213, 144,212"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="360,6, 361,7, 364,7, 371,26, 370,27, 357,26, 326,26, 315,26, 292,26, 294,20, 294,18, 300,7, 306,6, 330,5"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="107,7, 105,9, 104,9, 102,10, 94,14, 91,16, 88,18, 80,23, 79,24, 74,27, 73,27, 72,28, 67,27, 67,26, 70,24, 90,12, 103,7"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="156,108, 163,111, 179,120, 181,122, 188,127, 188,128, 181,127, 181,126, 180,126, 179,124, 178,125, 177,124, 175,122, 172,121, 171,120, 170,119, 169,118, 169,119, 165,116, 164,115, 162,114, 154,111, 153,109, 152,110, 149,107, 149,107, 152,106, 153,107"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="183,205, 185,211, 186,212, 188,218, 188,221, 189,224, 190,235, 190,247, 190,259, 189,260, 188,266, 188,267, 184,277, 184,278, 183,281, 181,282, 179,281, 154,275, 152,275, 149,274, 145,273, 146,269, 147,268, 147,264, 148,260, 149,257, 149,248, 149,227, 148,226, 147,220, 147,218, 145,212, 149,211, 152,211, 154,210, 158,209, 165,207, 182,204, 183,204"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt="5"
                            coords="198,7, 200,8, 217,15, 224,20, 215,20, 214,18, 213,18, 213,18, 206,14, 205,14, 205,14, 196,10, 188,6, 193,6, 197,7"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="5"
                            coords="66,105, 66,107, 63,108, 62,109, 57,111, 37,120, 31,120, 31,118, 34,118, 36,116, 52,109, 64,105"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="359,106, 360,107, 361,107, 362,111, 362,112, 364,120, 346,120, 300,120, 300,114, 301,113, 303,107, 306,106, 319,105"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="44,211, 45,211, 66,213, 68,215, 66,220, 64,226, 63,236, 63,238, 63,254, 64,256, 65,262, 65,264, 67,271, 63,272, 46,274, 40,275, 37,275, 34,275, 31,269, 29,262, 28,260, 27,256, 27,251, 26,244, 27,232, 27,231, 28,224, 29,223, 32,213, 35,210"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 12 -->
                        <area shape="poly" alt="12"
                            coords="304,39, 305,40, 307,41, 307,43, 308,46, 307,46, 307,47, 306,46, 296,46, 292,44, 288,44, 289,39"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="374,39, 374,41, 374,44, 372,44, 368,45, 366,46, 362,46, 355,46, 355,42, 357,40, 362,39, 366,39"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="24,41, 24,44, 22,48, 20,49, 18,48, 6,48, 7,44, 9,41, 12,41, 16,40"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="300,58, 299,62, 295,61, 294,60, 295,58, 296,57"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="368,58, 368,59, 368,61, 366,62, 363,61, 364,57"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="12,60, 11,63, 9,63, 9,59" onmouseover="mouseOver(12)"
                            onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="239,140, 245,141, 249,143, 249,148, 247,149, 234,148, 232,143, 231,141, 232,140, 236,139, 236,140, 237,139"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="247,160, 247,163, 245,164, 243,163, 244,159"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="236,201, 242,207, 248,216, 249,218, 248,219, 245,217, 235,204, 230,200, 230,200"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="248,268, 247,271, 245,273, 239,281, 234,285, 230,286, 231,285, 243,272, 247,267"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="296,45, 299,46, 308,47, 309,48, 319,48, 354,48, 355,47, 363,46, 366,46, 368,45, 370,44, 374,45, 375,52, 376,53, 379,54, 378,58, 377,60, 376,66, 373,68, 364,67, 288,67, 287,65, 286,63, 285,60, 285,58, 284,52, 287,53, 288,51, 289,44"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="14,49, 21,49, 22,54, 24,54, 32,55, 31,58, 30,65, 30,69, 26,69, 9,68, 9,67, 7,68, 2,65, 1,63, 2,50, 3,49, 6,48, 12,49"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="253,149, 254,154, 254,157, 254,166, 253,167, 253,166, 249,167, 245,168, 243,169, 231,169, 225,169, 224,158, 224,157, 224,154, 233,154, 235,151, 234,150, 234,149, 246,149"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="247,211, 251,220, 253,223, 252,224, 253,227, 254,234, 254,236, 254,254, 253,255, 253,261, 252,262, 253,262, 251,266, 250,269, 249,272, 247,273, 248,273, 246,275, 247,275, 247,275, 247,274, 246,275, 247,275, 245,277, 245,277, 238,283, 237,283, 238,281, 240,281, 243,276, 248,268, 250,264, 251,260, 251,256, 252,241, 251,228, 249,219, 245,211, 240,205, 240,204"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6" coords="242,204, 243,205, 243,205, 243,205"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6" coords="243,205, 243,205" onmouseover="mouseOver(6)"
                            onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6" coords="243,280, 243,281" onmouseover="mouseOver(6)"
                            onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7"
                            coords="248,36, 249,43, 254,46, 254,46, 254,54, 253,56, 253,61, 247,62, 243,63, 239,63, 236,64, 232,65, 224,67, 220,68, 220,68, 219,60, 218,58, 217,53, 215,49, 218,48, 237,46, 239,44, 239,35"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="16,135, 16,140, 16,145, 20,147, 31,148, 38,149, 41,149, 40,151, 37,158, 34,167, 33,167, 31,166, 27,166, 24,164, 23,165, 22,164, 16,164, 12,162, 5,162, 4,160, 3,161, 2,159, 2,156, 1,150, 2,146, 7,141, 8,135"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="304,135, 306,142, 308,143, 311,144, 356,143, 358,141, 358,139, 359,136, 360,135, 373,135, 374,141, 374,146, 377,148, 379,149, 379,158, 377,161, 377,163, 376,164, 377,164, 375,166, 374,167, 373,166, 372,167, 370,166, 369,164, 368,164, 367,162, 363,162, 297,162, 297,163, 296,162, 293,166, 292,167, 288,166, 287,164, 287,164, 287,164, 286,162, 285,158, 285,156, 284,148, 288,147, 290,145, 291,135"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="12,203, 11,205, 9,208, 7,213, 5,217, 4,220, 3,227, 3,231, 3,262, 4,263, 5,268, 7,272, 9,277, 13,283, 11,283, 10,282, 10,283, 7,279, 6,279, 5,276, 5,276, 5,275, 4,272, 3,268, 2,264, 1,251, 1,224, 2,223, 4,212, 6,208, 7,207, 11,203"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="90,18, 79,29, 77,34, 77,64, 74,63, 73,63, 72,59, 71,56, 71,56, 71,55, 70,53, 69,52, 69,52, 69,51, 67,50, 68,49, 65,46, 64,46, 63,44, 58,43, 51,42, 46,43, 41,44, 39,45, 35,50, 33,53, 31,54, 21,54, 22,48, 22,46, 24,42, 25,41, 22,39, 21,39, 21,38, 27,36, 31,35, 34,35, 38,34, 42,33, 54,31, 69,29, 71,29, 73,28, 75,27, 77,26, 79,24, 81,23, 82,22, 85,20, 89,18"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="371,21, 372,22, 375,29, 378,33, 379,35, 379,50, 378,52, 374,52, 374,39, 372,39, 370,38, 369,38, 371,26, 370,20"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="219,195, 220,196, 226,198, 228,198, 230,200, 234,203, 234,205, 228,204, 209,203, 196,203, 181,203, 179,204, 175,205, 174,205, 170,206, 164,208, 148,211, 144,212, 139,213, 131,213, 118,214, 113,214, 114,211, 130,211, 147,209, 149,208, 167,203, 168,203, 176,200, 177,197, 178,196, 186,195, 192,194"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt=""
                            coords="292,24, 292,29, 293,38, 288,40, 288,45, 288,52, 288,52, 284,52, 283,45, 284,39, 285,32, 290,26, 290,24"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt=""
                            coords="167,118, 168,119, 169,118, 169,120, 171,120, 171,121, 172,120, 173,122, 174,122, 175,124, 176,123, 177,125, 178,124, 179,126, 180,126, 181,128, 184,129, 196,131, 204,131, 205,132, 220,134, 222,135, 227,135, 228,136, 232,137, 235,139, 234,139, 232,140, 231,141, 234,149, 234,154, 222,153, 220,150, 220,150, 219,148, 217,146, 217,147, 215,145, 208,142, 197,143, 196,143, 196,143, 194,143, 193,144, 192,145, 187,149, 186,150, 184,156, 183,159, 183,161, 183,162, 182,164, 178,163, 179,143, 178,133, 177,131, 175,128, 167,120, 166,119, 166,118"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt=""
                            coords="136,272, 137,273, 147,273, 148,274, 158,276, 165,278, 169,279, 171,279, 174,280, 177,281, 179,281, 185,283, 215,282, 220,281, 234,281, 228,287, 226,288, 226,288, 226,288, 224,289, 224,290, 222,289, 221,290, 217,290, 205,291, 182,290, 181,290, 177,290, 175,285, 159,280, 156,279, 154,279, 151,278, 149,277, 146,277, 143,276, 139,275, 131,275, 117,274, 113,273, 113,271"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="180,5, 181,5, 185,6, 189,8, 192,9, 192,9, 193,9, 194,10, 196,10, 197,11, 198,10, 198,12, 200,12, 201,13, 203,14, 218,21, 220,22, 224,22, 230,23, 236,24, 240,24, 244,25, 243,26, 238,29, 239,37, 238,46, 236,47, 229,48, 216,48, 213,48, 211,45, 210,46, 209,44, 207,43, 206,44, 204,43, 190,43, 189,44, 186,46, 186,46, 182,50, 180,54, 179,57, 178,60, 177,63, 172,62, 173,58, 175,54, 179,45, 190,28, 190,26, 189,24, 188,24, 181,10, 181,9, 180,7, 171,5, 169,5, 169,4"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="304,102, 302,111, 301,113, 299,121, 302,124, 301,126, 297,126, 296,127, 292,129, 290,134, 290,142, 289,147, 288,147, 284,147, 283,145, 284,132, 285,131, 285,130, 287,128, 287,126, 288,127, 290,124, 292,120, 293,118, 294,115, 294,114, 294,114, 295,113, 294,113, 296,112, 295,111, 296,111, 296,110, 297,109, 297,108, 299,107, 300,106, 302,103"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="71,195, 70,196, 65,198, 67,201, 73,210, 80,211, 82,211, 113,211, 113,214, 103,215, 68,214, 67,213, 58,213, 56,212, 35,210, 34,209, 26,210, 24,211, 18,211, 13,212, 10,213, 10,213, 10,210, 16,203, 16,201, 25,199, 27,198, 35,196, 37,196, 39,195, 47,194"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="363,104, 365,107, 366,108, 370,116, 372,120, 373,123, 374,125, 375,126, 376,126, 379,130, 378,130, 379,131, 379,132, 379,133, 380,140, 379,148, 376,147, 374,147, 374,141, 373,133, 372,131, 370,128, 366,126, 364,126, 362,126, 362,123, 364,121, 361,107, 360,105, 360,102"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="90,104, 90,105, 81,105, 80,106, 74,108, 73,112, 71,114, 65,128, 66,130, 76,145, 82,156, 83,162, 82,162, 78,162, 77,160, 77,156, 76,156, 77,155, 73,149, 70,147, 68,145, 67,145, 67,144, 66,145, 65,143, 61,142, 51,143, 50,143, 49,143, 47,144, 47,145, 46,144, 45,145, 41,149, 34,148, 21,147, 16,145, 17,130, 18,130, 15,128, 12,126, 12,124, 17,124, 25,123, 29,122, 34,122, 37,121, 37,120, 38,121, 53,113, 53,113, 54,113, 58,111, 59,111, 64,109, 65,108, 68,107, 69,106, 72,105, 76,105, 80,104, 85,103"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="68,272, 69,271, 113,272, 113,273, 113,274, 76,275, 75,275, 72,276, 71,278, 65,288, 69,290, 71,290, 71,291, 38,290, 37,290, 31,288, 29,287, 16,284, 15,282, 12,278, 10,273, 10,273, 14,273, 20,274, 24,275, 27,275, 35,276, 36,275, 54,273, 60,273, 65,272"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 13 -->
                        <area shape="poly" alt="13"
                            coords="247,26, 249,30, 248,31, 249,31, 247,32, 247,35, 239,34, 238,30, 239,28, 244,26, 245,27, 246,26"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="13,126, 17,129, 16,135, 8,135, 8,131, 7,131, 7,130, 7,128, 9,126"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="304,126, 311,126, 311,130, 307,131, 304,134, 291,134, 292,130, 293,128, 300,126"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="366,126, 366,127, 367,126, 368,127, 369,127, 370,128, 370,128, 372,130, 372,132, 372,134, 358,133, 355,131, 353,130, 352,130, 353,127, 354,126"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="16,202, 15,204, 12,208, 9,216, 7,222, 6,221, 7,215, 8,213, 9,209, 12,204, 13,203, 15,201"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="7,264, 8,266, 10,273, 14,281, 16,283, 15,284, 10,279, 8,274, 7,271, 7,271, 7,270, 7,269, 6,265, 7,264"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt="14"
                            coords="177,63, 178,63, 177,69, 175,69, 164,68, 160,67, 87,68, 86,69, 76,69, 75,70, 75,69, 73,70, 73,69, 73,63, 82,65, 169,65, 173,62, 175,63"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15"
                            coords="84,163, 86,164, 89,165, 178,164, 179,164, 183,164, 183,169, 173,169, 169,168, 158,167, 82,168, 81,169, 78,169, 78,163, 83,162"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="142,5, 141,9, 141,11, 140,14, 139,18, 139,20, 138,23, 137,26, 137,27, 137,28, 137,29, 136,35, 135,43, 135,65, 78,65, 77,60, 78,31, 79,29, 80,27, 84,24, 85,23, 90,19, 92,17, 103,11, 114,7, 116,7, 120,6, 126,5, 131,5"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="383,20, 385,22, 384,26, 377,27, 379,29, 379,33, 377,32, 374,27, 374,26, 375,26, 374,24, 374,20, 374,20"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="164,190, 167,194, 172,195, 177,196, 175,201, 173,201, 170,202, 164,203, 160,205, 139,210, 122,211, 114,211, 116,202, 116,201, 118,198, 118,195, 120,194, 161,194, 159,191, 159,189"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="289,20, 288,25, 288,26, 289,27, 288,29, 284,33, 285,27, 286,27, 285,26, 278,26, 277,24, 279,21, 281,20, 285,20"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="126,105, 141,107, 143,107, 145,108, 147,109, 152,111, 162,116, 167,121, 173,125, 176,128, 177,130, 177,131, 178,134, 177,165, 119,165, 120,160, 119,131, 117,127, 118,124, 117,123, 114,105, 114,104"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="140,275, 141,276, 147,277, 149,277, 162,281, 164,282, 169,283, 171,283, 176,286, 177,290, 166,291, 166,292, 162,296, 159,296, 160,293, 161,292, 160,291, 133,291, 118,290, 116,285, 114,275"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 18 -->
                        <area shape="poly" alt="18"
                            coords="167,5, 169,5, 180,7, 188,24, 189,26, 190,26, 189,31, 181,43, 179,47, 176,52, 171,62, 169,65, 136,65, 135,60, 136,33, 137,31, 137,27, 138,26, 139,20, 139,18, 141,7, 142,5, 142,5, 146,4"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="297,111, 296,114, 294,118, 292,124, 290,124, 291,121, 293,118, 292,117, 295,113"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="104,194, 118,195, 115,204, 113,211, 104,211, 78,211, 77,210, 73,209, 70,207, 65,198, 65,197, 67,196, 70,196, 74,195, 92,194"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt="19"
                            coords="113,105, 114,106, 119,135, 120,146, 119,164, 118,165, 111,164, 84,164, 84,162, 79,152, 78,149, 76,145, 67,133, 65,129, 66,126, 67,124, 67,122, 72,114, 75,107, 77,107, 87,105, 100,104"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="366,107, 366,108, 366,109, 367,111, 368,112, 370,115, 370,116, 372,120, 372,120, 372,122, 373,124, 371,123, 370,122, 365,108, 365,107"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="114,275, 116,283, 117,284, 118,287, 117,287, 118,290, 118,291, 88,291, 80,290, 68,290, 67,289, 65,288, 65,285, 73,275, 81,275, 84,274"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="58,45, 60,46, 64,48, 68,54, 69,60, 69,69, 68,70, 67,73, 65,74, 61,78, 56,80, 46,79, 44,78, 41,76, 39,75, 35,69, 35,67, 34,64, 35,56, 39,49, 41,48, 44,46, 46,45, 49,44"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="379,58, 379,79, 377,79, 367,79, 366,76, 367,68, 373,68, 377,64, 377,61, 378,59, 379,58"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="285,58, 287,65, 290,68, 292,69, 294,68, 296,68, 295,79, 284,79, 283,77, 284,58"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="209,145, 210,145, 214,147, 217,150, 219,153, 221,158, 220,168, 216,175, 214,176, 212,178, 205,180, 198,179, 197,179, 193,177, 192,175, 192,176, 187,169, 186,164, 186,156, 187,154, 189,151, 192,148, 198,145, 205,144, 207,145"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="205,45, 213,51, 215,55, 216,58, 217,60, 216,68, 215,69, 213,73, 208,78, 205,79, 200,80, 192,79, 187,76, 186,74, 184,73, 182,67, 181,65, 182,56, 186,50, 190,46, 192,46, 193,45, 196,44"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="285,160, 287,164, 291,167, 294,167, 294,165, 296,164, 296,164, 296,179, 284,179, 285,159"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="61,145, 63,145, 69,149, 71,151, 71,152, 72,152, 74,158, 73,169, 67,177, 60,179, 56,180, 48,178, 47,177, 45,176, 43,173, 41,172, 39,167, 39,162, 41,153, 43,150, 48,146, 54,144"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="379,160, 379,179, 367,179, 367,164, 369,164, 370,167, 374,167, 377,163, 378,160, 378,160"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="143,29, 145,31, 144,35, 135,42, 136,44, 139,48, 139,52, 154,51, 169,50, 181,49, 182,47, 184,46, 207,46, 208,45, 216,46, 220,46, 226,47, 232,48, 238,48, 242,49, 246,50, 249,50, 253,52, 255,55, 256,65, 262,69, 261,79, 249,84, 231,88, 230,88, 228,88, 227,93, 221,100, 218,102, 212,104, 204,103, 195,97, 190,91, 186,93, 88,93, 87,92, 82,93, 82,95, 75,102, 72,103, 69,104, 60,103, 51,97, 46,92, 24,92, 22,91, 20,90, 20,88, 18,86, 18,84, 18,80, 16,76, 15,73, 16,69, 19,65, 26,60, 39,56, 42,56, 46,55, 48,54, 52,54, 55,53, 59,52, 65,52, 73,51, 79,50, 86,50, 103,49, 111,44, 114,43, 141,29"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="220,230, 221,230, 232,231, 237,236, 243,238, 253,246, 254,250, 256,253, 258,256, 261,271, 260,301, 260,302, 259,306, 257,309, 254,317, 241,327, 236,330, 232,334, 217,335, 204,336, 192,335, 191,334, 188,334, 186,333, 169,334, 82,334, 80,335, 53,334, 52,334, 44,333, 38,330, 31,328, 23,322, 20,315, 18,312, 18,308, 17,306, 16,302, 16,299, 15,294, 16,265, 16,264, 20,247, 30,237, 34,236, 35,236, 38,235, 46,230, 68,230, 82,230, 83,231, 86,231, 115,230, 171,231, 190,230, 192,230, 197,229"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/sedan/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/5.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/10.gif" usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/11.gif" usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/12.gif" usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/13.gif" usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/14.gif" usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/15.gif" usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/16.gif" usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/17.gif" usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/18.gif" usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/19.gif" usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/20.gif" usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/21.gif" usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/22.gif" usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/mouseover/23.gif" usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/11.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/sedan/active/23.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'stationwagon'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 12 -->
                        <area shape="poly" alt="12"
                            coords="254,36, 257,38, 261,41, 262,42, 263,41, 268,46, 268,48, 264,47, 256,47, 254,46, 251,44, 250,40, 250,36"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="333,36, 332,43, 328,46, 325,47, 320,47, 314,47, 315,45, 323,39, 331,36"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="29,38, 29,41, 22,47, 21,47, 19,49, 8,50, 8,50, 8,47, 13,44, 13,45, 15,44, 18,42, 23,40, 24,40"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="268,60, 268,62, 267,64, 264,64, 263,62, 265,59"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="319,60, 319,62, 317,64, 314,64, 314,62, 314,60, 317,59"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="204,138, 206,139, 216,143, 217,144, 220,144, 222,147, 222,149, 211,148, 203,142, 201,139, 201,137"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="208,203, 209,204, 214,206, 219,210, 222,214, 225,221, 225,222, 224,222, 221,220, 220,218, 205,207, 200,204, 200,203"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="225,267, 225,271, 222,277, 214,284, 210,285, 207,286, 204,287, 200,287, 200,285, 215,275"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="257,26, 258,26, 326,26, 327,25, 330,26, 333,29, 332,34, 330,36, 327,37, 320,41, 260,40, 254,36, 250,34, 250,29, 252,26, 254,25"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="53,29, 54,30, 54,30, 47,33, 35,37, 27,39, 21,41, 17,43, 13,44, 9,43, 9,43, 10,42, 13,40, 14,40, 15,38, 17,37, 18,37, 19,37, 20,36, 22,36, 24,34, 24,34, 26,33, 28,33, 33,31, 34,30, 37,30, 38,29"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="188,128, 193,129, 195,129, 197,130, 200,131, 203,132, 203,132, 207,133, 210,135, 210,135, 211,136, 211,135, 212,136, 213,136, 214,137, 217,139, 218,140, 221,142, 221,142, 221,143, 214,142, 210,140, 208,140, 204,138, 201,137, 197,137, 196,136, 186,133, 185,133, 176,129, 176,128"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="200,203, 203,206, 214,214, 220,218, 220,219, 221,220, 222,226, 223,228, 224,231, 224,236, 225,240, 224,256, 224,257, 223,262, 222,263, 222,266, 221,267, 221,270, 220,270, 219,272, 207,281, 197,287, 194,286, 192,285, 189,285, 176,282, 175,281, 175,280, 179,278, 185,273, 188,267, 188,264, 189,263, 190,255, 191,247, 190,233, 190,232, 188,224, 188,223, 185,217, 179,211, 175,208, 175,208, 192,204, 199,203"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt="2"
                            coords="223,29, 224,30, 225,31, 225,38, 225,39, 225,42, 221,41, 221,31, 220,30, 221,28, 221,29, 222,28"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="266,126, 324,126, 324,147, 323,148, 322,149, 323,150, 320,151, 261,151, 259,149, 259,145, 258,143, 259,125"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2" coords="10,127, 9,134, 9,136, 8,141, 5,141, 6,128, 9,128"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="10,211, 8,222, 7,227, 6,230, 6,248, 6,256, 7,265, 8,266, 8,271, 9,272, 10,278, 10,278, 8,278, 4,277, 3,275, 2,272, 2,267, 1,266, 1,241, 1,229, 2,221, 2,220, 3,215, 6,211"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 3 -->
                        <area shape="poly" alt="3"
                            coords="313,1, 314,2, 314,1, 317,3, 316,3, 302,3, 266,3, 266,2, 268,1, 268,2, 270,1, 286,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="189,3, 190,3, 205,4, 206,5, 209,5, 208,7, 203,8, 201,7, 200,5, 175,4, 114,5, 112,5, 107,6, 105,6, 95,9, 94,10, 88,9, 89,8, 92,7, 93,8, 96,6, 105,5, 109,4, 113,3, 129,3, 129,2"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="310,99, 311,100, 318,100, 319,101, 321,105, 321,105, 317,105, 314,104, 301,104, 288,104, 267,104, 266,105, 261,105, 264,100, 272,100, 273,99, 285,98"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="122,103, 123,104, 129,104, 135,105, 139,107, 142,108, 142,107, 142,108, 137,109, 131,107, 130,107, 122,105, 119,104, 114,104, 29,104, 27,107, 26,107, 24,107, 22,106, 21,106, 22,104, 26,103, 30,102"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="139,217, 139,221, 140,223, 140,225, 141,230, 142,234, 142,243, 142,257, 141,259, 139,270, 139,271, 137,273, 135,273, 125,272, 119,271, 86,271, 33,271, 31,272, 31,272, 30,268, 29,249, 30,220, 30,219, 31,218, 65,218, 125,218, 126,217"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="316,3, 317,4, 319,5, 323,13, 326,20, 326,23, 326,26, 324,26, 256,26, 257,20, 257,19, 263,6, 264,4, 269,3, 294,3, 311,3"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="88,10, 90,10, 89,12, 88,12, 86,13, 84,15, 82,16, 76,18, 75,19, 75,19, 73,20, 70,22, 68,23, 65,24, 62,26, 58,28, 52,29, 48,29, 47,29, 47,28, 51,26, 58,22, 61,20, 62,20, 70,16, 74,14, 83,10, 86,9, 87,10"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="147,109, 148,109, 155,112, 163,116, 167,118, 181,126, 183,128, 182,128, 175,128, 173,127, 171,126, 165,124, 165,123, 162,122, 158,119, 153,116, 151,116, 146,112, 145,113, 140,109, 140,109"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="178,210, 181,213, 182,214, 184,215, 188,224, 190,232, 190,235, 190,252, 190,259, 189,260, 188,267, 185,273, 183,275, 179,279, 174,281, 166,279, 158,277, 154,277, 146,275, 143,274, 140,274, 139,273, 139,268, 140,267, 140,263, 141,260, 142,257, 142,250, 142,231, 141,230, 139,219, 139,218, 139,216, 141,215, 143,215, 153,213, 155,213, 164,211, 167,210, 170,210, 172,209"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt="5"
                            coords="211,8, 221,19, 224,24, 224,28, 224,29, 221,28, 217,25, 215,24, 204,8, 207,8"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="314,104, 315,105, 321,105, 323,107, 323,107, 323,106, 322,107, 323,107, 323,107, 324,109, 326,112, 325,112, 326,114, 328,119, 328,122, 328,124, 327,125, 327,124, 325,125, 324,125, 323,125, 256,125, 254,123, 254,122, 254,121, 254,119, 256,116, 256,115, 256,114, 257,111, 257,111, 259,110, 258,109, 259,109, 259,108, 262,105, 266,105, 271,104, 284,104"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="26,107, 25,109, 23,112, 13,124, 12,125, 12,126, 11,125, 9,127, 6,128, 6,123, 9,118, 20,107"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="15,209, 16,208, 17,209, 24,211, 26,213, 29,215, 30,217, 29,236, 29,275, 25,278, 20,280, 15,281, 12,280, 11,279, 12,278, 10,277, 9,273, 7,264, 6,255, 7,225, 8,224, 8,218, 9,217, 9,215, 12,209"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="320,41, 314,47, 316,48, 323,47, 325,47, 328,46, 329,45, 331,46, 332,47, 334,47, 335,47, 336,47, 334,64, 334,65, 333,68, 332,69, 249,68, 249,67, 247,58, 246,48, 246,47, 249,47, 250,47, 253,45, 257,47, 261,47, 267,48, 268,47, 263,42, 263,41"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="12,44, 11,45, 8,48, 8,50, 18,49, 21,48, 23,48, 24,49, 33,49, 31,54, 30,56, 29,57, 28,60, 27,66, 27,70, 10,70, 8,69, 3,68, 2,66, 2,61, 2,57, 1,58, 2,51, 4,50, 6,47, 9,44"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="223,144, 225,147, 227,150, 228,150, 229,151, 229,157, 228,157, 228,165, 228,167, 226,168, 221,168, 219,170, 203,169, 202,159, 201,158, 200,153, 197,149, 197,148, 207,147, 208,147, 211,147, 213,148, 216,149, 222,148, 221,146, 218,144, 218,143"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="203,201, 204,201, 209,202, 212,203, 214,204, 215,204, 216,205, 218,206, 219,207, 221,210, 222,210, 223,212, 224,213, 224,213, 225,214, 225,215, 227,218, 227,219, 228,221, 228,224, 228,228, 228,230, 229,236, 229,242, 229,256, 228,257, 228,262, 228,264, 228,269, 227,272, 225,275, 224,277, 218,284, 217,284, 216,285, 213,286, 211,287, 210,287, 207,288, 197,289, 196,289, 197,288, 198,287, 206,287, 207,286, 210,285, 211,285, 214,283, 222,275, 224,271, 225,267, 225,267, 222,269, 221,269, 222,265, 222,264, 224,256, 225,250, 224,233, 224,232, 223,227, 222,226, 222,220, 225,222, 224,216, 218,210, 214,206, 207,203, 197,201, 196,201, 197,200"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7"
                            coords="226,43, 227,45, 227,51, 228,52, 229,52, 229,58, 225,62, 219,63, 213,64, 205,63, 204,58, 200,51, 199,50, 195,47, 193,45, 193,44, 197,43, 210,43"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="258,141, 259,147, 261,151, 263,151, 265,152, 320,151, 323,148, 324,146, 324,143, 324,140, 337,140, 336,146, 335,151, 335,155, 334,157, 334,159, 333,160, 334,160, 333,161, 332,161, 329,162, 251,162, 250,161, 250,162, 249,160, 249,160, 249,160, 248,158, 248,154, 247,153, 246,142, 247,140"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="17,142, 34,142, 36,143, 37,143, 37,145, 33,147, 27,153, 26,158, 25,160, 25,163, 10,162, 9,162, 5,160, 2,158, 1,155, 1,151, 3,150, 3,149, 4,142, 8,141, 16,142"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="20,201, 21,201, 24,201, 23,202, 22,203, 20,203, 15,204, 13,204, 10,206, 8,208, 5,211, 6,207, 8,206, 7,206, 8,204, 9,205, 11,203, 12,203, 15,201"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="6,278, 10,283, 16,286, 22,287, 23,287, 24,288, 24,289, 13,287, 12,287, 10,286, 10,286, 9,285, 9,285, 5,281, 4,277"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="321,5, 321,6, 322,6, 323,9, 326,15, 327,17, 327,17, 328,19, 330,23, 331,26, 326,24, 326,20, 325,19, 321,9, 318,3, 318,3"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="132,4, 131,6, 119,7, 118,8, 111,8, 110,9, 98,12, 95,13, 90,15, 89,16, 81,20, 71,26, 69,27, 66,33, 66,45, 67,46, 68,50, 68,51, 69,53, 69,54, 72,60, 74,62, 73,63, 71,63, 66,63, 65,58, 64,55, 63,54, 63,54, 63,54, 63,52, 58,48, 57,47, 56,47, 56,46, 54,45, 53,45, 50,44, 48,44, 42,44, 41,45, 40,45, 38,46, 38,47, 38,46, 37,47, 34,48, 33,49, 22,48, 22,47, 22,46, 32,38, 34,37, 50,32, 53,30, 58,29, 61,27, 61,27, 62,27, 66,24, 66,24, 70,22, 70,22, 72,22, 75,19, 76,19, 77,19, 80,17, 83,15, 89,12, 90,12, 92,11, 94,9, 105,6, 114,5, 125,4"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="334,30, 334,37, 335,38, 335,38, 335,40, 337,41, 336,47, 330,46, 331,44, 333,41, 333,37, 331,34, 332,33, 333,31, 333,29"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="195,200, 197,202, 195,203, 187,205, 185,206, 182,206, 176,208, 174,208, 172,209, 169,210, 160,211, 158,212, 155,213, 146,214, 144,215, 135,217, 125,217, 118,218, 98,218, 98,216, 100,214, 133,212, 135,211, 140,211, 144,210, 147,210, 150,209, 153,208, 155,208, 158,207, 161,207, 165,204, 167,202, 167,200"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9"
                            coords="264,3, 264,5, 259,16, 257,20, 256,24, 255,25, 252,25, 253,22, 253,21, 253,19, 256,16, 257,15, 257,14, 257,13, 261,5"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="250,29, 250,36, 249,39, 250,41, 252,45, 251,47, 246,46, 247,38, 248,36, 249,30"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="116,104, 117,104, 123,105, 124,105, 132,107, 133,108, 138,109, 139,111, 140,110, 145,114, 147,114, 149,115, 149,115, 150,116, 152,117, 154,118, 158,120, 158,121, 159,120, 160,121, 163,123, 164,123, 164,123, 165,124, 168,126, 168,125, 169,126, 169,126, 170,127, 171,126, 173,128, 176,129, 179,130, 193,136, 200,137, 207,145, 208,146, 208,147, 206,148, 196,147, 195,147, 195,147, 193,146, 193,146, 192,145, 192,146, 191,144, 189,144, 186,143, 184,143, 176,144, 176,145, 175,144, 174,145, 173,146, 167,153, 166,154, 167,154, 165,157, 164,162, 163,162, 156,162, 157,160, 160,155, 163,147, 164,142, 164,139, 164,130, 162,128, 162,128, 161,127, 161,127, 160,126, 152,121, 139,114, 130,111, 125,109, 122,108, 120,108, 118,107, 114,107, 108,106, 102,105, 98,105, 99,103"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="128,272, 129,273, 139,273, 140,274, 155,277, 163,278, 171,280, 178,282, 184,284, 192,285, 195,286, 197,287, 195,289, 183,289, 167,289, 166,286, 161,282, 161,283, 160,282, 157,282, 149,280, 146,280, 135,278, 129,277, 125,277, 114,276, 100,275, 98,272, 98,271"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="200,5, 203,7, 203,8, 203,8, 207,13, 208,13, 207,14, 210,17, 215,26, 211,33, 207,42, 207,43, 195,43, 192,45, 184,45, 183,45, 182,45, 175,48, 170,54, 169,56, 169,57, 167,59, 162,59, 161,58, 163,55, 169,44, 174,34, 172,26, 171,13, 171,7, 145,6, 132,6, 132,4"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="262,101, 261,104, 259,109, 256,116, 254,121, 253,123, 250,126, 252,119, 252,118, 253,116, 253,116, 253,115, 254,112, 256,108, 257,105, 259,104, 260,102"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10" coords="248,135, 248,140, 246,139, 246,138, 247,137"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="59,201, 58,204, 59,205, 61,213, 80,213, 100,213, 99,216, 97,218, 33,218, 32,217, 30,217, 27,213, 20,210, 15,208, 16,206, 18,204, 19,203, 22,203, 26,200"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="323,104, 324,105, 324,105, 325,107, 326,107, 328,114, 330,117, 331,119, 331,121, 332,123, 333,125, 333,127, 333,128, 332,128, 329,123, 328,122, 326,112, 324,109, 322,105, 320,103, 320,101"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="98,104, 98,105, 98,105, 59,106, 59,107, 58,126, 58,128, 57,131, 56,132, 57,136, 59,139, 62,145, 66,152, 69,157, 68,158, 64,158, 62,158, 61,155, 61,154, 59,151, 53,146, 50,144, 47,144, 36,143, 33,142, 26,142, 23,141, 19,132, 15,123, 19,119, 24,111, 30,104, 31,104, 79,103"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11" coords="335,137, 336,139, 335,140, 335,140"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="98,272, 100,275, 100,276, 61,277, 60,278, 58,285, 58,287, 59,289, 27,289, 26,288, 23,288, 20,286, 17,285, 15,282, 16,281, 19,280, 20,280, 26,277, 31,272, 45,271"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 13 -->
                        <area shape="poly" alt="13" coords="220,29, 221,34, 221,38, 221,42, 207,42, 211,32, 215,25"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="254,123, 256,125, 258,126, 257,140, 248,139, 249,131, 249,130, 251,125, 253,123"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="330,123, 333,130, 334,133, 334,135, 335,139, 334,140, 324,139, 325,125, 326,125"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="19,131, 23,140, 22,142, 12,141, 9,141, 9,132, 11,126, 15,125, 18,130"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="17,204, 16,206, 13,209, 10,211, 6,211, 7,209, 9,207, 14,204"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="12,280, 16,282, 16,285, 16,285, 12,285, 8,282, 6,278, 6,278"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 14 -->
                        <area shape="poly" alt=""
                            coords="164,59, 165,59, 168,59, 167,69, 149,69, 66,69, 66,68, 66,67, 66,64, 80,63, 110,62, 147,62, 154,61, 159,59, 161,58"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15"
                            coords="70,158, 73,160, 76,160, 83,161, 118,161, 151,162, 160,162, 161,163, 164,163, 163,169, 105,168, 63,168, 62,161, 62,160, 62,159, 64,158, 68,158, 69,157"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="131,7, 128,17, 127,20, 123,30, 123,30, 123,31, 123,33, 122,35, 122,37, 121,45, 121,62, 112,62, 73,62, 70,56, 68,51, 66,45, 66,34, 66,32, 67,30, 69,28, 69,27, 69,27, 70,27, 72,25, 84,18, 90,15, 94,13, 96,13, 105,10, 107,9, 111,9, 113,8, 116,8, 123,7"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="342,24, 343,27, 343,29, 341,30, 338,30, 334,30, 334,26, 335,24, 338,24"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="248,123, 248,128, 248,130, 242,129, 239,128, 240,124, 241,123, 245,123"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="148,193, 151,197, 153,200, 154,200, 167,200, 166,204, 164,206, 161,206, 160,207, 158,207, 150,209, 146,210, 143,210, 139,211, 135,211, 130,212, 125,213, 112,213, 101,213, 102,211, 108,203, 107,201, 108,200, 147,200, 147,199, 146,199, 146,197, 144,194, 145,193"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="248,24, 248,26, 248,29, 248,31, 245,30, 241,30, 239,29, 240,25, 241,24, 243,24"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="115,107, 116,107, 126,109, 127,109, 136,112, 140,114, 148,118, 154,121, 156,123, 160,126, 160,126, 161,126, 161,126, 161,127, 164,130, 164,135, 164,143, 161,154, 157,161, 156,161, 155,161, 154,162, 132,161, 108,161, 109,153, 108,135, 108,134, 107,130, 107,129, 106,129, 100,108, 99,107, 100,106"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="342,123, 343,125, 342,129, 339,129, 335,130, 334,129, 334,125, 336,123"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="126,277, 127,277, 143,279, 144,280, 160,282, 161,284, 162,283, 163,284, 167,287, 167,289, 152,290, 150,294, 147,296, 144,296, 145,294, 147,290, 146,289, 138,289, 107,289, 108,286, 107,285, 101,277, 101,276"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 18 -->
                        <area shape="poly" alt="18"
                            coords="171,8, 171,15, 172,28, 172,29, 173,31, 172,38, 169,44, 169,45, 161,58, 158,61, 153,61, 131,62, 122,61, 121,52, 122,37, 122,36, 124,29, 125,27, 125,25, 126,24, 132,7"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="107,201, 107,205, 103,210, 100,213, 68,213, 60,212, 59,207, 59,206, 58,201, 59,200"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt="19"
                            coords="99,107, 106,127, 107,127, 107,129, 107,130, 107,131, 108,135, 108,137, 109,149, 108,160, 106,161, 75,160, 73,160, 71,159, 69,157, 65,151, 63,147, 58,139, 57,133, 58,128, 58,126, 59,117, 59,106"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="101,277, 104,281, 104,281, 105,282, 105,283, 107,284, 107,288, 107,289, 58,288, 60,277, 61,277"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="51,48, 52,49, 56,51, 56,52, 57,51, 59,54, 59,55, 60,55, 62,59, 62,62, 62,69, 57,76, 55,77, 50,79, 41,79, 36,76, 36,75, 33,73, 31,68, 31,66, 31,58, 34,55, 34,54, 36,51, 37,52, 38,50, 43,48, 46,48"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="334,68, 334,77, 333,78, 324,77, 323,76, 324,69, 333,69, 334,68"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="249,69, 250,69, 259,70, 258,78, 249,78, 249,77, 248,77, 248,68"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="188,147, 189,148, 193,150, 193,151, 195,151, 196,153, 196,154, 197,154, 199,158, 199,161, 199,168, 194,175, 192,176, 187,178, 178,178, 173,175, 172,174, 170,172, 168,167, 168,165, 168,157, 171,154, 171,153, 172,151, 174,150, 175,149, 179,147, 183,147"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22"
                            coords="192,48, 193,49, 195,51, 197,51, 198,53, 200,54, 201,58, 202,61, 203,63, 202,67, 200,72, 197,76, 196,76, 194,78, 193,77, 192,79, 189,79, 181,79, 174,73, 174,72, 173,72, 171,68, 171,62, 171,61, 172,57, 174,55, 174,54, 179,50, 185,48"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="250,161, 251,163, 252,162, 253,163, 258,163, 258,176, 256,177, 248,176, 248,160"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23"
                            coords="48,147, 50,148, 52,150, 54,150, 55,153, 56,153, 57,155, 58,155, 58,157, 59,158, 59,165, 58,169, 53,175, 51,177, 48,178, 46,178, 37,178, 37,176, 36,177, 34,176, 34,175, 33,175, 30,171, 28,167, 29,157, 29,155, 32,151, 34,150, 37,148, 40,147, 43,147"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="335,160, 334,177, 324,176, 324,163, 332,162, 333,161, 334,160, 334,159"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="128,26, 129,28, 128,31, 121,37, 121,40, 123,43, 124,46, 137,45, 151,45, 161,44, 162,42, 164,41, 184,41, 185,40, 192,41, 196,41, 201,42, 206,43, 212,43, 215,44, 219,44, 221,45, 225,47, 227,49, 228,58, 233,61, 232,70, 222,75, 206,79, 204,78, 203,79, 202,83, 197,89, 195,91, 189,93, 182,92, 174,87, 169,81, 166,83, 79,83, 77,82, 73,83, 73,84, 66,91, 64,91, 61,93, 54,92, 45,87, 41,82, 21,82, 20,81, 17,80, 18,78, 16,77, 16,75, 16,71, 14,68, 13,65, 14,61, 17,58, 23,53, 35,50, 37,50, 41,49, 43,48, 47,48, 49,47, 52,47, 58,46, 65,45, 70,45, 77,44, 91,44, 98,40, 102,38, 125,26"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="196,204, 197,205, 207,206, 211,210, 216,212, 225,219, 227,222, 228,225, 230,228, 232,242, 232,268, 231,269, 231,272, 229,275, 226,282, 215,291, 210,294, 206,298, 193,298, 182,299, 171,298, 170,298, 168,297, 165,296, 150,297, 73,298, 72,298, 47,298, 46,297, 40,296, 34,294, 27,292, 20,287, 17,281, 16,278, 16,274, 15,272, 15,268, 14,266, 13,261, 14,236, 15,235, 17,220, 27,211, 30,210, 31,210, 34,209, 41,205, 61,204, 73,205, 74,206, 77,206, 102,205, 153,206, 169,205, 171,204, 175,204"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/stationwagon/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/5.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/10.gif"
                        usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/11.gif"
                        usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/12.gif"
                        usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/13.gif"
                        usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/14.gif"
                        usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/15.gif"
                        usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/16.gif"
                        usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/17.gif"
                        usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/18.gif"
                        usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/19.gif"
                        usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/20.gif"
                        usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/21.gif"
                        usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/22.gif"
                        usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/mouseover/23.gif"
                        usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/11.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/stationwagon/active/23.gif" usemap="#imgmap" />
                </div>
            </div>
            <div *ngSwitchCase="'terreinwagen'">
                <div id="imgmapdiv">
                    <map name="imgmap">
                        <!-- 12 -->
                        <area shape="poly" alt="12"
                            coords="259,35, 265,40, 264,41, 266,42, 265,43, 266,44, 247,43, 248,36, 249,35, 250,34"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="328,35, 329,38, 329,42, 328,44, 311,43, 312,42, 313,40, 314,39, 314,39, 319,35, 325,34"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="28,36, 22,45, 15,44, 16,40, 19,36, 21,36, 23,35"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="14,53, 15,56, 13,59, 11,59, 11,56, 11,53, 13,52"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="257,56, 258,57, 257,60, 256,60, 254,60, 253,59, 254,56, 255,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="320,56, 322,58, 321,59, 319,60, 317,60, 316,59, 317,56, 319,55"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12" coords="204,134, 207,136, 209,140, 208,143, 202,143, 197,133"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="213,151, 213,154, 211,157, 210,156, 209,155, 210,151, 211,150"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="201,212, 204,216, 207,225, 208,229, 206,228, 200,221, 197,217, 194,215, 190,210, 191,209, 196,210, 196,210, 197,210"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <area shape="poly" alt="12"
                            coords="208,271, 206,279, 205,280, 204,284, 199,289, 196,290, 192,290, 190,290, 190,288, 196,283, 201,276, 205,272, 207,271"
                            onmouseover="mouseOver(12)" onmouseout="mouseOut(12)" (click)="createDamage(12)">
                        <!-- 13 -->
                        <area shape="poly" alt="13"
                            coords="215,27, 215,28, 217,34, 217,41, 210,40, 211,37, 211,27, 211,26"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13" coords="13,125, 13,135, 14,139, 13,139, 7,139, 7,128, 9,125, 9,124"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="255,128, 256,130, 258,136, 259,141, 260,144, 253,144, 251,143, 247,141, 247,135, 248,128, 249,127"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="328,127, 329,131, 329,133, 329,143, 325,143, 323,144, 318,144, 317,144, 318,141, 318,139, 320,133, 322,127"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="17,210, 16,212, 12,214, 9,216, 5,217, 4,217, 5,214, 8,211, 14,209"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <area shape="poly" alt="13"
                            coords="7,283, 11,285, 15,288, 16,288, 17,290, 16,291, 12,290, 11,290, 7,288, 5,286, 4,283, 4,283"
                            onmouseover="mouseOver(13)" onmouseout="mouseOut(13)" (click)="createDamage(13)">
                        <!-- 1 -->
                        <area shape="poly" alt="1"
                            coords="186,213, 187,214, 194,214, 198,218, 204,225, 208,230, 209,246, 208,270, 207,270, 208,271, 201,276, 196,284, 194,285, 192,286, 186,286, 178,287, 160,287, 153,286, 153,286, 153,285, 157,280, 158,276, 159,274, 160,271, 160,268, 161,262, 161,246, 161,244, 160,230, 160,229, 159,225, 158,223, 156,217, 153,214, 155,213, 160,213"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="181,127, 182,127, 186,128, 188,128, 194,129, 195,129, 202,131, 203,132, 204,132, 204,133, 206,133, 205,135, 203,134, 202,133, 193,133, 192,132, 167,125, 167,125"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="57,27, 56,28, 50,30, 47,30, 45,31, 39,32, 37,33, 35,34, 32,34, 27,35, 21,36, 19,36, 18,36, 19,34, 20,35, 21,34, 24,32, 31,31, 35,30, 38,30, 48,28, 51,27, 56,27"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <area shape="poly" alt="1"
                            coords="319,25, 320,25, 322,26, 324,27, 325,30, 319,34, 315,37, 314,39, 309,46, 304,46, 268,46, 262,38, 262,36, 254,31, 252,29, 255,26, 256,25, 259,25, 292,24, 311,25"
                            onmouseover="mouseOver(1)" onmouseout="mouseOut(1)" (click)="createDamage(1)">
                        <!-- 2 -->
                        <area shape="poly" alt="2"
                            coords="263,128, 290,128, 312,128, 322,128, 318,141, 316,144, 313,145, 302,145, 289,146, 272,145, 265,145, 261,144, 259,141, 259,139, 257,135, 257,131, 256,130, 255,128, 255,127"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <area shape="poly" alt="2"
                            coords="9,217, 7,223, 7,230, 6,235, 6,244, 6,267, 7,268, 7,277, 8,278, 9,282, 9,283, 9,283, 3,282, 3,279, 2,274, 2,265, 1,254, 2,230, 2,229, 3,219, 3,218, 4,217, 6,217"
                            onmouseover="mouseOver(2)" onmouseout="mouseOut(2)" (click)="createDamage(2)">
                        <!-- 3 -->
                        <area shape="poly" alt="3"
                            coords="124,222, 124,228, 125,231, 125,250, 124,278, 121,278, 113,278, 104,277, 92,276, 79,276, 60,275, 29,275, 28,269, 27,258, 28,227, 29,226, 29,225, 83,224, 84,223, 103,223, 108,222, 123,222"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <area shape="poly" alt="3"
                            coords="306,1, 307,2, 310,2, 311,3, 312,6, 311,6, 306,6, 291,5, 268,6, 267,6, 265,6, 266,3, 267,2, 270,2, 272,1, 283,1"
                            onmouseover="mouseOver(3)" onmouseout="mouseOut(3)" (click)="createDamage(3)">
                        <!-- 4 -->
                        <area shape="poly" alt="4"
                            coords="152,214, 153,215, 154,215, 156,218, 159,225, 161,237, 160,269, 160,270, 158,279, 153,285, 150,286, 148,287, 147,286, 124,281, 124,272, 125,271, 125,258, 125,229, 124,227, 124,221, 124,218, 128,218, 130,217, 132,217, 135,216, 143,214, 150,213"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="144,115, 147,117, 148,117, 149,118, 149,118, 150,119, 152,119, 152,120, 153,120, 153,121, 154,121, 154,123, 155,122, 154,123, 153,123, 152,121, 150,121, 149,120, 148,120, 147,118, 143,117, 144,115"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="126,105, 127,105, 126,104, 125,105, 126,105, 127,105, 127,106, 128,105, 129,107, 130,107, 132,109, 133,108, 132,109, 133,109, 137,110, 138,112, 139,112, 140,112, 140,113, 142,113, 141,115, 138,114, 136,114, 136,112, 135,111, 134,111, 133,111, 132,110, 131,109, 130,108, 129,109, 128,109, 128,108, 124,107, 124,104, 125,104"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="83,17, 84,16, 83,17, 80,19, 79,19, 78,20, 76,22, 76,21, 75,23, 72,25, 70,25, 70,25, 70,23, 73,22, 75,20, 78,19, 80,18, 81,17"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4" coords="87,13, 87,14, 84,15, 85,14, 86,13" onmouseover="mouseOver(4)"
                            onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="311,6, 312,7, 315,7, 318,10, 318,13, 319,14, 320,18, 321,21, 322,23, 322,25, 296,24, 255,24, 256,19, 257,18, 259,10, 262,7, 268,6, 275,6"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <area shape="poly" alt="4"
                            coords="101,5, 100,7, 96,10, 95,11, 93,13, 92,13, 92,14, 91,14, 91,12, 89,13, 88,12, 88,11, 90,11, 90,10, 93,10, 94,10, 95,9, 95,7, 99,6, 99,6"
                            onmouseover="mouseOver(4)" onmouseout="mouseOut(4)" (click)="createDamage(4)">
                        <!-- 5 -->
                        <area shape="poly" alt="5"
                            coords="10,216, 11,215, 12,216, 25,221, 26,222, 29,224, 28,225, 29,226, 28,227, 27,238, 28,273, 29,274, 27,277, 22,280, 11,284, 9,283, 9,281, 8,279, 7,274, 7,270, 6,261, 6,246, 7,228, 7,227, 8,220, 9,219, 9,217"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="30,101, 29,104, 26,105, 19,113, 13,123, 12,124, 9,124, 10,122, 12,118, 13,117, 16,113, 17,112, 19,109, 27,102"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="308,101, 310,101, 315,102, 318,105, 320,108, 322,112, 324,120, 327,125, 327,127, 326,127, 295,128, 251,127, 250,127, 249,127, 250,125, 251,123, 253,117, 254,116, 255,111, 259,104, 262,102, 266,101, 271,101, 283,100"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <area shape="poly" alt="5"
                            coords="197,4, 200,6, 202,9, 207,13, 208,15, 209,16, 210,18, 211,18, 213,22, 213,22, 214,23, 215,26, 211,26, 210,22, 200,9, 194,4, 194,3"
                            onmouseover="mouseOver(5)" onmouseout="mouseOut(5)" (click)="createDamage(5)">
                        <!-- 6 -->
                        <area shape="poly" alt="6"
                            coords="253,44, 268,45, 269,46, 271,46, 308,46, 310,44, 332,44, 332,56, 331,58, 331,63, 329,64, 329,65, 330,64, 329,64, 329,64, 329,65, 327,66, 247,65, 247,63, 246,62, 246,58, 245,56, 245,46, 245,44"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="37,45, 36,47, 32,52, 30,58, 29,60, 28,66, 22,66, 21,65, 18,64, 17,64, 11,61, 10,59, 11,58, 11,58, 12,59, 14,58, 15,56, 14,54, 13,52, 10,54, 11,48, 15,46, 15,45"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="209,144, 210,145, 213,147, 214,148, 213,152, 213,152, 211,150, 210,152, 209,154, 210,156, 211,157, 214,155, 213,158, 210,161, 206,162, 204,163, 200,164, 198,164, 195,164, 194,155, 194,154, 191,148, 186,143"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <area shape="poly" alt="6"
                            coords="196,207, 198,208, 202,209, 204,211, 204,210, 206,213, 208,214, 207,214, 208,215, 210,220, 210,221, 211,223, 211,227, 212,230, 213,236, 213,245, 213,267, 212,268, 210,279, 210,279, 210,280, 209,281, 210,282, 209,283, 207,286, 206,286, 207,287, 204,289, 203,290, 201,291, 194,293, 188,293, 188,292, 196,290, 197,290, 200,288, 204,283, 206,279, 206,276, 207,274, 208,272, 208,268, 209,262, 208,230, 208,229, 206,221, 205,219, 204,216, 200,211, 193,209, 186,207, 186,206"
                            onmouseover="mouseOver(6)" onmouseout="mouseOut(6)" (click)="createDamage(6)">
                        <!-- 7 -->
                        <area shape="poly" alt="7" coords="15,291, 17,291, 18,292, 17,292, 13,292, 14,291, 15,290"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="10,290, 11,290, 11,290, 12,290, 12,290, 12,291, 11,291, 11,290, 10,290"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7" coords="9,288, 9,290, 7,290, 7,288" onmouseover="mouseOver(7)"
                            onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="3,282, 4,284, 5,286, 5,287, 4,287, 3,287, 3,284, 2,283, 3,283, 2,282, 2,281"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7" coords="5,214, 4,217, 3,218, 3,219, 2,219, 4,214"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="18,209, 17,210, 9,211, 6,213, 6,213, 5,214, 4,217, 3,218, 3,219, 2,219, 4,214, 4,214, 8,210, 9,210, 10,210, 11,209, 11,208"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="253,144, 258,144, 262,145, 270,145, 282,146, 310,145, 311,145, 322,144, 323,144, 331,143, 332,144, 331,162, 329,163, 309,164, 247,163, 245,161, 245,147, 245,144, 246,142"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="14,140, 14,143, 15,143, 36,143, 36,144, 31,150, 29,156, 28,158, 27,162, 27,163, 26,163, 25,161, 22,161, 10,160, 7,160, 3,159, 2,157, 1,154, 2,145, 5,143, 7,141, 7,140"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <area shape="poly" alt="7"
                            coords="217,42, 218,44, 221,46, 223,48, 223,49, 223,55, 222,59, 219,62, 209,62, 205,63, 199,63, 197,65, 196,60, 194,54, 189,47, 188,46, 188,45, 209,44, 210,43, 211,42"
                            onmouseover="mouseOver(7)" onmouseout="mouseOut(7)" (click)="createDamage(7)">
                        <!-- 8 -->
                        <area shape="poly" alt="8"
                            coords="130,216, 128,218, 124,219, 121,222, 102,222, 100,223, 94,222, 96,219, 117,218, 119,218, 127,217, 128,216, 128,215"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="185,207, 186,208, 190,208, 190,208, 190,211, 193,213, 192,214, 183,213, 177,213, 154,213, 153,214, 148,213, 143,214, 142,214, 141,215, 138,215, 136,217, 136,217, 135,215, 135,217, 134,217, 133,217, 132,216, 131,217, 132,215, 137,214, 137,215, 139,214, 140,214, 141,213, 144,213, 145,212, 148,211, 150,209, 150,207, 163,206"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="326,30, 327,32, 329,34, 330,35, 329,36, 331,38, 332,39, 332,42, 332,43, 329,43, 329,37, 327,34, 318,34, 319,33, 325,30"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="84,18, 82,19, 76,25, 75,30, 74,32, 75,55, 75,56, 75,64, 70,64, 69,58, 68,57, 67,54, 67,54, 67,54, 66,52, 66,52, 66,51, 64,49, 62,46, 61,47, 60,45, 59,46, 59,44, 58,45, 58,44, 50,43, 42,43, 41,44, 36,45, 22,44, 23,43, 29,35, 35,34, 38,33, 43,31, 46,31, 48,30, 50,30, 52,29, 58,27, 66,26, 72,24, 74,22, 80,18, 81,19"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="319,11, 320,12, 319,13, 320,14, 322,16, 322,18, 323,19, 324,28, 324,28, 323,26, 322,26, 321,19, 320,18, 318,11"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="97,9, 97,9, 98,9, 98,9, 97,10, 97,10, 97,9"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="95,9, 96,10, 95,11, 93,11, 92,12, 91,13, 91,14, 89,14, 87,17, 86,16, 85,17, 86,15, 87,15, 87,14, 92,11, 93,10, 93,11"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="110,4, 109,5, 103,6, 102,7, 100,7, 100,7, 102,5, 103,5, 104,4, 104,5, 105,4"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8"
                            coords="314,3, 316,5, 316,6, 317,6, 318,9, 317,9, 312,6, 312,6, 311,2"
                            onmouseover="mouseOver(8)" onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <area shape="poly" alt="8" coords="117,3, 116,3, 112,3, 113,2" onmouseover="mouseOver(8)"
                            onmouseout="mouseOut(8)" (click)="createDamage(8)">
                        <!-- 9 -->
                        <area shape="poly" alt="9"
                            coords="114,278, 115,278, 123,279, 125,281, 125,282, 127,281, 133,283, 141,284, 145,285, 147,286, 149,287, 150,286, 153,286, 156,287, 161,287, 185,287, 186,286, 193,286, 190,291, 189,292, 185,292, 182,293, 166,294, 150,293, 149,290, 147,288, 143,287, 136,286, 135,286, 134,285, 132,284, 130,283, 126,284, 125,283, 122,282, 115,282, 109,281, 95,280, 94,278, 94,277"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="138,113, 139,115, 140,114, 140,115, 141,115, 142,116, 144,117, 154,123, 156,123, 157,124, 162,124, 163,125, 179,129, 180,129, 192,132, 194,133, 197,135, 202,143, 201,143, 184,143, 183,142, 181,141, 178,141, 169,141, 169,142, 168,141, 167,142, 163,144, 163,145, 162,144, 158,149, 158,150, 157,150, 156,153, 157,153, 155,156, 154,161, 153,162, 148,162, 149,158, 149,149, 150,141, 149,126, 149,125, 147,122, 146,120, 141,117, 140,116, 140,115, 139,116, 138,114, 136,114, 136,112"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="133,110, 135,111, 134,112, 132,111, 131,110, 131,109"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="128,107, 129,108, 130,109, 129,109, 127,108, 127,108, 127,107"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="109,100, 109,101, 118,101, 121,103, 123,103, 124,105, 124,105, 124,106, 120,104, 115,103, 112,102, 108,101, 107,101, 105,100, 106,99"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9" coords="247,38, 247,43, 245,43, 245,38, 247,37"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <area shape="poly" alt="9"
                            coords="266,2, 265,6, 260,9, 259,10, 259,11, 258,14, 258,15, 256,20, 255,23, 255,26, 253,27, 253,30, 259,34, 258,35, 255,34, 249,34, 251,27, 253,24, 254,21, 257,15, 256,14, 257,14, 257,13, 257,12, 258,9, 259,9, 260,7, 261,6, 262,4, 264,2"
                            onmouseover="mouseOver(9)" onmouseout="mouseOut(9)" (click)="createDamage(9)">
                        <!-- 10 -->
                        <area shape="poly" alt="10"
                            coords="49,207, 50,211, 51,213, 57,216, 62,218, 64,218, 66,219, 72,219, 95,219, 94,223, 84,223, 71,224, 45,225, 28,224, 27,223, 22,220, 11,216, 11,216, 11,214, 16,211, 17,210, 18,208, 20,208, 26,207, 37,206"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <area shape="poly" alt="10"
                            coords="181,2, 182,3, 194,3, 195,5, 203,11, 210,21, 210,23, 211,23, 211,27, 211,33, 210,41, 210,44, 209,45, 185,45, 185,44, 178,43, 176,42, 174,42, 174,38, 175,32, 174,22, 169,15, 162,9, 155,5, 153,4, 150,3, 146,3, 143,3, 144,2"
                            onmouseover="mouseOver(10)" onmouseout="mouseOut(10)" (click)="createDamage(10)">
                        <!-- 11 -->
                        <area shape="poly" alt="11"
                            coords="81,276, 82,276, 94,277, 95,280, 65,281, 64,282, 58,283, 51,287, 50,289, 49,291, 49,293, 20,292, 19,292, 17,291, 17,290, 15,287, 13,287, 11,284, 10,284, 16,282, 19,280, 26,278, 29,275"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <area shape="poly" alt="11"
                            coords="82,100, 82,101, 73,101, 72,102, 67,104, 56,112, 50,119, 50,121, 49,123, 50,138, 50,139, 50,140, 43,141, 42,141, 39,143, 39,143, 38,143, 36,143, 14,143, 13,139, 7,139, 7,129, 8,128, 9,126, 8,125, 9,126, 10,124, 13,124, 14,119, 23,108, 27,104, 29,103, 30,101, 32,101, 50,100, 63,100"
                            onmouseover="mouseOver(11)" onmouseout="mouseOut(11)" (click)="createDamage(11)">
                        <!-- 14 -->
                        <area shape="poly" alt="14" coords="156,64, 156,67, 72,68, 70,69, 70,69, 70,65, 101,64"
                            onmouseover="mouseOver(14)" onmouseout="mouseOut(14)" (click)="createDamage(14)">
                        <!-- 15 -->
                        <area shape="poly" alt="15" coords="154,163, 154,167, 153,166, 151,165, 68,165, 68,162"
                            onmouseover="mouseOver(15)" onmouseout="mouseOut(15)" (click)="createDamage(15)">
                        <!-- 16 -->
                        <area shape="poly" alt="16"
                            coords="140,205, 141,206, 141,206, 142,207, 143,206, 150,207, 149,210, 147,212, 144,213, 143,213, 140,214, 139,214, 124,217, 118,218, 113,218, 97,219, 96,219, 96,215, 98,212, 101,206, 136,206, 136,205, 135,205, 136,204, 137,203"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="250,119, 251,124, 249,125, 248,125, 241,125, 242,119, 246,119"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="334,19, 336,20, 335,25, 334,25, 326,26, 326,25, 325,22, 327,20, 327,18"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <area shape="poly" alt="16"
                            coords="128,2, 127,17, 127,18, 126,29, 125,36, 125,64, 76,64, 75,56, 75,49, 74,36, 75,28, 75,27, 77,23, 87,15, 102,7, 107,5, 115,3, 120,3, 125,2"
                            onmouseover="mouseOver(16)" onmouseout="mouseOut(16)" (click)="createDamage(16)">
                        <!-- 17 -->
                        <area shape="poly" alt="17"
                            coords="117,282, 118,282, 126,283, 127,283, 132,284, 133,284, 141,286, 142,287, 145,287, 145,288, 146,287, 149,290, 150,291, 150,293, 141,294, 141,294, 137,296, 135,296, 136,294, 136,294, 135,293, 127,294, 100,293, 99,292, 96,284, 96,283, 96,281"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="335,119, 336,120, 335,125, 329,125, 327,125, 326,121, 327,120, 327,119"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="107,101, 108,101, 113,102, 115,103, 121,104, 131,109, 147,121, 149,124, 149,128, 150,132, 148,162, 147,162, 98,162, 99,153, 98,129, 97,120, 97,109, 96,108, 96,100"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <area shape="poly" alt="17"
                            coords="250,19, 251,21, 252,22, 253,22, 251,26, 246,26, 244,25, 241,25, 242,19, 244,19, 249,18"
                            onmouseover="mouseOver(17)" onmouseout="mouseOut(17)" (click)="createDamage(17)">
                        <!-- 18 -->
                        <area shape="poly" alt="18"
                            coords="100,207, 100,209, 97,215, 95,219, 63,218, 62,218, 54,215, 50,213, 49,209, 49,207, 51,206"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18" coords="162,48, 162,48" onmouseover="mouseOver(18)"
                            onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18" coords="163,47, 162,48, 162,48, 162,48" onmouseover="mouseOver(18)"
                            onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <area shape="poly" alt="18"
                            coords="147,3, 148,3, 154,4, 155,5, 159,6, 172,18, 174,23, 175,25, 174,38, 174,39, 174,42, 166,44, 161,49, 158,52, 156,59, 156,62, 155,64, 130,64, 126,64, 127,18, 127,17, 128,6, 128,3, 129,2"
                            onmouseover="mouseOver(18)" onmouseout="mouseOut(18)" (click)="createDamage(18)">
                        <!-- 19 -->
                        <area shape="poly" alt="19"
                            coords="95,281, 96,282, 99,290, 99,292, 99,294, 66,293, 49,293, 50,288, 52,286, 59,283, 65,281, 74,280"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <area shape="poly" alt="19"
                            coords="96,101, 96,108, 97,115, 97,118, 98,127, 98,162, 68,161, 67,153, 66,152, 64,148, 61,144, 58,142, 51,140, 50,140, 50,135, 49,128, 50,119, 54,114, 61,107, 68,103, 76,101, 92,100"
                            onmouseover="mouseOver(19)" onmouseout="mouseOut(19)" (click)="createDamage(19)">
                        <!-- 20 -->
                        <area shape="poly" alt="20"
                            coords="331,64, 331,82, 328,83, 320,82, 319,80, 320,66, 328,66, 331,64"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <area shape="poly" alt="20"
                            coords="54,48, 55,48, 59,50, 64,55, 67,62, 66,71, 66,72, 64,75, 63,76, 59,80, 52,83, 43,83, 39,80, 38,79, 35,77, 35,76, 34,75, 32,71, 31,68, 32,59, 32,58, 34,55, 40,50, 44,48, 50,47, 52,48"
                            onmouseover="mouseOver(20)" onmouseout="mouseOut(20)" (click)="createDamage(20)">
                        <!-- 21 -->
                        <area shape="poly" alt="21"
                            coords="180,146, 181,147, 185,148, 186,149, 188,151, 190,153, 190,154, 191,154, 193,160, 192,169, 192,170, 189,175, 185,178, 182,180, 174,181, 170,181, 169,180, 165,178, 161,175, 161,174, 160,173, 158,169, 157,166, 158,157, 158,156, 161,152, 167,147, 172,146, 174,145"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <area shape="poly" alt="21"
                            coords="247,64, 250,66, 258,67, 257,82, 255,83, 246,82, 246,81, 246,64"
                            onmouseover="mouseOver(21)" onmouseout="mouseOut(21)" (click)="createDamage(21)">
                        <!-- 22 -->
                        <area shape="poly" alt="22" coords="258,164, 257,182, 256,182, 246,182, 246,164"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <area shape="poly" alt="22"
                            coords="180,48, 181,48, 183,49, 187,52, 188,53, 190,55, 190,56, 191,56, 193,62, 192,72, 188,78, 183,82, 178,83, 170,83, 169,82, 164,79, 161,78, 162,77, 160,76, 158,72, 157,67, 158,59, 162,53, 166,50, 172,48, 175,47"
                            onmouseover="mouseOver(22)" onmouseout="mouseOut(22)" (click)="createDamage(22)">
                        <!-- 23 -->
                        <area shape="poly" alt="23" coords="331,164, 331,182, 328,182, 319,182, 320,164"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="54,146, 58,148, 61,150, 63,152, 66,158, 67,162, 66,169, 66,170, 64,174, 59,178, 53,181, 51,181, 43,181, 42,180, 39,178, 35,175, 32,169, 31,167, 32,157, 34,153, 40,148, 44,146, 50,145, 52,146"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                        <area shape="poly" alt="23"
                            coords="329,124, 330,125, 330,147, 319,146, 320,135, 323,137, 327,133, 328,128, 329,124"
                            onmouseover="mouseOver(23)" onmouseout="mouseOut(23)" (click)="createDamage(23)">
                    </map>
                </div>

                <div>
                    <map name="imgmap_top">
                        <!-- compleet -->
                        <area shape="poly" alt=""
                            coords="125,26, 126,27, 125,31, 118,36, 119,39, 121,42, 121,45, 134,44, 148,44, 157,43, 158,41, 161,40, 180,40, 181,39, 188,40, 192,40, 197,41, 202,42, 208,42, 211,43, 214,43, 217,44, 221,46, 222,48, 223,57, 228,60, 227,68, 217,74, 201,77, 200,76, 198,77, 198,81, 193,87, 190,89, 185,91, 178,90, 170,85, 166,79, 162,81, 77,81, 76,80, 72,81, 71,83, 65,89, 63,89, 60,91, 52,90, 44,85, 40,80, 21,80, 19,79, 17,79, 18,76, 15,75, 16,73, 15,70, 14,66, 13,64, 14,60, 17,56, 23,52, 34,49, 36,48, 40,48, 42,47, 46,47, 48,46, 51,46, 57,45, 64,44, 68,44, 75,43, 89,43, 96,39, 100,37, 123,25"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                        <area shape="poly" alt=""
                            coords="192,200, 193,201, 202,201, 206,206, 211,208, 220,214, 222,218, 223,220, 225,223, 227,237, 227,262, 226,263, 226,266, 224,270, 221,276, 210,285, 205,288, 202,291, 189,292, 178,292, 168,292, 166,291, 164,291, 162,290, 147,291, 71,291, 70,292, 46,291, 45,291, 39,290, 33,287, 27,286, 20,280, 17,275, 16,272, 15,268, 15,266, 14,263, 14,261, 13,256, 14,231, 14,230, 17,215, 26,206, 30,206, 31,205, 33,205, 40,201, 59,200, 71,201, 72,201, 75,201, 100,201, 149,201, 166,201, 167,200, 172,200"
                            onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                    </map>
                </div>

                <div style="position:relative; top:0px; left:0px" id="papa" usemap="#imgmap_top">
                    <img id="outline" src="../../../assets/images/schadespecificatie/terreinwagen/outline.gif"
                        alt="Selecteer met de muis de beschadigde onderdelen van uw auto" usemap="#imgmap" />
                    <img id="overlay_1" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/1.gif" usemap="#imgmap" />
                    <img id="overlay_2" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/2.gif" usemap="#imgmap" />
                    <img id="overlay_3" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/3.gif" usemap="#imgmap" />
                    <img id="overlay_4" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/4.gif" usemap="#imgmap" />
                    <img id="overlay_5" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/5.gif" usemap="#imgmap" />
                    <img id="overlay_6" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/6.gif" usemap="#imgmap" />
                    <img id="overlay_7" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/7.gif" usemap="#imgmap" />
                    <img id="overlay_8" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/8.gif" usemap="#imgmap" />
                    <img id="overlay_9" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/9.gif" usemap="#imgmap" />
                    <img id="overlay_10" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/10.gif"
                        usemap="#imgmap" />
                    <img id="overlay_11" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/11.gif"
                        usemap="#imgmap" />
                    <img id="overlay_12" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/12.gif"
                        usemap="#imgmap" />
                    <img id="overlay_13" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/13.gif"
                        usemap="#imgmap" />
                    <img id="overlay_14" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/14.gif"
                        usemap="#imgmap" />
                    <img id="overlay_15" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/15.gif"
                        usemap="#imgmap" />
                    <img id="overlay_16" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/16.gif"
                        usemap="#imgmap" />
                    <img id="overlay_17" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/17.gif"
                        usemap="#imgmap" />
                    <img id="overlay_18" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/18.gif"
                        usemap="#imgmap" />
                    <img id="overlay_19" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/19.gif"
                        usemap="#imgmap" />
                    <img id="overlay_20" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/20.gif"
                        usemap="#imgmap" />
                    <img id="overlay_21" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/21.gif"
                        usemap="#imgmap" />
                    <img id="overlay_22" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/22.gif"
                        usemap="#imgmap" />
                    <img id="overlay_23" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/mouseover/23.gif"
                        usemap="#imgmap" />
                    <img id="overlay_1_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/1.gif" usemap="#imgmap" />
                    <img id="overlay_2_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/2.gif" usemap="#imgmap" />
                    <img id="overlay_3_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/3.gif" usemap="#imgmap" />
                    <img id="overlay_4_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/4.gif" usemap="#imgmap" />
                    <img id="overlay_5_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/5.gif" usemap="#imgmap" />
                    <img id="overlay_6_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/6.gif" usemap="#imgmap" />
                    <img id="overlay_7_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/7.gif" usemap="#imgmap" />
                    <img id="overlay_8_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/8.gif" usemap="#imgmap" />
                    <img id="overlay_9_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/9.gif" usemap="#imgmap" />
                    <img id="overlay_10_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/10.gif" usemap="#imgmap" />
                    <img id="overlay_11_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/11.gif" usemap="#imgmap" />
                    <img id="overlay_12_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/12.gif" usemap="#imgmap" />
                    <img id="overlay_13_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/13.gif" usemap="#imgmap" />
                    <img id="overlay_14_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/14.gif" usemap="#imgmap" />
                    <img id="overlay_15_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/15.gif" usemap="#imgmap" />
                    <img id="overlay_16_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/16.gif" usemap="#imgmap" />
                    <img id="overlay_17_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/17.gif" usemap="#imgmap" />
                    <img id="overlay_18_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/18.gif" usemap="#imgmap" />
                    <img id="overlay_19_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/19.gif" usemap="#imgmap" />
                    <img id="overlay_20_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/20.gif" usemap="#imgmap" />
                    <img id="overlay_21_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/21.gif" usemap="#imgmap" />
                    <img id="overlay_22_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/22.gif" usemap="#imgmap" />
                    <img id="overlay_23_active" class="overlay"
                        src="../../../assets/images/schadespecificatie/terreinwagen/active/23.gif" usemap="#imgmap" />
                </div>
            </div>
        </div>

        <div>
            <map name="imgmap_top">
                <!-- compleet -->
                <area shape="poly" alt=""
                    coords="123,25, 124,27, 123,30, 116,36, 117,38, 119,41, 119,44, 132,44, 145,43, 155,42, 156,40, 158,40, 177,39, 178,39, 185,39, 188,40, 194,40, 198,41, 204,41, 207,42, 211,43, 213,43, 217,45, 219,47, 219,56, 224,59, 224,67, 214,72, 198,76, 197,75, 195,76, 194,80, 189,86, 187,87, 182,89, 175,89, 167,83, 163,78, 160,80, 76,80, 75,79, 71,80, 70,81, 64,87, 62,88, 59,89, 52,89, 44,83, 40,79, 20,78, 19,78, 17,77, 17,75, 15,74, 16,72, 15,68, 13,65, 13,63, 13,59, 16,55, 22,51, 34,48, 36,48, 39,47, 41,47, 45,46, 47,45, 50,45, 56,44, 63,44, 67,43, 74,43, 88,42, 95,38, 98,36, 120,25"
                    onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
                <area shape="poly" alt=""
                    coords="188,197, 189,197, 199,198, 203,202, 208,204, 216,211, 218,214, 220,216, 221,220, 224,233, 223,258, 222,259, 222,262, 220,265, 217,271, 207,280, 202,283, 198,286, 186,287, 175,287, 165,287, 164,286, 161,286, 159,285, 145,286, 70,286, 69,287, 45,286, 44,286, 38,285, 33,282, 26,281, 20,276, 17,270, 16,267, 15,264, 15,262, 14,258, 13,256, 13,252, 13,227, 14,226, 17,212, 26,203, 29,202, 30,202, 33,201, 39,197, 58,197, 70,197, 71,198, 74,198, 99,197, 147,198, 163,197, 164,197, 169,196"
                    onmouseover="showMousePointer()" onmouseout="hideMousePointer()">
            </map>
        </div>
    </div>

    <div fxFlex="50" style="padding-bottom: 20px">
        <form [formGroup]="form">
            <mat-form-field appearance="fill">
                <mat-label>Opmerkingen beschadigingen en technische gebreken</mat-label>
                <textarea (change)="updateForm()" formControlName="vs_commentTechnicalDefects" matInput
                    rows="4"></textarea>
            </mat-form-field>
        </form>

        <table class="mat-elevation-z8 striped-table">
            <thead>
                <tr class="first-header-row">
                    <th> Onderdeel </th>
                    <th> Kosten </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="center-align" style="vertical-align: middle;">
                        <form [formGroup]="form" style="margin-bottom: -20px;">
                            <mat-form-field appearance="fill" style="width: 80%; margin: 0 auto;">
                                <mat-label style="text-align:center;">Overige kosten</mat-label>
                                <input (change)="updateForm()" formControlName="ds_givenRepairCosts" min="0"
                                    pattern="[0-9]*" type="number" matInput placeholder="Opknapkosten"
                                    style="text-align:center;">
                                <span matSuffix>&euro;</span>
                            </mat-form-field>
                        </form>
                    </td>
                    <td class="center-align"> {{ form.controls['ds_givenRepairCosts'].value | currency:'EUR' }} </td>
                </tr>

                <tr *ngFor="let damage of damages" class="mat-row">
                    <td>
                        <button (click)="deleteDamage(damage.id)" mat-icon-button color="warn"
                            matTooltip="Verwijder schade">
                            <mat-icon>delete</mat-icon>
                        </button>

                        <button (click)="editDamage(damage.id)" mat-icon-button color="primary"
                            matTooltip="Bewerk schade">
                            <mat-icon>edit</mat-icon>
                        </button>

                        <span class="center-align">
                            {{ damage.name | capitalize }} {{damage.repair?.name}}
                        </span>
                    </td>
                    <td class="center-align"> {{ damage.repairCosts | currency:'EUR' }} </td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="last-footer-row">
                    <td class="center-align"><b>Totaal:</b>&nbsp;</td>
                    <td class="center-align"> <b>{{ getTotalCost() | currency:'EUR' }}</b> </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

<div fxLayout="row" style="padding-top: 20px;">
    <div fxLayout="column" fxFlex="100">
        <h2>Schade foto's uploaden</h2>
        <mat-divider style="margin-top: unset;" class="divider m-b-10"></mat-divider>
        <p>Voeg foto's toe van schades</p>
        <div fxLayout="column" fxFlex="100">
            <ngx-awesome-uploader *ngIf="adapter" #uploader [fileMaxCount]="100" [fileMaxSize]="20" [totalMaxSize]="100"
                [uploadType]="'multi'" [adapter]="adapter" [fileExtensions]="['jpg', 'png', 'PNG']"
                [enableCropper]="false" [showeDragDropZone]="true" [showPreviewContainer]="true"
                [enableAutoUpload]="true" [captions]="captions"></ngx-awesome-uploader>
        </div>
    </div>
</div>