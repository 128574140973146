import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VehicleDescription, VehicleDescriptionDamage } from 'src/app/models/vehicle-description';
import { ApiService } from 'src/app/services/api/api.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { SchadeToevoegenComponent } from './modals/schade-toevoegen/schade-toevoegen.component';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { FilePickerComponent, FilePreviewModel, UploaderCaptions } from 'ngx-awesome-uploader';
import { SpecsFilePickerAdapter } from '../templates/template-108/file-upload-adapter';
import { VehicleSpecsService } from 'src/app/services/vehicle-specs/vehicle-specs.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';

export interface Damage {
  id: number
  name: string
  part: { name: string, id: number },
  damage?: { name: string, id: number },
  repair?: { name: string, id: number },
  repairCosts: number
};

export interface DamageOption {
  id: string,
  descriptionNl: string
}

@Component({
  selector: 'app-damage',
  templateUrl: './damage.component.html',
  styleUrls: ['./damage.component.scss']
})

export class DamageComponent implements OnInit {
  @Input() vehicleDescriptionId: number | undefined;
  @Input() vehicleType: string | undefined;
  @Input() fields!: VehicleDescription;
  @Output() formChange = new EventEmitter<any>();
  loading = false;

  damageParts = [
    { value: 7, label: 'Achterbumper' },
    { value: 18, label: 'Achterdeur/ruit links' },
    { value: 19, label: 'Achterdeur/ruit rechts' },
    { value: 2, label: 'Achterklep' },
    { value: 13, label: 'Achterlampen' },
    { value: 5, label: 'Achterruit' },
    { value: 3, label: 'Dak' },
    { value: 14, label: 'Dorpel links' },
    { value: 15, label: 'Dorpel rechts' },
    { value: 1, label: 'Motorkap' },
    { value: 10, label: 'Paneel linksachter' },
    { value: 8, label: 'Paneel linksvoor' },
    { value: 11, label: 'Paneel rechtsachter' },
    { value: 9, label: 'Paneel rechtsvoor' },
    { value: 6, label: 'Voorbumper/grille' },
    { value: 16, label: 'Voordeur/ruit links' },
    { value: 17, label: 'Voordeur/ruit rechts' },
    { value: 12, label: 'Voorlampen' },
    { value: 4, label: 'Voorruit' },
    { value: 30, label: 'Dashboard' },
    { value: 31, label: 'Bekleding' }
  ];

  selectedOption: { value: number; label: string; } | undefined;
  filteredDamageParts = this.damageParts;
  damagePartSearchInput: string | undefined;

  @ViewChild('uploader') uploader!: FilePickerComponent;
  adapter: SpecsFilePickerAdapter | undefined;
  public captions: UploaderCaptions = {
    dropzone: {
      title: 'Sleep afbeeldingen om te uploaden',
      or: 'Of klik op "browsen" om bestanden te selecteren.',
      browse: 'Browsen'
    },
    cropper: {
      crop: 'Knippen',
      cancel: 'Annuleren'
    },
    previewCard: {
      remove: 'Verwijderen',
      uploadError: 'Er is een fout opgetreden tijdens het uploaden'
    }
  };

  damages: Damage[] = [];

  public form: FormGroup = new FormGroup({
    vs_commentTechnicalDefects: new FormControl('', null),
    ds_repairCosts: new FormControl(0, []),
    ds_givenRepairCosts: new FormControl(350, []),
  });

  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService,
    private mtxDialog: MtxDialog,
    private vehicleSpecs: VehicleSpecsService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.getAndSetDamages();
    // Init file uploader
    this.adapter = new SpecsFilePickerAdapter(this.vehicleSpecs, Number(this.vehicleDescriptionId), 'damage');
    this.setDamageImages();
  }

  filterDamageParts(event: any) {
    if (event !== "" && isNaN(event)) {
      const filterValue = event.toLowerCase();
      this.filteredDamageParts = this.damageParts.filter(option => option.label.toLowerCase().includes(filterValue));
    }
  }

  private getAndSetDamages() {
    // Add existing damages
    const damages = this.utilsService.getSingleFieldFromSpec(this.fields, 'vs_damages')?.value
    if (damages) {
      for (const damage of damages) {
        const damageObj: Damage = {
          id: this.utilsService.getSingleFieldFromDamage(damage, 'id')?.value,
          name: this.utilsService.getSingleFieldFromDamage(damage, 'd_partTypeId')?.descriptionNl,
          part: { id: this.utilsService.getSingleFieldFromDamage(damage, 'd_partTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(damage, 'd_partTypeId')?.descriptionNl },
          damage: { id: this.utilsService.getSingleFieldFromDamage(damage, 'd_damageTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(damage, 'd_damageTypeId')?.descriptionNl },
          repair: { id: this.utilsService.getSingleFieldFromDamage(damage, 'd_repairTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(damage, 'd_repairTypeId')?.descriptionNl },
          repairCosts: this.utilsService.getSingleFieldFromDamage(damage, 'd_repairCosts')?.value,
        }
        // Set view data
        this.damages.push(damageObj);
      }
    }

    const totalRepairCosts = this.utilsService.getSingleFieldFromSpec(this.fields, 'ds_repairCosts')?.value
    const totalCostsDamages = this.damages.map(t => t.repairCosts).reduce((acc, value) => acc + value, 0);

    if (totalRepairCosts - totalCostsDamages > 0) {
      this.form.controls['ds_givenRepairCosts'].setValue(totalRepairCosts - totalCostsDamages);
    }
  }

  private setDamageImages() {
    const images = this.utilsService.getVehicleDamageImages(this.fields);

    let files = [] as unknown as FilePreviewModel[];
    // Check if field.value exists and is an array before proceeding
    if (Array.isArray(images) && images.length > 0) {
      const downloadAndPushImage = async (url: string) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          files.push({ fileName: url, file: blob, uploadResponse: null });
        } catch (error) {
          console.error('Error downloading image:', error);
        }
      };

      const downloadPromises = images.map((image: any) => downloadAndPushImage(image.image));
      Promise.all(downloadPromises).then(() => {
        setTimeout(() => {
          this.uploader.enableAutoUpload = false;
          this.uploader.setFiles(files);
          this.uploader.enableAutoUpload = true;
        }, 1000);
      });
    }
  }

  // Gets the total cost of all damages
  public getTotalCost() {
    const totalCost = this.damages.map(t => t.repairCosts).reduce((acc, value) => acc + value, this.form.get('ds_givenRepairCosts')?.value);
    this.form.controls['ds_repairCosts'].setValue(totalCost);
    return totalCost;
  }

  public updateForm() {
    this.formChange.emit({ form: this.form, totalCosts: this.getTotalCost() });
  }

  public createDamage(damagePartId: any) {
    this.damagePartSearchInput = "";
    this.selectedOption = undefined;

    this.loading = true;
    const data: VehicleDescriptionDamage = {
      d_partTypeId: damagePartId
    }

    this.apiService.addVehicleDescriptionDamage(Number(this.vehicleDescriptionId), data).subscribe(data => {
      this.loading = false;
      if (data.error == false) {
        this.mtxDialog.open({
          width: '600px',
          data: { type: 'add', damagePartId: damagePartId, vehicleDescriptionId: this.vehicleDescriptionId, damageData: data.data }
        }, SchadeToevoegenComponent).afterClosed().subscribe(result => {
          if (result) {
            this.form.controls['ds_givenRepairCosts'].setValue(0);
            const damageObj: Damage = {
              id: this.utilsService.getSingleFieldFromDamage(result, 'id')?.value,
              name: this.utilsService.getSingleFieldFromDamage(result, 'd_partTypeId')?.descriptionNl,
              part: { id: this.utilsService.getSingleFieldFromDamage(result, 'd_partTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(result, 'd_partTypeId')?.descriptionNl },
              damage: { id: this.utilsService.getSingleFieldFromDamage(result, 'd_damageTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(result, 'd_damageTypeId')?.descriptionNl },
              repair: { id: this.utilsService.getSingleFieldFromDamage(result, 'd_repairTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(result, 'd_repairTypeId')?.descriptionNl },
              repairCosts: this.utilsService.getSingleFieldFromDamage(result, 'd_repairCosts')?.value,
            }
            // Set view data
            this.damages.push(damageObj);
          }
        });
      }
    })
  }

  public editDamage(damagePartId: number) {
    this.loading = false;

    // We have to find the specific damage object
    const damages = this.utilsService.getSingleFieldFromSpec(this.fields, 'vs_damages')?.value

    let damageObj = null;
    for (const damage of damages) {
      if (this.utilsService.getSingleFieldFromDamage(damage, 'id')?.value == damagePartId) {
        damageObj = damage;
      }
    }

    if (damageObj) {
      this.mtxDialog.open({
        width: '600px',
        data: { type: 'edit', damagePartId: damagePartId, vehicleDescriptionId: this.vehicleDescriptionId, damageData: damageObj }
      }, SchadeToevoegenComponent).afterClosed().subscribe(result => {
        if (result) {
          const damageObj: Damage = {
            id: this.utilsService.getSingleFieldFromDamage(result, 'id')?.value,
            name: this.utilsService.getSingleFieldFromDamage(result, 'd_partTypeId')?.descriptionNl,
            part: { id: this.utilsService.getSingleFieldFromDamage(result, 'd_partTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(result, 'd_partTypeId')?.descriptionNl },
            damage: { id: this.utilsService.getSingleFieldFromDamage(result, 'd_damageTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(result, 'd_damageTypeId')?.descriptionNl },
            repair: { id: this.utilsService.getSingleFieldFromDamage(result, 'd_repairTypeId')?.value, name: this.utilsService.getSingleFieldFromDamage(result, 'd_repairTypeId')?.descriptionNl },
            repairCosts: this.utilsService.getSingleFieldFromDamage(result, 'd_repairCosts')?.value,
          }
          // Set view data
          this.damages.push(damageObj);
        }
      });
    }
  }

  public deleteDamage(damageId: number) {
    this.loading = true;
    this.apiService.deleteVehicleDescriptionDamage(Number(this.vehicleDescriptionId), damageId).subscribe(data => {
      this.loading = false;
      if (data.error == false) {
        // Detete from damages array
        const damage = this.findDamageById(damageId);
        if (damage) {
          this.damages.splice(damage.index, 1);
        }
      }
    })
  }

  private findOptionById(options: [any], id: number) {
    for (const item of options) {
      if (item.id == id) {
        return item;
      }
    }
    return null;
  }

  private findDamageById(id: number) {
    let index = 0;
    for (const item of this.damages) {
      if (item.id == id) {
        return { index: index, item: item };
      }
      index++;
    }
    return null;
  }

  public parseVehicleType(vehicleType: string | undefined) {
    switch (vehicleType) {
      // Bestelauto
      case "bestelauto":
        return 'bestelauto';
      // Bestelbus
      case "bestelbus":
      case "gesloten bestel dubbele cabin":
      case "gesloten bestel enkele cabin":
      case "bakwagen":
        return 'bestelbus'
      // Cabrio
      case "cabrio":
      case "cabriolet":
        return 'cabriolet'
      // Chassis
      case "chassis cabine":
      case "chassis dubbele cabine":
      case "chassis enkele cabine":
      case "bestelwagen chassis enkele cabine":
      case "bestelwagen chassis dubbele cabine":
        return 'chassis'
      // Coupe
      case "coupe":
        return 'coupe'
      // Hatchback
      case "hatchback":
      case "hatchback 3":
      case "hatchback 5":
        return 'hatchback'
      // Mpv
      case "mpv":
        return 'mpv'
      // Open laadbak
      case "open laadbak":
        return 'open laadbak'
      // Personenvervoer
      case "personenvervoer":
      case "kampeervoertuig":
      case "minibus":
        return 'personenvervoer'
      // Pick-up
      case "pick-up":
      case "pickup":
      case "pick-up anderhalve cabine":
      case "pick-up dubbele cabine":
      case "pick-up enkele cabine":
        return 'pick-up'
      // Sedan
      case "sedan":
      case "sedan 2":
        return 'sedan'
      // Stationwagon
      case "Stationwagon":
      case "stationwagen":
      case "station":
        return 'stationwagon'
      // Terreinwagen
      case "terreinwagon":
      case "terreinwagen":
      case "suv 3":
      case "suv 5":
      case "targa":
        return 'terreinwagen'
    }
    return null;
  }
}