<div style="height: 100vh; display: flex; align-items: center; justify-content: center;">
    <mat-card class="maintenance-card" style="max-width: 400px; width: 100%; text-align: center;">
        <!-- Logo added here -->
        <img src="../../../assets/images/logo.jpg" alt="Company Logo" style="padding-left: 20px; width: 200px; height: auto; margin-top: 20px;">

        <mat-card-header>
            <mat-card-title>Onderhoudsmodus</mat-card-title>
        </mat-card-header>
        <mat-card-content class="card-content">
            <!-- Maintenance image -->
            <img src="../../../assets/images/maintenance.png" alt="Maintenance" style="width: 90%; height: auto; margin-bottom: 20px;">
            <p>
                We voeren momenteel gepland onderhoud uit. We zijn binnenkort weer online.
            </p>
        </mat-card-content>
        <!-- Reload page button -->
        <mat-card-actions>
            <button color="primary" mat-button (click)="reloadPage()">Pagina herladen</button>
        </mat-card-actions>
    </mat-card>
</div>
