import { FilePondOptions } from 'filepond';

export const pondOptions: FilePondOptions = {
    labelIdle: 'Sleep een foto om te uploaden of <span class="filepond--label-action"> <b>selecteren...</b> </span>',
    labelInvalidField: 'Dit zijn geen geldige bestanden.',
    labelFileWaitingForSize: 'Wachten op formaat...',
    labelFileLoading: 'Laden...',
    labelFileLoadError: 'Er is een fout opgetreden.',
    labelFileProcessingComplete: 'Upload voltooid.',
    labelFileProcessingAborted: 'Upload geannuleerd.',
    labelFileProcessingError: 'Er is een upload fout opgetreden.',
    labelFileProcessing: 'Uploaden...',
    labelFileRemoveError: 'Er is een fout opgetreden bij verwijderen.',
    labelTapToCancel: 'Klik om te annuleren.',
    labelTapToRetry: 'Klik om opnieuw te proberen.',
    labelButtonRemoveItem: 'Verwijderen',
    labelButtonAbortItemLoad: 'Annuleren',
    labelButtonRetryItemLoad: 'Opnieuw proberen',
    labelButtonAbortItemProcessing: 'Upload annuleren',
    labelButtonUndoItemProcessing: 'Ongedaan maken',
    labelButtonRetryItemProcessing: 'Opnieuw proberen',
    labelButtonProcessItem: 'Uploaden',
    labelTapToUndo: 'Klik om te annuleren.',
    credits: false,
    acceptedFileTypes: ['image/jpeg', 'image/png', 'image/heic'],
    maxFileSize: '15MB',
    labelMaxFileSizeExceeded: 'Dit bestand is te groot.',
    labelMaxFileSize: 'Maximaal bestandsgrootte is {filesize}.',
  }

  export const pondOptionsMultiple: FilePondOptions = {
    labelIdle: 'Sleep een foto om te uploaden of <span class="filepond--label-action"> <b>selecteren...</b> </span>',
    labelInvalidField: 'Dit zijn geen geldige bestanden.',
    labelFileWaitingForSize: 'Wachten op formaat...',
    labelFileLoading: 'Laden...',
    labelFileLoadError: 'Er is een fout opgetreden.',
    labelFileProcessingComplete: 'Upload voltooid.',
    labelFileProcessingAborted: 'Upload geannuleerd.',
    labelFileProcessingError: 'Er is een upload fout opgetreden.',
    labelFileProcessing: 'Uploaden...',
    labelFileRemoveError: 'Er is een fout opgetreden bij verwijderen.',
    labelTapToCancel: 'Klik om te annuleren.',
    labelTapToRetry: 'Klik om opnieuw te proberen.',
    labelButtonRemoveItem: 'Verwijderen',
    labelButtonAbortItemLoad: 'Annuleren',
    labelButtonRetryItemLoad: 'Opnieuw proberen',
    labelButtonAbortItemProcessing: 'Upload annuleren',
    labelButtonUndoItemProcessing: 'Ongedaan maken',
    labelButtonRetryItemProcessing: 'Opnieuw proberen',
    labelButtonProcessItem: 'Uploaden',
    labelTapToUndo: 'Klik om te annuleren.',
    credits: false,
    allowMultiple: true,
    instantUpload: true,
    allowProcess: true,
    acceptedFileTypes: ['image/jpeg', 'image/png', 'image/heic'],
    maxFileSize: '15MB',
    labelMaxFileSizeExceeded: 'Dit bestand is te groot.',
    labelMaxFileSize: 'Maximaal bestandsgrootte is {filesize}.',
  }

  export const pondOptionsMultipleWithPreview: FilePondOptions = {
    labelIdle: 'Sleep een foto om te uploaden of <span class="filepond--label-action"> <b>selecteren...</b> </span>',
    labelInvalidField: 'Dit zijn geen geldige bestanden.',
    labelFileWaitingForSize: 'Wachten op formaat...',
    labelFileLoading: 'Laden...',
    labelFileLoadError: 'Er is een fout opgetreden.',
    labelFileProcessingComplete: 'Upload voltooid.',
    labelFileProcessingAborted: 'Upload geannuleerd.',
    labelFileProcessingError: 'Er is een upload fout opgetreden.',
    labelFileProcessing: 'Uploaden...',
    labelFileRemoveError: 'Er is een fout opgetreden bij verwijderen.',
    labelTapToCancel: 'Klik om te annuleren.',
    labelTapToRetry: 'Klik om opnieuw te proberen.',
    labelButtonRemoveItem: 'Verwijderen',
    labelButtonAbortItemLoad: 'Annuleren',
    labelButtonRetryItemLoad: 'Opnieuw proberen',
    labelButtonAbortItemProcessing: 'Upload annuleren',
    labelButtonUndoItemProcessing: 'Ongedaan maken',
    labelButtonRetryItemProcessing: 'Opnieuw proberen',
    labelButtonProcessItem: 'Uploaden',
    labelTapToUndo: 'Klik om te annuleren.',
    credits: false,
    allowMultiple: true,
    instantUpload: true,
    allowProcess: true,
    acceptedFileTypes: ['image/jpeg', 'image/png', 'image/heic'],
    maxFileSize: '15MB',
    labelMaxFileSizeExceeded: 'Dit bestand is te groot.',
    labelMaxFileSize: 'Maximaal bestandsgrootte is {filesize}.',
    allowImagePreview: true,
    allowImageEdit: true,
    imagePreviewMarkupShow: true,
    allowFilePoster: true,
    className: 'my-filepond'
  }
