import { Component, Input, OnInit } from '@angular/core';
import { StartVehicleDescription } from 'src/app/models/vehicle-description';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-template-default',
  templateUrl: './template-default.component.html',
  styleUrls: ['./template-default.component.scss']
})
export class TemplateDefaultComponent implements OnInit {
  @Input() template!: number;
  @Input() token!: string;
  @Input() parameters!: any;
  
  loading = false;

  constructor(
    private apiService: ApiService,
    private auth: AuthenticationService) { }

  ngOnInit(): void {

  }

  next() {
    this.loading = true;
    const requestData: StartVehicleDescription = {
      fields: [],
      skipHistoricRequestCheck: true,
      pageDetails: true,
      pageName: 'Start'
    }
    this.apiService.startVehicleDescription(requestData).subscribe(response => {
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }
}
