<h2 mat-dialog-title><b>Historische aanvragen</b></h2>
<mat-dialog-content>
    <p><b>Let op:</b>&nbsp;Dit voertuig is eerder door u of een van uw collega's ter taxatie aangeboden bij Autotaxatie
        Partners. <br> Het betreft een {{data.historicRequests[0].Valuation.VehicleSpecification.Vehicle.make}}
        {{data.historicRequests[0].Valuation.VehicleSpecification.Vehicle.model}}.<br>
        Klik op het taxatienummer om de details te bekijken.</p>

    <mat-table [dataSource]="data.historicRequests">
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>Taxatienummer</mat-header-cell>
            <mat-cell *matCellDef="let request" style="color: #47B6A1;">{{request.Valuation.id}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef>Datum</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.created.date | date}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="person">
            <mat-header-cell *matHeaderCellDef>Contactpersoon</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.Employee?.Person.fullName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef>Vestiging</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.Company?.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="registration">
            <mat-header-cell *matHeaderCellDef>Kenteken</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.Valuation.VehicleSpecification.Vehicle.registration |
                registration: request.Valuation.VehicleSpecification.Vehicle.registration }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="statusType">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let request">{{request.Valuation.LeadStatusType.descriptionNl |
                capitalize}}</mat-cell>
        </ng-container>

        <!-- Display the table header row -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <!-- Display the table data rows -->
        <mat-row (click)="navigateToDashboard(row.Valuation.id)"
            *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <hr>

    <br><br>
    <p>Wilt u dit voertuig opnieuw aanbieden aan Autotaxatie Partners? Klik dan op de knop <b>Ga verder</b>.</p>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons" align="start">
    <button mat-raised-button color="warn" (click)="onNoClick()" mat-dialog-close>Annuleren</button>
    <button mat-raised-button color="primary" (click)="onYesClick()" mat-button>Ga verder</button>
</mat-dialog-actions>