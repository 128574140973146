export interface authModel {
    save_login: boolean;
    access_token: string;
    access_token_expires: Date;
    refresh_token: string;
    refresh_token_expires: Date;
    api_key?: string;
    api_key_expires?: Date;
    device_token?: string;
    username: string;
    level: string
}

export interface authLocalStorageModel {
    access_token: string;
    access_token_expires: string;
    device_token: string;
    refresh_token: string;
    refresh_token_expires: string;
}

export interface apiAuthModel {
    access_token: string;
    expires_in: number;
    token_type: string;
    refresh_token: string;
    expires: {
        date: string,
        timezone_type: number,
        timezone: string
    };
    refresh_token_expires: {
        date: string,
        timezone_type: number,
        timezone: string
    };
    one_time_password: boolean;
}

export interface apiKeyModel {
    expires: {
        date: Date,
        timezone_type: string,
        timezone: string
    }
    apiKey: string;
}

export interface storageApiKey {
    api_key: string;
    api_key_expires: Date;
}

export interface authUserModel {
    id: number;
    status: boolean;
    Company: {
        id: number;
        name: String;
        logo: string;
    }
    Companies: {
        id: number;
        name: string
    }
    Person: {
        id: number;
    }
    Employee: {
        id: number;
    }
}

export class authStorage {
    /**
     * @param auth The response of the api's token call
     * @param saveLogin 
     * @param deviceToken
     */
    static createapiAuth(auth: apiAuthModel, saveLogin: boolean, deviceToken: string, username: string, level: string) {
        const storageData = {
            save_login: saveLogin,
            access_token: auth.access_token,
            access_token_expires: auth.expires.date,
            refresh_token: auth.refresh_token,
            refresh_token_expires: auth.refresh_token_expires.date,
            device_token: deviceToken,
            username: username,
            level: level
        }
        return JSON.stringify(storageData);
    }

    static createapiKey(apikey: apiKeyModel) {
        const storageData = {
            'api_key': apikey.apiKey,
            'api_key_expires': apikey.expires.date,
        }
        return JSON.stringify(storageData);
    }
}