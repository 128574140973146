import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environmentDashboard } from 'src/environments/environment';

@Component({
  selector: 'app-historische-aanvragen',
  templateUrl: './historische-aanvragen.component.html',
  styleUrls: ['./historische-aanvragen.component.scss']
})
export class HistorischeAanvragenComponent implements OnInit {
  displayedColumns: string[] = ['id', 'created', 'company', 'person', 'statusType'];

  constructor(
    public dialogRef: MatDialogRef<HistorischeAanvragenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public onYesClick(): void {
    this.dialogRef.close(true);
  }

  public navigateToDashboard(valuationId: number) {
    window.open(environmentDashboard.url + '/dashboard/detail/id/' + valuationId, "_blank");
  }
}
